import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {concatMap, delay, retryWhen} from 'rxjs/operators';


@Injectable()
export class RetryInterceptor implements HttpInterceptor {

    private retryCount = 3;
    private retryWaitMilliSeconds = 700;

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retryWhen(error =>
                error.pipe(
                    concatMap((error, count) => {
                        if (request.method !== 'POST' && count < this.retryCount && (error.status >= 500 || error.status === 0)) {
                            return of(error).pipe(delay(this.retryWaitMilliSeconds * (count + 1)));
                        }
                        return throwError(error);
                    }),
                )
            )
        )
    }
}
