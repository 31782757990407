import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input} from '@angular/core';
import {TranslationService} from "../../../../../../services/root/translation/translation.service";

const customValidators = new FinMatchValidators(APP_DI_CONFIG);

export const FastLanesFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    financingVolume: [null, [Validators.required, customValidators.price()]],
    // description: [null, [Validators.maxLength(500)]],
};

@Component({
    selector: 'app-fast-lane-financing-form',
    templateUrl: './fast-lane-financing-form.component.html',
})
export class FastLaneFinancingFormComponent {
    @Input() form: UntypedFormGroup;
    @Input() readonly = false;
    @Input() currency: string;

    descriptionPlaceholder: string;

    public fieldLabels = this.translationService.moduleFields.fastLane;

    constructor(
        private translationService: TranslationService,
    ) {
        this.descriptionPlaceholder = $localize`Inquiry Description`;
    }
}
