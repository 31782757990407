import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {CookieService} from 'ngx-cookie-service';
import {AuthRepository} from '../../repositories/auth.repository';
import {AuthService} from './auth.service';

export const COOKIE_EXPIRATION_DATE = new Date();
COOKIE_EXPIRATION_DATE.setHours(COOKIE_EXPIRATION_DATE.getHours() + 24);

@Injectable({providedIn: 'root'})
export class AuthServerProvider {
    public token: string;

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig,
        private cookieService: CookieService,
        private authRepository: AuthRepository,
        private authService: AuthService,
    ) {
        const existingToken = this.getToken();
        if (existingToken) {
            this.token = existingToken;
        }

        window.addEventListener('focus', () => {
            if ((this.token || this.getToken()) && (this.getToken() !== this.token)) {
                document.location.reload();
            }
        });
    }

    getToken() {
        return this.cookieService.get(this.config.browserStorageTypes.authenticationToken);
    }

    getTokenFromHeaders(headers) {
        const bearerToken = headers.get('Authorization');
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
            return bearerToken.slice(7, bearerToken.length);
        } else {
            return null;
        }
    }

    login(credentials): Observable<any> {
        return this.authRepository.authenticate(credentials).pipe(map(resp => this.authenticateSuccess(resp)));
    }

    authenticateSuccess(resp) {
        const token = resp.id_token;
        if (token) {
            this.storeAuthenticationToken(token);
            return {
                token: token,
                changePassword: resp.changePassword,
                role: resp.role
            };
        }
    }

    // loginWithToken(jwt, rememberMe) {
    //     if (jwt) {
    //         this.storeAuthenticationToken(jwt, rememberMe);
    //         return Promise.resolve(jwt);
    //     } else {
    //         return Promise.reject('auth-jwt-service Promise reject');
    //     }
    // }

    storeAuthenticationToken(jwt) {
        this.token = jwt;
        this.cookieService.set(this.config.browserStorageTypes.authenticationToken,
            jwt,
            COOKIE_EXPIRATION_DATE,
            '/',
            this.config.general_domain,
            true,
            'Lax');
    }

    logout(): void {
        // if (this.token) {
        //     this.http.post(this.config.API_URL + 'api/logout', {}, {observe: 'response'}).pipe(share()).subscribe();
        // }

        this.token = null;
        // As far as I know the service below was meant to replace this service and principal service.
        // The result is there are currently two ways where the authentication is managed (authService vs principalService and authJwtService).
        // What's below is a just workaround for a bug https://finmatch.atlassian.net/browse/DEV-925,
        // but those service should be unified.
        this.authService.logout();
    }
}
