import {NgModule} from '@angular/core';
import {InquiryRoutingModule} from './inquiry-routing.module';

@NgModule({
    imports: [
        InquiryRoutingModule
    ]
})
export class InquiryModule {
}
