import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsageAgreementDenyDialogComponent} from "./usage-agreement-deny-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

const dependencies = [
    MatDialogModule,
    MatButtonModule
];

@NgModule({
    declarations: [
        UsageAgreementDenyDialogComponent
    ],
    imports: [
        CommonModule,
        ...dependencies,
    ],
    exports: [
        UsageAgreementDenyDialogComponent
    ]
})
export class UsageAgreementDenyDialogModule {
}
