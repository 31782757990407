<form class="finmatch-form" [formGroup]="form" autocomplete="off" [class.readonly]="readonly">
    <div class="form-required-information" *ngIf="!readonly" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form"></app-financing-plans-field>

    <div class="form-row">
        <div class="form-field-100">
            <mat-form-field *ngIf="!readonly">
                <textarea maxlength="500" #message mat-autosize [matAutosizeMaxRows]="10" matInput placeholder="Type of Project" formControlName="buildingDescription" required i18n-placeholder></textarea>
                <mat-hint align="end">{{message.value.length}} / 500</mat-hint>
                <mat-error [errorMessage]="form.get('buildingDescription')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingDescription')" i18n>Type of Project?</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Building</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Building type" formControlName="buildingType" required i18n-placeholder>
                    <ng-container *ngFor="let type of buildingTypes">
                        <mat-option *ngIf="!type.subTypes" [value]="type.value" [innerText]="buildingTypeLabels[type.value]"></mat-option>
                        <mat-optgroup *ngIf="type.subTypes" [label]="buildingTypeLabels[type.value]">
                            <mat-option *ngFor="let subType of type.subTypes" [value]="subType.value" [innerText]="buildingTypeLabels[subType.value]"></mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error [errorMessage]="form.get('buildingType')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingType')" [map]="buildingTypeLabels" i18n>Building type</app-form-field-readonly>
        </div>
        <div class="form-field-50" *ngIf="form.controls.classification.enabled">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Classification" formControlName="classification" required i18n-placeholder>
                    <ng-container *ngFor="let classification of buildingClassificationOptions">
                        <mat-option [value]="classification.value" [innerText]="classification.viewValue"></mat-option>
                    </ng-container>
                </mat-select>
                <mat-error [errorMessage]="form.get('classification')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('classification')" [map]="buildingClassificationOptions" i18n>Classification</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [simple]="true" formControlName="buildingConstructionYear" i18n-placeholder placeholder="Construction year"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingConstructionYear')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingConstructionYear')" format="NONE" i18n>Construction year</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="housingUnitsCount" i18n-placeholder placeholder="Number of housing units" required></app-number-input>
                <mat-error [errorMessage]="form.get('housingUnitsCount')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('housingUnitsCount')" i18n>Number of housing units</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Planned energy efficiency standard" formControlName="efficiencyStandard" required i18n-placeholder>
                    <mat-option *ngFor="let efficiencyStandard of efficiencyStandards" [value]="efficiencyStandard.value" [innerText]="efficiencyStandard.viewValue"></mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('efficiencyStandard')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('efficiencyStandard')" [map]="efficiencyStandards" i18n>Planned energy efficiency standard</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Areas</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="propertyArea" i18n-placeholder placeholder="Property area"></app-number-input>
                <mat-error [errorMessage]="form.get('propertyArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('propertyArea')" i18n suffix="m²">Property area</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="buildingArea" i18n-placeholder placeholder="Building area before the project"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingArea')" i18n suffix="m²">Building area before the project</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="buildingAreaAfterProject" i18n-placeholder placeholder="Building area after the project"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingAreaAfterProject')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingAreaAfterProject')" i18n suffix="m²">Building area after the project</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Costs</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="renovationCosts" i18n-placeholder placeholder="Renovation costs" required></app-number-input>
                <mat-error [errorMessage]="form.get('renovationCosts')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('renovationCosts')" i18n>Renovation costs</app-form-field-readonly>
        </div>
    </div>

    <div class="field-label" *ngIf="!readonly" i18n="Renovation|In the context of renovation module">Is there a mortgage on property and building?</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-radio-group *ngIf="!readonly" formControlName="mortgageOnProperty">
                <mat-radio-button [value]="true" class="radio-option" i18n>Yes</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-option" i18n>No</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('mortgageOnProperty')" i18n="Renovation|In the context of renovation module">Is there a mortgage on property and building?</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="mortgageRemainingDebt" i18n-placeholder placeholder="Remaining debt"></app-number-input>
                <mat-error [errorMessage]="form.get('mortgageRemainingDebt')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('mortgageRemainingDebt')" i18n>Remaining debt</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Leasehold</div>
    <div class="field-label" *ngIf="!readonly" i18n>Is there a leasehold on the property?</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="leaseHoldOnProperty">
                <mat-radio-button [value]="true" class="radio-option" i18n>Yes</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-option" i18n>No</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('leaseHoldOnProperty')" i18n>Is there a leasehold on the property?</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="groundRent" i18n-placeholder placeholder="Ground rent"></app-number-input>
                <mat-error [errorMessage]="form.get('groundRent')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('groundRent')" i18n>Ground rent</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="remainingTermLeaseHold" i18n-placeholder placeholder="Remaining term leasehold"></app-number-input>
                <mat-error [errorMessage]="form.get('remainingTermLeaseHold')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('remainingTermLeaseHold')" i18n>Remaining term leasehold</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Location</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Country" formControlName="country" required i18n-placeholder>
                    <mat-option *ngFor="let country of countries" [value]="country.value" [innerText]="country.viewValue"></mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('country')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('country')" [map]="countries" i18n>Country</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input type="text" matInput placeholder="Street" formControlName="streetAddress" i18n-placeholder>
                <mat-error [errorMessage]="form.get('streetAddress')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('streetAddress')" i18n>Street</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input type="text" matInput placeholder="House number" formControlName="houseNumber" i18n-placeholder>
                <mat-error [errorMessage]="form.get('houseNumber')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('houseNumber')" i18n>House number</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input matInput placeholder="Postcode" formControlName="postalCode" i18n-placeholder>
                <mat-error [errorMessage]="form.get('postalCode')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('postalCode')" i18n>Postcode</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input type="text" matInput placeholder="City" formControlName="city" required i18n-placeholder>
                <mat-error [errorMessage]="form.get('city')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('city')" i18n>City</app-form-field-readonly>
        </div>
    </div>
</form>
