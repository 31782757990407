import {Router} from '@angular/router';
import {Inject, Injectable} from '@angular/core';
import {SessionStorageService} from 'ngx-webstorage';
import {APP_CONFIG, AppConfig} from '../../app-config.module';

@Injectable({providedIn: 'root'})
export class StateStorageService {
    constructor(
        private $sessionStorage: SessionStorageService,
        @Inject(APP_CONFIG) public config: AppConfig,
        public router: Router,
    ) {
    }

    getPreviousState() {
        return this.$sessionStorage.retrieve(this.config.browserStorageTypes.previousUrlState);
    }

    resetPreviousState() {
        this.$sessionStorage.clear(this.config.browserStorageTypes.previousUrlState);
    }

    storePreviousState(previousStateName, previousStateParams) {
        const previousState = {name: previousStateName, params: previousStateParams};
        this.$sessionStorage.store(this.config.browserStorageTypes.previousUrlState, previousState);
    }

    getDestinationState() {
        return this.$sessionStorage.retrieve(this.config.browserStorageTypes.destinationUrlState);
    }

    storeUrl(url: string) {
        if (!url) {
            sessionStorage.removeItem(this.config.browserStorageTypes.previousUrlState);
        } else {
            sessionStorage.setItem(this.config.browserStorageTypes.previousUrlState, url);
        }
    }

    getUrl() {
        return sessionStorage.getItem(this.config.browserStorageTypes.previousUrlState);
    }

    storeDestinationState(destinationState, destinationStateParams, fromState) {
        const destinationInfo = {
            destination: {
                name: destinationState.name,
                data: destinationState.data
            },
            params: destinationStateParams,
            from: {
                name: fromState.name
            }
        };
        this.$sessionStorage.store(this.config.browserStorageTypes.destinationUrlState, destinationInfo);
    }

    goBack(goToPage: string = 'inquiry/list', $event?) {
        if ($event) {
            $event.preventDefault();
        }

        const redirect = this.getUrl();
        if (redirect) {
            this.storeUrl(null);
            this.router.navigateByUrl(redirect);
        } else {
            this.router.navigateByUrl(goToPage);
        }
    }
}
