import {Inject, Injectable} from '@angular/core';
import {AccountRepository} from '../../repositories/account.repository';
import {FinMatchAccount} from '../../models/finmatch-account.model';
import {AppStateService} from '../root/app-state.service';
import {ReplaySubject} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {CookieService} from 'ngx-cookie-service';
import {AES, enc} from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class AuthService { // This is not used yet but is planned to replace the existing auth services
    private _userData: FinMatchAccount = null;
    private _userData$ = new ReplaySubject<FinMatchAccount>(1);

    constructor(private authRepository: AccountRepository,
                private appStateService: AppStateService,
                @Inject(APP_CONFIG) private config: AppConfig,
                private cookieService: CookieService) {
    }

    private _token: string = null;

    get token() {
        if (!!this._token) {
            return this._token;
        }
        this._token = this.cookieService.get(this.config.browserStorageTypes.authenticationToken);
        return this._token;
    }

    get user(): FinMatchAccount {
        return this._userData;
    }

    get isAuthenticated(): boolean {
        return !!this.user;
    }

    get user$(): ReplaySubject<FinMatchAccount> {
        return this._userData$;
    }

    get termsAccepted(): boolean {
        return !!this.user && this.user.approvalTerms && this.user.privacyPolicy;
    }

    hasRole(role: string): boolean {
        return this.isAuthenticated && this.user.hasRole(role);
    }

    hasAnyRole(roles: string[]): boolean {
        return this.isAuthenticated
            && this.user.authorities.some((role) => roles.includes(role));
    }

    logout() {
        this._userData = null;
        this._userData$.next(null);
        this._token = null;
        this.cookieService.delete(this.config.browserStorageTypes.authenticationToken, '/', this.config.general_domain);
    }

    loadUser(): Promise<FinMatchAccount> {
        return new Promise((resolve, reject) => {
            this.appStateService.showGlobalSpinner();
            this.authRepository.getAccountData().subscribe({
                next: (account) => {
                    this._userData = account;
                    this._userData$.next(account);
                    resolve(account);
                },
                error: (err) => reject(err)
            }).add(() => this.appStateService.hideGlobalSpinner());
        });
    }

    encryptPassword(password) {
        const key = enc.Base64.parse(this.config.key);
        const iv = enc.Base64.parse(this.config.iv);
        return AES.encrypt(password, key, {iv}).toString();
    }
}
