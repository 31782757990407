import { HttpClient, HttpResponse } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, Subject, switchMap, of} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {getMachineExpertModeFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/machine-expert-form/machine-expert-form.component';
import {MachineQuickModeFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/machine-quick-form/machine-quick-form.component';
import {OthersFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/other-financing-form/other-financing-form.component';
import {FastLanesFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/fast-lane-financing-form/fast-lane-financing-form.component';
import {PropertyPurchaseFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/property-purchase-form/property-purchase-form.component';
import {RealEstateExistingBuildingQuickFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/real-estate-existing-building-quick-form/real-estate-existing-building-quick-form.component';
import {RealEstateNewBuildingQuickFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/real-estate-new-building-quick-form/real-estate-new-building-quick-form.component';
import {WorkingCapitalFormGroup} from 'src/app/modules/shared/components/inquiry/inquiry-forms/working-capital-form/working-capital-form.component';
import {ConfirmationDialogService} from 'src/app/services/root/confirmation-dialog.service';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {ERROR_INTERCEPTOR_SKIP_HEADER} from '../../interceptors/errorhandler.interceptor';
import {DocumentFile} from '../../models/document-file.model';
import {Agreement, Inquiry, InquiryModuleKey, MandateSettings} from '../../models/InquiryModel';
import {PropertyFollowupFinacingFormGroup} from '../../modules/shared/components/inquiry/inquiry-forms/property-followup-financing-form/property-followup-financing-form.component';
import {RealEstateExistingBuildingExpertFormGroup} from '../../modules/shared/components/inquiry/inquiry-forms/real-estate-existing-building-expert-form/real-estate-existing-building-expert-form.component';
import {RealEstateFollowupFinancingFormGroup} from '../../modules/shared/components/inquiry/inquiry-forms/real-estate-followup-financing-form/real-estate-followup-financing-form.component';
import {RealEstateNewBuildingExpertFormGroup} from '../../modules/shared/components/inquiry/inquiry-forms/real-estate-new-building-expert-form/real-estate-new-building-expert-form.component';
import {RealEstateRenovationFormGroup} from '../../modules/shared/components/inquiry/inquiry-forms/real-estate-renovation-form/real-estate-renovation-form.component';
import {VehicleFormGroup} from '../../modules/shared/components/inquiry/inquiry-forms/vehicle-form/vehicle-form.component';
import {Principal} from '../auth/principal.service';
import {TranslationService} from '../root/translation/translation.service';
import {InquiryOverdueLimits, RealEstateModuleType} from './inquiry-configuration.service';

@Injectable({providedIn: 'root'})
export class InquiryService {
    inquiryRejectedEvent$ = new Subject<void>();
    showSuccessfullyFinanced = false;

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig,
        private principal: Principal,
        public confirmationDialog: ConfirmationDialogService,
        public translationService: TranslationService,
    ) {}

    public getInquiries(sortParam: string, size = 1000000, page = 0, headerOnly = false): Observable<any> {
        const options: any = {observe: 'response', params: {size: size, page: page}};
        if (sortParam) {
            options.params.sort = sortParam;
        }
        if (headerOnly) {
            options.params.extractOnly = 'true';
        }
        return this.http.get<any>(this.config.API_URL + 'inquiry/api/inquiries', options);
    }


    public getInquiry(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http.get<any>(this.config.API_URL + `inquiry/api/inquiries/${inquiryId}`,
            {observe: 'response', headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'}}
        ).pipe(
            switchMap(response => {
                // response.body.inquiry.fastLaneMode = true;  // mock
                // response.body.inquiry.fastLanes =  [{  // mock
                //     "id" : "648b16631ccfc64949eb3eb6_other_0",
                //     "financingPlans" : "fsdfsdf",
                //     "financingVolume" : 42343.0,
                //     "documents" : [ {
                //         "documentType" : "DOCUMENT",
                //         "documentHash" : "0e4a186512ae0fb952210fea5144e106",
                //         "documentName" : "Finmatch-retest-report[1].pdf",
                //         "documentSize" : 3386446
                //     }]
                // }];

                return of(response);
            })
        );
    }

    public saveInquiry(inquiry: any): Observable<HttpResponse<any>> {
        if (inquiry.id) {
            return this.http
                .put(this.config.API_URL + 'inquiry/api/inquiries', inquiry, {observe: 'response'})
                .pipe(share());
        } else {
            return this.http
                .post(this.config.API_URL + 'inquiry/api/inquiries', inquiry, {observe: 'response'})
                .pipe(share());
        }
    }

    public markInquiryAsSeen(inquiryId: string) {
        return this.http.post(`${this.config.API_URL}inquiry/api/inquiries/${inquiryId}/user-display`, {});
    }

    public saveInquirySummaryDocuments(inquiry: any, summaryDocuments: DocumentFile[]): Observable<DocumentFile[]> {
        return this.http.put<any>(this.config.API_URL + this.config.API_URLs.summaryDocuments, {
            inquiryId: inquiry.id,
            summaryDocuments
        }).pipe(map(response => response.summaryDocuments));
    }

    public sendInquiry(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http
            .post(this.config.API_URL + 'inquiry/api/inquiry/send', {inquiryId}, {observe: 'response'})
            .pipe(share());
    }

    public assignInquiry(inquiryId: string, userId?: string): Observable<HttpResponse<any>> {
        const body = userId ? {userId} : null;
        return this.http
            .post(this.config.API_URL + `inquiry/api/inquiries/${inquiryId}/assignee`, body, {observe: 'response'});
    }

    public assignInquiryToBroker(inquiryId: string, userId: string): Observable<HttpResponse<any>> {
        return this.http
            .put(this.config.API_URL + 'inquiry/api/inquiry/assign', {inquiryId, userId}, {observe: 'response'})
            .pipe(share());
    }

    public acceptInquiry(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http
            .post(this.config.API_URL + 'inquiry/api/inquiry/accept', {inquiryId}, {observe: 'response'})
            .pipe(share());
    }

    public cancelInquiry(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http
            .post(this.config.API_URL + 'inquiry/api/inquiry/cancel-inquiry', {inquiryId}, {observe: 'response'})
            .pipe(share());
    }

    public deleteInquiry(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http
            .delete(this.config.API_URL + `inquiry/api/inquiry/${inquiryId}`, {observe: 'response'})
            .pipe(share());
    }

    public rejectInquiry(inquiryId: string, reasons, reasonsTranslated, reasonOther, documents: DocumentFile[] = [], bankId: string = null): Observable<HttpResponse<any>> {
        const payload: any = {
            reasons: reasons || null,
            reasonsTranslated: reasonsTranslated || null,
            reason: reasonOther || null,
            documents: documents || [],
        };
        if (bankId) {
            payload.bankId = bankId;
        }
        return this.http
            .post(
                `${this.config.API_URL}${this.config.API_URLs.rejectInquiries}/${inquiryId}`,
                payload,
                {observe: 'response'}
            ).pipe(
                tap(() => this.inquiryRejectedEvent$.next()),
                share()
            );
    }

    // not in use
    // public getInquiriesWithoutAgreement(): Observable<InquiryOtua[]> {
    //     return this.http.get<{ data: InquiryOtua[] }>(this.config.API_URL + 'inquiry/api/inquiries', {
    //         params: {
    //             agreementNeeded: 'yes',
    //             size: '100',
    //         },
    //         observe: 'body'
    //     }).pipe(
    //         map(responseBody => responseBody.data)
    //     );
    // }

    public getInquiryAgreements(inquiryId: string): Observable<Agreement[]> {
        return this.http.get<Agreement[]>(this.config.API_URL + 'inquiry/api/inquiries/' + inquiryId + '/bank-agreements');
    }

    public setMandateType(inquiryId: string, mandateSettings: MandateSettings): Observable<HttpResponse<any>> {
        return this.http.put(`${this.config.API_URL}inquiry/api/inquiries/${inquiryId}/settings`, mandateSettings, {observe: 'response'});
    }

    public getMandateType(inquiryId: string): Observable<MandateSettings> {
        return this.http.get<MandateSettings>(`${this.config.API_URL}inquiry/api/inquiries/${inquiryId}/settings`);
    }

    public reviseInquiry(inquiryId: string, reason?: string, documents?: DocumentFile[]): Observable<HttpResponse<any>> {
        const postData: {inquiryId: string, reason?: string, documents?: DocumentFile[]} = {
            inquiryId: inquiryId
        };
        if (reason) {
            postData.reason = reason;
        }
        if (documents?.length) {
            postData.documents = documents;
        }
        return this.http
            .post(this.config.API_URL + 'inquiry/api/inquiry/revise', postData, {observe: 'response'})
            .pipe(share());
    }

    public closeInquiry(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http
            .post(this.config.API_URL + 'inquiry/api/inquiry/close', {inquiryId}, {observe: 'response'})
            .pipe(share());
    }

    public reOpenInquiry(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http.post(this.config.API_URL + 'inquiry/api/inquiry/reopen', {inquiryId}, {observe: 'response'}).pipe(share());
    }

    public confirmInquiryOffers(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http
            .post(this.config.API_URL + 'inquiry/api/inquiry/confirm-offers', {inquiryId}, {observe: 'response'})
            .pipe(share());
    }

    public returnInquiryOffersToCompany(inquiryId: string): Observable<HttpResponse<any>> {
        return this.http
            .post(this.config.API_URL + 'inquiry/api/inquiry/return-offers', {inquiryId}, {observe: 'response'})
            .pipe(share());
    }

    public isOverdue(inquiry: Inquiry): boolean {
        const limits = InquiryOverdueLimits;
        const inquiryState = inquiry.getInternalState();

        if (typeof limits[inquiryState] === 'undefined') {
            return false;
        }

        let statusChangedAt;
        if (inquiry.inquiryState === 'DRAFT' || inquiry.inquiryState === 'REVISION') {
            statusChangedAt = new Date(inquiry.lastModifiedDate || inquiry.createdDate);
        } else {
            statusChangedAt = new Date(inquiry.stateChangedAt || inquiry.createdDate);
        }

        const currentTime = new Date();
        const timeDifference = currentTime.getTime() - statusChangedAt.getTime();

        return (timeDifference >= (limits[inquiryState] * 1000));
    }

    public openSendConfirmationDialog(): Observable<boolean> {
        return this.confirmationDialog.open({
            modalHeader: this.translationService.inquiry.sendConfirmationHeader,
            confirmationMessage: this.translationService.inquiry.sendConfirmation
        });
    }

    public openSendConfirmationDialogWFinancingMismatch(): Observable<boolean> {
        return this.confirmationDialog.open({
            modalHeader: this.translationService.inquiry.financingParametersConfirmation,
            confirmationMessage: this.translationService.inquiry.financingParametersMessage,
            confirmationYesButton: $localize`Send inquiry`,
            confirmationNoButton: $localize`Edit inquiry`,
        });
    }

    public openAcceptWithoutDocumentsConfirmationDialog(): Observable<boolean> {
        return this.confirmationDialog.open({
            modalHeader: this.translationService.inquiry.acceptConfirmationHeader,
            confirmationMessage: this.translationService.inquiry.acceptWithoutDocumentsUploadedConfirmation
        });
    }

    public openReviseConfirmationDialog(): Observable<boolean> {
        return this.confirmationDialog.open({
            modalHeader: this.translationService.inquiry.reviseConfirmationHeader,
            confirmationMessage: this.translationService.inquiry.reviseConfirmation
        });
    }

    public getItemFormGroup(inquiry: Inquiry, moduleKey: InquiryModuleKey): any {
        const moduleMode = inquiry.getModuleMode(moduleKey);
        const moduleType = inquiry.getModuleType(moduleKey);

        switch (true) {
            case (moduleKey === 'machine' && moduleMode === 'QUICK'):
                return MachineQuickModeFormGroup;
            case (moduleKey === 'machine' && moduleMode === 'EXPERT'):
                return getMachineExpertModeFormGroup(inquiry);

            case (moduleKey === 'vehicle'):
                return VehicleFormGroup;

            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.NEW_BUILDING && moduleMode === 'QUICK'):
                return RealEstateNewBuildingQuickFormGroup;
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.NEW_BUILDING && moduleMode === 'EXPERT'):
                return RealEstateNewBuildingExpertFormGroup;
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.EXISTING_BUILDING && moduleMode === 'QUICK'):
                return RealEstateExistingBuildingQuickFormGroup;
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.EXISTING_BUILDING && moduleMode === 'EXPERT'):
                return RealEstateExistingBuildingExpertFormGroup;
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.RENOVATION):
                return RealEstateRenovationFormGroup;
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.FOLLOWUP_FINANCING):
                return RealEstateFollowupFinancingFormGroup;

            case (moduleKey === 'property' && moduleType === 'PURCHASE'):
                return PropertyPurchaseFormGroup;
            case (moduleKey === 'property' && moduleType === 'FOLLOWUP_FINANCING'):
                return PropertyFollowupFinacingFormGroup;

            case (moduleKey === 'workingCapital'):
                return WorkingCapitalFormGroup;

            case (moduleKey === 'other'):
                return OthersFormGroup;

            case (moduleKey === 'fastLane'):
                return FastLanesFormGroup;
        }
    }

}
