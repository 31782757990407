import {Inject} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {ConfirmationDialogService} from '../services/root/confirmation-dialog.service';

export class UiVersionOutdatedInterceptor implements HttpInterceptor {
    private messageShown = false;
    private uiVersion: string;

    constructor(
        private confirmationDialogService: ConfirmationDialogService,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        const uiVersion = event.headers.get('ui-version');
                        if (!uiVersion) return;
                        if (!this.uiVersion) this.uiVersion = uiVersion;

                        if (uiVersion !== this.uiVersion && !this.messageShown) {
                            this.messageShown = true;
                            this.confirmationDialogService.open({
                                confirmationMessage: $localize`We have updated the portal. Please reload the page!`,
                                confirmationYesButton: $localize`Reload`,
                                confirmationNoButton: $localize`Cancel`,
                            }).subscribe(reload => {
                                if (reload) {
                                    window.location.reload();
                                }
                            });
                        }
                    }
                }
            )
        );
    }
}
