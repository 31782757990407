import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InquiryLayoutComponent} from "./inquiry-layout.component";
import {RouterModule} from "@angular/router";

const dependencies = [
    RouterModule
];

@NgModule({
    declarations: [
        InquiryLayoutComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        InquiryLayoutComponent
    ]
})
export class InquiryLayoutModule {
}
