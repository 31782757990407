import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CookieConsentComponent} from "./cookie-consent.component";

const dependencies = [];

@NgModule({
    declarations: [
        CookieConsentComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        CookieConsentComponent
    ]
})
export class CookieConsentModule {
}
