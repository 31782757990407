import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonSpinnerComponent} from "./common-spinner.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";

const dependencies = [
    MatProgressSpinnerModule,
    MatProgressBarModule
];

@NgModule({
    declarations: [
        CommonSpinnerComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        CommonSpinnerComponent
    ]
})
export class CommonSpinnerModule {
}
