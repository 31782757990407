import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OneTimeInquiriesDialogComponent} from "./one-time-inquiries-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

const dependencies = [
    MatDialogModule,
    MatButtonModule
];

@NgModule({
    declarations: [
        OneTimeInquiriesDialogComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        OneTimeInquiriesDialogComponent
    ]
})
export class OneTimeInquiriesDialogModule {
}
