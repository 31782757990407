import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppStateService {
    private globalSpinnerShown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    showGlobalSpinner() {
        this.globalSpinnerShown.next(true);
    }
    hideGlobalSpinner() {
        this.globalSpinnerShown.next(false);
    }
    getGlobalSpinnerState(): BehaviorSubject<boolean> {
        return this.globalSpinnerShown;
    }
}
