import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupportComponent} from "./support.component";
import {MatIconModule} from "@angular/material/icon";
import {NgScrollbarModule} from "ngx-scrollbar";
import {CommonSpinnerModule} from "../common-spinner/common-spinner.module";
import {MatTabsModule} from "@angular/material/tabs";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ErrorMessageModule} from "../../directives/error-message/error-message.module";
import {MatInputModule} from "@angular/material/input";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButtonModule} from "@angular/material/button";

const dependencies = [
    MatIconModule,
    NgScrollbarModule,
    CommonSpinnerModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ErrorMessageModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatButtonModule
];

@NgModule({
    declarations: [
        SupportComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        SupportComponent
    ]
})
export class SupportModule {
}
