import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {LabelsMap, TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {BuildingType} from '../../../../../../models/InquiryModel';
import {BuildingTypesQuickMode} from 'src/app/services/inquiry/inquiry-configuration.service';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const RealEstateNewBuildingQuickFormGroup = {
    id: [],
    buildingArea: [null, [customValidators.area7]],
    buildingType: [null, [Validators.required]],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    country: ['GERMANY', [Validators.required]],
    houseNumber: [null, [Validators.maxLength(5)]],
    postalCode: [null, [Validators.max(99999)]],
    propertyArea: [null, [customValidators.area9]],
    streetAddress: [null, [Validators.maxLength(100)]],
    totalSalesPrice: [null, [Validators.required, customValidators.price()]],
};

@Component({
    selector: 'app-real-estate-new-building-quick-form',
    templateUrl: './real-estate-new-building-quick-form.component.html',
    styleUrls: ['./real-estate-new-building-quick-form.component.less']
})
export class RealEstateNewBuildingQuickFormComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() currency: string;

    public buildingTypes: Array<BuildingType>;
    public buildingTypeLabels: LabelsMap;
    public countries: any[] = [];

    constructor(
        public translationService: TranslationService,
    ) {
        this.buildingTypes = BuildingTypesQuickMode;
        this.buildingTypeLabels = this.translationService.inquiryBuildingTypesQuickMode;

        this.countries = this.translationService.inquiryRealEstateCountries;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateCountryRelatedFields();
        }
    }

    ngOnInit() {
        this.form.get('country').valueChanges.subscribe(() => this.updateCountryRelatedFields());
    }

    public updateCountryRelatedFields(): void {
        let country = this.form.get('country').value;

        let postCodeValidator = (country === 'GERMANY') ? customValidators.postalCodeOptionalValidator5 : customValidators.postalCodeOptionalValidator4;
        this.form.get('postalCode').setValidators([postCodeValidator]);
        this.form.get('postalCode').updateValueAndValidity();
    }

}
