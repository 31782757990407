<mat-toolbar class="bottom-nav">
    <div>
        <img class="logo" src="../../../../../../assets/images/ssl-certificate.png" alt="SSL Certificate">
    </div>
    <div class="footer-menu">
        <a href="{{config.landing_URL + 'agb'}}" i18n="Footer|Footer">Terms of Use</a>
        <a href="{{config.landing_URL + 'impressum'}}" i18n>Legal Notice</a>
        <a href="{{config.landing_URL + 'datenschutz'}}" i18n="Footer|Footer">Privacy Policy</a>
    </div>
    <div class="footer-menu">
        <a href="{{config.landing_URL + 'news/#press'}}" i18n="Footer|Footer">Press</a>
        <a href="{{config.landing_URL + 'jobs'}}" i18n>Career</a>
        <a href="{{config.landing_URL + 'kontakt-standorte'}}" i18n>Locations</a>
    </div>
    <div class="footer-menu" style="display: block; font-size: 16px; margin-bottom: -19px">
        <div style="">Folge uns auf</div>
        <a class="social-icon icon-ln" href="https://www.linkedin.com/company/finmatch-ag" target="_blank"></a>
        <a class="social-icon icon-xing" href="https://www.xing.com/pages/finmatchag" target="_blank"></a>
    </div>
</mat-toolbar>
<mat-toolbar class="bottom-nav copyright">
    Copyright © 2022 — FinMatch AG. All Rights Reserved
</mat-toolbar>
<div class="app-ver">{{appVer}}</div>

<!--<div class="address">-->
<!--    <p>FinMatch AG</p>-->
<!--    <p>Löffelstraße 4, 70597 Stuttgart</p>-->
<!--</div>-->
<!--<div class="links">-->
<!--    <a href="{{config.landing_URL + 'news/#press'}}" i18n>Press</a>-->
<!--    <a href="{{config.landing_URL + 'impressum'}}" i18n>Imprint</a>-->
<!--    <a href="{{config.landing_URL + 'datenschutz'}}" i18n="@@DataProtectionMenu">Data Protection</a>-->
<!--    <a href="{{config.landing_URL + 'kontakt-standorte'}}" i18n>Contact</a>-->
<!--</div>-->
<!--<div class="copyrights">&#169; FinMatch AG {{ date | date: 'yyyy' }}</div>-->
<!--<div class="social-network-icons">-->
<!--    <div class="wrapper">-->
<!--        <a class="icon" href="https://www.linkedin.com/company/finmatch-ag/" target="_blank" inlineSVG="../../assets/icons/LinkedIn.svg"></a>-->
<!--    </div>-->
<!--</div>-->
