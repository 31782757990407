import {Injectable, InjectionToken, NgModule} from '@angular/core';
import {environment} from '../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

@Injectable()
export class AppConfig {
    applicationTitle: string;
    version: string;
    envName: string;
    API_URL: string;
    landing_URL: string;
    general_domain: string;
    API_URLs: {
        dashboard: string;
        admin: string;
        corporates: string;
        banks: string;
        brokers: string;
        orgUnits: string;
        users: string; // update user from any user list
        account: string; // update logged user profile
        auth: string;
        inquiry: string;
        summaryDocuments: string;
        financingBanks: string;
        multiInquiriesFinancingBanks: string;
        selectedBanks: string;
        bankSelectionInquiries: string;
        rejectInquiries: string;
        markInquirySeenByBank: string;
        msgChannel: string;
        docupload: string;
        document: string;
        profilePhotos: string;
        bankOffers: string;
        bankOfferInquiries: string;
        bankOfferInquiriesOffers: string;
        support: string;
        states: string;
        suggestedBanks: string;
        matchingBanks: string;
        ratings: string;
    };
    eventTypes: any;
    regexPatterns: any;
    currencySuffix: { [currency: string]: string };
    browserStorageTypes: any;
    appRequirements: any;
    allowedContentTypes: Array<string>;
    maximumUploadSize: number;
    allowedFileExtensions: Array<string>;
    isQuickModeDisabled: boolean;
    key: string;
    iv: string;
}

export const MAXIMUM_UPLOAD_SIZE_MB = 25;

export const APP_DI_CONFIG: AppConfig = {
    applicationTitle: 'FinMATCH',
    version: environment.version,
    envName: environment.envName,
    API_URL: environment.API_URL,
    landing_URL: environment.landing_URL,
    general_domain: environment.general_domain,
    API_URLs: {
        dashboard: 'api/dashboard',
        admin: 'api/admin',
        corporates: 'api/corporates',
        brokers: 'api/brokers',
        banks: 'api/banks',
        orgUnits: 'api/banks/units',
        users: 'api/users',
        account: 'api/account',
        inquiry: 'inquiry/api/inquiries',
        summaryDocuments: 'inquiry/api/inquiry/summary-documents',
        financingBanks: 'bankselection/api/inquiry-financing-banks',
        multiInquiriesFinancingBanks: 'bankselection/api/inquiries/financing-banks',
        selectedBanks: 'bankselection/api/inquiry-selected-banks',
        bankSelectionInquiries: 'bankselection/api/inquiries',
        rejectInquiries: 'bankselection/api/inquiry-rejected-by-bank',
        markInquirySeenByBank: 'bankselection/api/inquiry-seen-by-bank',
        msgChannel: 'msgchannel/api/channel/inquiry/all',
        docupload: 'docupload',
        document: 'docupload/api/document-files/file/',
        profilePhotos: '/profile-photos/',
        bankOffers: 'bankoffers/api/offers',
        bankOfferInquiries: 'bankoffers/api/inquiries',
        bankOfferInquiriesOffers: 'bankoffers/api/inquiries/offers',
        auth: '',
        support: 'support/api',
        states: 'api/states',
        suggestedBanks: 'bankselection/api/suggestedBanks',
        matchingBanks: 'bankselection/api/matchingResults',
        ratings: 'api/ratings',
    },
    eventTypes: {
        authenticationSuccess: 'authenticationSuccess',
        logout: 'logout',
        gatewayError: 'gatewayError'
    },
    regexPatterns: {
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\da-zA-Z]).{10,128}$/,
        // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\da-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\=\-[\]{};\'’\\:|,.\/<>?`~]+$/,
        email: /^[_.@A-Za-z0-9-+]*$/,
        // email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
    },
    currencySuffix: {
        EUR: '€',
        CHF: 'CHF',
        DEFAULT: '€'
    },
    browserStorageTypes: {
        authenticationToken: 'finMatch_authenticationToken',
        idleCookie: 'finMatch_idle',
        previousUrlState: 'finMatch|previousUrl',
        destinationUrlState: 'finMatch|destinationState',
        sessionTimeoutExpiry: 'ng2Idle.main.expiry',
        manualAutoOpen: 'finMatch_inquiryManualAutoOpen',
        sessionMessageKey: 'finMatch_unsupportedDeviceSeen',
        siteVisitedCookie: 'finmatch_cookies'
    },
    appRequirements: {
        supportedBrowsers: ['chrome', 'firefox', 'ms-edge-chromium', 'safari'],
        minViewportWidth: 1300
    },
    allowedContentTypes: [
        'image/png',
        'image/jpeg',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.ms-powerpoint',
        'text/plain',
        'application/pdf',
    ],
    maximumUploadSize: MAXIMUM_UPLOAD_SIZE_MB * 1024 * 1024,
    allowedFileExtensions: [
        'jpg',
        'xlsx',
        'png',
        'docx',
        'pptx',
        'txt',
        'pdf',
        'ppt',
        'doc',
        'jpeg',
        'xls'
    ],
    isQuickModeDisabled: true,
    key: '+VAqp1LJwAAGEHHZ55aDBQ==',
    iv: 'wsiqF8CL8VmuqXHwVL+E1g==',
};

@NgModule({
    providers: [
        {
            provide: APP_CONFIG,
            useValue: APP_DI_CONFIG
        }
    ]
})
export class AppConfigModule { }
