import {ChangeDetectorRef, Component, HostListener, Inject, NgZone, Renderer2} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router, RoutesRecognized} from '@angular/router';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {CookieService} from 'ngx-cookie-service';
import {distinctUntilChanged, filter, map, take} from 'rxjs/operators';
import {FinMatchAccount} from 'src/app/models/finmatch-account.model';
import {APP_CONFIG, AppConfig} from './app-config.module';
import {AppStateService} from './services/root/app-state.service';
import {Principal} from './services/auth/principal.service';
import {UsageAgreementService} from './services/bank/usage-agreement.service';
import {EventManagerService} from './services/event-manager.service';
import {LoginService} from './services/login/login.service';
import {environment} from "../environments/environment";
import * as introJs from 'intro.js/intro.js';
import {ConfirmationDialogService} from "./services/root/confirmation-dialog.service";
import {combineLatest, Subscription} from 'rxjs';
import {UnsavedChangesGuard} from "./guards/unsaved-changes.guard";
import { KeycloakService } from 'keycloak-angular';

export const COOKIE_EXPIRATION_DATE = new Date();
COOKIE_EXPIRATION_DATE.setFullYear(COOKIE_EXPIRATION_DATE.getFullYear() + 1);

export const DIRTY_FORM_SELECTOR = 'form.ng-dirty:not(.no-leave-warning)';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {

    timeoutSet: number;
    title = 'FinMatch';
    idleState = 'Logged out.';
    timedOut = false;
    lastPing?: Date = null;
    isLoggedIn: any;
    globalSpinnerShown: boolean;
    isSessionIdleInitialized: boolean = false;
    // firstVisit = true;
    appVer: string = null;
    // STATIC PAGES - UNCOMMENT IF NEEDED
    loggedUser: FinMatchAccount;
    introJS = introJs();
    tutorialSubscription: Subscription;

    constructor(
        private idle: Idle,
        private keepalive: Keepalive,
        private cd: ChangeDetectorRef,
        private eventManager: EventManagerService,
        @Inject(APP_CONFIG) public config: AppConfig,
        private loginService: LoginService,
        private principal: Principal,
        private router: Router,
        private appState: AppStateService,
        private cookieService: CookieService,
        private ngZone: NgZone,
        private dialog: MatDialog,
        private usageAgreementService: UsageAgreementService,
        private confirmationDialogService: ConfirmationDialogService,
        private renderer: Renderer2,
        private unsavedChangesGuard: UnsavedChangesGuard,
        private keycloakService: KeycloakService
    ) {
        this.registerChunkLifebuoy();

        this.appVer = this.config.version;
        this.eventManager.subscribe(this.config.eventTypes['logout'], data => {
            idle.stop();
            this.idleState = 'Logged out.';
            this.isSessionIdleInitialized = false;
            if (data.isForced) {
                this.ngZone.run(() => {
                    this.keycloakService.logout("/login-timeout");
                });
            }

            this.dialog.closeAll();
        });

        if (
            localStorage.getItem(this.config.browserStorageTypes.sessionTimeoutExpiry) &&
            +localStorage.getItem(this.config.browserStorageTypes.sessionTimeoutExpiry) - new Date().valueOf() < 0
        ) {
            this.idleState = 'Logging out';
            this.timedOut = true;
            idle.stop();
            this.idleState = 'Logged out.';
            this.isSessionIdleInitialized = false;
            this.ngZone.run(() => {
                    this.keycloakService.logout("/login-timeout");
                    // this.router.navigate(['login-timeout']);
                // window.location.href = environment.keycloak.issuer + "realms/" + environment.keycloak.realm + "/protocol/openid-connect/logout";
            });

            this.cookieService.delete(this.config.browserStorageTypes.authenticationToken, '/', this.config.general_domain);
            this.principal.authenticate(null);
        }

        this.tutorialSubscription = combineLatest([
            this.principal.getAuthenticationState().pipe(
                distinctUntilChanged((prev, curr) => (prev || {}).id === (curr || {}).id),
            ),
            this.router.events.pipe(
                filter(event => event instanceof RoutesRecognized),
                map(event => (event as RoutesRecognized).state.root.firstChild.data.skipTutorial)
            )
        ]).subscribe(([user, shouldRouteSkipTutorial]) => {
            if (
                user?.activated
                && user.isCompany()
                && !user.hasTutorialDone()
                && !shouldRouteSkipTutorial
            ) {
                this.tutorialSubscription.unsubscribe();
                this.runTutorial();
            }
        });

        this.principal.getAuthenticationState().pipe(
            distinctUntilChanged((prev, curr) => (prev || {}).id === (curr || {}).id),
        ).subscribe(subject => {
            this.loggedUser = subject;
            environment.sentryDsn && (window as any).Sentry && (window as any).Sentry.configureScope(function (scope) {
                scope.setUser({id: subject ? subject.id : null, companyId: subject ? subject.companyId : null})
            });

            if (subject && !this.isSessionIdleInitialized) {
                this.isSessionIdleInitialized = true;
                const timeout = subject.authorities.some(x => x === 'ROLE_FINMATCH') ? 60 : 20;
                this.setupIdle(timeout);
            }
        });

        this.appState.getGlobalSpinnerState().subscribe(state => {
            setTimeout(() => {
                this.globalSpinnerShown = state;
            });
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    public unloadAppHandler($event) {
        if (this.unsavedChangesGuard.hasPageUnsavedChanges()) {
            $event.returnValue = ' ';
            return ' ';
        }
    }

    setupIdle(timeout: number = 20) {
        // sets an idle timeout of 5 seconds, for testing purposes.
        this.idle.setIdle(3);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(timeout * 60);
        this.timeoutSet = timeout;
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.idleState = `Session: ${this.timeoutSet}:00`;
            this.cd.detectChanges();
        });
        this.idle.onTimeout.subscribe(() => {
            this.idleState = 'Logging out';
            this.timedOut = true;
            this.cd.detectChanges();
            this.isSessionIdleInitialized = false;
            this.loginService.logout(true);
        });
        this.idle.onIdleStart.subscribe(() => {
            this.idleState = `Session: ${this.timeoutSet}:00`;
            this.cd.detectChanges();
        });
        this.idle.onTimeoutWarning.subscribe(countdown => {
            let countdownMinutes: any = countdown % 60;
            countdownMinutes = countdownMinutes > 9 ? countdownMinutes : '0' + countdownMinutes;

            let countdownHours: any = Math.floor(countdown / 60);
            countdownHours = countdownHours > 9 ? countdownHours : '0' + countdownHours;

            this.idleState = 'Session: ' + countdownHours + ':' + countdownMinutes;
            // this.idleState = 'Session: ' + countdownHours + ' min';
        });

        // sets the ping interval to 15 seconds
        // keepalive.interval(15);
        // keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

        this.reset();
    }

    registerChunkLifebuoy() {
        const oldHandler = this.router.errorHandler;
        this.router.errorHandler = (err: any) => {
            if (err.originalStack && err.originalStack.indexOf('Error: Loading chunk') >= 0) {
                if (localStorage.getItem('lastChunkError') !== err.originalStack) {
                    localStorage.setItem('lastChunkError', err.originalStack);
                    location.reload();
                } else {
                    console.error('We really can\'t find the chunk...');
                }
            }
            oldHandler(err);
        };
    }

    reset() {
        this.idle.watch();
        this.idleState = `Session: ${this.timeoutSet}:00 min`;
        this.timedOut = false;
    }

    private runTutorial() {
        this.confirmationDialogService.open({
            modalHeader: $localize`Welcome to our Platform`,
            confirmationMessage: $localize`Congratulations! You have successfully registered yourself on the FinMatch financing platform. Please complete this short introduction to get a first overview on the functions of our portal.`,
            confirmationYesButton: $localize`Start introduction`,
            confirmationNoButton: $localize`Skip introduction`,
        }).pipe(
            take(1),
        ).subscribe((result) => {
            if (!result) {
                return this.router.navigate(['inquiry/list']).then(() => {
                    this.principal.setTutorialCompleted();
                });
            }
            this.introJS.setOptions({
                hideNext: false,
                hidePrev: true,
                prevLabel: $localize`Back`,
                nextLabel: $localize`Next`,
                doneLabel: $localize`Finish introduction`,
            });
            this.introJS.onexit(() => {
                this.renderer.removeClass(document.body, 'tutorial-open');
                return this.router.navigate(['inquiry/list']).then(() => {
                    this.principal.setTutorialCompleted();
                });
            });
            if ((window as any).scrollTo) {
                (window as any).scrollTo(0, 0);
            }
            this.renderer.addClass(document.body, 'tutorial-open');
            this.introJS.start();
        });
    }
}
