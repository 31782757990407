import {Inject, Injectable} from '@angular/core';
import {Principal} from '../auth/principal.service';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {EventManagerService} from '../event-manager.service';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {Router} from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    constructor(
        private principal: Principal,
        private authServerProvider: AuthServerProvider,
        private eventManager: EventManagerService,
        private router: Router,
        private http: HttpClient,
        private keycloak: KeycloakService,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
    }

    login(credentials) {
        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe({
                next: (data) => {
                    return this.principal.identity(true).then(_ => {
                        return resolve(data);
                    });
                },
                error: (err) => {
                    this.logout();
                    return reject(err);
                }
            });
        });
    }

    // loginWithToken(jwt, rememberMe) {
    //     return this.authServerProvider.loginWithToken(jwt, rememberMe);
    // }

    logout(isForcedLogout: boolean = false, informSubscribers: boolean = true) {
        this.principal.authenticate(null);
        this.authServerProvider.logout();

        if (informSubscribers) {
            this.eventManager.broadcast({
                name: this.config.eventTypes['logout'],
                content: 'Logout Success',
                isForced: isForcedLogout
            });
        }
        window.location.href = environment.keycloak.issuer + "/realms/" + environment.keycloak.realm + "/protocol/openid-connect/logout";
        // this.router.navigate(['/login']);
    }
}
