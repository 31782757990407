import {FinancingType, FinancingTypeFastLane} from '../services/inquiry/inquiry-configuration.service';
import {InquiryModuleKey, ProductBankOfferStatus} from './InquiryModel';
import {DocumentFile} from './document-file.model';

export enum BankOfferVersion {
    LEGACY = 1,
    MULTI_PRODUCTS_SIMPLE_FORM = 2,
    MULTI_PRODUCTS_FULL_FORM = 3,

    DEFAULT = BankOfferVersion.MULTI_PRODUCTS_FULL_FORM,
}

export class LegacyV1BankOffer {
    offerId: string;
    bankName: string;
    bankCity: string;
    bankCountry: string;
    bankId: string;
    comment: string;
    status: ('CREATED' | 'DRAFT' | 'SUBMITTED' | 'ACCEPTED' | 'REDRAFT');
    documents: DocumentFile[];
    new: boolean;
}

export class BankOffer {
    offerId: string;
    bankName: string;
    bankCity: string;
    bankCountry: string;
    version?: BankOfferVersion;
    bankId: string;
    status: ('CREATED' | 'DRAFT' | 'SUBMITTED' | 'ACCEPTED' | 'REDRAFT');
    productOffers: { [key in InquiryModuleKey]: FinancingProductOffer[] };
    new: boolean;
}


export interface FinancingProductOffer {
    financingType: FinancingType | FinancingTypeFastLane;
    details: {
        comment: string;
        [key: string]: any;
    };
    documents: DocumentFile[];
    status?: ProductBankOfferStatus;
}

export interface ProductBankOfferPayload {
    productIndex: number;
    productFinancing: FinancingProductOffer;
}
