import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DocumentRepository {
    url: string;

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
        this.url = this.config.API_URL + 'docupload/api/';
    }

    upload(data: FormData): Observable<any> {
        return this.http.post(this.url + 'document-files', data, {observe: 'response'})
            .pipe(share());
    }

    getZipped(documentsHash: string[], filename: string, kcToken: string = ""): Observable<any> {
        return this.http.put(this.url + `document-file/files/${filename}?kcToken=${kcToken ? "Bearer " + kcToken : ""}`, {
            documentsHash
        }, {
            responseType: 'blob',
            withCredentials: true,
        });
    }

    getPublicDownloadLink(documentsHash: string[], inquiryId: string, filename: string): Observable<any> {
        return this.http.post(this.url + `document-files/download-link/${filename}`, {documentsHash, inquiryId}, {withCredentials: true});
    }

    certify(postData: { documentHash: string }): Observable<any> {
        return this.http.put(this.url + 'certify-doc', postData, {observe: 'response'});
    }

    getDocument(docToken: string, kcToken = ""): Observable<any> {
        return this.http.get(this.config.API_URL + this.config.API_URLs.document + docToken + (kcToken ? "?kcToken=" + kcToken : ""), {
            responseType: 'blob' as 'json'
        });
    }
}
