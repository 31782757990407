<form [class.readonly]="readonly" [formGroup]="form" autocomplete="off" class="finmatch-form">
    <div *ngIf="!readonly" class="form-required-information" i18n>All fields with * are required</div>

    <div class="section-label" i18n>Building</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select formControlName="buildingType" i18n-placeholder placeholder="Building type" required>
                    <ng-container *ngFor="let type of buildingTypes">
                        <mat-option *ngIf="!type.subTypes" [innerText]="buildingTypeLabels[type.value]" [value]="type.value"></mat-option>
                        <mat-optgroup *ngIf="type.subTypes" [label]="buildingTypeLabels[type.value]">
                            <mat-option *ngFor="let subType of type.subTypes" [innerText]="buildingTypeLabels[subType.value]" [value]="subType.value"></mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error [errorMessage]="form.get('buildingType')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingType')" [map]="buildingTypeLabels" i18n>Building type</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="totalSalesPrice" i18n-placeholder="@@realEstateNewBuildingSalesPrice" placeholder="Sales price (net)" required></app-number-input>
                <mat-error [errorMessage]="form.get('totalSalesPrice')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('totalSalesPrice')" i18n="@@realEstateNewBuildingSalesPrice">Sales price (net)</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Areas</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="propertyArea" i18n-placeholder placeholder="Property area"></app-number-input>
                <mat-error [errorMessage]="form.get('propertyArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('propertyArea')" i18n suffix="m²">Property area</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="buildingArea" i18n-placeholder placeholder="Building area"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingArea')" i18n suffix="m²">Building area</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Location</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select formControlName="country" i18n-placeholder placeholder="Country" required>
                    <mat-option *ngFor="let country of countries" [innerText]="country.viewValue" [value]="country.value"></mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('country')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('country')" i18n>Country</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="streetAddress" i18n-placeholder matInput placeholder="Street" type="text">
                <mat-error [errorMessage]="form.get('streetAddress')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('streetAddress')" i18n>Street</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="houseNumber" i18n-placeholder matInput placeholder="House number" type="text">
                <mat-error [errorMessage]="form.get('houseNumber')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('houseNumber')" i18n>House number</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="postalCode" i18n-placeholder matInput placeholder="Postcode">
                <mat-error [errorMessage]="form.get('postalCode')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('postalCode')" i18n>Postcode</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="city" i18n-placeholder matInput placeholder="City" required type="text">
                <mat-error [errorMessage]="form.get('city')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('city')" i18n>City</app-form-field-readonly>
        </div>
    </div>

</form>
