<div *ngIf="confirmationHeader" class="dialog-title" mat-dialog-title>{{confirmationHeader}}</div>

<div class="confirm-message">{{confirmationMessage}}</div>

<div *ngIf="confirmationSecondaryText" class="second-message">{{confirmationSecondaryText}}</div>

<mat-dialog-actions>
    <button (click)="cancel()" *ngIf="!noRejectionBtn" class="action-button" mat-button tabindex="-1">{{confirmationNoButton}}</button>
    <button (click)="confirm()" class="action-button" color="primary" mat-flat-button tabindex="-1">{{confirmationYesButton}}</button>
</mat-dialog-actions>
