import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {ERROR_INTERCEPTOR_SKIP_HEADER} from '../interceptors/errorhandler.interceptor';
import {AuthService} from '../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthRepository {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private http: HttpClient,
        private authService: AuthService
    ) {
    }

    register(data): Observable<any> {
        if (data.password) {
            data.password = this.authService.encryptPassword(data.password);
        }
        return this.http.post(this.config.API_URL + 'api/register', data, {headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'}});
    }

    registerRequest(data): Observable<any> {
        return this.http.post(this.config.API_URL + 'api/register/request', data, {headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'}});
    }

    authenticate(credentials): Observable<any> {
        credentials.password = this.authService.encryptPassword(credentials.password);
        return this.http.post(this.config.API_URL + 'api/authenticate', credentials, {headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'}});
    }
}
