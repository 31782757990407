import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const PropertyFollowupFinacingFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    country: ['GERMANY', [Validators.required]],
    houseNumber: [null, [Validators.maxLength(5)]],
    postalCode: [null, []],
    propertyArea: [null, [customValidators.area9]],
    redemptionAmount: [null, [Validators.required, customValidators.price()]],
    streetAddress: [null, [Validators.maxLength(100)]],
};

@Component({
    selector: 'app-property-followup-financing-form',
    templateUrl: './property-followup-financing-form.component.html',
    styleUrls: ['./property-followup-financing-form.component.less']
})
export class PropertyFollowupFinancingFormComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean = false;
    @Input() currency: string;

    public countries: Array<{value: string, viewValue: string}> = [];

    public fieldLabels = this.translationService.moduleFields.property;

    constructor(
        public translationService: TranslationService,
    ) {
        this.countries = this.translationService.inquiryRealEstateCountries;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateCountryRelatedFields();
        }
    }

    ngOnInit() {
        this.form.get('country').valueChanges.subscribe(() => this.updateCountryRelatedFields() );
    }

    public updateCountryRelatedFields(): void {
        let country = this.form.get('country').value;

        let postCodeValidator = (country === 'GERMANY') ? customValidators.postalCodeOptionalValidator5 : customValidators.postalCodeOptionalValidator4;
        this.form.get('postalCode').setValidators([ postCodeValidator ]);
        this.form.get('postalCode').updateValueAndValidity();
    }

}
