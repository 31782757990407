import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {Observable} from 'rxjs';
import {ERROR_INTERCEPTOR_SKIP_HEADER} from '../../interceptors/errorhandler.interceptor';
import {CrefoTableData} from '../../models/crefo-table-data.model';
import {CrefoBalanceLookupTableDataModel} from '../../models/crefo-balance-lookup-table-data.model';
import {RatingPd} from '../../models/rating-pd.model';
import {KeyFigures} from '../../models/key-figures.model';
import {Cashflow} from '../../models/cashflow.model';
import {CrefoRatingRequest} from '../../models/crefo-rating-request.model';
import {CrefoRatingRequestResponse} from '../../models/crefo-rating-request-response.model';

@Injectable()
export class CrefoService {

    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig) {
    }

    public search(searchString: string): Observable<CrefoTableData[]> {
        return this.http.post<CrefoTableData[]>(`${this.config.API_URL}ratings/api/v1/crefo-rating/companies/search`, {searchString: searchString});
    }

    public balanceLookup(crefoId: string): Observable<CrefoBalanceLookupTableDataModel[]> {
        return this.http.get<CrefoBalanceLookupTableDataModel[]>(`${this.config.API_URL}ratings/api/v1/crefo-rating/balances`, {
            params: {
                crefoId: crefoId
            }
        });
    }

    public assignBalance(request: AssignBalanceRequest): Observable<void> {
        return this.http.post<void>(`${this.config.API_URL}ratings/api/v1/crefo-rating/balances`, request);
    }

    public requestRating(request: CrefoRatingRequest): Observable<CrefoRatingRequestResponse> {
        let params = new HttpParams();
        params = params.append('crefoId', request.crefoId);
        params = params.append('documentHash', request.documentHash);
        params = params.append('corporateId', request.corporateId);

        return this.http.get<CrefoRatingRequestResponse>(`${this.config.API_URL}ratings/api/v1/crefo-rating/rating`, {params});
    }

    public rating(ratingId: string): Observable<RatingPd> {
        return this.http.get<RatingPd>(`${this.config.API_URL}ratings/api/v1/ratings/${ratingId}`);
    }

    public keyFigures(ratingId: string): Observable<KeyFigures> {
        return this.http.get<KeyFigures>(`${this.config.API_URL}ratings/api/v1/ratings/${ratingId}/key-figures`);
    }

    public keyFiguresBranch(ratingId: string): Observable<KeyFigures> {
        return this.http.get<KeyFigures>(`${this.config.API_URL}ratings/api/v1/ratings/${ratingId}/wz-key-figures`, {
            headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'},
        });
    }

    public cashFlow(ratingId: string): Observable<Cashflow> {
        return this.http.get<Cashflow>(`${this.config.API_URL}ratings/api/v1/ratings/${ratingId}/cash-flow`, {
            headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'},
        });
    }
}

export interface AssignBalanceRequest {
    balanceId: string;
    crefoId: string;
    corporateId: string;
    customerId: string;
    documentHash: string;
    inquiryId: string;
}
