import * as _ from 'lodash';
import {Injectable} from '@angular/core';
import {UserState} from './UserModel';

export class FinMatchAccount {
    public activated: boolean;
    public newlyRegistered: boolean;
    public approvalTerms: boolean;
    public authorities: Array<string>;
    public companyId: string;
    public companyName: string;
    public customerId: string;
    public createdBy: string;
    public createdDate: string;
    public department: string;
    public position?: string;
    public email: string;
    public firstName: string;
    public id: string;
    public imageUrl: string;
    public langKey: string;
    public lastModifiedBy: string;
    public lastModifiedDate: string;
    public lastName: string;
    public login: string;
    public phone: string;
    public privacyPolicy: boolean;
    public roleInCompany: string;
    public salutation: string;
    public sameAddress: boolean;
    public showUnassignedInquiries: boolean;
    public state: UserState;
    public subCompanyId: string;
    public subCompanyName: string;
    public title: string;
    public workplaceCity: string;
    public workplaceCountry: string;
    public workplaceHouseNo: string;
    public workplacePostCode: string;
    public workplaceStreet: string;
    public usageAgreementSigned: boolean;
    public oneTimeUsageAgreementSigned: boolean;
    private tutorialDone: boolean;

    public _setData(rawObject: any): void {
        _.assign(this, rawObject);
    }

    public isBank(): boolean {
        return this.hasRole('ROLE_FINANCIAL');
    }

    public isBankAdmin(): boolean {
        return this.hasRole('ROLE_FINANCIAL') && this.hasRole('ROLE_MASTER');
    }

    public isBankUnit(): boolean {
        return this.hasRole('ROLE_FINANCIAL_UNIT');
    }

    public isFinMatch(): boolean {
        return this.hasRole('ROLE_FINMATCH');
    }

    public isBroker(): boolean {
        return this.hasRole('ROLE_BROKER');
    }

    public isCompany(): boolean {
        return this.hasRole('ROLE_COMPANY');
    }

    public isCompanyAdmin(): boolean {
        return this.hasRole('ROLE_COMPANY') && this.hasRole('ROLE_MASTER');
    }

    public isBankingUser(): boolean {
        return this.isBank() || this.isBankUnit();
    }

    public isMaster(): boolean {
        // Currently every FM user is always a master user, it will change in the future.
        return this.hasRole('ROLE_MASTER') || this.hasRole('ROLE_FINMATCH');
    }

    public isFinmatchMaster(): boolean {
        // Currently every FM user is always a master user, it will change in the future.
        return this.hasRole('ROLE_MASTER') && this.hasRole('ROLE_FINMATCH');
    }

    public hasRole(role: string): boolean {
        return _.includes(this.authorities, role);
    }

    public hasAcceptedDataPolicy(): boolean {
        return !!this.privacyPolicy;
    }

    // /**
    //  * Either the "uber" usage agreement or the one-time usage agreement
    //  */
    // public hasAcceptedAnyUsageAgreement(): boolean {
    //     return this.hasAcceptedUsageAgreement() || this.hasAcceptedOneTimeAgreement();
    // }

    // public hasAcceptedOneTimeAgreement(): boolean {
    //     return this.oneTimeUsageAgreementSigned;
    // }

    public hasAcceptedUsageAgreement(): boolean {
        return this.usageAgreementSigned;
    }

    public hasTutorialDone() {
        return !!this.tutorialDone;
    }
}

export const FinMatchLoggedOutMock = {
    authorities: [],
    isBank: () => false,
    isBankUnit: () => false,
    isFinMatch: () => false,
    isCompany: () => false,
    isBroker: () => false,
    isBankingUser: () => false,
    isMaster: () => false,
    hasRole: (role: string) => false,
    hasAcceptedUsageAgreement: () => false,
};

@Injectable({ providedIn: 'root' })
export class FinMatchAccountFactory {

    /** Returns FinMatchAccount based on raw data. */
    public get(rawAccountObject: any): FinMatchAccount {
        const finMatchAccount = new FinMatchAccount();
        finMatchAccount._setData(rawAccountObject);

        return finMatchAccount;
    }
}
