<form [class.readonly]="readonly" [formGroup]="form" autocomplete="off" class="finmatch-form">
    <div *ngIf="!readonly" class="form-required-information" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form"></app-financing-plans-field>

    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="financingVolume" [placeholder]="fieldLabels.financingVolume.label" required></app-number-input>
                <mat-error [errorMessage]="form.get('financingVolume')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('financingVolume')">{{fieldLabels.financingVolume.label}}</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row" *ngIf="form.get('description')?.value">
        <div class="form-field-50">
            <app-form-field-readonly [currency]="currency" [field]="form.get('description')">{{fieldLabels.description.label}}</app-form-field-readonly>
        </div>
    </div>
</form>
