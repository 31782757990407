import {UsageAgreementDialogService} from '../services/usage-agreement-dialog.service';
import {Inject, Injectable, isDevMode} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';

import {Principal} from '../services/auth/principal.service';
import {StateStorageService} from '../services/auth/state-storage.service';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {EventManagerService} from '../services/event-manager.service';
import {LoginService} from '../services/login/login.service';
import {lastValueFrom, Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';

@Injectable({providedIn: 'root'})
export class UserRouteAccessGuard  {
    private err: any;

    constructor(
        private router: Router,
        private principal: Principal,
        private stateStorageService: StateStorageService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private eventManager: EventManagerService,
        private authService: AuthService,
        public usageAgreementDialogService: UsageAgreementDialogService,
        private loginService: LoginService
    ) {
        this.eventManager.subscribe(this.config.eventTypes['gatewayError'], err => {
            this.err = err;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        const authorities = route.data['authorities'];
        const restrictLoggedIn = route.data['restrictLoggedIn'];

        // We need to call the checkAuthorities / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkAuthorities(authorities, state.url, restrictLoggedIn);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const authorities = route.data['authorities'];
        const restrictLoggedIn = route.data['restrictLoggedIn'];
        const url = segments.map(m => m.path).join('/');

        // ! DOESN'T WORK CURRENTLY. CANLOAD DOES NOT PROVIDE THE WHOLE URL OF CHILDREN. !

        // We need to call the checkAuthorities / and so the principal.identity() function, to ensure,
        // that the client has a principal too, if they already logged in by the server.
        // This could happen on a page refresh.
        return this.checkAuthorities(authorities, url, restrictLoggedIn);
    }

    checkAuthorities(authorities: string[], url: string, restrictLoggedIn: boolean): Promise<boolean> {
        const principal = this.principal;
        if (this.authService.token) {
            this.authService.loadUser().then().catch(() => {}); // This should replace the whole auth construct at some point
        }
        return Promise.resolve(
            principal.identity().then(account => {
                if (restrictLoggedIn && account && account.privacyPolicy && account.approvalTerms && account.activated) {
                    this.router.navigate(['inquiry/list']).then();
                    this.err = null;
                    return false;
                }

                if (!authorities || authorities.length === 0) {
                    this.err = null;
                    return true;
                }
                if (account) {
                console.log(account.activated)

                    //todo: Here we deal with activated
                    if(account.newlyRegistered) {
                        //todo: MAKE URL REDIRECT
                        this.err = 403;
                        this.router.navigate(['error']).then(() => {
                        });
                        return false;
                    }
                    if (account.privacyPolicy && account.approvalTerms) {
                        return principal.hasAnyAuthority(authorities).then(response => {
                            if (response) {
                                this.err = null;
                                return true;
                            }
                            if (isDevMode()) {
                                console.error('User has not any of required authorities: ', authorities);
                            }
                            this.err = null;
                            this.router.navigate(['accessdenied']).then(() => {
                            });
                            return false;
                        });
                    } else if ((!account.privacyPolicy || !account.approvalTerms) && url.indexOf('change-onetime-password') >= 0) {
                        this.err = null;
                        return true;
                    } else if (!account.privacyPolicy && !account.approvalTerms) {
                        this.router.navigate(['change-onetime-password']).then(() => {
                        });
                        this.err = null;
                        return false;
                    } else if (!account.hasAcceptedDataPolicy()) {
                        return lastValueFrom(this.usageAgreementDialogService.open(null, 'policy'))
                            .then(isConfirmed => {
                                if (isConfirmed === false) {
                                    this.loginService.logout();
                                    return false;
                                } else if (isConfirmed === null) {
                                    return false;
                                }
                                return true;
                            });
                    }
                }

                if (url && url.toLowerCase().indexOf('accessdenied') === -1) {
                    this.stateStorageService.storeUrl(url.replace('?login_redirect=true', ''));
                } else {
                    this.stateStorageService.storeUrl(null);
                }

                if (this.err && this.err.content && this.err.content.status === 400) {
                    this.router.navigate(['badrequest']).then(() => {
                    });
                } else {
                    if (url.indexOf('login_redirect=true') >= 0) {
                        this.router.navigate(['/login']).then(() => {
                        });
                    } else {
                        this.router.navigate(['accessdenied']).then(() => {
                        });
                    }
                }
                this.err = null;
                return false;
            })
        );
    }
}
