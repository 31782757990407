import {Component, Inject, Input} from '@angular/core';
import {APP_CONFIG, AppConfig} from 'src/app/app-config.module';

@Component({
    selector: 'app-bottom-nav',
    templateUrl: './bottom-nav.component.html',
    styleUrls: ['./bottom-nav.component.less']
})
export class BottomNavComponent {
    @Input() appVer: string = null;
    date = new Date();

    constructor(@Inject(APP_CONFIG) public config: AppConfig) {
    }
}
