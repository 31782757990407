import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {LabelsMap, TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {Inquiry} from "../../../../../../models/InquiryModel";
import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const getMachineExpertModeFormGroup = (inquiry: Inquiry) => {
    const moduleDetails = inquiry.getModuleMetadataDetails('machine');
    let formControls: { [key: string]: any } = {
        id: [],
        financingPlans: [null, customValidators.financingPlans],
        emAlreadyOrdered: ['NO', [Validators.required]],
        emCondition: ['NEW', [Validators.required]],
        emDeliveryDate: [null, [Validators.required]],
        quantity: [moduleDetails.hasMultiple === false ? 1 : null, [Validators.required, customValidators.quantity3]],
    };
    if (!moduleDetails.isAggregated) {
        formControls = {
            ...formControls,
            emDeprecationPeriod: [null, [customValidators.ageOrYearsLimit]],
            emMachineType: [null, [Validators.maxLength(50)]],
            emManufacturer: [null, [Validators.maxLength(50)]],
            emPricePerUnit: [null, [Validators.required, customValidators.price()]],
            emUsedAge: [null, [Validators.required, customValidators.ageOrYearsLimit]],
            emUsedOriginalSalesPrice: [null, [customValidators.price()]],
            emYearOfManufacturing: [null, [customValidators.yearOfEstValidator()]],
            machineType: [null, [Validators.required]],
            totalSalesPrice: [0, []],
        };
    } else {
        formControls.totalSalesPrice = [null, [Validators.required, Validators.min(1)]];
    }

    return formControls;
};


@Component({
    selector: 'app-machine-expert-form',
    templateUrl: './machine-expert-form.component.html',
    styleUrls: ['./machine-expert-form.component.less']
})
export class MachineExpertFormComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() readonly = false;
    @Input() currency: string;
    @Input() isAggregated: boolean;
    @Input() hasMultiple: boolean;

    public machineTypes: any[] = [];
    public conditions: LabelsMap;
    public orderStates: LabelsMap;

    public changeQuantityTooltip = this.translationService.changeQuantityTooltip;

    public machineFieldLabels = this.translationService.moduleFields.machine;

    constructor(
        public translationService: TranslationService,
    ) {
        this.machineTypes = this.translationService.inquiryMachineTypes;
        this.conditions = this.translationService.itemConditions;
        this.orderStates = this.translationService.itemOrderedStates;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateDeliveryField();
            this.updateUsedFields();
            this.updateTotalPrice();
        }
    }

    ngOnInit() {
        this.form.controls.hasOwnProperty('emAlreadyOrdered') && this.form.controls.emAlreadyOrdered.valueChanges.subscribe(() => {
            this.updateDeliveryField();
        });
        this.form.controls.hasOwnProperty('emCondition') && this.form.controls.emCondition.valueChanges.subscribe(() => {
            this.updateUsedFields();
        });
        this.form.controls.hasOwnProperty('quantity') && this.form.controls.quantity.valueChanges.subscribe(() => {
            this.updateTotalPrice();
        });
        this.form.controls.hasOwnProperty('emPricePerUnit') && this.form.controls.emPricePerUnit.valueChanges.subscribe(() => {
            this.updateTotalPrice();
        });
    }

    // FORM UPDATES
    public updateTotalPrice(): void {
        if (this.isAggregated || !this.form.controls.totalSalesPrice) {
            return;
        }
        let quantity = this.form.controls.quantity.value;
        let pricePerUnit = this.form.controls.emPricePerUnit.value;

        let totalPrice = (quantity * pricePerUnit);
        this.form.controls.totalSalesPrice.setValue(totalPrice);
    }

    public updateUsedFields(): void {
        if (!this.form.controls.hasOwnProperty('emCondition')) {
            return;
        }
        let isUsed = (this.form.controls.emCondition.value === 'USED') ? true : false;
        this.form.controls.hasOwnProperty('emUsedAge') && this.form.controls.emUsedAge[(isUsed ? 'enable' : 'disable')]();
        this.form.controls.hasOwnProperty('emUsedOriginalSalesPrice') && this.form.controls.emUsedOriginalSalesPrice[(isUsed ? 'enable' : 'disable')]();
        if (!isUsed) {
            this.form.controls.hasOwnProperty('emUsedAge') && this.form.controls.emUsedAge.setValue(null);
            this.form.controls.hasOwnProperty('emUsedOriginalSalesPrice') && this.form.controls.emUsedOriginalSalesPrice.setValue(null);
        }
    }

    public updateDeliveryField(): void {
        let alreadyOrdered = this.form.controls.emAlreadyOrdered.value === "YES";
        this.form.controls.emDeliveryDate[(alreadyOrdered ? 'enable' : 'disable')]();
        if (!alreadyOrdered) {
            this.form.controls.emDeliveryDate.setValue(null);
        }
    }
}
