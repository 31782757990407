import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorMessageDirective} from "./error-message.directive";

const dependencies = [];

@NgModule({
    declarations: [
        ErrorMessageDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ErrorMessageDirective
    ]
})
export class ErrorMessageModule {
}
