import {Injectable} from '@angular/core';

export interface KeyFiguresMappingItem {
    key: string;
    label: string;
    decimals?: number;
    skipInTeaserKeyFigures?: boolean;
}

@Injectable({providedIn: 'root'})
export class KeyFiguresMapping {
    assetStructure: KeyFiguresMappingItem[] = [];
    capitalStructure: KeyFiguresMappingItem[] = [];
    financialStrength: KeyFiguresMappingItem[] = [];
    profitability: KeyFiguresMappingItem[] = [];
    liquidity: KeyFiguresMappingItem[] = [];

    keyFiguresOptions: KeyFiguresMappingItem[];

    constructor() {
        this.assetStructure = [
            {
                key: 'assetIntensity',
                label: $localize`Asset intensity (%)`,
                decimals: 2
            },
            {
                key: 'capitalTurnover',
                label: $localize`Capital turnover`,
                decimals: 2
            },
            {
                key: 'turnaroundTime',
                label: $localize`Turnaround time (days)`,
                decimals: 2,
            },
            {
                key: 'coverageRatioFixedAssets',
                label: $localize`Coverage ratio fixed assets (%)`,
                decimals: 2
            },
            {
                key: 'liquidFundsRatio',
                label: $localize`Liquid funds ratio (%)`,
                decimals: 2
            },
            {
                key: 'customerTarget',
                label: $localize`Customer target (days)`,
                decimals: 2,
            },
        ];

        this.capitalStructure = [
            {
                key: 'equityRatio',
                label: $localize`Equity ratio (%)`,
                decimals: 2
            },
            {
                key: 'debtEquityRatio',
                label: $localize`Debt equity ratio`,
                decimals: 2
            },
            {
                key: 'shortTermDebtRatio',
                label: $localize`Short term debt ratio (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'longTermDebtRatio',
                label: $localize`Long term debt ratio (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'shortTermCapitalCommitment',
                label: $localize`Short term capital commitment (%)`,
                decimals: 2
            },
            {
                key: 'capitalCommitmentPeriod',
                label: $localize`Capital commitment period (days)`,
                decimals: 2,
            },
            {
                key: 'debtStructure',
                label: $localize`Debt structure (%)`,
                decimals: 2,
            },
            {
                key: 'tradePayablesRatio',
                label: $localize`Trade payables ratio (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'supplierTarget',
                label: $localize`Supplier target (days)`,
                decimals: 2,
            },
        ];

        this.financialStrength = [
            {
                key: 'cashFlow',
                label: $localize`Cash flow (total)`,
                decimals: 2
            },
            {
                key: 'cashFlowToTotalOutput',
                label: $localize`Cash flow to total output (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'cashFlowToEffectiveDebt',
                label: $localize`Cash flow to effective debt (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'cashFlowROI',
                label: $localize`Cash flow ROI (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'dynamicDebtReliefPeriod',
                label: $localize`Dynamic debt relief period (years)`,
                decimals: 2,
            },
            {
                key: 'debtServiceCapacity',
                label: $localize`Debt service capacity (%)`,
                decimals: 2
            }
        ];

        this.profitability = [
            {
                key: 'returnOnInvestment',
                label: $localize`Return on investment (%)`,
                decimals: 2
            },
            {
                key: 'returnOnEquity',
                label: $localize`Return on equity (%)`,
                decimals: 2
            },
            {
                key: 'returnOnAssets',
                label: $localize`Return on assets (%)`,
                decimals: 2
            },
            {
                key: 'profitabilityOnSales',
                label: $localize`Profatibility on sales (%)`,
                decimals: 2
            },
            {
                key: 'grossProfitRatio',
                label: $localize`Gross profatibility ratio (%)`,
                decimals: 2
            },
            {
                key: 'ebitToInterestExpense',
                label: $localize`EBIT to interest expense`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'ebitdaToInterestExpense',
                label: $localize`EBITDA to interest expense`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'personnelExpenseRatio',
                label: $localize`Personnel expense ratio (%)`,
                decimals: 2
            },
            {
                key: 'costOfMaterialsRatio',
                label: $localize`Cost of materials ratio (%)`,
                decimals: 2
            },
            {
                key: 'costToIncomeRatio',
                label: $localize`Cost to income ratio`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'turnoverPerEmployee',
                label: $localize`Turn over per employee (total)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'interestExpenseOnBorrowedCapital',
                label: $localize`Interest expense on borrowed capital (%)`,
                decimals: 2
            },
            {
                key: 'successRate',
                label: $localize`Success rate (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
        ];

        this.liquidity = [
            {
                key: 'firstDegree',
                label: $localize`First degree liquidity (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'secondDegree',
                label: $localize`Second degree liquidity (%)`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            },
            {
                key: 'thirdDegree',
                label: $localize`Third degree liquidity (%)`,
                decimals: 2
            },
            {
                key: 'netWorkingCapital',
                label: $localize`Net working capital (total)`,
                decimals: 2
            },
            {
                key: 'firstDegreeExtended',
                label: $localize`First degree liquidity (%) extended`,
                decimals: 2,
                skipInTeaserKeyFigures: true,
            }
        ];

        this.keyFiguresOptions = [
            ...this.assetStructure,
            ...this.capitalStructure,
            ...this.financialStrength,
            ...this.profitability,
            ...this.liquidity
        ].filter(item => !item.skipInTeaserKeyFigures);
    }
}
