import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivateRepository} from '../../../repositories/activate.repository';

export enum ActivationError {
    alreadyUsedActivationKey = 'error.alreadyUsedActivationKey'
}

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.less']
})
export class ActivateComponent implements OnInit {
    error: string;
    success: string;
    errorType: ActivationError | null;
    ActivationError = ActivationError;

    constructor(
        private activateRepository: ActivateRepository,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.activateRepository.get(params['key']).subscribe({
                next: () => {
                    this.error = null;
                    this.success = 'OK';
                },
                error: (e) => {
                    if (e?.status === 400 && e?.error?.message === ActivationError.alreadyUsedActivationKey) {
                        this.errorType = e.error.message;
                    }
                    this.success = null;
                    this.error = 'ERROR';
                }
            });
        });
    }
}
