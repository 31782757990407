import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import {combineLatest, Subscription} from "rxjs";
import {environment} from '../../../../../environments/environment';
import {CookieConsentService} from "../../../../services/root/cookie-consent.service";

@Component({
    selector: 'app-google-analytics',
    template: ''
})
export class GoogleAnalyticsComponent implements OnInit, OnDestroy {

    private routerSubscription: Subscription;

    constructor(
        private router: Router,
        private cookieConsentService: CookieConsentService,
        private gtmService: GoogleTagManagerService,
    ) {
    }

    ngOnInit() {
        this.routerSubscription = combineLatest([
            this.router.events.pipe(filter(e => e instanceof NavigationEnd)),
            this.cookieConsentService.cookieCategoryStatuses.pipe(
                map(categoryStatuses => {
                    return categoryStatuses ? categoryStatuses['analytics'] : false;
                }),
                distinctUntilChanged(),
            ),
        ]).pipe(
            filter(([event, isAnalyticsConsented]) => isAnalyticsConsented === true)
        ).subscribe(([event, _]) => {
            if (typeof (<any>window).ga === 'function') {
                this.sendPageView((event as NavigationEnd).urlAfterRedirects);
            } else {
                // Covers initial page load (analytics might not yet be loaded)
                // and the case when the user has just accepted the cookie and changed the route instantly after.
                setTimeout(() => {
                    typeof (<any>window).ga === 'function' && this.sendPageView((event as NavigationEnd).urlAfterRedirects);
                }, 2000);
            }
            if (environment.googleTagManager?.id) {
                this.sendGTMTag((event as NavigationEnd).urlAfterRedirects);
            }
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    public sendPageView(url: string): void {
        (<any>window).ga('set', 'page', url);
        (<any>window).ga('send', 'pageview');
    }

    public sendGTMTag(url: string): void {
        this.gtmService.pushTag({
            event: 'page',
            pageUrl: url,
        });
    }
}
