import {Component, HostBinding, Inject, OnInit, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {WINDOW} from 'src/app/services/window.service';
import {UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {FinMatchValidators} from '../../../../validators/finmatch-validators';
import {Router} from '@angular/router';
import {NgScrollbar} from 'ngx-scrollbar';
import {fromEvent} from 'rxjs';
import {debounceTime, finalize} from 'rxjs/operators';
import {ValidationHelpers} from '../../../../validators/validation-helpers';
import {formatDate} from '@angular/common';
import {APP_CONFIG, AppConfig} from "../../../../app-config.module";
import {SupportRepository} from "../../../../repositories/support.repository";

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.less']
})
export class SupportComponent implements OnInit {
    @ViewChild(NgScrollbar) scroll: NgScrollbar;
    @ViewChildren(FormGroupDirective) formGroups: FormGroupDirective[];
    @ViewChild('successDialog') successDialog: TemplateRef<any>;

    @HostBinding('class.supportFormOpened') public isSupportFormOpened = false;

    public supportByEmailForm: UntypedFormGroup;
    public supportByPhoneForm: UntypedFormGroup;

    public today = new Date();

    public showSpinner: boolean;
    public showSuccessMessage: boolean;

    constructor(
        private supportRepository: SupportRepository,
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private finMatchValidators: FinMatchValidators,
        @Inject(APP_CONFIG) public config: AppConfig
    ) {
    }

    ngOnInit() {
        this.buildSupportByEmailForm();
        this.buildSupportByPhoneForm();

        this.supportByEmailForm.valueChanges.subscribe(form =>
            this.supportByPhoneForm.patchValue(form, {emitEvent: false})
        );

        this.supportByPhoneForm.valueChanges.subscribe(form =>
            this.supportByEmailForm.patchValue(form, {emitEvent: false})
        );

        this.supportByEmailForm.controls.message.valueChanges
            .pipe(debounceTime(250))
            .subscribe(() => this.scroll && this.scroll.update());

        fromEvent(this.window, 'resize')
            .pipe(debounceTime(250))
            .subscribe(() => this.scroll && this.scroll.update());
    }

    openSupportForm() {
        this.isSupportFormOpened = true;
    }

    closeSupportForm() {
        if (this.formGroups) {
            this.formGroups.forEach(formGroup => formGroup.resetForm());
        }
        this.isSupportFormOpened = false;
        this.showSuccessMessage = false;
    }

    goToTermsAndConditions() {
        this.router.navigate(['termsofuse']);
    }

    goToDataProtection() {
        this.router.navigate(['data-protection']);
    }

    sendEmailSupportRequest() {
        if (this.supportByEmailForm.invalid) {
            ValidationHelpers.markFormAsDirtyAndTouched(this.supportByEmailForm);
        } else {
            this.showSpinner = true;
            const form = this.supportByEmailForm.value;
            this.supportRepository
                .sendEmailSupportRequest(form)
                .pipe(finalize(() => (this.showSpinner = false)))
                .subscribe(() => (this.showSuccessMessage = true));
        }
    }

    sendPhoneSupportRequest() {
        if (this.supportByPhoneForm.invalid) {
            ValidationHelpers.markFormAsDirtyAndTouched(this.supportByPhoneForm);
        } else {
            this.showSpinner = true;
            const form = this.supportByPhoneForm.value;
            form.preferredDate = formatDate(form.preferredDate, 'dd.MM.yyyy', 'en-US');
            this.supportRepository
                .sendPhoneSupportRequest(form)
                .pipe(finalize(() => (this.showSpinner = false)))
                .subscribe(() => (this.showSuccessMessage = true));
        }
    }

    private buildSupportByEmailForm() {
        this.supportByEmailForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(50)]],
            email: ['', [Validators.required, this.finMatchValidators.emailValidator]],
            message: ['', [Validators.required, Validators.maxLength(500)]],
            privacyPolicy: [false, [this.finMatchValidators.checkboxValidator()]],
            approvalTerms: [false, [this.finMatchValidators.checkboxValidator()]]
        });
    }

    private buildSupportByPhoneForm(): any {
        this.supportByPhoneForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(50)]],
            phoneNumber: ['', [Validators.required, this.finMatchValidators.phoneValidator()]],
            preferredDate: ['', [Validators.required, this.finMatchValidators.futureDate(true)]],
            preferredTime: ['', [Validators.required]],
            privacyPolicy: [false, [this.finMatchValidators.checkboxValidator()]],
            approvalTerms: [false, [this.finMatchValidators.checkboxValidator()]]
        });
    }
}
