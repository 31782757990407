import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopNavComponent} from "./top-nav.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {RouterModule} from "@angular/router";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatTabsModule} from "@angular/material/tabs";
import {MatIconModule} from "@angular/material/icon";

const dependencies = [
    MatToolbarModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatTabsModule,
    MatIconModule
];

@NgModule({
    declarations: [
        TopNavComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        TopNavComponent
    ]
})
export class TopNavModule {
}
