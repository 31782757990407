import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Company} from '../models/CompanyModel';
import {ERROR_INTERCEPTOR_SKIP_HEADER} from '../interceptors/errorhandler.interceptor';

@Injectable()
export class CorporateRepository {
    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig) {
    }

    all(): Observable<Company[]> {
        return this.http.get<any>(this.config.API_URL + this.config.API_URLs.corporates + '/all')
            .pipe(map(response => response.data));
    }

    get(companyId: string, errorInterceptorSkip = false): Observable<Company> {
        const context = errorInterceptorSkip ? {headers: {[ERROR_INTERCEPTOR_SKIP_HEADER]: '1'}} : {};
        return this.http.get<Company>(this.config.API_URL + this.config.API_URLs.corporates + `/${companyId}`, context);
    }

    getLight(corporateId: string): Observable<Company> {
        return this.http.get<Company>(this.config.API_URL + this.config.API_URLs.corporates + `/light/${corporateId}`);
    }

    update(company: Company): Observable<Company> {
        return this.http.put<Company>(this.config.API_URL + this.config.API_URLs.corporates, company);
    }

    delete(companyId: string) {
        return this.http.delete(this.config.API_URL + this.config.API_URLs.corporates + `/${companyId}`);
    }
}
