import {Inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {APP_CONFIG, AppConfig} from "../app-config.module";
import {Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SupportRepository {

    openSupportForm$ = new Subject<boolean>();

    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig) {
    }

    public sendEmailSupportRequest(emailSupportData): Observable<any> {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.support}/email`, emailSupportData);
    }

    public sendPhoneSupportRequest(phoneSupportData): Observable<any> {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.support}/phone`, phoneSupportData);
    }
}
