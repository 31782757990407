import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {ErrorComponent} from "./error.component";

const ERROR_ROUTES = [
    {path: '', component: ErrorComponent}
]

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forChild(ERROR_ROUTES)
    ],
    exports: [
        RouterModule
    ]
})
export class ErrorRoutingModule {
}
