import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input} from '@angular/core';
import {TranslationService} from "../../../../../../services/root/translation/translation.service";

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const OthersFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    financingVolume: [null, [Validators.required, customValidators.price()]],
    description: [null, [Validators.maxLength(500)]],
};

@Component({
    selector: 'app-other-financing-form',
    templateUrl: './other-financing-form.component.html',
})
export class OtherFinancingFormComponent {
    @Input() form: UntypedFormGroup;
    @Input() readonly = false;
    @Input() currency: string;

    public fieldLabels = this.translationService.moduleFields.other;

    constructor(
        private translationService: TranslationService
    ) {
    }
}
