import {ChangeDetectorRef, Component, HostListener, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {Principal} from '../../../../../services/auth/principal.service';
import {EventManagerService} from '../../../../../services/event-manager.service';
import {LoginService} from '../../../../../services/login/login.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {APP_CONFIG, AppConfig} from '../../../../../app-config.module';
import * as _ from 'lodash';
import {TranslationService} from '../../../../../services/root/translation/translation.service';
import {filter} from 'rxjs/operators';
import {NavigationsService} from '../../../../../services/navigations.service';
import {FinMatchAccount} from '../../../../../models/finmatch-account.model';
import Timeout = NodeJS.Timeout;
import {ProfilesService} from '../../../../../services/profiles.service';
import {BanksService} from '../../../../../services/provided/banks.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.less'],
})
export class TopNavComponent implements OnInit {
    @Input()
    sessionTimeout = '';

    @ViewChild('mainNavTrigger') menuTrigger: MatMenuTrigger;
    @ViewChild('userMenuTrigger') userMenuTrigger: MatMenuTrigger;

    public account: FinMatchAccount;
    currentRoute: any;
    routeLinksUnlogged: any[];
    routeLinks: any[];
    isLanding: boolean;
    isEditingInquiry: boolean;
    currentWindowWidth: number;
    usersTabName: string;
    tutorialTexts = this.translationService.tutorialTexts;
    menuTriggerTimeout: Timeout;
    profilePhotoUrl: string;

    constructor(
        private principal: Principal,
        private eventManager: EventManagerService,
        private loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        @Inject(APP_CONFIG) public config: AppConfig,
        public translationService: TranslationService,
        private navigationsService: NavigationsService,
        private profilesService: ProfilesService,
        private banksService: BanksService,
    ) {
    }

    @HostListener('window:resize', ['$event'])
    closeNavigation(event) {
        if (this.currentWindowWidth !== event.target.innerWidth) {
            this.currentWindowWidth = event.target.innerWidth;
        } else {
            return;
        }

        this.menuTrigger.closeMenu();
        if (this.userMenuTrigger) {
            this.userMenuTrigger.closeMenu();
        }
    }

    ngOnInit() {
        this.resolvePrincipalIdentity();
        this.registerAuthenticationSuccess();

        // when bank data changes, reload user data to update bank name in the header
        this.banksService.bankDataUpdated$.subscribe(() => {
            this.principal.identity(true);
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.currentRoute = event.url;

            this.isEditingInquiry = event.url.startsWith('/inquiry/edit') || event.url.startsWith('/inquiry/new');

            if (this.route.snapshot.firstChild) {
                this.isLanding = this.route.snapshot.firstChild.data && this.route.snapshot.firstChild.data.isLanding;
            }
        });

        this.principal.identity().then(account => this.userIdenitityRetrivalCallback(account));
    }

    registerAuthenticationSuccess() {
        this.eventManager.subscribe(this.config.eventTypes['authenticationSuccess'], () => {
            this.resolvePrincipalIdentity();
        });
    }

    resolvePrincipalIdentity() {
        // if (this.principal.getAccountRetrivalInProgress()) {
        this.principal.getUserIdentitySubject().subscribe(account => this.userIdenitityRetrivalCallback(account));
        // } else {
        //     this.principal.identity().then(account => this.userIdenitityRetrivalCallback(account));
        // }
    }

    userIdenitityRetrivalCallback(account: FinMatchAccount) {
        if (account) {
            this.account = account;
            this.usersTabName = this.getSettingsTabName();
            this.routeLinks = this.navigationsService.getPortalNavigationItems(account);
        } else {
            this.account = null;
            this.routeLinksUnlogged = this.navigationsService.getUnloggedNavigationItems();
        }
        this.profilePhotoUrl = this.profilesService.getProfilePhotoUrl(account) || '../../../../../../assets/images/building.png';
    }

    logout() {
        this.loginService.logout();
        // this.router.navigate(['']).then();
        window.location.href = environment.keycloak.issuer + "/realms/" + environment.keycloak.realm + "/protocol/openid-connect/logout";
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    hasTermsAccepted() {
        return this.principal.hasTermsAccepted();
    }

    isVisibleForRoles(roles: string | string[], matchAnyRole: boolean) {
        if (!this.account) {
            return false;
        }

        if (typeof roles === 'string') {
            return _.includes(this.account.authorities, roles);
        } else if (roles instanceof Array) {
            return this.checkRolesFromArray(this.account.authorities, roles, matchAnyRole);
        }
        return true;
    }

    checkRolesFromArray(authorities, roles, matchAnyRole) {
        if (matchAnyRole) {
            return _.some(roles, role => this.hasRoles(role, authorities));
        } else {
            return _.every(roles, role => this.hasRoles(role, authorities));
        }
    }

    hasRoles(role, authorities) {
        if (role instanceof Array) {
            return _.every(role, item => _.includes(authorities, item));
        } else if (typeof role === 'string') {
            return _.includes(authorities, role);
        }
    }

    getSettingsTabName() {
        if (this.account.isFinMatch()) {
            return this.translationService.settingsUserTabMap['ROLE_FINMATCH'];
        } else if (this.account.isCompany()) {
            return this.translationService.settingsUserTabMap['ROLE_COMPANY'];
        } else if (this.account.isBank()) {
            return this.translationService.settingsUserTabMap['ROLE_FINANCIAL'];
        } else if (this.account.isBankUnit() && this.account.isMaster()) {
            return this.translationService.settingsUserTabMap['ROLE_FINANCIAL_UNIT'];
        }
    }

    public openMenu(trigger: MatMenuTrigger) {
        trigger.openMenu();
        if (this.menuTriggerTimeout) {
            clearTimeout(this.menuTriggerTimeout);
        }
        this.menuTriggerTimeout = setTimeout(() => {
            trigger.closeMenu();
        }, 2_500);
    }

    generateIdFromRouteLink(routeLink) {
        return routeLink.link.slice(1).replaceAll('/', '_') + '_Page';
    }
}
