import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {Observable, Subject} from 'rxjs';
import {Bank} from '../../models/bank.model';
import {map, shareReplay} from 'rxjs/operators';
import {BankRemovalModalComponent} from '../../modules/settings/banks/bank-removal-modal/bank-removal-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {FinMatchAccount, FinMatchAccountFactory} from "../../models/finmatch-account.model";

export enum InstituteType {
    BANK = 'BANK',
    FACTORING_AGENCY = 'FACTORING_AGENCY',
    LEASING_AGENCY = 'LEASING_AGENCY',
    OTHER = 'OTHER',
}

export enum BankType {
    KREISSPARKASSE = 'KREISSPARKASSE',
    VOLKSBANK = 'VOLKSBANK',
    COMMERCIAL = 'COMMERCIAL',
    OTHER = 'OTHER',
}
export interface States {
    [key: string]: {
        counties: string[],
        cities: string[],
    };
}

@Injectable({
    providedIn: 'root'
})
export class BanksService {
    public bankDataUpdated$ = new Subject<void>();

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig,
        private dialog: MatDialog,
        private finMatchAccountFactory: FinMatchAccountFactory,
    ) {
    }

    getBank(bankId: string): Observable<Bank> {
        return this.http.get<Bank>(this.config.API_URL + this.config.API_URLs.banks, {
            params: {bankId}
        });
    }

    getBanks(): Observable<Bank[]> {
        return this.http
            .get<Bank[]>(this.config.API_URL + this.config.API_URLs.banks + '/all?sort=normalizedBankName,asc', {
                observe: 'response'
            })
            .pipe(map(res => res.body['data']));
    }

    getBanksUsers$(bankId: string, searchPhrase: string): Observable<FinMatchAccount[]> {
        return this.http.get<FinMatchAccount[]>(`${this.config.API_URL}${this.config.API_URLs.banks}/${bankId}/users`, {
            params: searchPhrase ? {q: searchPhrase} : {},
        }).pipe(
            map(res => (res as any).map(jsonUser => this.finMatchAccountFactory.get(jsonUser)))
        );
    }

    getAvailableStates$(): Observable<States> {
        return this.http.get<States>(`${this.config.API_URL}${this.config.API_URLs.states}`)
            .pipe(shareReplay(1));
    }

    updateBank(bank: Bank): Observable<any> {
        return this.http.put(this.config.API_URL + this.config.API_URLs.banks, bank);
    }

    createBank(bank: Bank): Observable<any> {
        return this.http.post(this.config.API_URL + this.config.API_URLs.banks, bank);
    }

    addBankUnit(unit): Observable<any> {
        return this.http.post(this.config.API_URL + this.config.API_URLs.banks + '/units', unit);
    }

    deleteBank(bank: Bank): Observable<any> {
        return this.http.delete(this.config.API_URL + this.config.API_URLs.banks + `/${bank.bankId}`);
    }

    deactivateBank(bankId: string): Observable<any> {
        return this.http.delete(`${this.config.API_URL}${this.config.API_URLs.banks}/${bankId}/activation`);
    }

    reactivateBank(bankId: string): Observable<any> {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.banks}/${bankId}/activation`, null);
    }

    bankDeletionDialog(bank: Bank) {
        return this.dialog.open(BankRemovalModalComponent, {
            width: '500px',
            data: {
                bank: `${bank.bankName}`,
                bankUsersCount: bank.users.length
            }
        });
    }

    public deactivateBankUser(bankId: string, user: FinMatchAccount) {
        return this.http.delete(`${this.config.API_URL}${this.config.API_URLs.banks}/${bankId}/users/${user.id}/activation`);
    }

    public reactivateBankUser(bankId: string, user: FinMatchAccount) {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.banks}/${bankId}/users/${user.id}/activation`, null);
    }
}
