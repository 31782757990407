import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {LabelsMap, TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnChanges, OnInit} from '@angular/core';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const VehicleFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    category: [null, [Validators.required]],
    condition: ['NEW', [Validators.required]],
    deprecationPeriod: [null, [Validators.max(99)]],
    expectedMileage: [null, [Validators.required, Validators.max(9999999)]],
    manufacturer: [null, [Validators.maxLength(100)]],
    mileage: [null, [Validators.max(9999999)]],
    quantity: [null, [Validators.required, customValidators.quantity3]],
    salesPricePerUnit: [null, [Validators.required, customValidators.area9]],
    totalSalesPrice: [null, []],
    type: [null, [Validators.maxLength(100)]],
    yearOfManufacture: [null, [customValidators.yearOfEstValidator()]]
};

@Component({
    selector: 'app-vehicle-form',
    templateUrl: './vehicle-form.component.html',
    styleUrls: ['./vehicle-form.component.less']
})
export class VehicleFormComponent implements OnInit, OnChanges {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() currency: string;

    public vehicleCategories: any[];
    public conditions: LabelsMap;

    public vehicleFieldLabels = this.translationService.moduleFields.vehicle;

    constructor(public translationService: TranslationService) {
        this.vehicleCategories = this.translationService.inquiryVehicleCategories;
        this.conditions = this.translationService.itemConditions;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateUsedFields();
            this.updateTotalPrice();
        }
    }

    ngOnInit() {
        this.form.controls.condition.valueChanges.subscribe(() => {
            this.updateUsedFields();
        });
        this.form.controls.quantity.valueChanges.subscribe(() => {
            this.updateTotalPrice();
        });
        this.form.controls.salesPricePerUnit.valueChanges.subscribe(() => {
            this.updateTotalPrice();
        });
    }

    // FORM UPDATES
    public updateTotalPrice(): void {
        let quantity = this.form.controls.quantity.value;
        let pricePerUnit = this.form.controls.salesPricePerUnit.value;

        let totalPrice = quantity * pricePerUnit;
        this.form.controls.totalSalesPrice.setValue(totalPrice);
    }

    public updateUsedFields(): void {
        let isUsed = this.form.controls.condition.value === 'USED' ? true : false;
        this.form.controls.yearOfManufacture[isUsed ? 'enable' : 'disable']();
        this.form.controls.mileage[isUsed ? 'enable' : 'disable']();

        if (!isUsed) {
            this.form.controls.yearOfManufacture.setValue(null);
            this.form.controls.mileage.setValue(null);
        }
    }
}
