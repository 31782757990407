import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {map} from 'rxjs/operators';
import {BankOffer, BankOfferVersion, LegacyV1BankOffer, ProductBankOfferPayload} from '../../models/BankOffer';
import {Inquiry, InquiryModuleKey} from "../../models/InquiryModel";

@Injectable({providedIn: 'root'})
export class BankOffersService {
    public offersUpdated$ = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
    }

    public getBankOfferVersion(inquiry: Inquiry, offer: BankOffer | LegacyV1BankOffer): BankOfferVersion {
        if (inquiry.isLegacyFinancingParametersInquiry()) {
            return BankOfferVersion.LEGACY;
        }
        if (!offer) {
            return BankOfferVersion.DEFAULT;
        }
        if ((offer as BankOffer).version) {
            return (offer as BankOffer).version;
        }
        if ((offer as BankOffer).productOffers === null) {
            return BankOfferVersion.LEGACY;
        }

        return BankOfferVersion.DEFAULT;
    }

    public createInquiryBankOffer(inquiryId: string, bankId: string = null): Observable<LegacyV1BankOffer> {
        return this.http.post<LegacyV1BankOffer>(`${this.config.API_URL}${this.config.API_URLs.bankOffers}`, {
            inquiryId,
            bankId
        });
    }

    public sendInquiryBankOffer(offerId: string): Observable<BankOffer> {
        return this.http.post<BankOffer>(`${this.config.API_URL}${this.config.API_URLs.bankOffers}/${offerId}/status`, {
            status: 'SUBMITTED',
        });
    }

    /**
     * @param bankId is optional - only in use when finmatch user saves the offer.
     */
    public saveInquiryBankOfferPerProduct(inquiryId: string, moduleKey: InquiryModuleKey, offer: ProductBankOfferPayload, bankId?: string | null): Observable<any> {
        const payload = bankId ? {...offer, bankId: bankId} : offer;
        return this.http.put(
            `${this.config.API_URL}${this.config.API_URLs.bankOfferInquiries}/${inquiryId}/offers/${moduleKey}`,
            payload
        );
    }

    public editInquiryBankOffer(offer: LegacyV1BankOffer): Observable<any> {
        return this.http.put(`${this.config.API_URL}${this.config.API_URLs.bankOffers}`, offer);
    }

    public getInquiryBankOffers(inquiryId: string): Observable<LegacyV1BankOffer[] | BankOffer[]> {
        return this.http.get(`${this.config.API_URL}${this.config.API_URLs.bankOffers}`, {
            params: {inquiryId}
        }).pipe(map(response => response['offers']));
    }

    public undoRejectInquiryBankOffer(inquiryId: string, bankId: string) {
        return this.http.request('DELETE', `${this.config.API_URL}${this.config.API_URLs.rejectInquiries}/${inquiryId}`, {
            body: {bankId: bankId ? bankId : ''},
        });
    }

    // public getBankOffersPerInquiries(ids: string[]): Observable<any> {
    //     return this.http.put(`${this.config.API_URL}${this.config.API_URLs.bankOfferInquiriesOffers}`, ids, {observe: 'body'});
    // }

    public saveInquirySelectedOffers(acceptedOffers: LegacyV1BankOffer[] | BankOffer[]): Observable<any> {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.bankOffers}/accept`, {
            offerIds: (acceptedOffers as BankOffer[]).map(offer => offer.offerId)
        });
    }

    public markAsRead(offer: LegacyV1BankOffer): Observable<any> {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.bankOffers}/read`, {}, {
            params: {
                offerId: offer.offerId
            }
        });
    }

    public removeTrancheOffer(inquiryId: string, moduleKey: InquiryModuleKey, finProductIndex: number, bankId: string = null) {
        return this.http.request('DELETE', `${this.config.API_URL}${this.config.API_URLs.bankOfferInquiries}/${inquiryId}/offers/${moduleKey}/${finProductIndex}`, {
            body: {bankId: bankId ? bankId : ''},
        });
    }
}
