<div *ngIf="!isSupportFormOpened" (click)="openSupportForm()" class="support-button-box">
    <img src="../../../../../assets/icons/contact.png" alt="Contact Us" />
    <p (click)="openSupportForm()" class="label" i18n>Contact</p>
</div>

<div *ngIf="isSupportFormOpened" class="support-form">
    <div class="header" i18n>Contact</div>
    <button (click)="closeSupportForm()" class="close-icon" color="primary" mat-icon-button>
        <mat-icon aria-label="close">clear</mat-icon>
    </button>
        <div class="info-section">
            <img class="profile-photo" [src]="assignee.profilePhotoUrl" alt="Profile Photo">
            <p class="finmatch-team-label">{{assignee.firstName}} {{assignee.lastName}}</p>
            <a class="contact-item" [href]="'tel:'+assignee.phone">
                <mat-icon class="text-icon">phone</mat-icon>{{ assignee.phone }}
            </a>
            <a class="contact-item" [href]="'mailto:'+assignee.email+'?subject='+emailSubject">
                <mat-icon class="text-icon">email</mat-icon>{{ assignee.email }}
            </a>
        </div>
</div>
