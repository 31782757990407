import {User} from './UserModel';
import {Company} from './CompanyModel';

export enum BrokerState {
    Activated = 'Activated',
    Deleted = 'Deleted',
    Deactivated = 'Deactivated'
}
export enum BrokerPartnerModel {
    professionalPilot = 'professionalPilot',
    partner = 'partner',
    expert = 'expert',
    professional = 'professional',
    professionalPlus = 'professionalPlus',
    professionalExtra = 'professionalExtra',
}
export enum BrokerInquirySubmissionType {
    toFinmatch = 'toFinmatch',
    toMainBroker = 'toMainBroker',
    toSuperordinateBroker = 'toSuperordinateBroker',
}
export interface ParentBrokerHeader {
    brokerId: string;
    brokerName: string;
    street: string;
    houseNumber: string;
    postCode: string;
    city: string;
    country: string;
}
export interface ChildBrokerHeader {
    brokerId: string;
    brokerName: string;
    city: string;
    postCode: string;
    children?: ChildBrokerHeader[];
}
export interface BrokerAddress {
    street: string;
    houseNumber: string;
    postCode: string;
    city: string;
}

export interface Broker extends BrokerAddress {
    brokerId: string;
    parentBroker?: ParentBrokerHeader;
    mainBroker?: ParentBrokerHeader;
    crn: string;
    crhr: string;
    brokerName: string;
    legalForm: string;
    salesClass: string;
    country: string;
    companies: Company[];
    business: string;
    businessPurpose: string;
    yearOfEst: string;
    website: string;
    users: User[];
    creationDate: string;
    activationDate: string;
    state: BrokerState;
    children?: ChildBrokerHeader[];

    inquirySubmission: BrokerInquirySubmissionType;

    partnerModel: BrokerPartnerModel;
    baseProvision: number;
    baseProvisionDelegatedInq: number;
    bonusProvision: number;

    accountHolder: string;
    accountBank: string;
    accountIBAN: string;
    accountBIC: string;
}
