<div class="dialog-title" i18n mat-dialog-title>Warning</div>
<div mat-dialog-content>
    <p i18n>Do you really want to delete a bank with users?</p>
    <p>{{data.bank}}<span i18n> with </span>{{data.bankUsersCount}}<span i18n> users?</span></p>
    <p i18n>This action can't be undone!</p>
</div>
<div class="dialog-buttons" mat-dialog-actions>
    <button (click)="onNoClick()" i18n="@@cancelForm" mat-button>Cancel</button>
    <button [mat-dialog-close]="true" color="primary" i18n mat-flat-button>Delete</button>
</div>
