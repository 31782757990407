import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {APP_CONFIG, AppConfig} from 'src/app/app-config.module';
import {Principal} from 'src/app/services/auth/principal.service';
import {FinMatchAccount} from '../../models/finmatch-account.model';
import { AccountRepository } from 'src/app/repositories/account.repository';
import { tap } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {
    errorMessage: string;
    errorType = '';

    public loggedUser: FinMatchAccount;
    mailResent: boolean = false;

    constructor(
        private loginService: LoginService,
        public route: ActivatedRoute,
        public router: Router,
        public principal: Principal,
        public accountRepository: AccountRepository,
        @Inject(APP_CONFIG) public config: AppConfig
    ) {
        this.principal.identity().then(account => {
            this.loggedUser = account;
        });
    }

    ngOnInit() {
        this.route.data.subscribe(routeData => {
            this.errorType = routeData.errorType;
            if (routeData.errorMessage) {
                this.errorMessage = routeData.errorMessage;
            }
        });
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    isFreshlyRegistered() {
        return this.principal.needsActivation();
    }

    resendActivationLink() {
         this.accountRepository.resetActivationLink().pipe(
            tap(() => {
                this.mailResent = true
            })
         ).subscribe();
    }
    logout() {
        this.loginService.logout();
    }
}
