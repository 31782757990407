<div *ngIf="data.mode === 'reject-inquiry'" i18n mat-dialog-title>Reject Inquiry</div>
<div *ngIf="data.mode === 'reject-otua'" i18n mat-dialog-title>Reject One Time Usage Agreement</div>
<div *ngIf="data.mode === 'revision'" i18n mat-dialog-title>Revise Inquiry</div>

<ng-container *ngIf="!reasonSkipped">
    <app-common-spinner [glass]="true" [progress]="uploadProgress" [visible]="isLoading"></app-common-spinner>
    <form [formGroup]="rejectionReasonForm">
        <div mat-dialog-content>
            <div class="form-row">
                <mat-label style="font-weight: bold" i18n>Reason for rejection *</mat-label>
                <mat-error *ngIf="validate && !rejectionReasonForm.controls.reasons?.value?.length" i18n>This field is required</mat-error>
                <mat-checkbox *ngFor="let reason of reasons" (change)="reasonChanged($event.checked, reason.name)" [checked]="reason.checked" [disabled]="rejectOtuaReadOnly">{{reason.translation}}</mat-checkbox>
            </div>
            <mat-form-field class="form-field-100">
                <textarea #reason formControlName="reasonOther" mat-autosize matAutosizeMaxRows="10" matInput maxlength="1000" rows="1"></textarea>
                <mat-label *ngIf="['reject-inquiry', 'reject-otua'].includes(data.mode)" i18n>Description of the rejection</mat-label>
                <mat-label *ngIf="data.mode === 'revision'" i18n>Reason for revision</mat-label>
                <mat-hint align="end">{{reason.value.length}} / 1000</mat-hint>
                <mat-error *ngIf="rejectionReasonForm.controls.reasonOther?.invalid" [errorMessage]="rejectionReasonForm.controls.reasonOther"></mat-error>
            </mat-form-field>

            <div *ngIf="data.mode !== 'reject-inquiry' && data.mode !== 'reject-otua' && !rejectOtuaReadOnly" i18n-matTooltip matTooltip="You can upload documents to this section, e.g. your institute's data policy.">
                <h3 class="box-heading"><span i18n>Documents</span> (<span i18n>optional</span>)</h3>

                <app-upload-panel>
                    <app-upload-tile [files]="files" [saveClicked]="this.rejectionReasonForm.touched" (filesChange)="files = $event"></app-upload-tile>
                </app-upload-panel>
            </div>

            <div *ngIf="rejectOtuaReadOnly && data?.rejectionDetails?.documents?.length">
                <h3 class="box-heading" i18n>Documents</h3>
                <app-inquiry-documents-tile [files]="data?.rejectionDetails?.documents" [hasAction]="false"></app-inquiry-documents-tile>
            </div>
        </div>

        <div mat-dialog-actions *ngIf="!rejectOtuaReadOnly">
            <button (click)="dialogRef.close()" class="action-button" i18n mat-button tabindex="1">Cancel</button>
            <button *ngIf="data.mode !== 'reject-otua'" (click)="clearForm()" class="action-button" i18n mat-button tabindex="2">Clear Form</button>
            <button (click)="sendRejection(true)?.subscribe()" class="action-button" color="primary" i18n mat-flat-button tabindex="3">Send Rejection</button>
        </div>
        <div mat-dialog-actions *ngIf="rejectOtuaReadOnly">
            <button (click)="dialogRef.close()" class="action-button" i18n mat-button tabindex="1">Close</button>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="reasonSkipped">
    <app-common-spinner [glass]="true" [visible]="isLoading"></app-common-spinner>
    <div mat-dialog-content>
        <div class="content-container" *ngIf="data.mode === 'reject-inquiry'">
            <p i18n>Are you sure you want to send your rejection without giving reasons?</p>
        </div>
        <div class="content-container" *ngIf="data.mode === 'revision'">
            <p i18n>Are you sure you want to send your revision without giving reasons?</p>
        </div>
    </div>

    <div mat-dialog-actions>
        <button (click)="backToReasonForm()" class="action-button" i18n mat-button tabindex="1">Cancel</button>
        <button (click)="sendRejection().subscribe()" class="action-button" color="primary" i18n mat-flat-button tabindex="2">Send Rejection</button>
    </div>
</ng-container>
