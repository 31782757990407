<form [class.readonly]="readonly" [formGroup]="form" autocomplete="off" class="finmatch-form">
    <div *ngIf="!readonly" class="form-required-information" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form" [placeholder]="descriptionPlaceholder"></app-financing-plans-field>

    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [placeholder]="fieldLabels.financingVolume.label" formControlName="financingVolume" required></app-number-input>
                <mat-error [errorMessage]="form.get('financingVolume')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('financingVolume')">{{fieldLabels.financingVolume.label}}</app-form-field-readonly>
        </div>
    </div>
<!--    <div class="form-row">-->
<!--        <div class="form-field-100">-->
<!--            <mat-form-field *ngIf="!readonly">-->
<!--                <input matInput placeholder="Description" formControlName="description" required i18n-placeholder>-->
<!--                <mat-error [errorMessage]="form.get('description')"></mat-error>-->
<!--            </mat-form-field>-->
<!--            <app-form-field-readonly *ngIf="readonly" [field]="form.get('description')" [unwrapped]="true" i18n>Description</app-form-field-readonly>-->
<!--        </div>-->
<!--    </div>-->
</form>
