import {Component} from '@angular/core';
import {CookieConsentService} from "../../../../services/root/cookie-consent.service";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-cookie-consent',
    template: ''
})
export class CookieConsentComponent {

    constructor(
        private cookieConsentService: CookieConsentService,
    ) {
        if (!environment.cookieHubId) {
            return;
        }
        const cookieConsentConfig = {
            onStatusChange: (status) => {
                this.cookieConsentService.setCookieConsentStatus(status);
            },
            onInitialise: (status) => {
                const links = document.querySelectorAll('.ch2 a[href="#"]');
                for (let i = 0; i < links.length; i++) {
                    (links[i] as any).onclick = function (e) {
                        e.preventDefault();
                    };
                }
                this.cookieConsentService.setCookieConsentStatus(status);
            },
        };
        if (environment.envName !== 'PROD') {
            // for prod subdomains are handled by cookiehub, for other env we set the cookie domain,
            // manually to avoid prod / preprod cookie propagation
            (cookieConsentConfig as any).cookie = {
                name: `cookiehub_${environment.envName}`,
                domain: environment.general_domain,
            }
        }
        if ((window as any).cookiehub) {
            (window as any).cookiehub.load(cookieConsentConfig)
        } else {
            (window as any).onload = function () {
                if ((window as any).cookiehub) {
                    (window as any).cookiehub.load(cookieConsentConfig)
                } else {
                    setTimeout(() => {
                        (window as any).cookiehub && (window as any).cookiehub.load(cookieConsentConfig);
                    }, 1500);
                }
            };
        }
        // if (['DEV', 'LOCAL'].includes(environment.envName) ) {
        //     (window as any).__cookiehub.settings.general.domain = '';
        // }
    }
}
