<app-common-spinner [glass]="true" [visible]="isLoading"></app-common-spinner>
<div class="dashboard-container">
    <mat-tab-group class="fm-dashboard-tabs" [dynamicHeight]="true" (selectedTabChange)="tabChange($event)" [selectedIndex]="selectedTabIndex">
        <mat-tab label="Inquiries" i18n-label>
            <ng-template matTabContent>
                <div class="header" i18n>Inquiries</div>
                <div class="sub-header" i18n>By Status</div>

                <div *ngIf="inquiriesLoadError" class="error-info" i18n>Loading inquiries data failed - please try again later</div>

                <div class="table-container">
                    <ng-scrollbar *ngIf="!isLoading && !inquiriesLoadError" [trackX]="true" class="user-status" [style.height.px]="56 + userStatusTable.data.length * 48 + 49">
                        <table mat-table [dataSource]="userStatusTable">
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef i18n>Status</th>
                            </ng-container>

                            <ng-container matColumnDef="draft">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef i18n>Draft</th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.draft.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.draft.all" (click)="element.draft.all && goToInqList('DRAFT', element.userId)">
                                    <span *ngIf="element.draft.all; else emptyCell">{{element.draft.all}}</span>
                                    <span *ngIf="element.draft.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.draft.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.draft.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.draft.all" (click)="statusTotal?.draft.all && goToInqList('DRAFT', null)">
                                    <span *ngIf="statusTotal?.draft.all; else emptyCell">{{statusTotal?.draft.all}}</span>
                                    <span *ngIf="statusTotal?.draft.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.draft.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="revision">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef i18n>Revision</th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.revision.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.revision.all" (click)="element.revision.all && goToInqList('REVISION', element.userId)">
                                    <span *ngIf="element.revision.all; else emptyCell">{{element.revision.all}}</span>
                                    <span *ngIf="element.revision.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.revision.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.revision.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.revision.all" (click)="statusTotal?.revision.all && goToInqList('REVISION', null)">
                                    <span *ngIf="statusTotal?.revision.all; else emptyCell">{{statusTotal?.revision.all}}</span>
                                    <span *ngIf="statusTotal?.revision.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.revision.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="fm-assessment">
                                <th colspan="3" mat-header-cell *matHeaderCellDef i18n>FM Assessment</th>
                            </ng-container>

                            <ng-container matColumnDef="bankSelection">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef>
                                    <span class="fixed-width" i18n>Bank Selection</span></th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.bank_selection.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.bank_selection.all" (click)="element.bank_selection.all && goToInqList('BANK_SELECTION', element.userId)">
                                    <span *ngIf="element.bank_selection.all; else emptyCell">{{element.bank_selection.all}}</span>
                                    <span *ngIf="element.bank_selection.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.bank_selection.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.bank_selection.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.bank_selection.all" (click)="statusTotal?.bank_selection.all && goToInqList('BANK_SELECTION', null)">
                                    <span *ngIf="statusTotal?.bank_selection.all; else emptyCell">{{statusTotal?.bank_selection.all}}</span>
                                    <span *ngIf="statusTotal?.bank_selection.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.bank_selection.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="active">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef i18n>Active</th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.active.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.active.all" (click)="element.active.all && goToInqList('ACTIVE', element.userId)">
                                    <span *ngIf="element.active.all; else emptyCell">{{element.active.all}}</span>
                                    <span *ngIf="element.active.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.active.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.active.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.active.all" (click)="statusTotal?.active.all && goToInqList('ACTIVE', null)">
                                    <span *ngIf="statusTotal?.active.all; else emptyCell">{{statusTotal?.active.all}}</span>
                                    <span *ngIf="statusTotal?.active.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.active.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="waitingForFM">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef>
                                    <span class="fixed-width longname-width" i18n>Review Offer Selection</span>
                                </th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.review_offer_selection.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.review_offer_selection.all" (click)="element.review_offer_selection.all && goToInqList('REVIEW_OFFER_SELECTION', element.userId)">
                                    <span *ngIf="element.review_offer_selection.all; else emptyCell">{{element.review_offer_selection.all}}</span>
                                    <span *ngIf="element.review_offer_selection.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.review_offer_selection.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.review_offer_selection.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.review_offer_selection.all" (click)="statusTotal?.review_offer_selection.all && goToInqList('REVIEW_OFFER_SELECTION', null)">
                                    <span *ngIf="statusTotal?.review_offer_selection.all; else emptyCell">{{statusTotal?.review_offer_selection.all}}</span>
                                    <span *ngIf="statusTotal?.review_offer_selection.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.review_offer_selection.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="signing">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef>
                                    <span class="fixed-width" i18n>Review Signing</span>
                                </th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.review_signing.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.review_signing.all" (click)="element.review_signing.all && goToInqList('REVIEW_SIGNING', element.userId)">
                                    <span *ngIf="element.review_signing.all; else emptyCell">{{element.review_signing.all}}</span>
                                    <span *ngIf="element.review_signing.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.review_signing.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.review_signing.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.review_signing.all" (click)="statusTotal?.review_signing.all && goToInqList('REVIEW_SIGNING', null)">
                                    <span *ngIf="statusTotal?.review_signing.all; else emptyCell">{{statusTotal?.review_signing.all}}</span>
                                    <span *ngIf="statusTotal?.review_signing.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.review_signing.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="closed">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef i18n>Closed</th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.closed.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.closed.all" (click)="element.closed.all && goToInqList('CLOSED', element.userId)">
                                    <span *ngIf="element.closed.all; else emptyCell">{{element.closed.all}}</span>
                                    <span *ngIf="element.closed.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.closed.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.closed.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.closed.all" (click)="statusTotal?.closed.all && goToInqList('CLOSED', null)">
                                    <span *ngIf="statusTotal?.closed.all; else emptyCell">{{statusTotal?.closed.all}}</span>
                                    <span *ngIf="statusTotal?.closed.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.closed.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="cancelled">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef i18n>Deactivated</th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.canceled.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.canceled.all" (click)="element.canceled.all && goToInqList('CANCELED', element.userId)">
                                    <span *ngIf="element.canceled.all; else emptyCell">{{element.canceled.all}}</span>
                                    <span *ngIf="element.canceled.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.canceled.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.canceled.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.canceled.all" (click)="statusTotal?.canceled.all && goToInqList('CANCELED', null)">
                                    <span *ngIf="statusTotal?.canceled.all; else emptyCell">{{statusTotal?.canceled.all}}</span>
                                    <span *ngIf="statusTotal?.canceled.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.canceled.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="deleted">
                                <th rowspan="2" mat-header-cell *matHeaderCellDef i18n>Deleted</th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.deleted.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.deleted.all" (click)="element.deleted.all && goToInqList('DELETED', element.userId)">
                                    <span *ngIf="element.deleted.all; else emptyCell">{{element.deleted.all}}</span>
                                    <span *ngIf="element.deleted.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.deleted.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.deleted.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.deleted.all" (click)="statusTotal?.deleted.all && goToInqList('DELETED', null)">
                                    <span *ngIf="statusTotal?.deleted.all; else emptyCell">{{statusTotal?.deleted.all}}</span>
                                    <span *ngIf="statusTotal?.deleted.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.deleted.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="total">
                                <th class="total" rowspan="2" mat-header-cell *matHeaderCellDef i18n>TOTAL</th>
                                <td class="total" mat-cell *matCellDef="let element" [matTooltip]="element.total.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.total.all" (click)="element.total.all && goToInqList('ALL', element.userId)">
                                    <span *ngIf="element.total.all; else emptyCell">{{element.total.all}}</span>
                                    <span *ngIf="element.total.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.total.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.total.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.total.all" (click)="statusTotal?.total.all && goToInqList('ALL', null)">
                                    <span *ngIf="statusTotal?.total.all; else emptyCell">{{statusTotal?.total.all}}</span>
                                    <span *ngIf="statusTotal?.total.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.total.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="user">
                                <th mat-header-cell *matHeaderCellDef i18n>User</th>
                                <td mat-cell *matCellDef="let element">{{element.userName}}</td>
                                <td class="total" mat-footer-cell *matFooterCellDef i18n>TOTAL</td>
                            </ng-container>

                            <ng-container matColumnDef="to-assign">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span i18n>To assign</span>
                                    <mat-icon class="info-icon" matTooltip="FM Assessment: To Assign" i18n-matTooltip>info_outline</mat-icon>
                                </th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.to_assign.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.to_assign.all" (click)="element.to_assign.all && goToInqList('TO_ASSIGN', element.userId)">
                                    <span *ngIf="element.to_assign.all; else emptyCell">{{element.to_assign.all}}</span>
                                    <span *ngIf="element.to_assign.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.to_assign.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.to_assign.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.to_assign.all" (click)="statusTotal?.to_assign.all && goToInqList('TO_ASSIGN', null)">
                                    <span *ngIf="statusTotal?.to_assign.all; else emptyCell">{{statusTotal?.to_assign.all}}</span>
                                    <span *ngIf="statusTotal?.to_assign.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.to_assign.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="assigned">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span i18n>Assigned</span>
                                    <mat-icon class="info-icon" matTooltip="FM Assessment: Assigned" i18n-matTooltip>info_outline</mat-icon>
                                </th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.assigned.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.assigned.all" (click)="element.assigned.all && goToInqList('ASSIGNED', element.userId)">
                                    <span *ngIf="element.assigned.all; else emptyCell">{{element.assigned.all}}</span>
                                    <span *ngIf="element.assigned.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.assigned.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.assigned.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.assigned.all" (click)="statusTotal?.assigned.all && goToInqList('ASSIGNED', null)">
                                    <span *ngIf="statusTotal?.assigned.all; else emptyCell">{{statusTotal?.assigned.all}}</span>
                                    <span *ngIf="statusTotal?.assigned.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.assigned.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="accepted">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span i18n>Accepted</span>
                                    <mat-icon class="info-icon" matTooltip="FM Assessment: Accepted" i18n-matTooltip>info_outline</mat-icon>
                                </th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.accepted.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.accepted.all" (click)="element.accepted.all && goToInqList('ACCEPTED', element.userId)">
                                    <span *ngIf="element.accepted.all; else emptyCell">{{element.accepted.all}}</span>
                                    <span *ngIf="element.accepted.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.accepted.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.accepted.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.accepted.all" (click)="statusTotal?.accepted.all && goToInqList('ACCEPTED', null)">
                                    <span *ngIf="statusTotal?.accepted.all; else emptyCell">{{statusTotal?.accepted.all}}</span>
                                    <span *ngIf="statusTotal?.accepted.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.accepted.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="bankResponseCompleted">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="fixed-width" i18n>Bank Response Completed</span>
                                    <!-- <mat-icon class="info-icon" matTooltip="Bank Response Completed" i18n-matTooltip>info_outline</mat-icon> -->
                                </th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.bank_response_completed.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.bank_response_completed.all" (click)="element.bank_response_completed.all && goToInqList('BANK_RESPONSE_COMPLETED', element.userId)">
                                    <span *ngIf="element.bank_response_completed.all; else emptyCell">{{element.bank_response_completed.all}}</span>
                                    <span *ngIf="element.bank_response_completed.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.bank_response_completed.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.bank_response_completed.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.bank_response_completed.all" (click)="statusTotal?.bank_response_completed.all && goToInqList('BANK_RESPONSE_COMPLETED', null)">
                                    <span *ngIf="statusTotal?.bank_response_completed.all; else emptyCell">{{statusTotal?.bank_response_completed.all}}</span>
                                    <span *ngIf="statusTotal?.bank_response_completed.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.bank_response_completed.overdue}}
                                    </span>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="matchingFailed">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="fixed-width" i18n>Matching Failed</span>
                                    <!-- <mat-icon class="info-icon" matTooltip="Bank Response Completed" i18n-matTooltip>info_outline</mat-icon> -->
                                </th>
                                <td mat-cell *matCellDef="let element" [matTooltip]="element.matching_failed.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="element.matching_failed.all" (click)="element.matching_failed.all && goToInqList('MATCHING_FAILED', element.userId)">
                                    <span *ngIf="element.matching_failed.all; else emptyCell">{{element.matching_failed.all}}</span>
                                    <span *ngIf="element.matching_failed.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{element.matching_failed.overdue}}
                                    </span>
                                </td>
                                <td class="total" mat-footer-cell *matFooterCellDef [matTooltip]="statusTotal?.matching_failed.all ? translations.finmatchDashboard.inquiriesNumberTooltip : null" [class.clickable]="statusTotal?.matching_failed.all" (click)="statusTotal?.matching_failed.all && goToInqList('MATCHING_FAILED', null)">
                                    <span *ngIf="statusTotal?.matching_failed.all; else emptyCell">{{statusTotal?.matching_failed.all}}</span>
                                    <span *ngIf="statusTotal?.matching_failed.overdue" class="overdue"><span class="delimiter">|</span>
                                        <mat-icon>access_time</mat-icon>{{statusTotal.matching_failed.overdue}}
                                    </span>
                                </td>
                            </ng-container>

                            <!-- <tr class="first-header-row" mat-header-row *matHeaderRowDef="userStatusHeaderTopRow; sticky:true"></tr>
                <tr mat-header-row *matHeaderRowDef="userStatusHeaderBottomRow; sticky: true"></tr> -->
                            <tr mat-header-row *matHeaderRowDef="userStatusRows; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns:userStatusRows;"></tr>
                            <tr mat-footer-row *matFooterRowDef="userStatusRows; sticky:true;"></tr>
                        </table>
                    </ng-scrollbar>
                </div>
            </ng-template>
        </mat-tab>

        <mat-tab label="Company Profiles" i18n-label>
            <ng-template matTabContent>
                <div class="header" i18n>Company Profiles</div>

                <div class="company-filters">
                    <div class="title" i18n>Profiles:</div>
                    <div class="filter" (click)="selectCompanyProfilesFilter('NEW_TODAY')" [class.selected]="companyProfilesFilter === 'NEW_TODAY'" i18n>New Today</div>
                    <div class="filter" (click)="selectCompanyProfilesFilter('NEW_THIS_WEEK')" [class.selected]="companyProfilesFilter === 'NEW_THIS_WEEK'" i18n>New This Week</div>
                </div>

                <ng-scrollbar *ngIf="!isLoading && !companiesLoadError" class="company-profiles" [style.height.px]="56 + userStatusTable.data.length * 48 + 49">
                    <table mat-table [dataSource]="companyProfilesTable" matSort>
                        <ng-container matColumnDef="company">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="companyName" i18n>Company</th>
                            <td mat-cell *matCellDef="let element" class="company-name" (click)="goToCompaniesList(element.companyId)">{{element.companyName}}</td>
                        </ng-container>

                        <ng-container matColumnDef="city">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="city" i18n>City</th>
                            <td mat-cell *matCellDef="let element">{{element.city}}</td>
                        </ng-container>

                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="country" i18n>Country</th>
                            <td mat-cell *matCellDef="let element">{{element.country}}</td>
                        </ng-container>

                        <ng-container matColumnDef="postCode">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="postCode" i18n>Post Code</th>
                            <td mat-cell *matCellDef="let element">{{element.postCode}}</td>
                        </ng-container>

                        <ng-container matColumnDef="profileStatus">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="state" i18n>Profile Status</th>
                            <td mat-cell *matCellDef="let element" class="company-status">
                                <mat-icon *ngIf="element.state === 'Activated'" class="active-icon">check_circle</mat-icon>
                                <mat-icon *ngIf="element.state === 'Registered'" class="registered-icon">access_time</mat-icon>
                                <mat-icon *ngIf="element.state === 'Deleted'" class="deactivated-icon">block</mat-icon>
                                {{translations.companyStates[element.state]}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="timestamp">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="creationDate" i18n>Date Added</th>
                            <td mat-cell *matCellDef="let element">{{element.creationDate | date: 'dd.MM.yyyy HH:mm'}}</td>
                        </ng-container>

                        <ng-container matColumnDef="activation">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="activationDate" i18n>Activation Date</th>
                            <td mat-cell *matCellDef="let element">{{element.activationDate | date: 'dd.MM.yyyy HH:mm'}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="corporateProfilesRows; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns:corporateProfilesRows;"></tr>
                    </table>
                </ng-scrollbar>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #emptyCell>-</ng-template>
