import { Injectable } from '@angular/core';

export type LabelsMap = { [value: string]: string };

@Injectable({
    providedIn: 'root'
})
export class ErrorCodesTranslationService {
    errorCodes: any = {
        // Gateway
        'error.validation': $localize`Data validation error`,
        'error.cannotRemoveYourself': $localize`It is impossible to remove yourself`,
        'error.durationtoerror': $localize`Duration To is out of range (greater than 0 and lower than 1000)`,
        'error.durationfromerror': $localize`Duration From is out of range (greater than 0 and lower than 1000)`,
        'error.durationerror': $localize`Duration to should be greater then duration from`,
        'error.freetexterror': $localize`Free text length error`,
        'error.turnovererror': $localize`Turnover field length mismatch error`,
        'error.crediterror': $localize`Credit to/from length mismatch error`,
        'error.postcodererror': $localize`Postcode to/from length or format mismatch error`,
        'error.bankcodeandpostcodeerror': $localize`Bank with bank code and post code collation already exists`,
        'error.bankdoesnotexist': $localize`The Bank with provided ID does not exist`,
        'error.lastUser': $localize`Cannot delete last user in this collection`,
        'error.bankpermission': $localize`No permission to perform this operation`,
        'error.bankunitdoesntexists': $localize`The Bank Organization Unit with provided ID does not exist`,
        'error.bankuserdata': $localize`Not a valid user data`,
        'error.companydoesntexists': $localize`The Company with provided ID does not exist`,
        'error.companypermission': $localize`No permission to perform this operation`,
        'error.companyuserdata': $localize`Not valid user data`,
        'error.emailexists': $localize`Account with provided Email address already exists`,
        'error.inconsistentdataerror': $localize`Inconsistent data`,
        'error.userexists': $localize`Account with provided Login / Email address already exists`,
        'error.permission': $localize`No permission to perform this operation`,
        'error.unexpectedrole': $localize`Unexpected role`,
        'error.updateusererror': $localize`Update user verification error`,
        'error.usernotexisterror': $localize`User with provided ID does not exist`,
        'error.same.password': $localize`New password should be different than previous one`,
        'error.lackofexpectedrole': $localize`Access Denied`,
        'error.throttlingLoginError': $localize`Maximum amount of unsuccessful logins. Please try again later.`,
        'error.noUserForActivationKey': $localize`No user was found for this activation key`,
        'error.noUserForResetKey': $localize`No user was found for this reset key`,
        'error.concurrencyFailure': $localize`This record has been modified by another user. Please reload the page and try again.`,
        'error.captchaFailed': $localize`Captcha verification failed`,

        // Inquiry
        'error.idexists': $localize`This inquiry has its ID already assigned and it cannot be changed`,
        'error.newInquiryOnlyInDraft': $localize`Newly created inquiry must be in the following states: DRAFT`,
        'error.newInquiryOnlyByCorp': $localize`New Inquiry can only be created by Company user`,
        'error.wrongStateToSelectBanks': $localize`To Propose Banks to an Inquiry it must be in one of the following states: ACCEPTED, BANK SELECTION, ACTIVE`,
        'error.noAssignedUser': $localize`You need to be assigned to the Inquiry to perform this action`,
        // TODO: States
        'error.wrongStateToActivate': $localize`To Accept Offers Inquiry must be in one of the following states: ACTIVE`,
        'error.wrongCompany': $localize`You are not the owner of the Inquiry you are trying to view or change`,
        'error.notSendableStateToCorp': $localize`To Send an Inquiry it must be in one of the following states: DRAFT, REVISION`,
        // TODO: You cannot cancel already canceled Inquiry?
        'error.wrongStateToCancel': $localize`To Cancel an Inquiry it must be in one of the following states: DRAFT, FINMATCH ASSESSMENT, BANK SELECTION, REVISION, ACTIVE, WAITING FOR FINMATCH`,
        'error.wrongUserAssigned': $localize`You need to be assigned to the Inquiry to perform this action`,
        'error.notAcceptableState': $localize`To Accept an Inquiry it must to be in the following states: FINMATCH ASSESSMENT`,
        'error.wrongAcceptingUser': $localize`You need to be assigned to the Inquiry to perform this action`,
        'error.idnull': $localize`Invalid ID`,
        'error.notEditableStateToCorp': $localize`To Edit an Inquiry it must to be in the following states: DRAFT, REVISION`,
        'error.notEditableStateToFin': $localize`To Edit an Inquiry it must be in the following states: DRAFT, REVISION`,
        'error.notAssignedToUser': $localize`You need to be assigned to the Inquiry to perform this action`,
        'error.noRightsToChangeState': $localize`You have not enough privileges to change the state of the Inquiry`,
        'error.nullField': $localize`One of the fields provided in the request is null or empty. Change the field and try again`,
        'error.wrongStateToRevise': $localize`To Revise an Inquiry it must be in the following states: FINMATCH ASSESSMENT`,
        'error.wrongStateReturnOffers': $localize`To Return Offer Selection for an Inquiry it must be in the following states: WAITING FOR FINMATCH`,
        'error.notSupportedOperation': $localize`Operation not supported. Inquiry too old.`,

        // Docupload
        'error.noFileExtension': $localize`Empty or not supported file extension. Extensions supported: jpg, xlsx, png, docx, pptx, txt , pdf, ppt, doc, jpeg, xls`,
        'error.forbiddenChangeOfParentObjId': $localize`Inquiry/Offer/Company ID associated with the file cannot be changed (parentObjectId)`,
        'error.emptyUserCompanyId': $localize`Missing data in authentication token (userCompanyId)`,
        'error.emptyUserCompanyIdDocClass': $localize`Missing data in authentication token (userCompanyId - no document class)`,
        'error.emptyUserCompanyIdParentId': $localize`Missing data in authentication token (userCompanyId - no parentObjectId in document)`,
        'error.virusDetected': $localize`The file may be infected with a virus. Please upload another file and contact your IT support.`,

        // Bankoffers
        'error.offerexisterror': $localize`The offer you are trying to retrieve does not exist`,
        'error.offerRejectedError': $localize`The offer you were trying to work with is in rejected state`,

        // Bankselection
        'error.err.state.to.bankSelection': $localize`To Propose Banks to an Inquiry it must to be in the following states: ACCEPTED, BANK SELECTION, ACTIVE`,
        'error.wrongAssignedUser': $localize`You need to be assigned to the Inquiry to perform this action`,
        'error.err.banks.doesnt.match': $localize`Provided Banks list does not match with the one already proposed before`,

        // // Support
        'error.terms': $localize`FinMatch ToS and Privacy Policy must be set to perform this action`
    };
    constructor() {}
}
