import {Component, OnInit, ViewChild} from '@angular/core';
import {catchError, finalize} from 'rxjs/operators';
import {zip} from 'rxjs';
import {Inquiry, InquiryFactory} from 'src/app/models/InquiryModel';
import {User} from 'src/app/models/UserModel';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {UserStatusRowModel} from '../../models/user-status-row.model';
import {TranslationService} from 'src/app/services/root/translation/translation.service';
import {Company} from 'src/app/models/CompanyModel';
import * as moment from 'moment';
import {UserRepository} from "../../repositories/user.repository";
import {CorporateRepository} from "../../repositories/corporate.repository";

@Component({
    selector: 'app-finmatch-dashboard',
    templateUrl: './finmatch-dashboard.component.html',
    styleUrls: ['./finmatch-dashboard.component.less']
})
export class FinmatchDashboardComponent implements OnInit {
    userStatusHeaderTopRow = [
        'status',
        'draft',
        'revision',
        'fm-assessment',
        'bankSelection',
        'active',
        'bankResponseCompleted',
        'matchingFailed',
        'waitingForFM',
        'signing',
        'closed',
        'cancelled',
        'deleted',
        'total'
    ];
    userStatusHeaderBottomRow = ['user', 'to-assign', 'assigned', 'accepted'];
    userStatusRows = [
        'user',
        'draft',
        'revision',
        'to-assign',
        'assigned',
        'accepted',
        'bankSelection',
        'active',
        'bankResponseCompleted',
        'matchingFailed',
        'waitingForFM',
        'signing',
        'closed',
        'cancelled',
        'deleted',
        'total'
    ];
    corporateProfilesRows = ['company', 'city', 'country', 'postCode', 'profileStatus', 'timestamp', 'activation'];
    navigationTabs = ['inquiries', 'companies'];
    isLoading = true;
    inquiriesLoadError = false;
    companiesLoadError = false;
    userStatusTable: MatTableDataSource<UserStatusRowModel> = new MatTableDataSource<UserStatusRowModel>();
    companyProfiles: Company[];
    companyProfilesFilter: 'NEW_TODAY' | 'NEW_THIS_WEEK' = 'NEW_TODAY';
    companyProfilesTable: MatTableDataSource<Company> = new MatTableDataSource<Company>();
    statusTotal: UserStatusRowModel;
    selectedTabIndex = 0;

    constructor(
        private inqService: InquiryFactory,
        private router: Router,
        public translations: TranslationService,
        private route: ActivatedRoute,
        private userRepository: UserRepository,
        private corporateRepository: CorporateRepository
    ) {
    }

    @ViewChild(MatSort) set sort(sort: MatSort) {
        if (sort) {
            this.companyProfilesTable.sort = sort;
        }
    }

    ngOnInit() {
        this.route.params.subscribe(
            params =>
                (this.selectedTabIndex =
                    this.navigationTabs.indexOf(params.tab) < 0 ? 0 : this.navigationTabs.indexOf(params.tab))
        );

        zip(
            this.userRepository.index().pipe(
                catchError(() => {
                    this.inquiriesLoadError = true;
                    return [];
                })
            ),
            this.inqService.getInquiries('').pipe(
                catchError(() => {
                    this.inquiriesLoadError = true;
                    return [];
                })
            ),
            this.corporateRepository.all().pipe(
                catchError(() => {
                    this.companiesLoadError = true;
                    return [];
                })
            )
        )
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe(([fmUsers, {inquiries}, companies]) => {
                if (fmUsers && inquiries) {
                    this.createUserStatusTable(fmUsers, inquiries);
                }
                this.companyProfiles = companies;
                this.selectCompanyProfilesFilter('NEW_TODAY');
            });
    }

    createUserStatusTable(users: User[], inquiries: Inquiry[]) {
        const userStatusData = new Array<UserStatusRowModel>();

        this.statusTotal = {
            draft: {all: 0, overdue: 0},
            revision: {all: 0, overdue: 0},
            to_assign: {all: 0, overdue: 0},
            assigned: {all: 0, overdue: 0},
            accepted: {all: 0, overdue: 0},
            bank_selection: {all: 0, overdue: 0},
            bank_response_completed: {all: 0, overdue: 0},
            matching_failed: {all: 0, overdue: 0},
            active: {all: 0, overdue: 0},
            review_offer_selection: {all: 0, overdue: 0},
            review_signing: {all: 0, overdue: 0},
            closed: {all: 0, overdue: 0},
            canceled: {all: 0, overdue: 0},
            deleted: {all: 0, overdue: 0},
            total: {all: 0, overdue: 0}
        };

        const userDataUnassigned: UserStatusRowModel = {
            userName: this.translations.unassigned,
            userId: false,
            draft: {all: 0, overdue: 0},
            revision: {all: 0, overdue: 0},
            to_assign: {all: 0, overdue: 0},
            assigned: {all: 0, overdue: 0},
            accepted: {all: 0, overdue: 0},
            bank_selection: {all: 0, overdue: 0},
            bank_response_completed: {all: 0, overdue: 0},
            matching_failed: {all: 0, overdue: 0},
            active: {all: 0, overdue: 0},
            review_offer_selection: {all: 0, overdue: 0},
            review_signing: {all: 0, overdue: 0},
            closed: {all: 0, overdue: 0},
            canceled: {all: 0, overdue: 0},
            deleted: {all: 0, overdue: 0},
            total: {all: 0, overdue: 0}
        };

        inquiries
            .filter(inq => !inq.getFinmatchAssigneeId())
            .forEach(inq => {
                const state = inq.getInternalState().toLowerCase();
                const overdue = inq.isOverdue() ? 1 : 0;
                if (userDataUnassigned[state]) {
                    userDataUnassigned[state].all += 1;
                    userDataUnassigned[state].overdue += overdue;
                    userDataUnassigned.total.all += 1;
                    userDataUnassigned.total.overdue += overdue;
                }
                if (this.statusTotal[state]) {
                    this.statusTotal[state].all += 1;
                    this.statusTotal[state].overdue += overdue;
                    this.statusTotal.total.all += 1;
                    this.statusTotal.total.overdue += overdue;
                }
            });

        userStatusData.push(userDataUnassigned);

        users.forEach(user => {
            const userDataPersonal: UserStatusRowModel = {
                userName: user.firstName + ' ' + user.lastName,
                userId: user.id,
                draft: {all: 0, overdue: 0},
                revision: {all: 0, overdue: 0},
                to_assign: {all: 0, overdue: 0},
                assigned: {all: 0, overdue: 0},
                accepted: {all: 0, overdue: 0},
                bank_selection: {all: 0, overdue: 0},
                bank_response_completed: {all: 0, overdue: 0},
                matching_failed: {all: 0, overdue: 0},
                active: {all: 0, overdue: 0},
                review_offer_selection: {all: 0, overdue: 0},
                review_signing: {all: 0, overdue: 0},
                closed: {all: 0, overdue: 0},
                canceled: {all: 0, overdue: 0},
                deleted: {all: 0, overdue: 0},
                total: {all: 0, overdue: 0}
            };

            inquiries
                .filter(inq => inq.getFinmatchAssigneeId() === user.id)
                .forEach(inq => {
                    const state = inq.getInternalState().toLowerCase();
                    const overdue = inq.isOverdue() ? 1 : 0;
                    if (userDataPersonal[state]) {
                        userDataPersonal[state].all += 1;
                        userDataPersonal[state].overdue += overdue;
                        userDataPersonal.total.all += 1;
                        userDataPersonal.total.overdue += overdue;
                    }
                    if (this.statusTotal[state]) {
                        this.statusTotal[state].all += 1;
                        this.statusTotal[state].overdue += overdue;
                        this.statusTotal.total.all += 1;
                        this.statusTotal.total.overdue += overdue;
                    }
                });
            userStatusData.push(userDataPersonal);
        });

        this.userStatusTable.data = userStatusData;
    }

    tabChange(tab) {
        this.router.navigate(['/dashboard', this.navigationTabs[tab.index]]);
    }

    goToInqList(status: string, assignment: string) {
        this.router.navigate(['/inquiry/list'], {
            queryParams: {
                status,
                assignment
            }
        });
    }

    goToCompaniesList(id: string) {
        this.router.navigate(['/settings/companies'], {
            queryParams: {
                id
            }
        });
    }

    selectCompanyProfilesFilter(filter: 'NEW_TODAY' | 'NEW_THIS_WEEK') {
        this.companyProfilesFilter = filter;
        this.companyProfilesTable.data = this.companyProfiles.filter((company: Company) => {
            const creationDate = new Date(company.creationDate);
            return company.creationDate && this.companyProfilesFilter === 'NEW_TODAY' ? this.isToday(creationDate) : this.isThisWeek(creationDate);
        });
    }

    private isToday(date: Date) {
        return date && moment().isSame(date, 'day');
    }

    private isThisWeek(date: Date) {
        return moment().subtract(7, 'days').isSameOrBefore(date, 'day');
    }
}
