import {FinMatchAccount} from '../models/finmatch-account.model';
import {Router} from '@angular/router';
import {Principal} from './auth/principal.service';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UsageAgreementDialogComponent} from '../modules/shared/components/usage-agreement/usage-agreement-dialog/usage-agreement-dialog.component';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Inquiry} from '../models/InquiryModel';

@Injectable({providedIn: 'root'})
export class UsageAgreementDialogService {
    public loggedUser: FinMatchAccount = null;
    dialogRef: MatDialogRef<UsageAgreementDialogComponent> = null;

    constructor(
        private dialogService: MatDialog,
        public principal: Principal,
        public router: Router,
        private http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {
        this.principal.getAuthenticationState().subscribe(subject => {
            this.loggedUser = subject;
        });
    }

    public open(bankData: any, agreementType: 'usage' | 'policy' = 'usage', inquiry?: Inquiry, reWatch = false, readOnly = false): Observable<boolean> {
        const modalData = {
            bank: bankData || null,
            agreementType: agreementType,
            inquiry: inquiry || null,
            reWatch,
            readOnly
        };

        if (this.dialogRef) {
            this.dialogRef.close(null);
            this.dialogRef = null;
        }

        this.dialogRef = this.dialogService.open(UsageAgreementDialogComponent, {
            data: modalData,
            disableClose: !reWatch && !readOnly,
        });

        return this.dialogRef.beforeClosed();
    }
}
