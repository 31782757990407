import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {Bank} from '../../models/bank.model';
import {map} from 'rxjs/operators';
import {InquiryModuleKey} from "../../models/InquiryModel";

@Injectable({ providedIn: 'root' })
export class BankSelectionService {
    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}

    public getInquiryMatchingActiveBanks(): Observable<Bank[]> {
        return this.http
            .get<Bank[]>(`${this.config.API_URL}${this.config.API_URLs.banks}/all/?onlyActive=true`)
            .pipe(map(response => response['data']));
    }

    /**
     * @deprecated, use `getInquiryMatchingBanks` as of 1.35 version.
     */
    public getInquiryAutoMatchedBanks(inquiryId: string): Observable<{[key in InquiryModuleKey]: string[]}> {
        return this.http.get<{[key in InquiryModuleKey]: string[]}>(`${this.config.API_URL}${this.config.API_URLs.suggestedBanks}/${inquiryId}`);
    }

    public getInquiryMatchingBanks(inquiryId: string): Observable<Bank[]> {
        return this.http.get<Bank[]>(`${this.config.API_URL}${this.config.API_URLs.matchingBanks}/${inquiryId}`).pipe(
            map(response => response['data'])
        );
    }

    public getInquiryFinancingBanks(inquiryId: string, withUsers = false): Observable<Bank[]> {
        return this.http
            .get(`${this.config.API_URL}${this.config.API_URLs.financingBanks}/${inquiryId}`, {
                params: {
                    withUsers: !!withUsers ? "true" : "false",
                }
            })
            .pipe(map(response => (response['data'] ? response['data'].financingBanks : [])));
    }

    // public getMultipleInquiriesFinancingBanks(ids: string[]): Observable<{[key: string]: Bank[]}> {
    //     return this.http
    //         .put<{[key: string]: Bank[]}>(`${this.config.API_URL}${this.config.API_URLs.multiInquiriesFinancingBanks}`, ids, {observe: 'body'});
    // }

    public saveInquiryFinancingBanks(inquiryId: string, financingBanks: Bank[]): Observable<Bank[]> {
        return this.http
            .post(`${this.config.API_URL}${this.config.API_URLs.financingBanks}`, {
                inquiryId,
                financingBanks
            })
            .pipe(map(response => response['financingBanks']));
    }

    public markInquiryBanksAsSeenByCorp(inquiryId: string, banks: string[]) {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.bankSelectionInquiries}/${inquiryId}/seen-banks`, {
            bankIds: banks,
        });
    }

    public markInquiryBanksAsSeenByBank(inquiryId: string) {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.markInquirySeenByBank}/${inquiryId}`, {});
    }

    public confirmBanks(inquiryId: string, banks: string[]) {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.bankSelectionInquiries}/${inquiryId}/confirmed-banks`, {
            bankIds: banks,
        });
    }

    public rejectBanks(inquiryId: string, banks: string[]) {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.bankSelectionInquiries}/${inquiryId}/rejected-banks`, {
            bankIds: banks,
        });
    }

    public setBanksContacts(usersPerBanksMap, inquiryId: string, notifyUsers) {
        return this.http.post(`${this.config.API_URL}${this.config.API_URLs.bankSelectionInquiries}/${inquiryId}/contact-persons`, {
            data: usersPerBanksMap,
            notifyUsers,
        });
    }

    public setBanksContactsByBank(usersPerBanksMap, inquiryId: string) {
        return this.http.put(`${this.config.API_URL}${this.config.API_URLs.bankSelectionInquiries}/${inquiryId}/contact-persons`, usersPerBanksMap);
    }

    public saveInquirySelectedBanks(inquiryId: string, financingBanks: Bank[]): Observable<Bank[]> {
        return this.http
            .post(`${this.config.API_URL}${this.config.API_URLs.selectedBanks}`, {
                inquiryId,
                financingBanks
            })
            .pipe(map(response => response['financingBanks']));
    }
}
