<form [class.readonly]="readonly" [formGroup]="form" autocomplete="off" class="finmatch-form">
    <div *ngIf="!readonly" class="form-required-information" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form"></app-financing-plans-field>

    <div class="section-label" i18n="@@Finanzierung">Loan</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="redemptionAmount" i18n-placeholder placeholder="Redemption amount" required></app-number-input>
                <mat-error [errorMessage]="form.get('redemptionAmount')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('redemptionAmount')" i18n>Redemption amount</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Areas</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="propertyArea" [placeholder]="fieldLabels.propertyArea.label"></app-number-input>
                <mat-error [errorMessage]="form.get('propertyArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('propertyArea')" suffix="m²">{{fieldLabels.propertyArea.label}}</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Location</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select formControlName="country" i18n-placeholder placeholder="Country" required>
                    <mat-option *ngFor="let country of countries" [innerText]="country.viewValue" [value]="country.value"></mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('country')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('country')" [map]="countries" i18n>Country</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="streetAddress" i18n-placeholder matInput placeholder="Street" type="text">
                <mat-error [errorMessage]="form.get('streetAddress')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('streetAddress')" i18n>Street</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="houseNumber" i18n-placeholder matInput placeholder="House number" type="text">
                <mat-error [errorMessage]="form.get('houseNumber')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('houseNumber')" i18n>House number</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="postalCode" i18n-placeholder matInput placeholder="Postcode">
                <mat-error [errorMessage]="form.get('postalCode')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('postalCode')" i18n>Postcode</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="city" i18n-placeholder matInput placeholder="City" required type="text">
                <mat-error [errorMessage]="form.get('city')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('city')" i18n>City</app-form-field-readonly>
        </div>
    </div>
</form>
