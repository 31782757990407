import {
    BuildingClassification,
    BuildingTypes,
    BuildingTypesV2,
    InquiryConfigurationService
} from 'src/app/services/inquiry/inquiry-configuration.service';
import {BuildingType} from '../../../../../../models/InquiryModel';
import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {LabelsMap, TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const RealEstateNewBuildingExpertFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    buildingApartmentsArea: [null, [Validators.max(9999999)]],
    buildingArea: [null, [customValidators.area9]],
    buildingCosts: [null, [Validators.required]],
    buildingDescription: [null, [Validators.required, Validators.maxLength(100)]],
    buildingOfficesArea: [null, [customValidators.area7]],
    buildingProductionArea: [null, [customValidators.area7]],
    buildingRetailArea: [null, [customValidators.area7]],
    buildingStoreArea: [null, [customValidators.area7]],
    buildingType: [null, [Validators.required]],
    buildingUsageCategory: ['OWN_USAGE', []],
    buyingCosts: [null, [Validators.required]],
    classification: [null, [Validators.required]],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    country: ['GERMANY', [Validators.required]],
    efficiencyStandard: ['UNKNOWN', [Validators.required]],
    groundRent: [null, [customValidators.price()]],
    houseNumber: [null, [Validators.maxLength(5)]],
    housingUnitsCount: [null, [Validators.required, Validators.max(9999)]],
    knownConstructionCosts: [false, [Validators.required]],
    leaseHoldOnProperty: [false, []],
    mortgageOnProperty: [false, [Validators.required]],
    mortgageRemainingDebt: [null, [Validators.required, customValidators.price()]],
    ownerPropertyValue: [null, [Validators.required, Validators.max(999999999)]],
    plannedDateOfCompletion: [null, [Validators.required]],
    plannedStartOfConstruction: [null, [Validators.required]],
    postalCode: [null, []],
    propertyArea: [null, [Validators.max(999999999)]],
    propertyCosts: [null, [Validators.required, customValidators.price()]],
    propertyOwner: [false, [Validators.required]],
    remainingTermLeaseHold: [null, [customValidators.remainingTermLeaseHold]],
    streetAddress: [null, [Validators.maxLength(100)]],
    totalSalesPrice: [null, [Validators.required, customValidators.price()]],
};
const buildingTypeToBuildingClassifications = {
    COMMERCIAL_BUILDING: [
        BuildingClassification.PROJECT_FINANCING,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.HOTEL,
        BuildingClassification.PLANT,
    ],
    RESIDENTIAL_USE: [
        BuildingClassification.FINANCING_OF_PROPERTY_DEVELOPER,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.APPORTIONMENT,
    ],
};

@Component({
    selector: 'app-real-estate-new-building-expert-form',
    templateUrl: './real-estate-new-building-expert-form.component.html',
    styleUrls: ['./real-estate-new-building-expert-form.component.less']
})
export class RealEstateNewBuildingExpertFormComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean = false;
    @Input() currency: string;
    public buildingTypes: Array<BuildingType>;
    public buildingTypeLabels: LabelsMap;
    public efficiencyStandards: Array<{ value: string, viewValue: string }> = [];
    public countries: Array<{ value: string, viewValue: string }> = [];
    public usageCategories: LabelsMap;
    public buildingClassificationOptions = [];
    private subscriptions: Subscription[] = [];

    constructor(
        public translationService: TranslationService,
        private inquiryConfigurationService: InquiryConfigurationService,
    ) {

        this.efficiencyStandards = this.translationService.inquiryEfficiencyStandards;
        this.countries = this.translationService.inquiryRealEstateCountries;

        this.usageCategories = this.translationService.buildingUsageCategories;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateBuildingTypeFields();
            this.updatePropertyOwnershipFields();
            this.updateMortgageFields();
            this.updateCostAllocationFields();
            this.updateTotalSalesPrice();
            this.updateLeaseholdFields();
            this.updateCountryRelatedFields();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit() {
        if (this.inquiryConfigurationService.hasFormOldBuildingType(this.form)) {
            this.buildingTypes = BuildingTypes;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypes;
            this.form.controls.classification.disable();
        } else {
            this.buildingTypes = BuildingTypesV2;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypesV2;
            this.subscriptions.push(
                this.form.controls.buildingType.valueChanges.pipe(startWith(this.form.controls.buildingType.value))
                    .subscribe(buildingType => {
                        this.buildingClassificationOptions = this.inquiryConfigurationService.buildingClassificationOptions.filter(
                            option => !buildingTypeToBuildingClassifications[buildingType] || buildingTypeToBuildingClassifications[buildingType].includes(option.value)
                        );
                        if (!this.buildingClassificationOptions.map(option => option.value).includes(this.form.controls.classification.value)) {
                            this.form.controls.classification.setValue(null);
                        }
                    })
            )
        }
        this.form.get('buildingType').valueChanges.subscribe(() => {
            this.updateBuildingTypeFields();
        });
        this.form.get('propertyOwner').valueChanges.subscribe(() => {
            this.updatePropertyOwnershipFields(true);
        });
        this.form.get('mortgageOnProperty').valueChanges.subscribe(() => {
            this.updateMortgageFields();
        });
        this.form.get('knownConstructionCosts').valueChanges.subscribe(() => {
            this.updateCostAllocationFields(true);
        });
        this.form.get('leaseHoldOnProperty').valueChanges.subscribe(() => {
            this.updateLeaseholdFields();
        });

        this.form.get('propertyCosts').valueChanges.subscribe(() => this.updateTotalSalesPrice());
        this.form.get('buildingCosts').valueChanges.subscribe(() => this.updateTotalSalesPrice());
        this.form.get('buyingCosts').valueChanges.subscribe(() => this.updateTotalSalesPrice());

        this.form.get('country').valueChanges.subscribe(() => this.updateCountryRelatedFields());
    }

    public updateBuildingTypeFields(): void {
        let isOtherUse = (this.form.get('buildingType').value === 'OTHER') ? true : false;
        let isMixedUse = (this.form.get('buildingType').value === 'MIXED_USE') ? true : false;
        let isResidentialUse = (this.form.get('buildingType').value === 'RESIDENTIAL_USE') ? true : false;
        let isCommercialBuilding = this.form.get('buildingType').value === 'COMMERCIAL_BUILDING';
        const isMixedOrCommercial = isMixedUse || isCommercialBuilding;

        this.form.get('buildingDescription')[(isOtherUse ? 'enable' : 'disable')]();

        this.form.get('buildingProductionArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingRetailArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingStoreArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingOfficesArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingApartmentsArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();

        this.form.get('housingUnitsCount')[(isResidentialUse ? 'enable' : 'disable')]();
        this.form.get('buildingUsageCategory')[(isResidentialUse ? 'enable' : 'disable')]();

        if (!isOtherUse) {
            this.form.get('buildingDescription').setValue(null);
        }

        if (!isMixedOrCommercial) {
            this.form.get('buildingProductionArea').setValue(null);
            this.form.get('buildingRetailArea').setValue(null);
            this.form.get('buildingStoreArea').setValue(null);
            this.form.get('buildingOfficesArea').setValue(null);
            this.form.get('buildingApartmentsArea').setValue(null);
        }

        if (!isResidentialUse) {
            this.form.get('housingUnitsCount').setValue(null);
            this.form.get('buildingUsageCategory').setValue(null);
        } else {
            if (this.form.get('buildingUsageCategory').value === null) {
                this.form.get('buildingUsageCategory').setValue('OWN_USAGE');
            }
        }
    }

    public updatePropertyOwnershipFields(userChange: boolean = false): void {
        let isOwner = (this.form.get('propertyOwner').value === true) ? true : false;

        this.form.get('ownerPropertyValue')[(isOwner ? 'enable' : 'disable')]();
        this.form.get('mortgageOnProperty')[(isOwner ? 'enable' : 'disable')]();

        if (!isOwner) {
            this.form.get('ownerPropertyValue').setValue(null);
            this.form.get('mortgageOnProperty').setValue(null);
        }

        if (isOwner && userChange) {
            this.form.get('mortgageOnProperty').setValue(false);
        }

        this.updateMortgageFields();
        this.updateCostAllocationFields();
    }

    public updateMortgageFields(): void {
        let isMortgage = (this.form.get('mortgageOnProperty').value === true) ? true : false;

        this.form.get('mortgageRemainingDebt')[(isMortgage ? 'enable' : 'disable')]();

        if (!isMortgage) {
            this.form.get('mortgageRemainingDebt').setValue(null);
        }
    }

    public updateCostAllocationFields(clearTotal: boolean = false): void {
        let knownAllocationCosts = (this.form.get('knownConstructionCosts').value === true) ? true : false;
        let isPropertyOwner = (this.form.get('propertyOwner').value === true) ? true : false;

        this.form.get('propertyCosts')[(knownAllocationCosts && !isPropertyOwner ? 'enable' : 'disable')]();
        this.form.get('buildingCosts')[(knownAllocationCosts ? 'enable' : 'disable')]();
        this.form.get('buyingCosts')[(knownAllocationCosts ? 'enable' : 'disable')]();

        if (!(knownAllocationCosts && !isPropertyOwner)) {
            this.form.get('propertyCosts').setValue(null);
        }

        if (!knownAllocationCosts) {
            this.form.get('buildingCosts').setValue(null);
            this.form.get('buyingCosts').setValue(null);
        }

        if (!knownAllocationCosts && clearTotal) {
            this.form.get('totalSalesPrice').setValue(null);
        }
    }

    public updateTotalSalesPrice(): void {
        let knownAllocationCosts = (this.form.get('knownConstructionCosts').value === true) ? true : false;
        if (!knownAllocationCosts) {
            return;
        }

        let propertyCosts = this.form.get('propertyCosts').value || 0;
        let buildingCosts = this.form.get('buildingCosts').value || 0;
        let buyingCosts = this.form.get('buyingCosts').value || 0;

        let totalPrice = (propertyCosts + buildingCosts + buyingCosts);
        this.form.controls.totalSalesPrice.setValue(totalPrice);
    }

    public updateLeaseholdFields(): void {
        let isLeasehold = (this.form.get('leaseHoldOnProperty').value === true) ? true : false;

        this.form.get('groundRent')[(isLeasehold ? 'enable' : 'disable')]();
        this.form.get('remainingTermLeaseHold')[(isLeasehold ? 'enable' : 'disable')]();

        if (!isLeasehold) {
            this.form.get('groundRent').setValue(null);
            this.form.get('remainingTermLeaseHold').setValue(null);
        }
    }

    public updateCountryRelatedFields(): void {
        let country = this.form.get('country').value;

        let postCodeValidator = (country === 'GERMANY') ? customValidators.postalCodeOptionalValidator5 : customValidators.postalCodeOptionalValidator4;
        this.form.get('postalCode').setValidators([postCodeValidator]);
        this.form.get('postalCode').updateValueAndValidity();
    }

}
