import {Router} from '@angular/router';
import {FinMatchAccount} from '../../models/finmatch-account.model';
import {APP_CONFIG, AppConfig} from 'src/app/app-config.module';
import {Inject, Injectable} from '@angular/core';
// import * as pdfMake from 'pdfmake/build/pdfmake.js';
// import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import {Observable, of} from 'rxjs';
import {map, share, shareReplay, switchMap} from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Principal} from '../auth/principal.service';
import {Bank} from "../../models/bank.model";
import {InquiryService} from "../inquiry/inquiry.service";
import {LoginService} from "../login/login.service";
import {StateStorageService} from "../auth/state-storage.service";
import {UsageAgreementDialogService} from "../usage-agreement-dialog.service";
import {MatDialog} from '@angular/material/dialog';
import {Inquiry, InquiryOtua} from '../../models/InquiryModel';
import {
    InquiryRejectionDialogComponent,
    InquiryRejectionModalData
} from '../../modules/shared/components/inquiry/inquiry-rejection-dialog/inquiry-rejection-dialog.component';
import {BanksService} from '../provided/banks.service';

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// const AgreementFileName = 'FinMatch-One_Time_Usage_and_Fee_Agreement.pdf';

interface TemplateParams {
    [key: string]: string | Array<string>;
}

export interface OtuaUrlResponse {
    download_url: string;
    template_id?: string;
    transaction_ref?: string;
    status?: string;
}

@Injectable({providedIn: 'root'})
export class UsageAgreementService {
    public loggedUser: FinMatchAccount = null;

    public documentContent: TemplateParams = {
        documentTitle: $localize`Agreement`,
        betweenAlias: $localize`between`,
        representedBy: $localize`represented by Thomas Becer`,
        operatorAlias: $localize`hereinafter referred to as „FinMatch“`,
        bankAlias: $localize`hereinafter referred to as „financing partner"`,
        companyAlias: $localize`Company`,
        andAlias: $localize`and`,
        preambleTitle: $localize`Preamble`,
        preambleContent: $localize`FinMatch operates a platform for corporate financing, where users have the opportunity to place credit requests and credit inquiries via the platform to potential financing partners.`,
        preambleContentPar2: $localize`The financing partners, for their part, have the opportunity to also register on the platform and then receive credit requests and credit inquiries from individual users.`,

        agreementHintAlias: $localize`This said, the following is agreed upon:`,

        intermediaryServicesTitle: $localize`Intermediary services of FinMatch`,
        intermediaryServicesIntro: $localize`FinMatch provides the following mediation services:`,
        intermediaryServicesList: [
            $localize`Mediation, tendering, application and support of the conclusion of contracts for corporate financing using the MatchPoint financing platform.`,
            $localize`For this purpose, the financing partner is provided with the necessary tender and application documents via his access account.`,
            $localize`FinMatch is entitled to contract sub-agents for the mediation, tendering and application process who act in  their  own  name  towards  the  company.  The  financing  partner  is  prohibited  from  making  direct  or additional commission payments to the intermediary for cases submitted via FinMatch.`,
        ],

        claimForCommissionTitle: $localize`Claim for commission`,
        claimForCommissionList: [
            $localize`FinMatch is entitled to payment of a brokerage commission in the event of a successful referral to the financing partner using the services which are the subject matter of the contract.`,
            $localize`The remuneration is based in each case on a single, specific transaction that was brokered and is deposited in the tender documents on the platform.`,
            $localize`By submitting an offer in response to a tender, the financing partner accepts payment of the commission in accordance with the tender documents in the event of success.`,
            $localize`The commission is payable to FinMatch. The claim of FinMatch to the remuneration for the brokerage arises after the conclusion of the loan agreement and the expiration of any statutory revocation periods.`,
        ],
        commissionParagraph1: $localize`The remuneration is settled weekly, is due 14 days after the conclusion of the loan agreement between the financing partner and the company and is paid to the following account of FinMatch:`,
        finmatchAccountLine1: $localize`Volksbank am Württemberg eG | BLZ 600 603 96 | Account 75802007`,
        finmatchAccountLine2: $localize`IBAN DE54 6006 0396 0075 8020 07 | BIC GENODES1UTV`,
        commissionParagraph2: $localize`With payment of the commission, all services and all related "costs" etc. of FINMATCH are settled. `,
        commissionParagraph3: $localize`The Financing Partner undertakes to inform FinMatch immediately if a loan agreement has been concluded with the corporate client named in the tender.`,
    };

    public checkUsageAgreement;
    public otuaURLsHTML = {};

    constructor(
        public http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig,
        public deviceService: DeviceDetectorService,
        public principal: Principal,
        public router: Router,
        private inquiryService: InquiryService,
        private loginService: LoginService,
        private banksService: BanksService,
        private stateStorageService: StateStorageService,
        private usageAgreementDialogService: UsageAgreementDialogService,
        private dialogService: MatDialog,
        // private eventManager: EventManagerService,
    ) {
        this.principal.getAuthenticationState().subscribe(subject => {
            this.loggedUser = subject;
        });

        // this.setThrottleOnCheckingAgreement();
        // this.eventManager.subscribe(this.appConfig.eventTypes['logout'], () => {
        //     // reset throttle on logout
        //     this.setThrottleOnCheckingAgreement();
        // });
    }

    // not in use
    // private setThrottleOnCheckingAgreement() {
    //     this.checkUsageAgreement = _.throttle(this.checkUsageAgreementToThrottle, 10000, {
    //         trailing: false,
    //     });
    // }

    public getDataPolicyHtml() {
        return this.http.get(`${this.appConfig.landing_URL}datenschutz`, {responseType: 'text'}).pipe(
            map(policyPage => {
                const parser = new DOMParser();
                const parsed = parser.parseFromString(policyPage, 'text/html');
                return parsed.getElementById('panel-30-0-0-0').innerHTML;
            }),
            shareReplay(),
        );
    }

    getOtuaUrl(inquiry, bank, formatHTML = false): Observable<string> {
        if (formatHTML && this.otuaURLsHTML[inquiry.id]) {
            return of(this.otuaURLsHTML[inquiry.id]);
        }

        const format = formatHTML ? '&output_format=html' : '';
        const otuaTemplateData = JSON.stringify(this.getOtuaTemplateData(inquiry, bank));
        const headers = {'Content-Type': 'application/json', 'x-api-key': 'fa2aNTg2OToyODgwOkZPT1hVT3FRNlA5MjEwRlk'};

        // below apitemplate url is used also on BE - so any change needs to be done in both places
        return this.http.post('https://rest-au.apitemplate.io/v2/create-pdf?template_id=9ae77b2b2a05e870' + format, otuaTemplateData, {headers}).pipe(
            switchMap((otuaUrlResponse: OtuaUrlResponse) => {
                if (formatHTML) {
                    this.otuaURLsHTML[inquiry.id] = otuaUrlResponse.download_url;
                }
                return of(otuaUrlResponse.download_url);
            })
        );
    }

    cacheOtuaUrl(inquiry) {
        if (this.otuaURLsHTML[inquiry.id]) return;

        this.banksService.getBank(this.loggedUser.companyId).subscribe(bank => {
            this.getOtuaUrl(inquiry, bank, true).subscribe();
        });
    }

    getOtuaTemplateData(inquiry: Inquiry, bank: Bank) {
        return {
            bankName: bank?.bankName,
            bankAddress: bank ? `${bank?.street} ${bank?.houseNumber}, ${bank?.postCode} ${bank?.city}` : undefined,
            projectTitle: inquiry?.heading,
            companyName: inquiry?.companyName
        };
    }

    mapInquiryResponseToInquiry(inquiry): Inquiry {
        return {
            id: inquiry.inquiry.id,
            heading: inquiry.inquiry.heading,
            companyName: inquiry.companyName,
        } as Inquiry;
    }

    mapInquiryOtuaToInquiry(inquiry: InquiryOtua): Inquiry {
        return {
            id: inquiry.inquiryId,
            heading: inquiry.inquiryTitle,
            companyName: inquiry.companyName,
        } as Inquiry;
    }

    acceptAgreement(type: 'usage' | 'policy' = 'usage'): Observable<HttpResponse<any>> {
        const path = {
            usage: 'api/bank/signs-one-time-usage-agreement',
            policy: 'api/users/current/policy-agreement'
        }[type];
        return this.http
            .post(this.appConfig.API_URL + path, {}, {observe: 'response'})
            .pipe(share());
    }

    acceptOneTimeInquiry(inquiry: Inquiry, bank: Bank) {
        const body = this.getOtuaTemplateData(inquiry, bank);
        return this.http.post(this.appConfig.API_URL + `inquiry/api/inquiries/${inquiry.id}/one-time-agreement`, body);
    }

    rejectAgreement(inquiryId, rejectionReason) {
        return this.http.post(this.appConfig.API_URL + `inquiry/api/inquiries/${inquiryId}/reject-one-time-agreement`, rejectionReason);
    }

    public openUsageAgreement(bank: Bank, inquiry?: Inquiry): Observable<boolean> {
        return this.usageAgreementDialogService.open(bank, 'usage', inquiry).pipe(
            switchMap((agreementAccepted) => {
                if (agreementAccepted === null) return of(null);

                if (agreementAccepted) {
                    if (!inquiry) {
                        this.loggedUser.usageAgreementSigned = true;
                    }
                    return of(true);
                } else if (agreementAccepted === false) {
                    return this.openUsageAgreementDenyConfirmation(bank, inquiry);
                }
            }),
        );
    }

    private openUsageAgreementDenyConfirmation(bank: Bank, inquiry: Inquiry) {
        const dialog = this.dialogService.open(InquiryRejectionDialogComponent, {data: <InquiryRejectionModalData> {
                mode: 'reject-otua'
            }});
        return dialog.beforeClosed().pipe(
            switchMap((result) => {
                if (result?.inquiryRejected) {
                    this.rejectAgreement(inquiry.id, result.reason).subscribe();
                    this.dialogService.closeAll();
                    return of(false);
                } else {
                    return this.openUsageAgreement(bank, inquiry);
                }
            }),
        );
    }

    // not in use
    // public getAgreementHTML(): Observable<any> {
    //     return this.http
    //         .get(`/assets/docs/usage_and_fee_agreement.html`, {observe: 'body', responseType: 'text'})
    //         .pipe(share());
    // }

    // not in use
    // public getLogoB64(): Observable<any> {
    //     return this.http.get(`/assets/docs/finmatch-logo.b64`, {observe: 'body', responseType: 'text'}).pipe(share());
    // }

    // not in use
    // public getFooterB64(): Observable<any> {
    //     return this.http
    //         .get(`/assets/docs/finmatch-footer.b64`, {observe: 'body', responseType: 'text'})
    //         .pipe(share());
    // }

    // not in use
    // getTemplateData(bankData: any): TemplateParams {
    //     const data = {
    //         bankName: bankData.bankName,
    //         bankStreet: bankData.street,
    //         bankHouseNumber: bankData.houseNumber,
    //         bankZipCode: bankData.postCode,
    //         bankCity: bankData.city
    //     };
    //     _.assign(data, this.documentContent);
    //
    //     return _.cloneDeep(data);
    // }

    // not in use
    // generateUsageAgreement(bankData: any, inquiry?: any): Observable<any> {
    //     let templateData: TemplateParams;
    //     templateData = this.getTemplateData(bankData);
    //
    //     const observable = new Observable(observer => {
    //         const agreementHeaderB64 = this.getLogoB64();
    //         const agreementFooterB64 = this.getFooterB64();
    //
    //         const docDependencies = forkJoin(agreementHeaderB64, agreementFooterB64);
    //         docDependencies.subscribe(([logoB64, footerB64]) => {
    //             if (inquiry) {
    //                 templateData.agreementHintAlias = this.i18n(`This said, the following is agreed upon for the financing project
    //                     {{inquiryTitle}} of the company {{companyName}}:
    //                     `,
    //                     {inquiryTitle: inquiry.inquiryTitle, companyName: inquiry.companyName}
    //                 );
    //             }
    //             const pdfData = UsageAgreementPDFDataFactory(templateData, logoB64, footerB64);
    //             const pdf = pdfMake.createPdf(pdfData);
    //
    //             observer.next(pdf);
    //         });
    //     });
    //
    //     return observable.pipe(share());
    // }

    // not in use
    // download(bankData: any, inquiry?: any): Observable<any> {
    //     const generate = this.generateUsageAgreement(bankData, inquiry);
    //     generate.subscribe(doc => {
    //         doc.download(AgreementFileName);
    //     });
    //     return generate;
    // }

    // not in use
    // printHTML(agreementContent: Element, agreementType: 'policy' | 'usage'): any {
    //     const agreementTemplate = this.getAgreementHTML();
    //     const agreementHeaderB64 = this.getLogoB64();
    //     const agreementFooterB64 = this.getFooterB64();
    //
    //     const docDependencies = forkJoin(agreementTemplate, agreementHeaderB64, agreementFooterB64);
    //     docDependencies.subscribe(([agreementHTML, logoB64, footerB64]) => {
    //         const printWindow = window.open('');
    //         printWindow.document.write(agreementHTML);
    //
    //         printWindow.document.getElementById('headerImg').setAttribute('src', logoB64);
    //         if (agreementType === 'usage') {
    //             printWindow.document.getElementById('footerImg').setAttribute('src', footerB64);
    //         }
    //         printWindow.document.getElementById('agreementContent').innerHTML = agreementContent.innerHTML;
    //
    //         // IE FIX - Removes headers and footers (url, date etc.) from page by actually telling IE to print it outside the paper
    //         // The side-effect is that printer will puke out one empty paper sheet extra with each print ¯\_(ツ)_/¯
    //         const browser = this.deviceService.browser.toLowerCase();
    //         if (browser === 'ie') {
    //             const styleElement = printWindow.document.createElement('style');
    //             styleElement.innerHTML =
    //                 '@page { size: A4; margin: 0 -10cm; } @media print { html { margin: 0 10cm; } }';
    //             printWindow.document.getElementsByTagName('head')[0].appendChild(styleElement);
    //         }
    //
    //         printWindow.document.getElementById('otuaIframe').addEventListener('load', () => {
    //             setTimeout(() => {
    //                 printWindow.document.close();
    //                 printWindow.focus();
    //                 printWindow.print();
    //                 setTimeout(() => {
    //                     printWindow.close();
    //                 }, 1);
    //             });
    //         });
    //     });
    // }

    // not in use
    // printPDF(bankData: any): Observable<any> {
    //     const generate = this.generateUsageAgreement(bankData);
    //     generate.subscribe(doc => {
    //         doc.print();
    //     });
    //     return generate;
    // }

    // not in use
    // print(bankData: any, agreementContent: Element, agreementType: 'policy' | 'usage'): void {
    //     this.printHTML(agreementContent, agreementType);
    // }

    // not in use
    // checkUsageAgreementToThrottle() {
    //     if (this.loggedUser.isBankingUser() && !this.loggedUser.hasAcceptedUsageAgreement()) {
    //         combineLatest([
    //             this.inquiryService.getInquiriesWithoutAgreement(),
    //             this.banksService.getBank(this.loggedUser.companyId),
    //         ]).pipe(
    //             switchMap(([inquiriesWoAgreement, bank]) => {
    //                 if (!this.loggedUser.hasAcceptedOneTimeAgreement() && inquiriesWoAgreement.length === 0) {
    //                     return this.openUsageAgreement(bank);
    //                 } else {
    //                     if (inquiriesWoAgreement.length === 1) {
    //                         return this.openUsageAgreement(bank, this.mapInquiryOtuaToInquiry(inquiriesWoAgreement[0]));
    //                     } else if (inquiriesWoAgreement.length > 1) {
    //                         return this.dialogService.open(OneTimeInquiriesDialogComponent, {
    //                             data: {
    //                                 inquiries: inquiriesWoAgreement,
    //                             },
    //                             disableClose: true,
    //                         }).afterClosed().pipe(
    //                             switchMap(result => {
    //                                 if (!result) {
    //                                     this.loginService.logout();
    //                                     return of(false);
    //                                 }
    //                                 let obs$ = this.openUsageAgreement(bank, this.mapInquiryOtuaToInquiry(inquiriesWoAgreement[0]));
    //                                 for (const inq of inquiriesWoAgreement.slice(1)) {
    //                                     obs$ = obs$.pipe(
    //                                         filter((previousAccepted) => !!previousAccepted),
    //                                         switchMap((isAccepted) => {
    //                                             if (!isAccepted) {
    //                                                 this.dialogService.closeAll();
    //                                                 this.loginService.logout();
    //                                                 return of(false);
    //                                             }
    //                                             return this.openUsageAgreement(bank, this.mapInquiryOtuaToInquiry(inq));
    //                                         }));
    //                                 }
    //                                 return obs$.pipe(take(1));
    //                             }),
    //                         );
    //                     } else {
    //                         return new Promise(null);
    //                     }
    //                 }
    //             }),
    //         ).subscribe();
    //     }
    // }
}
