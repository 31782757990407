import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-common-spinner',
    templateUrl: './common-spinner.component.html',
    styleUrls: ['./common-spinner.component.less'],
})
export class CommonSpinnerComponent {
    @Input() glass = false; // Include glass behind spinner (absolute, 100% width & height)
    @Input() fixed = false; // Position "fixed" instead of "absolute" for spinner
    @Input() visible = false; // Is spinner (and glass) visible
    @Input() global = false; // Is spinner (and glass) visible
    @Input() progress: number = null;
    @Input() progressDescription: string = null;
}
