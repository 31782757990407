import {InquiryLayoutComponent} from './modules/shared/components/inquiry/inquiry-layout/inquiry-layout.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserRouteAccessGuard} from './guards/user-route-access.guard';
import { KcAuthGuard } from './guards/kc-auth.guard';

export const CORE_APP_ROUTES: Routes = [
    // CONTENT ROUTES
    {
        path: '', pathMatch: 'full', redirectTo: 'login'
    },
    {
        path: 'start',
        redirectTo: 'inquiry/list',
        // loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        // canActivate: [UserRouteAccessGuard],
        // data: {
        //     authorities: ['ROLE_BROKER', 'ROLE_FINMATCH', 'ROLE_COMPANY', 'ROLE_FINANCIAL', 'ROLE_FINANCIAL_UNIT']
        // }
    },
    {
        path: 'dashboard/:tab',
        redirectTo: 'inquiry/list',
        // loadChildren: () => import('./modules/finmatch-dashboard/finmatch-dashboard.module').then(m => m.FinmatchDashboardModule),
        // canActivate: [UserRouteAccessGuard],
        // data: {
        //     authorities: ['ROLE_FINMATCH']
        // }
    },
    {
        path: 'dashboard',
        redirectTo: 'inquiry/list'
    },
    {
        path: 'notifications',
        redirectTo: 'inquiry/list'
    },
    {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            authorities: ['ROLE_BROKER', 'ROLE_FINMATCH', 'ROLE_COMPANY', 'ROLE_FINANCIAL', 'ROLE_FINANCIAL_UNIT']
        },
    },
    {
        path: 'inquiry',
        component: InquiryLayoutComponent,
        canActivate: [UserRouteAccessGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/inquiry/inquiry.module').then(m => m.InquiryModule)
            }
        ],
    },
    {
        path: 'financial-statements',
        loadChildren: () => import('./modules/financial-statement/financial-statement.module').then(m => m.FinancialStatementModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            authorities: ['ROLE_FINMATCH']
        },
    },
    // CONTENT ROUTES END

    // ACCOUNT ROUTES
    {
        path: 'login',
        loadChildren: () => import('./modules/account/login/login.module').then(m => m.LoginModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            restrictLoggedIn: true,
            timeout: false,
            isLanding: true,
            skipTutorial: true,
        }
    },
    {
        path: 'login-timeout',
        loadChildren: () => import('./modules/account/login/login.module').then(m => m.LoginModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            restrictLoggedIn: true,
            timeout: true,
            isLanding: true
        }
    },
    {
        path: 'register',
        loadChildren: () => import('./modules/account/register/register.module').then(m => m.RegisterModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            restrictLoggedIn: true,
            isLanding: true
        }
    },
    {
        path: 'activate',
        loadChildren: () => import('./modules/account/activate/activate.module').then(m => m.ActivateModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            restrictLoggedIn: true,
            isLanding: true,
            skipTutorial: true,
        }
    },
    {
        path: 'reset/request',
        loadChildren: () => import('./modules/account/password-reset-init/password-reset-init.module').then(m => m.PasswordResetInitModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            restrictLoggedIn: true,
            isLanding: true,
            skipTutorial: true,
        }
    },
    {
        path: 'reset/finish',
        loadChildren: () => import('./modules/account/password-reset-finish/password-reset-finish.module').then(m => m.PasswordResetFinishModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            restrictLoggedIn: true,
            isLanding: true
        }
    },
    {
        path: 'change-password',
        loadChildren: () => import('./modules/account/password-change/password-change.module').then(m => m.PasswordChangeModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            skipTutorial: true,
            authorities: ['ROLE_BROKER', 'ROLE_FINMATCH', 'ROLE_COMPANY', 'ROLE_FINANCIAL', 'ROLE_FINANCIAL_UNIT']
        }
    },
    {
        path: 'change-onetime-password',
        loadChildren: () => import('./modules/account/password-change/password-change.module').then(m => m.PasswordChangeModule),
        canActivate: [UserRouteAccessGuard],
        data: {
            authorities: ['ROLE_BROKER', 'ROLE_FINMATCH', 'ROLE_COMPANY', 'ROLE_FINANCIAL', 'ROLE_FINANCIAL_UNIT'],
            isOnetime: true,
            isLanding: true,
            skipTutorial: true,
        }
    },
    // ACCOUNT ROUTES END

    // ERROR ROUTES
    {
        path: 'error',
        loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
        data: {
            isLanding: true,
            skipTutorial: true,
        }
    },
    {
        path: 'accessdenied',
        loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
        data: {
            errorType: '403',
            isLanding: true,
        }
    },
    {
        path: 'badrequest',
        loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
        data: {
            errorType: '400',
            isLanding: true
        }
    },
    {
        path: '**',
        loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
        data: {
            errorType: '404'
        }
    }
    // ERROR ROUTES END
];


const APP_ROUTES: Routes = [
    // {
    //     path: '',
    //     redirectTo: 'login',
    //     pathMatch: 'full'
    //
    // },
    ...CORE_APP_ROUTES
];

@NgModule({
    imports: [RouterModule.forRoot(
        APP_ROUTES, {
    scrollPositionRestoration: 'top',
    paramsInheritanceStrategy: 'always'
})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
