import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../modules/shared/components/confirmation-dialog/confirmation-dialog.component';

interface ModalMessage {
    modalHeader?: string;
    confirmationMessage: string;
    bottomText?: string;
    confirmationYesButton?: string;
    confirmationNoButton?: string;
    noRejectionBtn?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {
    constructor(private dialogService: MatDialog) {}

    public open(confirmationMessage: string | ModalMessage): Observable<boolean> {
        let modalData;

        if  (typeof confirmationMessage === 'string' ) {
            modalData = { confirmationMessage };
        } else {
            modalData = {
                modalHeader: confirmationMessage.modalHeader,
                confirmationMessage: confirmationMessage.confirmationMessage,
                bottomText: confirmationMessage.bottomText,
                confirmationYesButton: confirmationMessage.confirmationYesButton,
                confirmationNoButton: confirmationMessage.confirmationNoButton,
                noRejectionBtn: confirmationMessage.noRejectionBtn,
            };
        }

        const dialog = this.dialogService.open(ConfirmationDialogComponent, {
            data: modalData,
            disableClose: true,
        });

        return dialog.afterClosed();
    }
}
