import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-bank-removal-modal',
    templateUrl: './bank-removal-modal.component.html',
    styleUrls: ['./bank-removal-modal.component.less']
})
export class BankRemovalModalComponent {

    constructor(public dialogRef: MatDialogRef<BankRemovalModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    onNoClick() {
        this.dialogRef.close();
    }

}
