import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupportExpertComponent} from "./support-expert.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    declarations: [
        SupportExpertComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        SupportExpertComponent
    ]
})
export class SupportExpertModule {
}
