import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {InquiryDataService} from '../../../services/provided/inquiry/inquiry-data.service';
import {Inquiry} from '../../../models/InquiryModel';
import {Company} from '../../../models/CompanyModel';
import {CorporateRepository} from '../../../repositories/corporate.repository';
import {AuthService} from '../../../services/auth/auth.service';
import {take} from 'rxjs/operators';
import {FinMatchAccount} from '../../../models/finmatch-account.model';

@Injectable({
    providedIn: 'root'
})
export class InquiryDataResolver  {
    constructor(private inquiryDataService: InquiryDataService,
                private corporateRepository: CorporateRepository,
                private authService: AuthService) {
    }

    // This resolver loads the inquiry and company data before the route is loaded (in list and edit)
    // It provides the data through ActivatedRoute as "data" parameter (which is not kept up to date on changes)
    // And it updates the data in the InquiryData service
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<{ inquiry: Inquiry, company: Company }> {
        return new Promise<{ inquiry: Inquiry; company: Company }>((resolve) => {
            if (route.params.hasOwnProperty('inquiryId')) {
                const inquiryId = route.params.inquiryId;
                this.loadInquiry(inquiryId).subscribe({
                    next: (inquiry) => {
                        this.inquiryDataService.inquiry = inquiry;

                        this.authService.user$.pipe(take(1)).subscribe(user => {
                            this.loadCompany(inquiry.companyId, user, true).subscribe({
                                next: (company) => {
                                    this.inquiryDataService.company = company;
                                    resolve({inquiry, company});
                                },
                                error: () => {
                                    this.inquiryDataService.company = null;
                                    console.error('Error: Company could not be loaded');
                                    resolve({inquiry, company: null});
                                }
                            });
                        });
                    },
                    error: () => {
                        this.inquiryDataService.company = null;
                        this.inquiryDataService.inquiry = null;
                        console.error('Error: Inquiry and Company could not be loaded');
                        resolve({inquiry: null, company: null});
                    }
                });
            } else {
                console.error('Error: No Id parameter found');
                resolve({inquiry: null, company: null});
            }
        });
    }

    private loadInquiry(inquiryId: string): Observable<Inquiry> {
        return this.inquiryDataService.inquiry.load(inquiryId);
    }

    private loadCompany(companyId: string, user: FinMatchAccount, errorInterceptorSkip = false): Observable<Company> {
        if (user.isFinMatch()) {
            return this.corporateRepository.get(companyId, errorInterceptorSkip);
        } else {
            return this.corporateRepository.getLight(companyId);
        }
    }
}
