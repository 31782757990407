import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../../app-config.module';
import {FinancingProduct, InquiryModuleKey} from '../../../models/InquiryModel';
import {SpaceType} from '../../../modules/shared/components/inquiry/teaser-widget/teaser-key-figures/teaser-key-figures.component';
import {TranslationService} from '../../root/translation/translation.service';
import {map} from 'rxjs/operators';
import {CompanyYearlyCreditRating} from '../../provided/companies.service';
import {formatDate} from '@angular/common';


interface CompanyWidget {
    companyName: string;
    street: string;
    houseNumber: string;
    city: string;
    postCode: string;
    logoFile: {
        name: string;
        mimetype: string;
        b64content: string;
    };
    titleFile: {
        name: string;
        mimetype: string;
        b64content: string;
    };
}
interface CompanyOverviewWidget {
    purpose: string;
    location: string;
    branch: string;
    specialization: string;
    turnover: number;
    employees: number;
    ebitda: number;
    foundingYear: number;
    annualProfit: number;
    financingProject: string;
    financingVolume: number;
    financingConcept: string;
    customRows: {
        label: string,
        value: string
    }[];
}
export interface KeyFiguresWidgetSection {
    company: string;
    financialReportCategories: string[];
    financialReport: Array<{
        label: string
        key: string;
        unit: string;
        categories: string[];
        isDisabled: boolean
    }>;
    keyFigures: Array<{ label: string, value: string }>;
    turnoverAllocation: any;
}
export type KeyFiguresWidget = KeyFiguresWidgetSection[];
export interface TeaserFinancingProject {
    id?: number;
    fields: {key?: string, label: string, value: string}[];
}
export type FinancingProjectsWidget = {
    [key in InquiryModuleKey]?: TeaserFinancingProject[];
};
export interface FinancingProjectsSlide {
    enabled: boolean;
    subHeading: string;
    subTitle: string;
    text: string;
}
export interface IndicationRatingWidget {
    developmentOfCreditworthiness: CompanyYearlyCreditRating[];
    textField1: string;
    // As of DEV-447 temporarily (?) removed, but old entries can still have that data:
    creditInformation?: {
        creditIndex: number;
        paymentMethod: string;
        creditRating: string;
        orderSituation: string;
        ageOfManager: number;
    };
}
export enum CompanyPresentationItemType {
    subheading = 'subheading',
    subtitle= 'subtitle',
    paragraph = 'paragraph',
}
export interface CompanyPresentationSlideItem {
    type: CompanyPresentationItemType;
    value: string;
}
export interface CompanyPresentationSlide extends Array<CompanyPresentationSlideItem> {}

export enum TeaserWidget {
    company= 'company',
    companyOverview = 'companyOverview',
    companyPresentation = 'companyPresentation',
    keyFigures = 'keyFigures',
    indicationRating = 'indicationRating',
    projectStandards = 'projectStandards',
    financingProjects = 'financingProjects',
    financingProjectsSlides = 'financingProjectsSlides',
    financingConcept = 'financingConcept',
    disclaimer = 'disclaimer'
}


export interface Teaser {
    [TeaserWidget.company]: CompanyWidget;
    [TeaserWidget.companyOverview]: CompanyOverviewWidget;
    [TeaserWidget.companyPresentation]: CompanyPresentationSlide[];
    [TeaserWidget.keyFigures]: KeyFiguresWidget;
    [TeaserWidget.indicationRating]: IndicationRatingWidget;
    [TeaserWidget.projectStandards]: { projectStartDate: string, projectEndDate: string };
    [TeaserWidget.financingProjects]: FinancingProjectsWidget;
    [TeaserWidget.financingProjectsSlides]: FinancingProjectsSlide[];
    [TeaserWidget.financingConcept]: {[key in InquiryModuleKey]: any};
    [TeaserWidget.disclaimer]: {
        consolidatedAccountsYear: number;
        businessAssessmentYear: number;
        companyName: string;
        examinationDate: string;
    };
    version: number;
    hiddenElements: {
        widgets: TeaserWidget[],
        [TeaserWidget.keyFigures]: {
            [SpaceType.financialReport]: string[] // list of table field keys
        }
    };
}

@Injectable({
    providedIn: 'root'
})
export class InquiryTeaserService {

    public teaserFieldsDateFormat = 'd.MM.yyyy';
    public formatTeaserFieldDate = (date: string) => formatDate(date, this.teaserFieldsDateFormat, this.locale);

    constructor(
        private http: HttpClient,
        private translationService: TranslationService,
        @Inject(APP_CONFIG) private config: AppConfig,
        @Inject(LOCALE_ID) public locale: string
) {
    }

    public getCollateralLabelsPerFinProduct(financingProduct: FinancingProduct): string[] {
        const collateralLabels = [];
        if (financingProduct.collateral === 'BANK_STANDARD') {
            return [this.translationService.financingParametersCollaterals.BANK_STANDARD];
        }
        Object.entries(this.translationService.collateralsKeyToLabels).forEach(([collateralKey, label]) => {
            if (financingProduct[collateralKey]) {
                collateralLabels.push(label);
            }
        });
        if (financingProduct.otherCollateral) {
            collateralLabels.push(financingProduct.otherCollateralDesc || this.translationService.other);
        }
        return collateralLabels;
    }

    /**
     * Round to thousands with one decimal
     * @param value
     */
    public roundToThousands(value: number) {
        if (isNaN(value)) {
            return null;
        }
        return Math.round((value / 1000) * 10) / 10;
    }

    public formatToThousandsCurrency(value: number, currency: string): string {
        if (!value) {
            return `0 T${currency}`;
        }
        const localeNumber = (value / 1000).toLocaleString(this.locale, {minimumFractionDigits: 1, maximumFractionDigits: 1});
        return `${localeNumber} T${currency}`;
    }


    getTeaser(inquiryId: string): Observable<Teaser> {
        return this.http.get<Teaser>(`${this.config.API_URL}api/teaser/inquiry/${inquiryId}`, {
            observe: 'body'
        });
    }

    saveTeaser(inquiryId: string, teaser: Teaser): Observable<Blob> {
        return this.http.post(`${this.config.API_URL}api/teaser/inquiry/${inquiryId}`, teaser, {
            responseType: 'blob',
            observe: 'response',
        }).pipe(
            map(response => {
                return new Blob([response.body], {type: 'application/vnd.ms-powerpoint'});
            })
        );
    }

    saveTeaserDraft(inquiryId: string, teaser: Partial<Teaser>): Observable<Teaser> {
        return this.http.post<Teaser>(`${this.config.API_URL}api/teaser/inquiry/${inquiryId}/draft`, teaser, {
            observe: 'body',
        });
    }
}
