import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {Observable} from 'rxjs';
import {GetFinancialStatementsParameters} from './models/get-financial-statements-parameters.interface';
import {AvailableFinancialStatement} from './models/available-financial-statements.interface';
import {HelicAnalyzerUploadRequest} from './models/helic-analyzer-upload-request.interface';
import {BalanceSheet} from '../../models/balance-sheet.model';

@Injectable()
export class FinancialStatementsRepository {
    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig) {
    }

    analyze(data: HelicAnalyzerUploadRequest): Observable<void> {
        return this.http.put<void>(this.config.API_URL + 'ratings/api/v1/document/analyze', data);
    }

    get(corporateId: string, params: GetFinancialStatementsParameters = {}): Observable<BalanceSheet[]> {
        return this.http.get<BalanceSheet[]>(this.config.API_URL + `ratings/api/v1/financial-statements/company/${corporateId}`,
            {
                headers: {
                    accept: 'application/json'
                },
                params: {
                    ...params
                }
            });
    }

    getXBRL(corporateId: string, params: GetFinancialStatementsParameters = {}): Observable<any> {
        return this.http.get<any>(this.config.API_URL + `ratings/api/v1/financial-statements/company/${corporateId}`,
            {
                headers: {
                    accept: 'application/xml'
                },
                params: {
                    ...params
                },
                responseType: 'blob' as 'json'
            });
    }

    available(corporateId: string): Observable<AvailableFinancialStatement[]> {
        return this.http.get<AvailableFinancialStatement[]>(this.config.API_URL + `ratings/api/v1/financial-statements/company/${corporateId}/available`);
    }
}
