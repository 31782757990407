import {Inquiry, InquiryFactory} from 'src/app/models/InquiryModel';
import {FinMatchAccount, FinMatchLoggedOutMock} from 'src/app/models/finmatch-account.model';
import {TranslationService} from 'src/app/services/root/translation/translation.service';
import {Principal} from 'src/app/services/auth/principal.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {finalize, map, take, tap} from 'rxjs/operators';
import {Company} from '../../models/CompanyModel';

@Injectable({
    providedIn: 'root'
})
export class InquiryListService {
    public loggedUser: FinMatchAccount;
    public inquiries: Array<Inquiry> = [];
    public focusedInquiryId: string;
    public selectedInquiryId: string;
    public inquiry: Inquiry;
    public company: Company;

    constructor(
        public principal: Principal,
        public translationService: TranslationService,
        public inquiryFactory: InquiryFactory,
    ) {
        this.principal.getUserIdentitySubject().subscribe(account => {
            this.loggedUser = account || FinMatchLoggedOutMock;
        });
    }

    public _inquiriesList$: ReplaySubject<Array<Inquiry>> = new ReplaySubject<Array<Inquiry>>(1);

    public get inquiriesList$(): Observable<Array<Inquiry>> {
        return this._inquiriesList$.asObservable();
    }

    private _selectedInquiry$: ReplaySubject<Inquiry> = new ReplaySubject<Inquiry>(1);

    public get selectedInquiry$(): Observable<Inquiry> {
        return this._selectedInquiry$.asObservable();
    }

    public _isSelectedInquiryLoading$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    public get isSelectedInquiryLoading$(): Observable<boolean> {
        return this._isSelectedInquiryLoading$.asObservable();
    }

    public _listReloadRequest: Subject<string> = new Subject<string>();

    public get listReloadRequest(): Observable<string> {
        return this._listReloadRequest.asObservable();
    }

    public selectInquiry$(inquiryId: string | null): Observable<Inquiry> {
        this.selectedInquiryId = inquiryId;
        if (!inquiryId) {
            this._selectedInquiry$.next(null);
            return of(null);
        }
        this._isSelectedInquiryLoading$.next(true);

        return this.inquiryFactory.loadInquiry$(inquiryId).pipe(
            take(1),
            finalize(() => {
                this._isSelectedInquiryLoading$.next(false);
            }),
            map((inquiry: Inquiry) => {
                this._selectedInquiry$.next(inquiry);
                inquiry.onUpdate().subscribe(() => {
                    this.requestListReload();
                });
                return inquiry;
            }),
        );
    }

    loadInquiries(): Observable<any> {
        return this.inquiryFactory.getInquiries(
            'lastModifiedDate,desc', 10000, 0,
            false, false, true
        ).pipe(
            tap(({inquiries}) => {
                this.inquiries = inquiries;
                this._inquiriesList$.next(inquiries);
            }));
    }

    requestListReload() {
        this._listReloadRequest.next(null);
    }

    // private getInquiry$(inquiryId: string): Observable<Inquiry> {
    //     return combineLatest([
    //         this.inquiryFactory.loadInquiry$(inquiryId),
    //         this.inquiriesList$,
    //     ]).pipe(
    //         take(1),
    //         map(([selectedInquiry, inquiryList]) => {
    //             const listInq = inquiryList.find(inq => inq.id === selectedInquiry.id);
    //             if (listInq) {
    //                 selectedInquiry.setBankOffers(listInq._bankOffers);
    //                 selectedInquiry.setFinancingBanks(listInq._financingBanks);
    //             }
    //             return selectedInquiry;
    //         }));
    // }

    // readInquiryAdditionalData(inquiryId) {
    //     return combineLatest(
    //         this.bankOffersService.getBankOffersPerInquiries([inquiryId]),
    //         this.bankSelectionService.getMultipleInquiriesFinancingBanks([inquiryId]),
    //     ).pipe(
    //         map(([offersData, financingBanksData]) => {
    //             const inquiry = this.inquiries.find(inq => inq.id === inquiryId);
    //             inquiry.setBankOffers(offersData[inquiryId]);
    //             inquiry.setFinancingBanks(financingBanksData[inquiryId]);
    //             this._inquiriesList$.next(this.inquiries);
    //         }),
    //     );
    // }
}
