import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DocumentFileService} from "../../../../../services/document-file.service";
import {Inquiry} from "../../../../../models/InquiryModel";
import {bufferCount, finalize, switchMap, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {DocumentFileClass} from "../../../../../services/inquiry/inquiry-configuration.service";
import {DocumentFile} from '../../../../../models/document-file.model';

export interface InquiryRejectionModalData {
    inquiry: Inquiry;
    bankId?: string | null;
    rejectionDetails?: any;
    mode?: 'reject-inquiry' | 'revision' | 'reject-otua';
}

interface Reason {
    name: string;
    translation: string;
    checked?: boolean;
}

@Component({
    selector: 'app-inquiry-rejection-dialog',
    templateUrl: './inquiry-rejection-dialog.component.html',
    styleUrls: ['./inquiry-rejection-dialog.component.less']
})
export class InquiryRejectionDialogComponent {

    public isLoading = false;
    public reasonValidators = [Validators.maxLength(1000)];
    public reasonValidatorsRequired = [Validators.required, Validators.maxLength(1000)];
    public reasonOtherName = 'Other';
    public reasons: Reason[] = [
        {name: 'Regionality', translation: $localize`Regionality`},
        {name: 'Industry sector', translation: $localize`Industry sector`},
        {name: 'Lack of equity', translation: $localize`Lack of equity`},
        {name: 'Creditworthiness / ability to service debt', translation: $localize`Creditworthiness / ability to service debt`},
        {name: 'Lack of collateral', translation: $localize`Lack of collateral`},
        {name: 'Lack of internal capacities', translation: $localize`Lack of internal capacities`},
        {name: 'Volume too low / too high', translation: $localize`Volume too low / too high`},
        {name: 'Term / fixed interest rate not representable', translation: $localize`Term / fixed interest rate not representable`},
        {name: this.reasonOtherName, translation: $localize`${this.reasonOtherName}`},
    ];
    public rejectionReasonForm = this.formBuilder.group({
        reasons: [[]],
        reasonOther: [null, this.reasonValidators],
    });
    public files: DocumentFile[] = [];
    public uploadProgress = 0;
    public reasonSkipped = false;
    public validate = false;
    public rejectOtuaReadOnly = this.data.rejectionDetails !== undefined;

    constructor(
        public dialogRef: MatDialogRef<InquiryRejectionDialogComponent>,
        private formBuilder: UntypedFormBuilder,
        private documentFileService: DocumentFileService,
        @Inject(MAT_DIALOG_DATA) public data: InquiryRejectionModalData,
    ) {
        if (this.rejectOtuaReadOnly) {
            this.reasons = this.reasons.map(reason => {
                reason.checked = this.data.rejectionDetails.reasons.includes(reason.name);
                return reason;
            });
            this.rejectionReasonForm.get('reasonOther').setValue(data?.rejectionDetails?.reason);
            this.rejectionReasonForm.get('reasonOther').disable();
        }

        this.rejectionReasonForm.get('reasonOther').valueChanges.subscribe(value => {
            var previouslyChecked = false;
            this.reasons.map(reason => {
                if (reason.name === this.reasonOtherName) {
                    previouslyChecked=reason.checked;
                }
            })
            this.setReasonCheckbox(!!value);
            this.reasonChanged(!!value, this.reasonOtherName, !(previouslyChecked == !!value));
        });
    }

    reasonChanged(checked, reason, updateValueAndValidity = true) {
        if (reason === this.reasonOtherName) {
            const validators = checked ? this.reasonValidatorsRequired : this.reasonValidators;
            this.rejectionReasonForm.get('reasonOther').setValidators(validators);
            if (updateValueAndValidity) this.rejectionReasonForm.get('reasonOther').updateValueAndValidity();
        }

        const reasons = this.rejectionReasonForm.get('reasons');

        if (checked && reasons.value.indexOf(reason) <= -1) {
            reasons.value.push(reason);
        } else if (!checked && reasons.value.indexOf(reason) > -1) {
            reasons.setValue(reasons.value.filter(r => r !== reason));
        }
    }

    setReasonCheckbox(checked) {
        this.reasons = this.reasons.map(reason => {
            if (reason.name === this.reasonOtherName) {
                reason.checked = checked;
            }
            return reason;
        });
    }

    private get documentsToSend(): DocumentFile[] {
        return this.files
            .filter(file => file.fileHandle && !file.createdDate)
            .map(file => {
                return {
                    ...file,
                    documentClass: (this.data.mode === 'revision' ? 'Inquiry__Revision' : 'Bank_Rejection__Document') as DocumentFileClass,
                    documentType: this.data.mode === 'revision' ? 'InquiryRevision' : 'BankRejection',
                    parentObjectId: this.data.inquiry.id,
                    companyId: this.data.inquiry.companyId
                };
            });
    }

    clearForm() {
        this.rejectionReasonForm.reset();
        this.files = [];
    }

    backToReasonForm() {
        this.files = [];
        this.reasonSkipped = false;
    }

    // test
    sendRejection(withReason = false): Observable<any> {
        this.validate = true;

        if (!this.rejectionReasonForm.valid || !this.rejectionReasonForm.controls.reasons?.value?.length) {
            return;
        }
        // if (withReason && !this.rejectionReasonForm.value.reasonOther) {
        //     this.reasonSkipped = true;
        //     return EMPTY;
        // }
        this.uploadProgress = 0;
        this.isLoading = true;
        const documents = this.documentsToSend;
        const totalUploadSize = documents.reduce((sum, item) => sum + item.documentSize, 0);

        const postDocs$ = this.documentFileService.postDocuments(documents).pipe(
            tap(doc => (this.uploadProgress += (doc.documentSize * 100) / totalUploadSize)),
            bufferCount(documents.length),
        );

        return (documents.length ? postDocs$ : of([])).pipe(
            switchMap(docs => {
                if (this.data.mode === 'reject-inquiry') {
                    const reasonsUnsorted = this.rejectionReasonForm.get('reasons').value;
                    const reasonsObjects = this.reasons.filter(reason => reasonsUnsorted.indexOf(reason.name) > -1);
                    const reasons = reasonsObjects.map(reason => reason.name);
                    const reasonsTranslated = reasonsObjects.map(reason => reason.translation);
                    const reasonOther = this.rejectionReasonForm.get('reasonOther').value;
                    return this.data.inquiry.bankReject(reasons, reasonsTranslated, reasonOther, docs, this.data.bankId || null);
                }
                if (this.data.mode === 'reject-otua') {
                    return of([]);
                }
                if (this.data.mode === 'revision') {
                    return this.data.inquiry.revise(this.rejectionReasonForm.value.reasonOther || null, docs);
                }
            }),
            finalize(() => this.isLoading = false),
            tap(() => this.dialogRef.close({inquiryRejected: true, withReason: withReason, reason: this.rejectionReasonForm.value.reasonOther})),
        );
    }
}
