<div class="dialog-title" mat-dialog-title>
    <span *ngIf="agreementType === 'usage'" i18n>One Time Usage Agreement - Confirmation</span>
    <span *ngIf="agreementType === 'policy'" i18n>Update of Data Policy - Confirmation</span>
</div>

<p *ngIf="agreementType === 'usage'" class="agreement-title" i18n>In order to use the FinMatch-portal we need your agreement to this usage agreement.</p>
<p *ngIf="agreementType === 'policy'" class="agreement-title" i18n>In order to continue using FinMatch-portal, we need your agreement to our updated data policy.</p>

<mat-dialog-content>
    <div #agreementContent class="usage-agreement-content">
        <ng-container *ngIf="agreementType === 'usage'">
            <iframe id="otuaIframe" *ngIf="otuaUrl" [src]="otuaUrl" width="780px" frameborder="0" scrolling="no" (load)="resize()"></iframe>
        </ng-container>
<!--        <hr />-->
<!--        <ng-container *ngIf="agreementType === 'usage'">-->
<!--            <div class="agreement-heading">{{documentData.documentTitle}}</div>-->
<!--            <div class="agreement-paragraph">{{documentData.betweenAlias}}</div>-->
<!--            <div class="agreement-subject">-->
<!--                <div>-->
<!--                    <span class="subject-name"><strong>FinMatch AG</strong>,</span>-->
<!--                    Löffelstraße 4, 70597 Stuttgart-->
<!--                </div>-->
<!--                <div>{{documentData.representedBy}}</div>-->
<!--            </div>-->
<!--            <div class="subject-signature">{{documentData.operatorAlias}}</div>-->
<!--            <div class="agreement-paragraph">{{documentData.andAlias}}</div>-->
<!--            <div class="agreement-subject">-->
<!--                <span class="subject-name"><strong>{{documentData.bankName}}</strong>,</span>-->
<!--                {{documentData.bankStreet}} {{documentData.bankHouseNumber}}, {{documentData.bankZipCode}} {{documentData.bankCity}}-->
<!--            </div>-->
<!--            <div class="subject-signature">{{documentData.bankAlias}}</div>-->
<!--            <div class="agreement-heading preamble">{{documentData.preambleTitle}}</div>-->
<!--            <div class="agreement-paragraph preamble">{{documentData.preambleContent}}</div>-->
<!--            <div class="agreement-paragraph preamble">{{documentData.preambleContentPar2}}</div>-->

<!--            <div *ngIf="inquiry" class="agreement-hint" i18n>-->
<!--                <div>-->
<!--                    <span>This said, the following is agreed upon for the financing project</span>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <strong>{{inquiry.inquiryTitle}}</strong>&nbsp;<span>of the company</span>&nbsp;<strong>{{inquiry.companyName}}</strong>:-->
<!--                </div>-->
<!--            </div>-->

<!--            <div *ngIf="!inquiry" class="agreement-hint">{{documentData.agreementHintAlias}}</div>-->

<!--            <div class="agreement-heading">{{documentData.intermediaryServicesTitle}}</div>-->
<!--            <div class="agreement-paragraph">{{documentData.intermediaryServicesIntro}}</div>-->
<!--            <ul class="agreement-rules">-->
<!--                <li *ngFor="let service of documentData.intermediaryServicesList">{{service}}</li>-->
<!--            </ul>-->

<!--            <div class="agreement-heading">{{documentData.claimForCommissionTitle}}</div>-->
<!--            <ul class="agreement-rules">-->
<!--                <li *ngFor="let claim of documentData.claimForCommissionList">{{claim}}</li>-->
<!--            </ul>-->

<!--            <div class="agreement-paragraph">{{documentData.commissionParagraph1}}</div>-->
<!--            <div class="agreement-paragraph">-->
<!--                <div>-->
<!--                    <strong>{{documentData.finmatchAccountLine1}}</strong>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <strong>{{documentData.finmatchAccountLine2}}</strong>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="agreement-paragraph">{{documentData.commissionParagraph2}}</div>-->
<!--            <div class="agreement-paragraph">{{documentData.commissionParagraph3}}</div>-->
<!--        </ng-container>-->

        <ng-container *ngIf="agreementType === 'policy'">
            <button (click)="shouldPolicyBeShown = true" *ngIf="!shouldPolicyBeShown" class="no-print" i18n mat-button>Show updated data policy</button>
            <div *ngIf="shouldPolicyBeShown" [innerHTML]="dataPolicyHtml$ | async"  class="ext-data-policy"></div>
            <form [formGroup]="policyForm" class="policy-form no-print">
                <mat-checkbox formControlName="isAgreed"><span i18n>I agree to the updated</span> <a href="https://www.finmatch.de/datenschutz/"><span i18n>data policy</span></a></mat-checkbox>
            </form>
        </ng-container>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="document-options">
<!--        <button (click)="printOtua()" class="print-doc-button" i18n-matTooltip mat-icon-button matTooltip="Print document" matTooltipPosition="above">-->
<!--            <i class="material-icons">print</i>-->
<!--        </button>-->
        <button (click)="download()" *ngIf="agreementType === 'usage'" class="print-doc-button" i18n-matTooltip mat-icon-button matTooltip="Download document" matTooltipPosition="above">
            <i class="material-icons">save_alt</i>
        </button>
    </div>
    <div class="agreement-choices">
        <button *ngIf="!readOnly" (click)="deny()" class="action-button" i18n mat-button tabindex="-1">Deny</button>
        <button *ngIf="!readOnly" (click)="agreementType === 'usage' ? acceptUsageAgreement() : acceptPolicyAgreement()" [disabled]="agreementType === 'policy' && policyForm.invalid" class="action-button" color="primary" i18n mat-flat-button tabindex="-1">Accept</button>
        <button (click)="close()" i18n mat-flat-button>Close</button>
    </div>
</mat-dialog-actions>

<app-common-spinner [fixed]="false" [glass]="true" [visible]="spinnerVisible || (agreementType === 'policy' && shouldPolicyBeShown && !(dataPolicyHtml$ | async))"></app-common-spinner>
