import {Observable} from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Inject, Injectable} from '@angular/core';
import {AuthServerProvider} from '../services/auth/auth-jwt.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authJWTService: AuthServerProvider,
        @Inject(APP_CONFIG) private config: AppConfig
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!request ||
            !request.url ||
            (/^http/.test(request.url) && !(this.config.API_URL && request.url.startsWith(this.config.API_URL)))) {
            return next.handle(request);
        }

        const token = this.authJWTService.token;
        if (!!token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });
        }
        return next.handle(request);
    }
}
