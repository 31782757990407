import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../../../../../app-config.module';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LoginService} from '../../../../../services/login/login.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SessionStorageService} from 'ngx-webstorage';
import {CookieService} from 'ngx-cookie-service';
import {filter} from 'rxjs/operators';
import {UnsupportedDeviceModalComponent} from '../unsupported-device-modal/unsupported-device-modal.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EventManagerService} from '../../../../../services/event-manager.service';
import * as _ from 'lodash';
import {COOKIE_EXPIRATION_DATE} from 'src/app/app.component';

@Component({
    selector: 'app-unsupported-device-detector',
    template: '',
    styleUrls: ['./unsupported-device-detector.component.less']
})
export class UnsupportedDeviceDetectorComponent implements OnInit {
    unsupportedDevice: boolean;
    unsupportedBrowser: boolean;
    isLanding: boolean;
    currentBrowser: string;
    messageWasSeen: boolean;
    dialogRef: MatDialogRef<UnsupportedDeviceModalComponent>;

    constructor(
        private deviceService: DeviceDetectorService,
        private route: ActivatedRoute,
        private router: Router,
        private sessionStorage: SessionStorageService,
        private dialog: MatDialog,
        private loginService: LoginService,
        private renderer: Renderer2,
        private cookieService: CookieService,
        private eventManager: EventManagerService,
        @Inject(APP_CONFIG) public config: AppConfig
    ) {
    }

    ngOnInit() {
        this.currentBrowser =
            this.deviceService.getDeviceInfo().browser.toLowerCase() === 'ie'
                ? `${this.deviceService.getDeviceInfo().browser} ${parseInt(
                this.deviceService.getDeviceInfo().browser_version,
                11
                )}`
                : this.deviceService.getDeviceInfo().browser;

        const unsupportedDeviceSeen = this.cookieService.get(this.config.browserStorageTypes.sessionMessageKey);
        this.messageWasSeen = unsupportedDeviceSeen === 'true';

        this.eventManager.subscribe(this.config.eventTypes['logout'], () => {
            this.cookieService.delete(this.config.browserStorageTypes.sessionMessageKey);
        });

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (this.route.snapshot.firstChild) {
                this.isLanding = this.route.snapshot.firstChild.data && this.route.snapshot.firstChild.data.isLanding;
                if (!this.messageWasSeen) {
                    this.checkRequirements();
                }
            }
        });
    }

    isDeviceSupported() {
        return this.deviceService.isDesktop() && this.isResolutionSupported();
    }

    isBrowserSupported() {
        return _.includes(this.config.appRequirements.supportedBrowsers, this.currentBrowser.toLowerCase());
    }

    isResolutionSupported() {
        return window.innerWidth >= this.config.appRequirements.minViewportWidth;
    }

    userAcceptedMessage() {
        this.cookieService.set(this.config.browserStorageTypes.sessionMessageKey,
            'true',
            COOKIE_EXPIRATION_DATE,
            '/',
            this.config.general_domain,
            false, 'Lax');
        this.messageWasSeen = true;
    }

    checkRequirements() {
        // if (this.dialog.openDialogs.length !== 0) return;

        if (!this.isBrowserSupported()) {
            this.renderer.addClass(document.documentElement, 'unsupported-browser');
            this.dialogRef = this.dialog.open(UnsupportedDeviceModalComponent, {
                width: '700px',
                disableClose: true,
                data: {
                    modal: 'unsupported_browser'
                }
            });
        } else if (!this.isLanding && !this.isDeviceSupported()) {
            this.renderer.addClass(document.documentElement, 'unsupported-device-popup');
            this.dialogRef = this.dialog.open(UnsupportedDeviceModalComponent, {
                width: '700px',
                disableClose: true
            });
        }

        if (this.dialogRef) {
            this.dialogRef.afterClosed().subscribe(logout => {
                this.renderer.removeClass(document.documentElement, 'unsupported-device-popup');
                this.renderer.removeClass(document.documentElement, 'unsupported-browser');
                logout ? this.loginService.logout(true) : this.userAcceptedMessage();
            });
        }
    }
}
