import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {Observable} from 'rxjs';
import {User} from '../models/UserModel';

@Injectable()
export class UserRepository {
    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig) {
    }

    index(): Observable<User[]> {
        return this.http.get<User[]>(this.config.API_URL + this.config.API_URLs.users);
    }

    get(userId: string): Observable<User> {
        return this.http.get<User>(this.config.API_URL + this.config.API_URLs.users + `/${userId}`);
    }
}
