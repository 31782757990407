import {
    BuildingClassification,
    BuildingTypes,
    BuildingTypesV2,
    InquiryConfigurationService
} from 'src/app/services/inquiry/inquiry-configuration.service';
import {BuildingType} from '../../../../../../models/InquiryModel';
import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {LabelsMap, TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const RealEstateExistingBuildingExpertFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    buildingArea: [null, [Validators.required, Validators.max(999999999)]],
    buildingConstructionYear: [null, [customValidators.yearOfEstValidator()]],
    buildingCosts: [null, [Validators.required, customValidators.price()]],
    buildingType: [null, [Validators.required]],
    buyingCosts: [null, [Validators.required, customValidators.price()]],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    classification: [null, [Validators.required]],
    commercialArea: [null, [Validators.required, Validators.max(9999999)]],
    country: ['GERMANY', [Validators.required]],
    groundRent: [null, [customValidators.price()]],
    houseNumber: [null, [Validators.maxLength(5)]],
    housingUnitsCount: [null, [Validators.required, Validators.max(9999)]],
    knownConstructionCosts: [false, [Validators.required]],
    leaseHoldOnProperty: [false, []],
    livingArea: [null, [Validators.required, Validators.max(9999999)]],
    plannedDateOfPurchase: [null, [Validators.required]],
    postalCode: [null, [Validators.max(99999)]],
    propertyArea: [null, [Validators.required, Validators.max(999999999)]],
    propertyCosts: [null, [Validators.required, customValidators.price()]],
    remainingTermLeaseHold: [null, [customValidators.remainingTermLeaseHold]],
    renovationCosts: [null, [Validators.required, customValidators.price()]],
    streetAddress: [null, [Validators.maxLength(100)]],
    totalSalesPrice: [null, [Validators.required, customValidators.price()]],
};
const buildingTypeToBuildingClassifications = {
    COMMERCIAL_BUILDING: [
        BuildingClassification.PROJECT_FINANCING,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.HOTEL,
        BuildingClassification.PLANT,
    ],
    MIXED_USE: [
        BuildingClassification.PROJECT_FINANCING,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.HOTEL,
        BuildingClassification.PLANT,
        BuildingClassification.APPORTIONMENT,
    ],
    RESIDENTIAL_USE: [
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.APPORTIONMENT,
    ],
};

@Component({
    selector: 'app-real-estate-existing-building-expert-form',
    templateUrl: './real-estate-existing-building-expert-form.component.html',
    styleUrls: ['./real-estate-existing-building-expert-form.component.less']
})
export class RealEstateExistingBuildingExpertFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() currency: string;
    public buildingTypes: Array<BuildingType>;
    public buildingTypeLabels: LabelsMap;
    public countries: Array<{ value: string, viewValue: string }> = [];
    public buildingClassificationOptions = [];
    private subscriptions: Subscription[] = [];

    constructor(
        public translationService: TranslationService,
        private inquiryConfigurationService: InquiryConfigurationService,
    ) {
        this.countries = this.translationService.inquiryRealEstateCountries;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateBuildingTypeFields();
            this.updateCostAllocationFields();
            this.updateTotalSalesPrice();
            this.updateLeaseholdFields();
            this.updateCountryRelatedFields();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit() {
        if (this.inquiryConfigurationService.hasFormOldBuildingType(this.form)) {
            this.buildingTypes = BuildingTypes;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypes;
            this.form.controls.classification.disable();
        } else {
            this.buildingTypes = BuildingTypesV2;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypesV2;
            this.subscriptions.push(
                this.form.controls.buildingType.valueChanges.pipe(startWith(this.form.controls.buildingType.value))
                    .subscribe(buildingType => {
                        this.buildingClassificationOptions = this.inquiryConfigurationService.buildingClassificationOptions.filter(
                            option => !buildingTypeToBuildingClassifications[buildingType] || buildingTypeToBuildingClassifications[buildingType].includes(option.value)
                        );
                        if (!this.buildingClassificationOptions.map(option => option.value).includes(this.form.controls.classification.value)) {
                            this.form.controls.classification.setValue(null);
                        }
                    })
            );
        }

        this.form.get('buildingType').valueChanges.subscribe(() => {
            this.updateBuildingTypeFields();
        });
        this.form.get('knownConstructionCosts').valueChanges.subscribe(() => {
            this.updateCostAllocationFields(true);
        });
        this.form.get('leaseHoldOnProperty').valueChanges.subscribe(() => {
            this.updateLeaseholdFields();
        });

        this.form.get('propertyCosts').valueChanges.subscribe(() => this.updateTotalSalesPrice());
        this.form.get('buildingCosts').valueChanges.subscribe(() => this.updateTotalSalesPrice());
        this.form.get('renovationCosts').valueChanges.subscribe(() => this.updateTotalSalesPrice());
        this.form.get('buyingCosts').valueChanges.subscribe(() => this.updateTotalSalesPrice());

        this.form.get('country').valueChanges.subscribe(() => this.updateCountryRelatedFields());
    }


    public updateBuildingTypeFields(): void {
        const isMixedUse = (this.form.get('buildingType').value === 'MIXED_USE') ? true : false;
        const isResidentialUse = (this.form.get('buildingType').value === 'RESIDENTIAL_USE') ? true : false;

        this.form.get('housingUnitsCount')[(isResidentialUse ? 'enable' : 'disable')]();
        this.form.get('livingArea')[(isMixedUse ? 'enable' : 'disable')]();
        this.form.get('commercialArea')[(isMixedUse ? 'enable' : 'disable')]();

        if (!isResidentialUse) {
            this.form.get('housingUnitsCount').setValue(null);
        }

        if (!isMixedUse) {
            this.form.get('livingArea').setValue(null);
            this.form.get('commercialArea').setValue(null);
        }
    }

    public updateCostAllocationFields(clearTotal: boolean = false): void {
        const knownAllocationCosts = (this.form.get('knownConstructionCosts').value === true) ? true : false;

        this.form.get('propertyCosts')[(knownAllocationCosts ? 'enable' : 'disable')]();
        this.form.get('buildingCosts')[(knownAllocationCosts ? 'enable' : 'disable')]();
        this.form.get('renovationCosts')[(knownAllocationCosts ? 'enable' : 'disable')]();
        this.form.get('buyingCosts')[(knownAllocationCosts ? 'enable' : 'disable')]();

        if (!knownAllocationCosts) {
            this.form.get('propertyCosts').setValue(null);
            this.form.get('buildingCosts').setValue(null);
            this.form.get('buyingCosts').setValue(null);
            this.form.get('renovationCosts').setValue(null);
        }

        if (!knownAllocationCosts && clearTotal) {
            this.form.get('totalSalesPrice').setValue(null);
        }
    }

    public updateTotalSalesPrice(): void {
        const knownAllocationCosts = (this.form.get('knownConstructionCosts').value === true) ? true : false;
        if (!knownAllocationCosts) {
            return;
        }

        const propertyCosts = this.form.get('propertyCosts').value || 0;
        const buildingCosts = this.form.get('buildingCosts').value || 0;
        const renovationCosts = this.form.get('renovationCosts').value || 0;
        const buyingCosts = this.form.get('buyingCosts').value || 0;

        const totalPrice = (propertyCosts + buildingCosts + renovationCosts + buyingCosts);
        this.form.controls.totalSalesPrice.setValue(totalPrice);
    }

    public updateLeaseholdFields(): void {
        const isLeasehold = (this.form.get('leaseHoldOnProperty').value === true) ? true : false;

        this.form.get('groundRent')[(isLeasehold ? 'enable' : 'disable')]();
        this.form.get('remainingTermLeaseHold')[(isLeasehold ? 'enable' : 'disable')]();

        if (!isLeasehold) {
            this.form.get('groundRent').setValue(null);
            this.form.get('remainingTermLeaseHold').setValue(null);
        }
    }

    public updateCountryRelatedFields(): void {
        const country = this.form.get('country').value;

        const postCodeValidator = (country === 'GERMANY') ? customValidators.postalCodeOptionalValidator5 : customValidators.postalCodeOptionalValidator4;
        this.form.get('postalCode').setValidators([postCodeValidator]);
        this.form.get('postalCode').updateValueAndValidity();
    }
}
