import {
    BuildingClassification,
    BuildingTypes,
    BuildingTypesV2,
    InquiryConfigurationService
} from 'src/app/services/inquiry/inquiry-configuration.service';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {LabelsMap, TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {BuildingType} from '../../../../../../models/InquiryModel';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const RealEstateRenovationFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    buildingArea: [null, [customValidators.area9]],
    buildingAreaAfterProject: [null, [customValidators.area9]],
    buildingConstructionYear: [null, [customValidators.yearOfEstValidator()]],
    buildingDescription: [null, [Validators.required, Validators.maxLength(500)]],
    buildingType: [null, [Validators.required]],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    classification: [null, [Validators.required]],
    country: ['GERMANY', [Validators.required]],
    groundRent: [null, [customValidators.price()]],
    efficiencyStandard: ['UNKNOWN', [Validators.required]],
    houseNumber: [null, [Validators.maxLength(5)]],
    housingUnitsCount: [null, [Validators.required, Validators.max(9999)]],
    leaseHoldOnProperty: [false, []],
    mortgageOnProperty: [false, []],
    mortgageRemainingDebt: [null, [customValidators.price()]],
    postalCode: [null, [Validators.max(99999)]],
    propertyArea: [null, [Validators.max(999999999)]],
    remainingTermLeaseHold: [null, [customValidators.remainingTermLeaseHold]],
    renovationCosts: [null, [Validators.required, customValidators.price()]],
    streetAddress: [null, [Validators.maxLength(100)]],
};
const buildingTypeToBuildingClassifications = {
    COMMERCIAL_BUILDING: [
        BuildingClassification.PROJECT_FINANCING,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.HOTEL,
        BuildingClassification.PLANT,
    ],
    MIXED_USE: [
        BuildingClassification.PROJECT_FINANCING,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.HOTEL,
        BuildingClassification.PLANT,
        BuildingClassification.APPORTIONMENT,
    ],
    RESIDENTIAL_USE: [
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.APPORTIONMENT,
    ],
};

@Component({
    selector: 'app-real-estate-renovation-form',
    templateUrl: './real-estate-renovation-form.component.html',
    styleUrls: ['./real-estate-renovation-form.component.less']
})
export class RealEstateRenovationFormComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean;
    @Input() currency: string;
    public buildingTypes: Array<BuildingType>;
    public buildingTypeLabels: LabelsMap;
    public efficiencyStandards: Array<{ value: string, viewValue: string }> = [];
    public countries: Array<{ value: string, viewValue: string }> = [];
    public buildingClassificationOptions = [];
    private subscriptions: Subscription[] = [];

    constructor(
        public translationService: TranslationService,
        private inquiryConfigurationService: InquiryConfigurationService,
    ) {
        this.efficiencyStandards = this.translationService.inquiryEfficiencyStandards;
        this.countries = this.translationService.inquiryRealEstateCountries;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateBuildingTypeFields();
            this.updateMortgageFields();
            this.updateLeaseholdFields();
            this.updateCountryRelatedFields();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit() {
        if (this.inquiryConfigurationService.hasFormOldBuildingType(this.form)) {
            this.buildingTypes = BuildingTypes;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypes;
            this.form.controls.classification.disable();
        } else {
            this.buildingTypes = BuildingTypesV2;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypesV2;
            this.subscriptions.push(
                this.form.controls.buildingType.valueChanges.pipe(startWith(this.form.controls.buildingType.value))
                    .subscribe(buildingType => {
                        this.buildingClassificationOptions = this.inquiryConfigurationService.buildingClassificationOptions.filter(
                            option => !buildingTypeToBuildingClassifications[buildingType] || buildingTypeToBuildingClassifications[buildingType].includes(option.value)
                        );
                        if (!this.buildingClassificationOptions.map(option => option.value).includes(this.form.controls.classification.value)) {
                            this.form.controls.classification.setValue(null);
                        }
                    })
            )
        }

        this.form.get('buildingType').valueChanges.subscribe(() => {
            this.updateBuildingTypeFields();
        });
        this.form.get('mortgageOnProperty').valueChanges.subscribe(() => {
            this.updateMortgageFields();
        });
        this.form.get('leaseHoldOnProperty').valueChanges.subscribe(() => {
            this.updateLeaseholdFields();
        });

        this.form.get('country').valueChanges.subscribe(() => this.updateCountryRelatedFields());
    }

    public updateBuildingTypeFields(): void {
        let isResidentialUse = (this.form.get('buildingType').value === 'RESIDENTIAL_USE') ? true : false;

        this.form.get('housingUnitsCount')[(isResidentialUse ? 'enable' : 'disable')]();
    }

    public updateMortgageFields(): void {
        let isMortgage = (this.form.get('mortgageOnProperty').value === true) ? true : false;

        this.form.get('mortgageRemainingDebt')[(isMortgage ? 'enable' : 'disable')]();

        if (!isMortgage) {
            this.form.get('mortgageRemainingDebt').setValue(null);
        }
    }

    public updateLeaseholdFields(): void {
        let isLeasehold = (this.form.get('leaseHoldOnProperty').value === true) ? true : false;

        this.form.get('groundRent')[(isLeasehold ? 'enable' : 'disable')]();
        this.form.get('remainingTermLeaseHold')[(isLeasehold ? 'enable' : 'disable')]();

        if (!isLeasehold) {
            this.form.get('groundRent').setValue(null);
            this.form.get('remainingTermLeaseHold').setValue(null);
        }
    }

    public updateCountryRelatedFields(): void {
        let country = this.form.get('country').value;

        let postCodeValidator = (country === 'GERMANY') ? customValidators.postalCodeOptionalValidator5 : customValidators.postalCodeOptionalValidator4;
        this.form.get('postalCode').setValidators([postCodeValidator]);
        this.form.get('postalCode').updateValueAndValidity();
    }

}
