import {Injectable} from '@angular/core';
import {ConfirmationDialogService} from "../services/root/confirmation-dialog.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class UnsavedChangesGuard  {

    private dirtyFormSelector = 'form.ng-dirty:not(.no-leave-warning)';

    private ua = window.navigator.userAgent;
    private isIE = /MSIE|Trident/.test(this.ua);

    constructor(
        private confirmationDialogService: ConfirmationDialogService,
    ) {}

    hasPageUnsavedChanges() {
        // disable unsaved changes warning in IE
        // since IE marks them all "dirty" right after initialized
        return !this.isIE && !!document.querySelector(this.dirtyFormSelector);
    }

    canDeactivate(
        component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ) {
        if (!this.hasPageUnsavedChanges() || nextState.url.includes('/login')) {
            return true;
        }
        return this.confirmationDialogService.open({
            modalHeader: $localize`Unsaved data`,
            confirmationMessage: $localize`The changes have not yet been saved. Are you sure you want to discard the changes?`,
            confirmationYesButton: $localize`Yes`,
            confirmationNoButton: $localize`No`,
        })
    }
}
