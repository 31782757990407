import {NgModule} from '@angular/core';
import {FinmatchDashboardComponent} from "./finmatch-dashboard.component";
import {RouterModule} from "@angular/router";

const FINMATCH_DASHBOARD_ROUTES = [
    {path: '', component: FinmatchDashboardComponent}
]

@NgModule({
    imports: [
        RouterModule.forChild(FINMATCH_DASHBOARD_ROUTES)
    ],
    exports: [
        RouterModule
    ]
})
export class FinmatchDashboardRoutingModule {
}
