import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, of} from 'rxjs';
import {InquiryDataService} from '../services/provided/inquiry/inquiry-data.service';
import {switchMap} from 'rxjs/operators';
import {Principal} from '../services/auth/principal.service';
import {UsageAgreementService} from '../services/bank/usage-agreement.service';
import {BanksService} from '../services/provided/banks.service';

@Injectable({
    providedIn: 'root'
})
export class InquiryDataGuard  {

    constructor(
        private inquiryDataService: InquiryDataService,
        private banksService: BanksService,
        private principal: Principal,
        private usageAgreementService: UsageAgreementService,
        private router: Router,
    ) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.principal.getUserIdentitySubject().pipe(
            switchMap(loggedUser => {
                return this.inquiryDataService.load(childRoute.params.inquiryId).pipe(
                    switchMap(inquiry => {
                        if (!inquiry) return of(false);

                        if ((inquiry.agreementNeeded || inquiry.otuaRejected) && loggedUser?.isBankingUser()) {
                            return this.banksService.getBank(loggedUser.companyId).pipe(
                                switchMap(bank => {
                                    return this.usageAgreementService.openUsageAgreement(
                                        bank,
                                        this.usageAgreementService.mapInquiryResponseToInquiry(inquiry)
                                    ).pipe(
                                        switchMap(agreementAccepted => {
                                            const urlArray = window.location.href.split('/');
                                            const routeInquiryId = urlArray[urlArray.length - 2];
                                            const directURLOpen = childRoute.params.inquiryId === routeInquiryId;

                                            return agreementAccepted || !directURLOpen ? of(agreementAccepted) : of(this.router.parseUrl('/inquiry/list'));
                                        })
                                    );
                                })
                            );
                        }

                        return of(true);
                    })
                );
            })
        );
    }
}
