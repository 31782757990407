<form [class.readonly]="readonly" [formGroup]="form" autocomplete="off" class="finmatch-form">
    <div *ngIf="!readonly" class="form-required-information" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form"></app-financing-plans-field>

    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <input formControlName="manufacturer" matInput [placeholder]="vehicleFieldLabels.manufacturer.label">
                <mat-error [errorMessage]="form.get('manufacturer')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('manufacturer')">{{vehicleFieldLabels.manufacturer.label}}</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <mat-select formControlName="category" [placeholder]="vehicleFieldLabels.category.label" required>
                    <mat-option *ngFor="let category of vehicleCategories" [value]="category.value">{{category.viewValue}}</mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('category')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('category')" [map]="vehicleCategories">{{vehicleFieldLabels.category.label}}</app-form-field-readonly>
        </div>
    </div>

    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <input formControlName="type" matInput [placeholder]="vehicleFieldLabels.type.label">
                <mat-error [errorMessage]="form.get('type')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('type')">{{vehicleFieldLabels.type.label}}</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <app-number-input [placeholder]="vehicleFieldLabels.quantity.label" formControlName="quantity" required></app-number-input>
                <mat-error [errorMessage]="form.get('quantity')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('quantity')">{{vehicleFieldLabels.quantity.label}}</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <app-number-input formControlName="salesPricePerUnit" [placeholder]="vehicleFieldLabels.salesPricePerUnit.label" required></app-number-input>
                <mat-error [errorMessage]="form.get('salesPricePerUnit')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('salesPricePerUnit')">{{vehicleFieldLabels.salesPricePerUnit.label}}</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <app-number-input formControlName="expectedMileage" i18n-placeholder placeholder="Expected mileage (per year)" required></app-number-input>
                <mat-error [errorMessage]="form.get('expectedMileage')"></mat-error>
                <span matSuffix>km</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('expectedMileage')" i18n suffix="km">Expected mileage (per year)</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <!-- AUTO-FILLED -->
            <app-form-field-readonly [currency]="currency" [field]="form.get('totalSalesPrice')">{{vehicleFieldLabels.totalSalesPrice.label}}</app-form-field-readonly>
        </div>
    </div>

    <div *ngIf="!readonly" class="section-label" i18n>Condition</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="condition">
                <mat-radio-button class="radio-option" value="NEW">{{conditions['NEW']}}</mat-radio-button>
                <mat-radio-button class="radio-option" value="USED">{{conditions['USED']}}</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('condition')" [map]="conditions" i18n>Condition</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <app-number-input [simple]="true" formControlName="yearOfManufacture" i18n-placeholder placeholder="Year of manufacture"></app-number-input>
                <mat-error [errorMessage]="form.get('yearOfManufacture')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('yearOfManufacture')" format="NONE" i18n>Year of manufacture</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <app-number-input formControlName="mileage" i18n-placeholder placeholder="Mileage"></app-number-input>
                <mat-error [errorMessage]="form.get('mileage')"></mat-error>
                <span matSuffix>km</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('mileage')" i18n suffix="km">Mileage</app-form-field-readonly>
        </div>
    </div>

    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" class="field-input">
                <app-number-input formControlName="deprecationPeriod" i18n-placeholder placeholder="Depreciation period"></app-number-input>
                <mat-error [errorMessage]="form.get('deprecationPeriod')"></mat-error>
                <span i18n matSuffix>years</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('deprecationPeriod')" i18n i18n-suffix suffix="years">Depreciation period</app-form-field-readonly>
        </div>
    </div>
</form>
