<div>
    <div class="activate-container">
        <h1 i18n>Activation</h1>
        <div class="activate-container-alert alert-success" *ngIf="success">
            <div i18n>Your account has been activated. Please use the button below to log in.</div>
        </div>
        <div class="activate-container-alert alert-error" *ngIf="error">
            <ng-container *ngIf="!errorType">
                <div i18n>Your account could not be activated. The activation link is no longer valid.</div>
                <div i18n>Try to register your account again or contact FinMatch for details.</div>
            </ng-container>
            <ng-container *ngIf="errorType === ActivationError.alreadyUsedActivationKey">
               <div i18n>Your account has already been activated. Please log in with your user name and initial password, that you received within your activation-email.</div>
            </ng-container>
        </div>
        <a [routerLink]="['/register']" mat-flat-button color="primary" *ngIf="error && errorType !== ActivationError.alreadyUsedActivationKey" i18n>Sign up</a>
        <a [routerLink]="['/login']" mat-flat-button color="primary" *ngIf="success || (error && errorType === ActivationError.alreadyUsedActivationKey)" i18n>Log In</a>
    </div>
    <div *ngIf="success" class="activate-container-login-container">
        
        <!-- <app-login [header]="false"></app-login> --> 
    </div>
</div>
