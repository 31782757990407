<app-common-spinner [glass]="true" [fixed]="true" [global]="true" [visible]="globalSpinnerShown"></app-common-spinner>
<app-top-nav [sessionTimeout]="idleState"></app-top-nav>
<div class="router-outlet">
    <router-outlet></router-outlet>
</div>
<app-bottom-nav [appVer]="appVer"></app-bottom-nav>
<app-support *ngIf="!loggedUser?.isBankingUser() && !loggedUser?.isFinMatch()"></app-support>
<app-support-expert *ngIf="loggedUser?.isBankingUser()"></app-support-expert>

<app-cookie-consent></app-cookie-consent>
<app-unsupported-device-detector></app-unsupported-device-detector>
<app-google-analytics></app-google-analytics>
