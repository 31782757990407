export function UnsubscribeAllOnDestroy() {
    return function(constructor) {
        const orig = constructor.prototype.ngOnDestroy;
        constructor.prototype.ngOnDestroy = function() {
            for (const property in this) {
                if (typeof this[property]?.unsubscribe === "function" && !this[property].closed) {
                    this[property].unsubscribe();
                }
            }
            orig?.apply();
        };
    };
}
