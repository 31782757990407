<div class="widget-content">
    <section *ngFor="let keyFiguresForm of form.controls; let keyFiguresIndex = index" class="key-figures-section">
        <form [formGroup]="keyFiguresForm">
            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                <mat-expansion-panel-header>
                    <div class="key-figures-section-title">
                        {{ keyFiguresIndex + 1}}.&nbsp;{{keyFiguresForm.get('company').value}}
                    </div>
                </mat-expansion-panel-header>
                <ng-container>
                    <ng-container *ngIf="keyFiguresIndex > 0">
                        <div class="form-row">
                            <div class="form-field-100" (click)="$event.stopPropagation()">
                                <mat-form-field class="company-name-input">
                                    <input [formControl]="keyFiguresForm.get('company')" matInput placeholder="Company name" i18n-placeholder required>
                                    <mat-error [errorMessage]="keyFiguresForm.get('company')"></mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="keyFiguresForm.get(SpaceType.financialReport)" class="space financial-report">
                        <h3 class="widget-label" i18n>Key Figures</h3>

                        <button (click)="addCategory(keyFiguresIndex)" *ngIf="canCategoryBeAdded(keyFiguresIndex)" class="add-col-btn" mat-button type="button">
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>
                        <mat-table #keyFiguresTable *ngIf="financialReportDataSources[keyFiguresIndex]" [dataSource]="financialReportDataSources[keyFiguresIndex]" id="report-table">
                            <ng-container matColumnDef="label">
                                <mat-header-cell *matHeaderCellDef class="key-col"></mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index" [class.invalid]="element.controls.label.invalid" [formGroup]="element" class="key-col">
                                    <input (keydown)="handleTableNavigationOnInput($event, i + 1, 0)" autocomplete="off" formControlName="label" matInput>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="unit">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index" [class.invalid]="element.controls.unit.invalid" [formGroup]="element" class="unit-col">
                                    <input (keydown)="handleTableNavigationOnInput($event, i + 1, 1)" formControlName="unit" matInput>
                                </mat-cell>
                            </ng-container>

                            <ng-container *ngFor="let control of categoryLabelsForms[keyFiguresIndex].controls; let i = index" [matColumnDef]="i.toString()">
                                <mat-header-cell *matHeaderCellDef="let j = dataIndex" [class.invalid]="categoryLabelsForms[keyFiguresIndex].controls[i].invalid" [formGroup]="categoryLabelsForms[keyFiguresIndex]">
                                    <input (keydown)="handleTableNavigationOnInput($event, 0, 2 + i)" [formControlName]="i" autocomplete="off" class="category-input" matInput>
                                    <button (click)="removeCategory(i, keyFiguresIndex)" *ngIf="canCategoryBeRemoved(keyFiguresIndex)" class="remove-col-btn" mat-button type="button">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element; let j = index" [class.invalid]="element.controls.categories.controls[i]?.invalid" [formGroup]="element.controls.categories">
                                    <app-number-input
                                        (keydown)="handleTableNavigationOnInput($event, j + 1, 2 + i)"
                                        [allowDecimalPadding]="true"
                                        [alwaysAllowDecimalCharacter]="true"
                                        [decimalPlaces]="1"
                                        [formControl]="element.controls.categories.controls[i]"
                                    ></app-number-input>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef class="action-column"></mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index" class="action-column">
                                    <button *ngIf="['leverage', 'leverageRatio'].includes(element.controls.key.value)" mat-button type="button">
                                        <mat-slide-toggle
                                            [checked]="!isKeyFiguresRowHidden(element, keyFiguresIndex)"
                                            (toggleChange)="toggleKeyFiguresRow(element, keyFiguresIndex)"
                                        ></mat-slide-toggle>
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="getDisplayedColumns(keyFiguresIndex)"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: getDisplayedColumns(keyFiguresIndex); let rowIndex = index" [class.hidden]="isKeyFiguresRowHidden(row, keyFiguresIndex)"></mat-row>
                        </mat-table>
                    </div>

                    <div *ngIf="keyFiguresForm.controls[SpaceType.keyFigures]; let form" class="space key-figures">
                        <h3 class="widget-label">
                            <span i18n>Key figures</span>
                            <button mat-button type="button">
                                <mat-icon (click)="removeSpace(SpaceType.keyFigures, keyFiguresIndex)">close</mat-icon>
                            </button>
                        </h3>

                        <form [formGroup]="form" class="finmatch-form">
                            <div class="form-row" *ngFor="let fieldIndex of [0, 1, 2]">
                                <div class="form-field-50">
                                    <mat-form-field *ngIf="!isReadonly" class="field-input">
                                        <mat-select [formControl]="form.controls[fieldIndex].controls.label" name="label" i18n-placeholder matInput placeholder="Key figure (Label)" required>
                                            <mat-option *ngFor="let option of keyFiguresLabelOptions"
                                                        [value]="option.key"
                                                        [disabled]="isKeyFiguresOptionDisabled(fieldIndex, option.key, keyFiguresIndex)"
                                            >{{option.label}}</mat-option>
                                        </mat-select>
                                        <mat-error [errorMessage]="form.controls[fieldIndex].get('label')"></mat-error>
                                    </mat-form-field>
                                    <app-form-field-readonly *ngIf="isReadonly" [field]="form.controls[fieldIndex].get('label')">Key figure 1 (Label)</app-form-field-readonly>
                                </div>
                                <div class="form-field-50">
                                    <mat-form-field *ngIf="!isReadonly" class="field-input">
                                        <input [formControl]="form.controls[fieldIndex].controls.value" matInput [placeholder]="getKeyFigurePlaceholder(fieldIndex, keyFiguresIndex)" required type="text">
                                        <mat-error [errorMessage]="form.controls[fieldIndex].get('value')"></mat-error>
                                    </mat-form-field>
                                    <app-form-field-readonly *ngIf="isReadonly" [field]="form.controls[fieldIndex].get('value')">Key figure 1 (Value)</app-form-field-readonly>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="keyFiguresForm.controls[SpaceType.explanations]; let form" class="space explanations">
                        <h3 class="widget-label">
                            <span i18n>Explanations</span>
                            <button mat-button type="button">
                                <mat-icon (click)="removeSpace(SpaceType.explanations, keyFiguresIndex)">close</mat-icon>
                            </button>
                        </h3>

                        <form [formGroup]="form" class="finmatch-form explanation-form">
                            <div class="add-explanation">
                                <button (click)="addExplanationRow(keyFiguresIndex)" mat-flat-button type="button" type="button">+ <span i18n>Add explanation</span></button>
                            </div>
                            <div *ngFor="let control of form.controls; let i = index" class="form-row explanation-row">
                                <div class="form-field-100">
                                    <mat-form-field *ngIf="!isReadonly" class="field-input">
                                        <input [formControl]="control" i18n-placeholder matInput placeholder="Enter text" required type="text">
                                        <mat-error [errorMessage]="control"></mat-error>
                                    </mat-form-field>
                                    <app-form-field-readonly *ngIf="isReadonly" [field]="control"></app-form-field-readonly>
                                </div>

                                <div *ngIf="i > 0" class="remove-explanation-row">
                                    <button (click)="removeExplanationRow(i, keyFiguresIndex)" mat-button type="button" type="button">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="keyFiguresForm.controls[SpaceType.turnoverAllocation]; let form" class="space turnover-allocation">
                        <h3 class="widget-label">
                            <span i18n>Turnover allocation</span>
                            <button mat-button type="button">
                                <mat-icon (click)="removeSpace(SpaceType.turnoverAllocation, keyFiguresIndex)">close</mat-icon>
                            </button>
                        </h3>

                        <form [formGroup]="form" class="finmatch-form">
                            <div class="turnover-rows">
                                <div *ngFor="let rowFormGroup of form.controls; let i = index" class="form-row">
                                    <div class="form-field-50">
                                        <mat-form-field *ngIf="!isReadonly" class="field-input">
                                            <input [formControl]="rowFormGroup.controls.label" i18n-placeholder matInput placeholder="Entity name" required type="text">
                                            <mat-error [errorMessage]="rowFormGroup.get('label')"></mat-error>
                                        </mat-form-field>
                                        <app-form-field-readonly *ngIf="isReadonly" [field]="rowFormGroup.get('label')">Entity name</app-form-field-readonly>
                                    </div>

                                    <div class="form-field-25">
                                        <mat-form-field *ngIf="!isReadonly" class="field-input">
                                            <app-number-input [formControl]="rowFormGroup.controls.value"
                                                              [decimalPlaces]="1"
                                                              [allowDecimalPadding]="true"
                                                              [alwaysAllowDecimalCharacter]="true"
                                                              matInput required
                                            ></app-number-input>
                                            <mat-error [errorMessage]="rowFormGroup.get('value')"></mat-error>
                                            <span matSuffix>%</span>
                                        </mat-form-field>
                                        <app-form-field-readonly *ngIf="isReadonly" [field]="rowFormGroup.get('label')" suffix="%"></app-form-field-readonly>
                                    </div>

                                    <div *ngIf="i > 1" class="remove-turnover-row">
                                        <button (click)="removeTurnoverRow(i, keyFiguresIndex)" mat-button type="button">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="form.errors?.turnoverNot100 && form.touched" class="error-text status-red">
                                    <mat-icon>info</mat-icon>
                                    <span i18n>The sum of your overall turnover allocation must add up to 100%</span>
                                </div>
                            </div>
                            <div class="turnover-actions">
                                <button (click)="addTurnoverRow(keyFiguresIndex)" i18n mat-flat-button type="button">Add row</button>
                            </div>
                        </form>
                    </div>

                    <div *ngIf="keyFiguresForm.errors?.missingSpaces && saveAttempted" class="error-text status-red">
                        <mat-icon>info</mat-icon>
                        <span i18n>Please capture at least three spaces for this widget in order to continue.</span>
                    </div>

                    <div class="add-actions">
                        <button [disabled]="!canNewSpaceBeAdded(keyFiguresIndex)" [matMenuTriggerFor]="spaceMenu" mat-button type="button">+ <span i18n>Add space</span></button>
                        <mat-menu #spaceMenu="matMenu">
                            <button (click)="addSpace(SpaceType.turnoverAllocation, keyFiguresIndex)" [disabled]="form.at(keyFiguresIndex).get(SpaceType.turnoverAllocation)" i18n mat-menu-item type="button">Turnover allocation</button>
                            <button (click)="addSpace(SpaceType.explanations, keyFiguresIndex)" [disabled]="form.at(keyFiguresIndex).get(SpaceType.explanations)" i18n mat-menu-item type="button">Explanations</button>
                            <button (click)="addSpace(SpaceType.keyFigures, keyFiguresIndex)" [disabled]="form.at(keyFiguresIndex).get(SpaceType.keyFigures)" i18n mat-menu-item type="button">Key figures</button>
                        </mat-menu>
                    </div>

                </ng-container>
            </mat-expansion-panel>
        </form>
    </section>
    <section class="bottom-actions">
        <button *ngIf="totalKeyFigureSections < maxKeyFigureSections" mat-button type="button" (click)="addCompanySection()" i18n>Add key figures section</button>
    </section>
</div>
