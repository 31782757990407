import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-unsupported-device-modal',
    templateUrl: './unsupported-device-modal.component.html',
    styleUrls: ['./unsupported-device-modal.component.less']
})
export class UnsupportedDeviceModalComponent {
    constructor(
        public dialogRef: MatDialogRef<UnsupportedDeviceModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }
}
