import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnsupportedDeviceDetectorComponent} from "./unsupported-device-detector.component";

const dependencies = [];

@NgModule({
    declarations: [
        UnsupportedDeviceDetectorComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        UnsupportedDeviceDetectorComponent
    ]
})
export class UnsupportedDeviceDetectorModule {
}
