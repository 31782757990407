import { HttpResponse } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import saveAs from 'file-saver';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {Md5} from 'ts-md5/dist/md5';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {DocumentFile} from '../models/document-file.model';
import {FinMatchAccount} from '../models/finmatch-account.model';
import {Inquiry, InquiryModuleKey} from '../models/InquiryModel';
import {DocumentRepository} from '../repositories/document.repository';
import {DocumentFileClass, RealEstateModuleType} from './inquiry/inquiry-configuration.service';
import {TranslationService} from './root/translation/translation.service';
 import { AuthServerProvider } from './auth/auth-jwt.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentFileService {
    constructor(private documentRepository: DocumentRepository,
                @Inject(APP_CONFIG) private config: AppConfig,
                private authJWTService: AuthServerProvider,
                public toaster: ToastrService,
                public translationService: TranslationService) {
    }

    public postDocument(
        file: File,
        documentName: string,
        documentHash: string,
        documentClass: DocumentFileClass,
        parentObjectId: string = null,
        documentType: string = null,
        companyId: string = null
    ): Observable<HttpResponse<any>> {
        const formData = new FormData();

        if (file) {
            formData.append('file', file, file.name);
        } else {
            formData.append('documentName', documentName);
        }

        formData.append('documentHash', documentHash);
        formData.append('documentClass', documentClass);
        formData.append('parentObjectId', parentObjectId);
        formData.append('documentType', documentType);
        formData.append('companyId', companyId);

        return this.documentRepository.upload(formData);
    }

    public fetchZippedDocuments(fileHashes: string[], filename?: string): Observable<any> {
        if (!filename) {
            filename = `finmatch-${new Date().toISOString()}.zip`;
        }
        const token = this.authJWTService.getToken();
        return this.documentRepository.getZipped(fileHashes, filename, token).pipe(tap(blob => {
            saveAs(blob, filename);
        }));
    }

    /** Uploads list of DocumentFile to the server. Triggers observer after each upload with recently uploaded document data. */
    public postDocuments(documents: Array<DocumentFile>): Observable<DocumentFile> {
        return new Observable<DocumentFile>(observer => {
            const pendingDocs = [...documents];
            const uploadNext = () => {
                if (pendingDocs.length !== 0) {
                    const doc = pendingDocs.shift();
                    if (!doc.fileHandle) {
                        observer.next(doc);
                        uploadNext();
                        return;
                    }

                    this.postDocument(
                        doc.fileHandle,
                        doc.documentName,
                        doc.documentHash,
                        doc.documentClass,
                        doc.parentObjectId,
                        doc.documentType,
                        doc.companyId
                    ).subscribe({
                        next: res => {
                            _.assign(doc, res.body);
                            observer.next(doc);
                            uploadNext();
                        },
                        error: res => {
                            observer.error(res);
                        }
                    });
                } else {
                    observer.complete();
                }
            };

            uploadNext();
        }).pipe(share());
    }

    public getRandomHash(): string {
        const hashStr = new Date().getTime().toString() + Math.random().toString();
        return <string>Md5.hashStr(hashStr);
    }

    public getDocumentTemporaryToken(documentHash: string): Observable<HttpResponse<any>> {
        const postData = {
            documentHash: documentHash
        };
        return this.documentRepository.certify(postData);
    }

    public viewFile(documentHash: string): Observable<HttpResponse<any>> {
        const token = this.getDocumentTemporaryToken(documentHash);
        token.subscribe({
            next: res => {
                if (_.indexOf(this.config.allowedContentTypes, res.body.contentType) >= 0) {
                    const id_token = res.body.id_token;
                    const kcToken = this.authJWTService.getToken();
                    const link = document.createElement('a');
                    link.target = '_blank';
                    link.href = this.config.API_URL + this.config.API_URLs.document + id_token + (kcToken ? "?kcToken=" + kcToken : "");
                    link.setAttribute('visibility', 'hidden');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    this.toaster.error(this.translationService.upload.invalidContentTypeMessage, this.translationService.upload.invalidContentTypeTitle);
                }
            }
        });
        return token;
    }


    public getDocumentFileClass(inquiry: Inquiry, moduleKey: InquiryModuleKey): DocumentFileClass {
        const moduleType = inquiry.getModuleType(moduleKey);

        switch (true) {
            case (moduleKey === 'machine'):
                return 'Inquiry__Machine';
            case (moduleKey === 'vehicle'):
                return 'Inquiry__Vehicle';
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.NEW_BUILDING):
                return 'Inquiry__Real_Estate__New_Building';
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.EXISTING_BUILDING):
                return 'Inquiry__Real_Estate__Existing_Building';
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.RENOVATION):
                return 'Inquiry__Real_Estate__Renovation';
            case (moduleKey === 'realEstate' && moduleType === RealEstateModuleType.FOLLOWUP_FINANCING):
                return 'Inquiry__Real_Estate__Follow_Up_Financing';
            case (moduleKey === 'property' && moduleType === 'PURCHASE'):
                return 'Inquiry__Property__Purchase';
            case (moduleKey === 'property' && moduleType === 'FOLLOWUP_FINANCING'):
                return 'Inquiry__Property__Follow_Up_Financing';
            case (moduleKey === 'workingCapital'):
                return 'Inquiry__Working_Capital';
            case (moduleKey === 'other'):
                return 'Inquiry__Other';
            case (moduleKey === 'fastLane'):
                return 'Inquiry__Fast_Lane';
            default:
                return null;
        }
    }

    canUserSendToHelic(user: FinMatchAccount): boolean {
        return user?.isFinMatch();
    }

    canUserSeeHelicAnalysis(user: FinMatchAccount) {
        return user && (user.isFinMatch() || user.isBankingUser());
    }
}
