import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {Inquiry, InquiryFactory} from '../../../models/InquiryModel';
import {Company} from '../../../models/CompanyModel';
import {catchError, map} from 'rxjs/operators';
import {InquiryService} from '../../inquiry/inquiry.service';
import {ToastrService} from 'ngx-toastr';
import {TranslationService} from '../../root/translation/translation.service';

@Injectable({
    providedIn: 'root'
})
export class InquiryDataService {
    inquiry$ = new ReplaySubject<Inquiry>(1);
    company$ = new ReplaySubject<Company>(1);
    beingReloaded = false;

    constructor(
        private inquiryFactory: InquiryFactory,
        private inquiryService: InquiryService,
        private toastrService: ToastrService,
        private translationService: TranslationService
    ) {
        this.inquiry = inquiryFactory.getInquiryInstance();
    }

    _inquiry: Inquiry;

    get inquiry(): Inquiry {
        return this._inquiry;
    }

    set inquiry(value: Inquiry) {
        this._inquiry = this.inquiryFactory.getInquiryInstance();
        this._inquiry.applyData(value);
        this.inquiry$.next(this._inquiry);
    }

    _company: Company;

    get company(): Company {
        return this._company;
    }

    set company(value: Company) {
        this._company = value;
        this.company$.next(value);
    }

    reload(): Observable<Inquiry> {
        if (!this.inquiry) {
            return of(null);
        }

        return this.load(this._inquiry.id);
    }

    public load(inquiryId: string): Observable<any> {
        return this.inquiryService.getInquiry(inquiryId).pipe(
            map(response => {
                if (response.body.hasOwnProperty('inquiry') && !response.body.inquiry.hasOwnProperty('financialStatements')) {
                    response.body.inquiry['financialStatements'] = [];
                } else if (!response.body.hasOwnProperty('inquiry') && !response.body.hasOwnProperty('financialStatements')) {
                    response.body['financialStatements'] = [];
                }

                this.inquiry = response.body;
                return response.body;
            }),
            catchError(() => {
                this.toastrService.error(
                    this.translationService.inquiry.notFound.replace('[INQUIRY_ID]', inquiryId),
                    this.translationService.inquiry.notFoundHeader
                );
                return of(this);
            })
        );
    }
}
