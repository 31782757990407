import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import {WINDOW} from 'src/app/services/window.service';
import {APP_CONFIG, AppConfig} from "../../../../app-config.module";
import {SupportRepository} from "../../../../repositories/support.repository";
import {InquiryListService} from '../../../../services/inquiry/inquiry-list.service';
import {UnsubscribeAllOnDestroy} from '../../decorators/unsubscribe-all-on-destroy.decorator';
import {FinmatchAssignee} from '../../../../models/InquiryModel';
import {ProfilesService} from '../../../../services/profiles.service';
import {AccountRepository} from '../../../../repositories/account.repository';

interface AssigneeData extends Omit<FinmatchAssignee, 'id'> {
    profilePhotoUrl?: string;
}

@Component({
    selector: 'app-support-expert',
    templateUrl: './support-expert.component.html',
    styleUrls: ['./support-expert.component.less']
})
@UnsubscribeAllOnDestroy()
export class SupportExpertComponent implements OnInit {

    @HostBinding('class.supportFormOpened') public isSupportFormOpened = false;

    private assigneeFallback: AssigneeData = {
        email: 'support@finmatch.de',
        firstName: 'FinMatch',
        lastName: 'Team',
        phone: '+49 (0) 711 / 995 215 0',
        profilePhotoUrl: '../../../../../assets/images/Fallback_Contact_General.jpeg',
    };

    private assigneeFallbackExpertPhotoUrl = '../../../../../assets/images/Fallback_Contact_Expert.png';

    public assignee = this.assigneeFallback;
    public emailSubject: string;

    constructor(
        private supportRepository: SupportRepository,
        private inquiryListService: InquiryListService,
        private profilesService: ProfilesService,
        private accountRepository: AccountRepository,
        @Inject(WINDOW) private window: Window,
        @Inject(APP_CONFIG) public config: AppConfig
    ) {
    }

    ngOnInit() {
        this.inquiryListService.selectedInquiry$.subscribe(inquiry => {
            const assignee = inquiry?.assignees?.finmatch || inquiry?.assignees?.broker;
            this.emailSubject = inquiry?.heading;

            if (assignee && (assignee.firstName || assignee.lastName || assignee.email || assignee.phone)) {
                this.assignee = assignee;
                this.assignee.phone = this.assignee.phone || this.assigneeFallback.phone;

                this.accountRepository.getAccountImage(assignee.id).subscribe(imageUrl => {
                    this.assignee.profilePhotoUrl = imageUrl ? this.profilesService.getProfilePhotoUrl({imageUrl}) : this.assigneeFallbackExpertPhotoUrl;
                }, () => {
                    this.assignee.profilePhotoUrl = this.assigneeFallbackExpertPhotoUrl;
                });
            } else {
                this.assignee = this.assigneeFallback;
            }
        });

        this.supportRepository.openSupportForm$.subscribe(open => {
            if (open) this.openSupportForm();
        });
    }

    openSupportForm() {
        this.isSupportFormOpened = true;
    }

    closeSupportForm() {
        this.isSupportFormOpened = false;
    }
}
