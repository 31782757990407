import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef, Inject,
    Input, LOCALE_ID, OnChanges,
    OnInit,
    ViewChild,
    SimpleChanges
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {DocumentFile} from '../../../../../../models/document-file.model';
import {Inquiry} from '../../../../../../models/InquiryModel';
import {MatTableDataSource} from '@angular/material/table';
import {filter, take} from 'rxjs/operators';
import {FinancialStatementsRepository} from '../../../../../../repositories/financial-statements/financial-statements.repository';
import {
    KeyFiguresWidget,
    KeyFiguresWidgetSection
} from '../../../../../../services/inquiry//teaser/inquiry-teaser.service';
import {CrefoService} from '../../../../../../services/provided/crefo.service';
import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {ConfirmationDialogService} from '../../../../../../services/root/confirmation-dialog.service';
import {KeyFiguresMapping} from '../../../../../financial-statement/mapping/key-figures.mapping';

export enum SpaceType {
    financialReport = 'financialReport',
    turnoverAllocation = 'turnoverAllocation',
    keyFigures = 'keyFigures',
    explanations = 'explanations',
}

@Component({
    selector: 'teaser-widget-key-figures-widget',
    templateUrl: './teaser-key-figures.component.html',
    styleUrls: ['./teaser-key-figures.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserKeyFiguresComponent implements OnInit, OnChanges {
    @Input() form: UntypedFormArray;
    @Input() inquiry: Inquiry;
    @Input() saveAttempted = false;
    @Input() isReadonly = false;
    @Input() initialValues: KeyFiguresWidget;
    @Input() hiddenElements: {[SpaceType.financialReport]: string[]}[];
    @Input() _reload: any;

    @ViewChild('keyFiguresTable', {read: ElementRef, static: false}) keyFiguresTable: ElementRef;

    public SpaceType = SpaceType;

    private defaultSpaces = [
        SpaceType.financialReport,
        SpaceType.keyFigures,
    ];

    public financialReportDataSources;
    public categoryLabelsForms: UntypedFormArray[];
    public maxSpaces = 3;
    public nonSpaceFormControls = 2;
    public maxKeyFigureSections = 4;
    private defaultReportCategories = ['2017', '2018', '2019'];
    private leftKeyCode = 37;
    private rightKeyCode = 39;
    private upKeyCode = 38;
    private downKeyCode = 40;

    public keyFiguresLabelOptions = this.keyFiguresMapping.keyFiguresOptions;
    public keyFiguresValuesMap: {[key: string]: number} = {};

    constructor(
        private cd: ChangeDetectorRef,
        private confirmationDialog: ConfirmationDialogService,
        private crefoService: CrefoService,
        private financialStatementsRepository: FinancialStatementsRepository,
        private finmatchValidators: FinMatchValidators,
        private formBuilder: UntypedFormBuilder,
        private keyFiguresMapping: KeyFiguresMapping,
        @Inject(LOCALE_ID) private locale: string,
    ) {
    }

    get totalKeyFigureSections(): number {
        return Object.keys(this.form.controls)?.length ?? 0;
    }

    getDisplayedColumns(keyFiguresIndex: number) {
        return ['label', 'unit', ...(Object.keys(this.categoryLabelsForms[keyFiguresIndex].value).map(value => value.toString())), 'actions'];
    }

    ngOnInit(): void {
        if (this.initialValues && !Array.isArray(this.initialValues)) {
            // TODO: remove once backend migration done
            this.initialValues = [this.initialValues];
        }
        if (this.hiddenElements && this.hiddenElements.hasOwnProperty(SpaceType.financialReport)) {
            // TODO: remove once backend migration done
            this.hiddenElements = [this.hiddenElements] as any;
        }
        const lastYearReportFile: DocumentFile = <DocumentFile>this.inquiry.financialStatements.find((file: DocumentFile) => {
            return file.analysisState === 'AVAILABLE'
                && file.documentType === 'ANNUAL_REPORT_LAST_FINANCIAL_YEAR'
                && file.ratingId;
        });
        if (lastYearReportFile) {
            this.crefoService.keyFigures(lastYearReportFile.ratingId).subscribe(keyFigures => {
                Object.values(keyFigures).forEach(keyFiguresSection => {
                    this.keyFiguresValuesMap = {...this.keyFiguresValuesMap, ...keyFiguresSection};
                });
                this.initForms();
                this.cd.markForCheck();
            });
        } else {
            this.initForms();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.form && !changes.form.isFirstChange()) {
            this.initForms();
        }
    }

    public addCompanySection() {
        this.addKeyFiguresPerCompany(this.form.length, null);
    }

    private initForms() {
        this.categoryLabelsForms = [];
        this.financialReportDataSources = [];
        for (const [index, keyFiguresCompany] of (this.initialValues || [null]).entries()) {
            this.addKeyFiguresPerCompany(index, keyFiguresCompany);
        }
    }

    private addKeyFiguresPerCompany(index: number, keyFiguresCompany: KeyFiguresWidgetSection) {
        this.categoryLabelsForms.push(this.formBuilder.array([]));

        if (keyFiguresCompany?.financialReportCategories?.length) {
            for (const category of keyFiguresCompany.financialReportCategories) {
                this.categoryLabelsForms[index].push(this.formBuilder.control(category, [Validators.required, Validators.maxLength(10)]));
            }
        } else {
            for (const category of this.defaultReportCategories) {
                this.categoryLabelsForms[index].push(this.formBuilder.control(category, [Validators.required, Validators.maxLength(10)]));
            }
        }
        const companyName = index === 0 ? this.inquiry.companyName : (keyFiguresCompany?.company ?? null);
        this.form.push(this.formBuilder.group({
            company: this.formBuilder.control(companyName, [Validators.required, Validators.maxLength(200)]),
            financialReportCategories: this.categoryLabelsForms[index],
        }, {
            validators: [
                (formGroup: UntypedFormGroup) => {
                    const expectedSpaces = 3;
                    if (Object.keys(formGroup.controls).length < expectedSpaces + this.nonSpaceFormControls) {
                        return {missingSpaces: true};
                    }
                    return null;
                },
            ]
        }));

        for (const space of Object.keys(SpaceType)) {
            if (keyFiguresCompany?.[space]) {
                this.addSpace(space as SpaceType, index);
            }
        }
        if (Object.keys((this.form.at(index) as UntypedFormGroup).controls).length < (this.maxSpaces + this.nonSpaceFormControls)) {
            for (const space of this.defaultSpaces) {
                if (!((this.form.at(index) as UntypedFormGroup).controls[space])) {
                    this.addSpace(space, index);
                }
            }
        }
    }

    getKeyFigurePlaceholder(fieldIndex: number, keyFiguresIndex: number) {
        const fieldKey = ((this.form.at(keyFiguresIndex) as UntypedFormGroup).get(SpaceType.keyFigures) as UntypedFormArray).controls[fieldIndex].get('label').value;
        return this.keyFiguresLabelOptions.find(option => option.key === fieldKey)?.label ?? fieldKey;
    }

    isKeyFiguresOptionDisabled(fieldIndex: number, optionValue: string, keyFiguresIndex: number) {
        const otherIndexes = [0, 1, 2].filter(i => i !== fieldIndex);
        const keyFiguresFormValue = (this.form.at(keyFiguresIndex) as UntypedFormGroup).get(SpaceType.keyFigures).value;
        const selectedOtherOptions = [keyFiguresFormValue[otherIndexes[0]].label, keyFiguresFormValue[otherIndexes[1]].label];
        return selectedOtherOptions.includes(optionValue);

    }

    public getReportCategoryValidators(fieldKey: string, unit: string, keyFiguresIndex: number): ValidatorFn[] {
        if (this.hiddenElements[keyFiguresIndex]?.[SpaceType.financialReport].includes(fieldKey)) {
            return [];
        }
        return [
            Validators.required,
            ...(unit === '%'
                ? [this.finmatchValidators.num(3, 2)]
                : [this.finmatchValidators.num(12, 1)]
            )
        ];

    }

    public addSpace(spaceType: SpaceType, keyFiguresIndex: number): void {
        switch (spaceType) {
            case SpaceType.financialReport: {
                const initialFinancialReportDataSource = [
                    {
                        key: 'turnover',
                        label: $localize`Revenues`,
                        unit: `T${this.inquiry.currency}`,
                        isDisabled: true,
                    },
                    {
                        key: 'EBIT',
                        label: $localize`EBIT`,
                        unit: `T${this.inquiry.currency}`,
                    },
                    {
                        key: 'EBIT %',
                        label: $localize`EBIT`,
                        unit: '%',
                    },
                    {
                        key: 'EBITDA',
                        label: $localize`EBITDA`,
                        unit: `T${this.inquiry.currency}`,
                        isDisabled: true,
                    },
                    {
                        key: 'EBITDA %',
                        label: $localize`EBITDA`,
                        unit: '%',
                        isDisabled: true,
                    },
                    {
                        key: 'totalSum',
                        label: $localize`Total sum balance sheet`,
                        unit: `T${this.inquiry.currency}`,
                    },
                    {
                        key: 'equity',
                        label: $localize`Equity`,
                        unit: `T${this.inquiry.currency}`,
                        isDisabled: true,
                    },
                    {
                        key: 'equity %',
                        label: $localize`Equity`,
                        unit: '%',
                        isDisabled: true,
                    },
                    {
                        key: 'leverage',
                        label: $localize`Net financial liabilities`,
                        unit: `T${this.inquiry.currency}`,
                        isDisabled: true,
                    },
                    {
                        key: 'leverageRatio',
                        label: $localize`Leverage ratio`,
                        unit: '',
                        isDisabled: true,
                    },
                ];
                let initialData;
                if (!this.initialValues?.[keyFiguresIndex]?.financialReport) {
                    initialData = initialFinancialReportDataSource;
                } else {
                    initialData = [];
                    initialFinancialReportDataSource.forEach(initialRow => {
                        const savedRow = this.initialValues[keyFiguresIndex].financialReport.find(saved => saved.key === initialRow.key);
                        if (savedRow) {
                            savedRow.label = initialRow.label;
                            savedRow.unit = savedRow.unit || initialRow.unit;
                            if (initialRow.key === 'leverageRatio') {
                                savedRow.unit = '';
                            }
                            savedRow.isDisabled = initialRow.isDisabled;
                            initialData.push(savedRow);
                            return savedRow;
                        }
                    });
                }
                (this.form.at(keyFiguresIndex) as UntypedFormGroup).setControl(SpaceType.financialReport, this.formBuilder.array((initialData as Array<any>).map(row => {
                    const group = this.formBuilder.group({
                        key: this.formBuilder.control(row.key),
                        label: this.formBuilder.control({
                            value: row.label || row.key,
                            disabled: row.isDisabled
                        }, [Validators.required, Validators.maxLength(40)]),
                        unit: this.formBuilder.control({
                            value: row.unit,
                            disabled: row.isDisabled || row.unit === '%',
                        }, [Validators.maxLength(20), Validators.required]),
                        isDisabled: this.formBuilder.control({value: row.isDisabled, disabled: true}),
                        categories: this.formBuilder.array(this.categoryLabelsForms[keyFiguresIndex].value.map((catValue, i) => {
                            return this.formBuilder.control((row.categories?.[i] || row.categories?.[i] === 0) ? (Math.round(row.categories[i] * 10) / 10) : null,
                                this.getReportCategoryValidators(row.key, row.unit, keyFiguresIndex));
                        }))
                    });
                    return group;
                })));
                this.financialReportDataSources[keyFiguresIndex] = new MatTableDataSource(((this.form.at(keyFiguresIndex) as UntypedFormGroup).get('financialReport') as UntypedFormArray).controls);
                return;
            }
            case SpaceType.keyFigures: {
                const initialData = this.initialValues?.[keyFiguresIndex]?.keyFigures;
                (this.form.at(keyFiguresIndex) as UntypedFormGroup).setControl(SpaceType.keyFigures, this.formBuilder.array([
                    this.formBuilder.group({
                        label: this.formBuilder.control(initialData ? initialData[0].label : null, [Validators.required, Validators.maxLength(50)]),
                        value: this.formBuilder.control(initialData ? initialData[0].value : null, [Validators.required, Validators.maxLength(50)]),
                    }),
                    this.formBuilder.group({
                        label: this.formBuilder.control(initialData ? initialData[1].label : null, [Validators.required, Validators.maxLength(50)]),
                        value: this.formBuilder.control(initialData ? initialData[1].value : null, [Validators.required, Validators.maxLength(50)]),
                    }),
                    this.formBuilder.group({
                        label: this.formBuilder.control(initialData ? initialData[2].label : null, [Validators.required, Validators.maxLength(50)]),
                        value: this.formBuilder.control(initialData ? initialData[2].value : null, [Validators.required, Validators.maxLength(50)]),
                    }),
                ]));
                if (keyFiguresIndex === 0) {
                    for (const group of (this.form.at(keyFiguresIndex).get(SpaceType.keyFigures) as UntypedFormArray).controls) {
                        group.get('label').valueChanges.subscribe(fieldLabel => {
                            if ((this.keyFiguresValuesMap || {}).hasOwnProperty(fieldLabel)) {
                                const selectOption = this.keyFiguresLabelOptions.find(option => option.key === fieldLabel);
                                const decimals = selectOption.decimals;
                                group.get('value').setValue((this.keyFiguresValuesMap[fieldLabel] ?? '').toLocaleString(this.locale, {
                                    minimumFractionDigits: decimals || 0,
                                    maximumFractionDigits: decimals || 0,
                                }));
                            } else {
                                group.get('value').setValue(null);
                            }
                        });
                    }
                }
                return;
            }
            case SpaceType.turnoverAllocation: {
                const initialData = this.initialValues?.[keyFiguresIndex]?.[SpaceType.turnoverAllocation] || [
                    {label: null, value: null},
                    {label: null, value: null},
                ];
                (this.form.at(keyFiguresIndex) as UntypedFormGroup).setControl(SpaceType.turnoverAllocation, this.formBuilder.array(initialData.map(row => {
                    return this.getTurnoverRowFormGroup(row);
                }), {
                    validators: [
                        (formArray: UntypedFormArray) => {
                            const totalTurnover = formArray.controls.reduce((agg, rowGroup) => {
                                return agg + (rowGroup as UntypedFormGroup).controls.value.value || 0;
                            }, 0);
                            if (totalTurnover !== 100) {
                                return {turnoverNot100: true};
                            }
                            return null;
                        }
                    ]
                }));
                return;
            }
            case SpaceType.explanations: {
                const initialData = this.initialValues?.[keyFiguresIndex]?.[SpaceType.explanations] || [null];
                (this.form.at(keyFiguresIndex) as UntypedFormGroup).setControl(SpaceType.explanations, this.formBuilder.array(initialData.map(value => {
                    return this.formBuilder.control(value, [Validators.required, Validators.maxLength(300)]);
                })));
                return;
            }
        }
    }

    public removeCategory(columnIndex: number, keyFiguresIndex: number) {
        for (const group of ((this.form.at(keyFiguresIndex) as UntypedFormGroup).get('financialReport') as UntypedFormArray).controls) {
            ((group as UntypedFormGroup).controls.categories as UntypedFormArray).removeAt(columnIndex);
        }
        this.categoryLabelsForms[keyFiguresIndex].removeAt(columnIndex);
    }

    public addCategory(keyFiguresIndex: number) {
        const name = [...this.categoryLabelsForms[keyFiguresIndex].value].sort().reverse()[0];
        let newCategoryName;
        if (isNaN(name as any)) {
            newCategoryName = `${name}(1)`;
        } else {
            newCategoryName = (Number(name) + 1).toString();
        }
        for (const group of ((this.form.at(keyFiguresIndex) as UntypedFormGroup).get('financialReport') as UntypedFormArray).controls) {
            ((group as UntypedFormGroup).controls.categories as UntypedFormArray).push(this.formBuilder.control(null,
                this.getReportCategoryValidators(group.get('key').value, group.get('unit').value, keyFiguresIndex)));
        }
        const categoryLabelControl = this.formBuilder.control(newCategoryName, [Validators.required, Validators.maxLength(10)]);
        this.categoryLabelsForms[keyFiguresIndex].push(categoryLabelControl);
    }

    public canCategoryBeRemoved(keyFiguresIndex: number) {
        return this.getDisplayedColumns(keyFiguresIndex).length >= 5;
    }

    public canCategoryBeAdded(keyFiguresIndex: number) {
        return this.getDisplayedColumns(keyFiguresIndex).length < 6;
    }

    public removeSpace(space: SpaceType, keyFiguresIndex: number) {
        this.confirmationDialog.open({
            modalHeader: $localize`Delete space - confirmation`,
            confirmationMessage: $localize`Are you sure you want to delete the space and the input captured for it? All entered data will irrevocably be deleted.`,
        }).pipe(
            take(1),
            filter(result => !!result),
        ).subscribe(() => {
            (this.form.at(keyFiguresIndex) as UntypedFormGroup).removeControl(space);
            this.cd.markForCheck();
        });
    }

    public canNewSpaceBeAdded(keyFiguresIndex: number) {
        return Object.keys((this.form.at(keyFiguresIndex) as UntypedFormGroup).controls).length - this.nonSpaceFormControls < this.maxSpaces;
    }

    public addTurnoverRow(keyFiguresIndex: number) {
        ((this.form.at(keyFiguresIndex) as UntypedFormGroup).controls[SpaceType.turnoverAllocation] as UntypedFormArray).push(
            this.getTurnoverRowFormGroup(null),
        );
    }

    public removeTurnoverRow(rowIndex: number, keyFiguresIndex: number) {
        ((this.form.at(keyFiguresIndex) as UntypedFormGroup).controls[SpaceType.turnoverAllocation] as UntypedFormArray).removeAt(rowIndex);
    }

    public addExplanationRow(keyFiguresIndex: number) {
        ((this.form.at(keyFiguresIndex) as UntypedFormGroup).controls[SpaceType.explanations] as UntypedFormArray).push(
            this.formBuilder.control(null, [Validators.required, Validators.maxLength(300)]),
        );
    }

    public removeExplanationRow(rowIndex: number, keyFiguresIndex: number) {
        ((this.form.at(keyFiguresIndex) as UntypedFormGroup).controls[SpaceType.explanations] as UntypedFormArray).removeAt(rowIndex);
    }

    public handleTableNavigationOnInput(event, rowIndex, colIndex) {
        const isLeftRight = [this.leftKeyCode, this.rightKeyCode].includes(event.keyCode);
        const isUpDown = [this.upKeyCode, this.downKeyCode].includes(event.keyCode);
        if (!isUpDown && !isLeftRight) {
            return;
        }

        if (
            (event.keyCode === this.leftKeyCode && event.target.selectionStart !== 0)
            ||
            (event.keyCode === this.rightKeyCode && event.target.selectionStart !== event.target.value?.length)
        ) {
            return;
        }
        event.preventDefault();
        let rowToFocusIndex = rowIndex;
        if (event.keyCode === this.upKeyCode) {
            rowToFocusIndex--;
        } else if (event.keyCode === this.downKeyCode) {
            rowToFocusIndex++;
        }
        const row = [
            ...this.keyFiguresTable.nativeElement.getElementsByTagName('mat-header-row'),
            ...this.keyFiguresTable.nativeElement.getElementsByTagName('mat-row')
        ][rowToFocusIndex];
        if (!row) {
            return;
        }
        let colIndexToFocus = colIndex;
        if (event.target.selectionStart === 0 && event.keyCode === this.leftKeyCode) {
            colIndexToFocus--;
        } else if (event.target.selectionStart === event.target.value?.length && event.keyCode === this.rightKeyCode) {
            colIndexToFocus++;
        }
        const column = row.getElementsByTagName('mat-cell')[isUpDown ? colIndex : colIndexToFocus];
        if (!column) {
            return;
        }
        const input = column.getElementsByTagName('input')[0];
        if (input) {
            input.focus();
        }
    }

    private getTurnoverRowFormGroup(dataPerRow = null): UntypedFormGroup {
        return this.formBuilder.group({
            label: this.formBuilder.control(dataPerRow?.label, [Validators.required, Validators.maxLength(20)]),
            value: this.formBuilder.control(dataPerRow?.value, [
                Validators.required,
                Validators.max(100),
                Validators.min(0),
                this.finmatchValidators.num(2, 2)],
            ),
        });
    }

    public toggleKeyFiguresRow(rowFormGroup: UntypedFormGroup, keyFiguresIndex: number) {
        const fieldKey = rowFormGroup?.value?.key;
        const unit = rowFormGroup.get('unit').value;
        if (this.hiddenElements[keyFiguresIndex]?.[SpaceType.financialReport].includes(fieldKey)) {
            this.hiddenElements[keyFiguresIndex][SpaceType.financialReport] = this.hiddenElements[keyFiguresIndex][SpaceType.financialReport].filter(row => row !== fieldKey);
        } else {
            if (!this.hiddenElements?.[keyFiguresIndex]) {
                this.hiddenElements[keyFiguresIndex] = {[SpaceType.financialReport]: []};
            }
            this.hiddenElements[keyFiguresIndex][SpaceType.financialReport].push(fieldKey);
        }
        for (const formGroup of (rowFormGroup.get('categories') as UntypedFormArray).controls) {
            formGroup.setValidators(this.getReportCategoryValidators(fieldKey, unit, keyFiguresIndex));
            formGroup.updateValueAndValidity();
        }
    }

    public isKeyFiguresRowHidden(rowFormGroup: UntypedFormGroup, keyFiguresIndex: number): boolean {
        const value = rowFormGroup?.value?.key;
        if (!value) {
            return false;
        }
        return this.hiddenElements?.[keyFiguresIndex]?.[SpaceType.financialReport].includes(value);
    }
}
