<form [class.readonly]="readonly" [formGroup]="form" autocomplete="off" class="finmatch-form">
    <div *ngIf="!readonly" class="form-required-information" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form"></app-financing-plans-field>

    <div *ngIf="!isAggregated" class="section-label" i18n>Machine</div>
    <div *ngIf="isAggregated" class="section-label" i18n>Machines</div>
    <div *ngIf="form.controls.hasOwnProperty('machineType') || form.controls.hasOwnProperty('quantity')" class="form-row">
        <div *ngIf="form.controls.hasOwnProperty('machineType')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select formControlName="machineType" [placeholder]="machineFieldLabels.machineType.label" required>
                    <mat-option *ngFor="let type of machineTypes" [value]="type.value">{{type.viewValue}}</mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('machineType')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('machineType')" [map]="machineTypes">{{machineFieldLabels.machineType.label}}</app-form-field-readonly>
        </div>
        <div *ngIf="form.controls.hasOwnProperty('quantity')" class="form-field-50">
            <mat-form-field *ngIf="!readonly && hasMultiple === true">
                <app-number-input [placeholder]="machineFieldLabels.quantity.label" formControlName="quantity" required></app-number-input>
                <mat-error [errorMessage]="form.get('quantity')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly || hasMultiple === false" [field]="form.get('quantity')" [title]="(hasMultiple || readonly) ? null : changeQuantityTooltip">{{machineFieldLabels.quantity.label}}</app-form-field-readonly>
        </div>
    </div>
    <!-- for aggregation view -->
    <div *ngIf="isAggregated && form.controls.hasOwnProperty('totalSalesPrice')" class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [placeholder]="machineFieldLabels.totalSalesPrice.label" formControlName="totalSalesPrice" required></app-number-input>
                <mat-error [errorMessage]="form.get('totalSalesPrice')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('totalSalesPrice')">{{machineFieldLabels.totalSalesPrice.label}}</app-form-field-readonly>
        </div>
    </div>
    <!-- end of for aggregation view -->

    <div *ngIf="form.controls.hasOwnProperty('emManufacturer') || form.controls.hasOwnProperty('emMachineType')" class="form-row">
        <div *ngIf="form.controls.hasOwnProperty('emManufacturer')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="emManufacturer" matInput [placeholder]="machineFieldLabels.emManufacturer.label">
                <mat-error [errorMessage]="form.get('emManufacturer')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emManufacturer')">{{machineFieldLabels.emManufacturer.label}}</app-form-field-readonly>
        </div>
        <div *ngIf="form.controls.hasOwnProperty('emMachineType')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="emMachineType" matInput [placeholder]="machineFieldLabels.emMachineType.label">
                <mat-error [errorMessage]="form.get('emMachineType')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emMachineType')">{{machineFieldLabels.emMachineType.label}}</app-form-field-readonly>
        </div>
    </div>
    <div *ngIf="form.controls.hasOwnProperty('emYearOfManufacturing') || form.controls.hasOwnProperty('emDeprecationPeriod')" class="form-row">
        <div *ngIf="form.controls.hasOwnProperty('emYearOfManufacturing')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [simple]="true" formControlName="emYearOfManufacturing" i18n-placeholder placeholder="Year of manufacture"></app-number-input>
                <mat-error [errorMessage]="form.get('emYearOfManufacturing')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emYearOfManufacturing')" format="NONE" i18n>Year of manufacture</app-form-field-readonly>
        </div>
        <div *ngIf="form.controls.hasOwnProperty('emDeprecationPeriod')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="emDeprecationPeriod" i18n-placeholder placeholder="Depreciation period"></app-number-input>
                <mat-error [errorMessage]="form.get('emDeprecationPeriod')"></mat-error>
                <span i18n matSuffix>years</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emDeprecationPeriod')" i18n i18n-suffix suffix="years">Depreciation period</app-form-field-readonly>
        </div>
    </div>

    <span *ngIf="!readonly && !isAggregated" class="field-label" i18n>Machine already ordered?</span>
    <span *ngIf="!readonly && isAggregated" class="field-label" i18n>Machines already ordered?</span>
    <div class="form-row">
        <div class="form-field-50">
            <ng-container *ngIf="!readonly">
                <mat-radio-group formControlName="emAlreadyOrdered">
                    <mat-radio-button class="radio-option" value="NO">{{orderStates.NO}}</mat-radio-button>
                    <mat-radio-button class="radio-option" value="YES">{{orderStates.YES}}</mat-radio-button>
                    <mat-radio-button *ngIf="isAggregated" class="radio-option" value="PARTIALLY_ORDERED">{{orderStates.PARTIALLY_ORDERED}}</mat-radio-button>
                </mat-radio-group>
            </ng-container>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emAlreadyOrdered')" [map]="orderStates">
                <ng-container *ngIf="!isAggregated" i18n>Machine already ordered?</ng-container>
                <ng-container *ngIf="isAggregated" i18n>Machines already ordered?</ng-container>
            </app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field (click)="emDeliveryDate.open()" *ngIf="!readonly">
                <input [matDatepicker]="emDeliveryDate" [readonly]="true" formControlName="emDeliveryDate" matInput [placeholder]="machineFieldLabels.emDeliveryDate.label" required>
                <mat-datepicker-toggle [for]="emDeliveryDate" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #emDeliveryDate></mat-datepicker>

                <mat-error [errorMessage]="form.get('emDeliveryDate')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emDeliveryDate')" format="DATE">{{machineFieldLabels.emDeliveryDate.label}}</app-form-field-readonly>
        </div>
    </div>

    <div *ngIf="!readonly" class="section-label">{{machineFieldLabels.emCondition.label}}</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="emCondition">
                <ng-container *ngIf="!isAggregated">
                    <mat-radio-button class="radio-option" value="NEW">{{conditions.NEW}}</mat-radio-button>
                    <mat-radio-button class="radio-option" value="USED">{{conditions.USED}}</mat-radio-button>
                </ng-container>
                <ng-container *ngIf="isAggregated">
                    <mat-radio-button class="radio-option" value="NEW">{{conditions.ALL_NEW}}</mat-radio-button>
                    <mat-radio-button class="radio-option" value="USED">{{conditions.ALL_USED}}</mat-radio-button>
                    <mat-radio-button class="radio-option" value="PARTIALLY_USED">{{conditions.PARTIALLY_USED}}</mat-radio-button>
                </ng-container>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emCondition')" [map]="conditions">{{machineFieldLabels.emCondition.label}}</app-form-field-readonly>
        </div>
    </div>
    <div *ngIf="form.controls.hasOwnProperty('emUsedAge') || form.controls.hasOwnProperty('emUsedOriginalSalesPrice')" class="form-row">
        <div *ngIf="form.controls.hasOwnProperty('emUsedAge')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="emUsedAge" i18n-placeholder placeholder="Age" required></app-number-input>
                <mat-error [errorMessage]="form.get('emUsedAge')"></mat-error>
                <span i18n matSuffix>years</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('emUsedAge')" i18n i18n-suffix suffix="years">Age</app-form-field-readonly>
        </div>
        <div *ngIf="form.controls.hasOwnProperty('emUsedOriginalSalesPrice')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="emUsedOriginalSalesPrice" [placeholder]="machineFieldLabels.emUsedOriginalSalesPrice.label"></app-number-input>
                <mat-error [errorMessage]="form.get('emUsedOriginalSalesPrice')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('emUsedOriginalSalesPrice')">{{machineFieldLabels.emUsedOriginalSalesPrice.label}}</app-form-field-readonly>
        </div>
    </div>

    <div *ngIf="!isAggregated && (form.controls.hasOwnProperty('emPricePerUnit') || form.controls.hasOwnProperty('totalSalesPrice'))" class="form-row">
        <div *ngIf="form.controls.hasOwnProperty('emPricePerUnit')" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="emPricePerUnit" [placeholder]="machineFieldLabels.emPricePerUnit.label" required></app-number-input>
                <mat-error [errorMessage]="form.get('emPricePerUnit')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('emPricePerUnit')">{{machineFieldLabels.emPricePerUnit.label}}</app-form-field-readonly>
        </div>
        <div *ngIf="hasMultiple !== false" class="form-field-50">
            <!-- AUTO-FILLED -->
            <app-form-field-readonly [currency]="currency" [field]="form.get('totalSalesPrice')" i18n>Total volume (net)</app-form-field-readonly>
        </div>
    </div>
</form>
