<form class="finmatch-form" [formGroup]="form" autocomplete="off" [class.readonly]="readonly">
    <div class="form-required-information" *ngIf="!readonly" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form"></app-financing-plans-field>

    <div class="section-label" i18n>Building</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Building type" formControlName="buildingType" required i18n-placeholder>
                    <ng-container *ngFor="let type of buildingTypes">
                        <mat-option *ngIf="!type.subTypes" [value]="type.value" [innerText]="buildingTypeLabels[type.value]"></mat-option>
                        <mat-optgroup *ngIf="type.subTypes" [label]="buildingTypeLabels[type.value]">
                            <mat-option *ngFor="let subType of type.subTypes" [value]="subType.value" [innerText]="buildingTypeLabels[subType.value]"></mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error [errorMessage]="form.get('buildingType')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingType')" [map]="buildingTypeLabels" i18n>Building type</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row" *ngIf="form.controls.classification.enabled">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Classification" formControlName="classification" required i18n-placeholder>
                    <ng-container *ngFor="let classification of buildingClassificationOptions">
                        <mat-option [value]="classification.value" [innerText]="classification.viewValue"></mat-option>
                    </ng-container>
                </mat-select>
                <mat-error [errorMessage]="form.get('classification')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('classification')" [map]="buildingClassificationOptions" i18n>Classification</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-form-field *ngIf="!readonly">
                <input type="text" matInput placeholder="Description" formControlName="buildingDescription" i18n-placeholder>
                <mat-error [errorMessage]="form.get('buildingDescription')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingDescription')" [unwrapped]="true" i18n>Description</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Building - Usage Category</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingRetailArea" i18n-placeholder placeholder="Retail / gastronomy"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingRetailArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingRetailArea')" i18n suffix="m²">Retail / gastronomy</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingOfficesArea" i18n-placeholder placeholder="Offices / doctor's practice"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingOfficesArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingOfficesArea')" i18n suffix="m²">Offices / doctor's practice</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingStoreArea" i18n-placeholder placeholder="Store"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingStoreArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingStoreArea')" i18n suffix="m²">Store</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingProductionArea" i18n-placeholder placeholder="Production"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingProductionArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingProductionArea')" i18n suffix="m²">Production</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingApartmentsArea" i18n-placeholder placeholder="Apartments"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingApartmentsArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingApartmentsArea')" i18n suffix="m²">Apartments</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="housingUnitsCount" i18n-placeholder placeholder="Number of housing units" required></app-number-input>
                <mat-error [errorMessage]="form.get('housingUnitsCount')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('housingUnitsCount')" i18n>Number of housing units</app-form-field-readonly>
        </div>
    </div>

    <div class="field-label" *ngIf="!readonly" i18n>Usage category</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="buildingUsageCategory">
                <mat-radio-button value="OWN_USAGE" class="radio-option">{{usageCategories['OWN_USAGE']}}</mat-radio-button>
                <mat-radio-button value="OTHER_USAGE" class="radio-option">{{usageCategories['OTHER_USAGE']}}</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingUsageCategory')" [map]="usageCategories" i18n>Usage category</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Property</div>
    <div class="field-label" *ngIf="!readonly" i18n>Do you own the property?</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-radio-group *ngIf="!readonly" formControlName="propertyOwner">
                <mat-radio-button [value]="true" class="radio-option" i18n>Yes</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-option" i18n>No</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('propertyOwner')" i18n>Do you own the property?</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="ownerPropertyValue" i18n-placeholder placeholder="Value of the property" required></app-number-input>
                <mat-error [errorMessage]="form.get('ownerPropertyValue')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('ownerPropertyValue')" i18n>Value of the property</app-form-field-readonly>
        </div>
    </div>

    <div class="field-label" *ngIf="!readonly" i18n="New building|Context of new building submodule">Is there a mortgage on the property?</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-radio-group *ngIf="!readonly" formControlName="mortgageOnProperty">
                <mat-radio-button [value]="true" class="radio-option" i18n>Yes</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-option" i18n>No</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('mortgageOnProperty')" i18n>Is there a mortgage on the property?</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="mortgageRemainingDebt" i18n-placeholder placeholder="Remaining debt" required></app-number-input>
                <mat-error [errorMessage]="form.get('mortgageRemainingDebt')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('mortgageRemainingDebt')" i18n>Remaining debt</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Construction Costs</div>
    <div class="field-label" *ngIf="!readonly" i18n>Do you know the allocation of the construction costs on the items property, building and additional costs?</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="knownConstructionCosts">
                <mat-radio-button [value]="true" class="radio-option" i18n>Yes</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-option" i18n>No</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('knownConstructionCosts')" i18n>Do you know the allocation of the construction costs on the items property, building and additional costs?</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="propertyCosts" i18n-placeholder placeholder="Property costs" required></app-number-input>
                <mat-error [errorMessage]="form.get('propertyCosts')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('propertyCosts')" i18n>Property costs</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="buildingCosts" i18n-placeholder placeholder="Building costs" required></app-number-input>
                <mat-error [errorMessage]="form.get('buildingCosts')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('buildingCosts')" i18n>Building costs</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="buyingCosts" i18n-placeholder placeholder="Buying costs" required></app-number-input>
                <mat-error [errorMessage]="form.get('buyingCosts')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('buyingCosts')" i18n>Buying costs</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly && form.get('knownConstructionCosts').value !== true">
                <app-number-input formControlName="totalSalesPrice" i18n-placeholder placeholder="Total construction costs" required></app-number-input>
                <mat-error [errorMessage]="form.get('totalSalesPrice')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly || form.get('knownConstructionCosts').value === true" [currency]="currency" [field]="form.get('totalSalesPrice')" [ngClass]="{'align-right': !readonly}">
                <span i18n>Total construction costs</span>
            </app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Building Schedule</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" (click)="plannedStartOfConstruction.open()">
                <input matInput [matDatepicker]="plannedStartOfConstruction" placeholder="Planned start of construction" formControlName="plannedStartOfConstruction" required [readonly]="true" i18n-placeholder>
                <mat-datepicker-toggle matSuffix [for]="plannedStartOfConstruction"></mat-datepicker-toggle>
                <mat-datepicker #plannedStartOfConstruction></mat-datepicker>
                <mat-error [errorMessage]="form.get('plannedStartOfConstruction')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('plannedStartOfConstruction')" format="DATE" i18n>Planned start of construction</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly" (click)="plannedDateOfCompletion.open()">
                <input matInput [matDatepicker]="plannedDateOfCompletion" placeholder="Planned date of completion" formControlName="plannedDateOfCompletion" required [readonly]="true" i18n-placeholder>
                <mat-datepicker-toggle matSuffix [for]="plannedDateOfCompletion"></mat-datepicker-toggle>
                <mat-datepicker #plannedDateOfCompletion></mat-datepicker>
                <mat-error [errorMessage]="form.get('plannedDateOfCompletion')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('plannedDateOfCompletion')" format="DATE" i18n>Planned date of completion</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Areas</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="propertyArea" i18n-placeholder placeholder="Property area" required></app-number-input>
                <mat-error [errorMessage]="form.get('propertyArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('propertyArea')" i18n suffix="m²">Property area</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="buildingArea" i18n-placeholder placeholder="Building area" required></app-number-input>
                <mat-error [errorMessage]="form.get('buildingArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingArea')" i18n suffix="m²">Building area</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Location</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Country" formControlName="country" required i18n-placeholder>
                    <mat-option *ngFor="let country of countries" [value]="country.value" [innerText]="country.viewValue"></mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('country')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('country')" [map]="countries" i18n>Country</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input type="text" matInput placeholder="Street" formControlName="streetAddress" i18n-placeholder>
                <mat-error [errorMessage]="form.get('streetAddress')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('streetAddress')" i18n>Street</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input type="text" matInput placeholder="House number" formControlName="houseNumber" i18n-placeholder>
                <mat-error [errorMessage]="form.get('houseNumber')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('houseNumber')" i18n>House number</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input matInput placeholder="Postcode" formControlName="postalCode" i18n-placeholder>
                <mat-error [errorMessage]="form.get('postalCode')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('postalCode')" i18n>Postcode</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input type="text" matInput placeholder="City" formControlName="city" required i18n-placeholder>
                <mat-error [errorMessage]="form.get('city')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('city')" i18n>City</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Leasehold</div>
    <div class="field-label" *ngIf="!readonly" i18n>Is there a leasehold on the property?</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="leaseHoldOnProperty">
                <mat-radio-button [value]="true" class="radio-option" i18n>Yes</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-option" i18n>No</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('leaseHoldOnProperty')" i18n>Is there a leasehold on the property?</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="groundRent" i18n-placeholder placeholder="Ground rent"></app-number-input>
                <mat-error [errorMessage]="form.get('groundRent')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('groundRent')" i18n>Ground rent</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="remainingTermLeaseHold" i18n-placeholder placeholder="Remaining term leasehold"></app-number-input>
                <mat-error [errorMessage]="form.get('remainingTermLeaseHold')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('remainingTermLeaseHold')" i18n>Remaining term leasehold</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Energy Efficiency</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select placeholder="Efficiency standard" formControlName="efficiencyStandard" required i18n-placeholder>
                    <mat-option *ngFor="let efficiencyStandard of efficiencyStandards" [value]="efficiencyStandard.value" [innerText]="efficiencyStandard.viewValue"></mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('efficiencyStandard')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('efficiencyStandard')" [map]="efficiencyStandards" i18n>Efficiency standard</app-form-field-readonly>
        </div>
    </div>
</form>
