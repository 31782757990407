import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UnsavedChangesGuard} from '../../guards/unsaved-changes.guard';
import {UserRouteAccessGuard} from '../../guards/user-route-access.guard';
import {InquiryDataResolver} from './resolvers/inquiry-data.resolver';
import {InquiryDataGuard} from '../../guards/inquiry-data.guard';

const INQUIRY_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list'
    },
    {
        path: '',
        canActivate: [UserRouteAccessGuard],
        children: [
            {
                path: 'new',
                loadChildren: () => import('./modules/inquiry-new/inquiry-new.module').then(m => m.InquiryNewModule),
                canDeactivate: [UnsavedChangesGuard],
                data: {
                    authorities: ['ROLE_COMPANY', 'ROLE_FINMATCH', 'ROLE_BROKER']
                }
            },
            {
                path: 'list',
                loadChildren: () => import('./modules/inquiry-list-view/inquiry-list-view.module').then(m => m.InquiryListViewModule),
                canActivate: [UserRouteAccessGuard],
                data: {
                    authorities: ['ROLE_FINMATCH', 'ROLE_COMPANY', 'ROLE_FINANCIAL', 'ROLE_FINANCIAL_UNIT', 'ROLE_BROKER']
                }
            },
            {
                path: 'edit/:inquiryId',
                resolve: {
                    data: InquiryDataResolver
                },
                children: [
                    {
                        path: '',
                        loadChildren: () => import('./modules/inquiry-overview/inquiry-overview.module').then(m => m.InquiryOverviewModule)
                    },
                    {
                        path: 'machine',
                        loadChildren: () => import('./modules/inquiry-module/inquiry-module.module').then(m => m.InquiryModuleModule),
                        data: {module: 'machine'}
                    },
                    {
                        path: 'vehicle',
                        loadChildren: () => import('./modules/inquiry-module/inquiry-module.module').then(m => m.InquiryModuleModule),
                        data: {module: 'vehicle'}
                    },
                    {
                        path: 'realEstate',
                        loadChildren: () => import('./modules/inquiry-module/inquiry-module.module').then(m => m.InquiryModuleModule),
                        data: {module: 'realEstate'}
                    },
                    {
                        path: 'property',
                        loadChildren: () => import('./modules/inquiry-module/inquiry-module.module').then(m => m.InquiryModuleModule),
                        data: {module: 'property'}
                    },
                    {
                        path: 'workingCapital',
                        loadChildren: () => import('./modules/inquiry-module/inquiry-module.module').then(m => m.InquiryModuleModule),
                        data: {module: 'workingCapital'}
                    },
                    {
                        path: 'other',
                        loadChildren: () => import('./modules/inquiry-module/inquiry-module.module').then(m => m.InquiryModuleModule),
                        data: {module: 'other'}
                    },
                    {
                        path: 'fastLane',
                        loadChildren: () => import('./modules/inquiry-module/inquiry-module.module').then(m => m.InquiryModuleModule),
                        data: {module: 'fastLane'}
                    },
                    {
                        path: 'financingParameters',
                        loadChildren: () => import('./modules/inquiry-financing-parameters/inquiry-financing-parameters.module').then(m => m.InquiryFinancingParametersModule),
                        canDeactivate: [UnsavedChangesGuard]
                    },
                    {
                        path: 'financialStatements',
                        loadChildren: () => import('./modules/inquiry-financial-statements/inquiry-financial-statements.module').then(m => m.InquiryFinancialStatementsModule)
                    },
                    {
                        path: 'equity',
                        loadChildren: () => import('./modules/inquiry-equity/inquiry-equity.module').then(m => m.InquiryEquityModule),
                        canDeactivate: [UnsavedChangesGuard]
                    },
                ],
                canActivateChild: [InquiryDataGuard],
                data: {
                    authorities: ['ROLE_COMPANY', 'ROLE_FINMATCH', 'ROLE_BROKER']
                }
            }
        ]
    }
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forChild(INQUIRY_ROUTES)
    ],
    exports: [
        RouterModule
    ]
})
export class InquiryRoutingModule {
}
