<div *ngIf="loggedUser !== undefined" class="error-page-container">
    <div class="error-page-denied-wrapper">
        <div class="error-message">
            <h1 *ngIf="!(errorType === '403') && !isFreshlyRegistered() " class="error-code">{{!!errorType ? errorType : '???'}}</h1>
            <h1 *ngIf="isAuthenticated() && isFreshlyRegistered()"  i18n>Account activation needed</h1>

            <h2 *ngIf="errorType === '403' && isAuthenticated() && !isFreshlyRegistered()" class="error-text" i18n>You do not have access to this page.</h2>
            <h2 *ngIf="errorType === '404'" class="error-text" i18n>Oops! This page could not be found.</h2>
            <h2 *ngIf="errorType !== '403' && errorType !== '404' && !isFreshlyRegistered()" class="error-text" i18n>Oops! An error occured.</h2>
            <h3 *ngIf="isAuthenticated() && isFreshlyRegistered()" i18n>Your account has not yet been activated. Please use the link in the activation email and, if in doubt, check your spam/junk folder. If you have not received an email, contact your FinMatch partner or <a href="mailto:support@finmatch.de">support&#64;finmatch.de</a></h3>

            <p *ngIf="errorType === '403' && !isAuthenticated()" class="error-text" i18n>
                Please log in before proceeding to the application.
            </p>
            <p *ngIf="errorType === '404'" class="error-description" i18n>
                Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarily unavailable.
            </p>

            <div class="error-actions">
                <div *ngIf="errorType === '403' && !isAuthenticated()">
                    <a [routerLink]="'/login'" class="link-button link-button-bg" i18n>Login</a>
                </div>
                <div *ngIf="!isAuthenticated()">
                    <a [href]="config.landing_URL" class="link-button" i18n>Landing Page</a>
                </div>
                <div *ngIf="isAuthenticated() && !isFreshlyRegistered()">
                    <a (click)="logout()" class="link-button" i18n>Start Page</a>
                </div>
                <div *ngIf="isAuthenticated() && isFreshlyRegistered()">
                     <a [routerLink]="'/start'" style="margin-left:5%;" class="link-button" i18n>Start Page</a>
                     <a *ngIf="!mailResent" style="margin-left:5%;" (click)="resendActivationLink()" class="link-button" i18n>Resend activation link</a>
                     <br>
                     <p *ngIf="mailResent" class="error-description" style="color: #00d3b4;" i18n>
                        Your activation link has been successfully sent.
                    </p>
                </div>
                
            </div>
        </div>
        <div [hidden]="!errorMessage">
            <div class="alert alert-danger">{{errorMessage}}</div>
        </div>
    </div>
</div>
