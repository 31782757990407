import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GoogleAnalyticsComponent} from "./google-analytics.component";

const dependencies = [];

@NgModule({
    declarations: [
        GoogleAnalyticsComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        GoogleAnalyticsComponent
    ]
})
export class GoogleAnalyticsModule {
}
