import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {startWith} from "rxjs/operators";

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const PropertyPurchaseFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    buyingCosts: [null, [customValidators.price()]],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    country: ['GERMANY', [Validators.required]],
    houseNumber: [null, [Validators.maxLength(5)]],
    postalCode: [null, [Validators.max(99999)]],
    propertyArea: [null, [customValidators.area9]],
    salesPrice: [null, [Validators.required, customValidators.price()]],
    streetAddress: [null, [Validators.maxLength(100)]],
    totalCosts: [null, []],
};

@Component({
    selector: 'app-property-purchase-form',
    templateUrl: './property-purchase-form.component.html',
    styleUrls: ['./property-purchase-form.component.less']
})
export class PropertyPurchaseFormComponent implements OnInit, OnDestroy {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean = false;
    @Input() currency: string;
    public countries: Array<{ value: string, viewValue: string }> = [];
    private subscriptions: Subscription[] = [];

    public fieldLabels = this.translationService.moduleFields.property;

    constructor(
        public translationService: TranslationService,
    ) {
        this.countries = this.translationService.inquiryRealEstateCountries;
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateCountryRelatedFields();
        }
    }

    ngOnInit() {
        this.subscriptions.push(
            this.form.get('country').valueChanges.subscribe(() => this.updateCountryRelatedFields())
        );
        this.subscriptions.push(
            combineLatest(
                this.form.controls.salesPrice.valueChanges.pipe(startWith(this.form.controls.salesPrice.value)),
                this.form.controls.buyingCosts.valueChanges.pipe(startWith(this.form.controls.buyingCosts.value)),
            ).subscribe(([salesPrice, buyingCosts]) => {
                this.form.controls.totalCosts.setValue((salesPrice || 0) + (buyingCosts || 0));
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public updateCountryRelatedFields(): void {
        let country = this.form.get('country').value;

        let postCodeValidator = (country === 'GERMANY') ? customValidators.postalCodeOptionalValidator5 : customValidators.postalCodeOptionalValidator4;
        this.form.get('postalCode').setValidators([postCodeValidator]);
        this.form.get('postalCode').updateValueAndValidity();
    }
}
