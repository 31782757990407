<mat-toolbar class="top-nav-container" color="primary" [class.landing]="isLanding">
    <div class="top-nav-container-wrapper">
        <div class="top-nav-links-content" [ngSwitch]="isAuthenticated() && hasTermsAccepted()">
            <a [href]="config.landing_URL + '/schnellanfrage'" *ngSwitchCase="false" mat-flat-button color="primary" class="sign-button">Schnellanfrage</a>
            <span *ngIf="currentRoute !== '/login'"><a routerLink="login" *ngSwitchCase="false" mat-flat-button color="primary" class="sign-button">Log In</a></span>
            <div *ngSwitchCase="true" class="top-nav-user-details" data-step="1" [attr.data-intro]="tutorialTexts.userDetailsInfo">
                <button class="top-nav-user-menu" mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
                    <i class="material-icons">keyboard_arrow_down</i>
                </button>
                <div class="top-nav-user-image">
                    <img [src]="profilePhotoUrl" [style.filter]="!account?.imageUrl ? 'brightness(1.2)': ''" [style.opacity]="!account?.imageUrl ? '0.4': ''" alt="Profile photo">
                </div>
                <mat-menu #userMenu="matMenu">
                    <button *ngIf="!account?.isBankingUser()" mat-menu-item routerLink="/settings/profiles/my-profile" i18n>My Profile</button>
                    <button *ngIf="account?.isBankingUser()" mat-menu-item routerLink="/settings/bank-and-users/my-profile" i18n>My Profile</button>
                    <button *ngIf="account && (account.isCompany() || account.isBroker())" mat-menu-item routerLink="/settings/profiles/company-profile" i18n>Company Profile</button>
                    <!--                    <button *ngIf="account && account.isBankUnit()" mat-menu-item routerLink="/settings/profiles/org-unit-profile" i18n>Org Unit Profile</button>-->
                    <button *ngIf="account && (account.isBankUnit() || account.isBank())" mat-menu-item routerLink="/settings/bank-and-users/bank-profile" i18n>Bank Profile</button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item routerLink="/change-password" i18n>Change Password</button>
                    <button mat-menu-item (click)="logout()" i18n>Logout</button>
                </mat-menu>

                <div class="top-nav-user-details-container" *ngIf="account">
                    <div class="top-nav-company-name">{{account.companyName || 'NO_NAME'}}</div>
                    <div class="top-nav-customer-number" *ngIf="account.customerId">
                        <span i18n="Customer ID for a company (DEV-473)@@customer-id">Customer ID</span>:&nbsp;{{account.customerId}}
                    </div>
                    <div class="top-nav-login">{{account.firstName}} {{account.lastName}}</div>
                    <div class="top-nav-timeout">{{sessionTimeout}}</div>
                </div>
            </div>
            <a href="/" class="logo-link"></a>
            <nav mat-tab-nav-bar class="top-nav-menu-container" *ngIf="isAuthenticated() && hasTermsAccepted() && !isLanding">
                <ng-container *ngFor="let routeLink of routeLinks; let i = index;">
                    <ng-container *ngIf="routeLink.mouseOverMenuLinks && isVisibleForRoles(routeLink.mouseOverMenuLinks.forRoles, false); else routeLinkTpl">
                        <div
                            [matMenuTriggerFor]="menu"
                            #trigger="matMenuTrigger"
                            (mouseenter)="openMenu(trigger)"
                        >
                            <ng-container *ngTemplateOutlet="routeLinkTpl"></ng-container>
                            <mat-menu #menu="matMenu" [hasBackdrop]="false" [overlapTrigger]="false">
                                <div (mouseleave)="trigger.closeMenu()">
                                    <div *ngFor="let sublink of routeLink.mouseOverMenuLinks.content">
                                        <a mat-menu-item
                                           [routerLink]="sublink.link"
                                           [queryParams]="sublink.queryParams || {}"
                                           routerLinkActive="sublink-active"
                                        >{{sublink.label}}</a>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </ng-container>
                    <ng-template #routeLinkTpl>
                        <a mat-tab-link
                           [id]="generateIdFromRouteLink(routeLink)"
                           *ngIf="isVisibleForRoles(routeLink.forRoles, routeLink.matchAnyRole)"
                           [attr.data-step]="routeLink.tutorialStep || null"
                           [attr.data-intro]="routeLink.tutorialText || null"
                           [active]="rla.isActive"
                           [routerLink]="routeLink.link"
                           routerLinkActive="mat-tab-link-active"
                           #rla="routerLinkActive"
                           [routerLinkActiveOptions]="{exact: false}"
                           class="top-nav-menu-button"
                           [disabled]="routeLink.disabled"
                           [class.disabled]="routeLink.disabled">
                            {{routeLink.label}}
                        </a>
                    </ng-template>
                </ng-container>
            </nav>

            <button mat-icon-button [matMenuTriggerFor]="menu" class="mobile-menu-trigger" #mainNavTrigger="matMenuTrigger">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let routeLink of routeLinksUnlogged; let i = index;">
                    <a mat-menu-item [routerLink]="routeLink.link" routerLinkActive="mobile-nav-active" #rla="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
                        <span>{{routeLink.label}}</span>
                    </a>
                </ng-container>
            </mat-menu>

            <nav mat-tab-nav-bar class="top-nav-menu-container" [ngClass]="{'unlogged': !isAuthenticated() && !hasTermsAccepted()}" *ngIf="isLanding">
                <ng-container *ngFor="let routeLink of routeLinksUnlogged; let i = index;">
                    <a mat-tab-link [active]="rla.isActive" href="{{routeLink.link}}" routerLinkActive="mat-tab-link-active" #rla="routerLinkActive" [routerLinkActiveOptions]="{exact: true}" class="top-nav-menu-button">
                        {{routeLink.label}}
                    </a>
                </ng-container>
                <a mat-tab-link href="{{config.landing_URL}}" class="top-nav-menu-button switch-site-button" *ngSwitchCase="true" i18n>Portal</a>
            </nav>
            <ng-container *ngIf="account && !isEditingInquiry && (account.isCompany() || account.isFinMatch() || account.isBroker()) && !isLanding">
                <a routerLink="/inquiry/new" mat-flat-button color="primary" class="top-nav-menu-button-inquiry" *ngSwitchCase="true" i18n>New Inquiry</a>
            </ng-container>
        </div>
    </div>
</mat-toolbar>
