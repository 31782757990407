import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FinmatchDashboardComponent} from './finmatch-dashboard.component';
import {FinmatchDashboardRoutingModule} from './finmatch-dashboard-routing.module';
import {UserRepository} from "../../repositories/user.repository";
import {CorporateRepository} from "../../repositories/corporate.repository";
import {CommonSpinnerModule} from "../shared/components/common-spinner/common-spinner.module";
import {MatTabsModule} from "@angular/material/tabs";
import {NgScrollbarModule} from "ngx-scrollbar";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSortModule} from "@angular/material/sort";

const dependencies = [];

@NgModule({
    declarations: [FinmatchDashboardComponent],
    imports: [
        CommonModule,
        FinmatchDashboardRoutingModule,
        ...dependencies,
        CommonSpinnerModule,
        MatTabsModule,
        NgScrollbarModule,
        MatTableModule,
        MatIconModule,
        MatTooltipModule,
        MatSortModule
    ],
    providers: [
        UserRepository,
        CorporateRepository
    ]
})
export class FinmatchDashboardModule {
}
