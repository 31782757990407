// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    keycloak: {
        issuer: '->> default .Env.KEYCLOAK_HOST "http://localhost:8080/" <<-',
        realm: 'finmatch-dev',
        clientId: 'fim-angular'
    },
    envName: '->> default .Env.ENVIRONMENT "LOCAL" <<-',
    general_domain: '->> default .Env.GENERAL_DOMAIN "localhost" <<-',
    API_URL: '->> default .Env.API_URL "/" <<-',
    landing_URL: '->> default .Env.LANDING_URL "http://localhost:4200/" <<-',
    version: require('../../package.json').version,
    // gaId: '->> default .Env.GA_ID "UA-129723072-4" <<-',
    recaptchaSiteKey: '->> default .Env.RECAPTCHA_SITE_KEY "6Lc2BbofAAAAAE7XdzcHZiM2Ae4MntZUpF8K5R7d" <<-',
    cookieHubId: '->> default .Env.COOKIEHUB_ID "5ac5053a" <<-',
    sentryDsn: '->> default .Env.SENTRY_DSN "" <<-',
    helicUrl: '->> default .Env.HELIC_URL "" <<-',
    googleTagManager: {
        id: '->> default .Env.GTM_ID "" <<-',
        // gtm_auth: '->> default .Env.GTM_AUTH "" <<-',
        // gtm_preview: '->> default .Env.GTM_PREVIEW "" <<-',
    },

    features: {
        crefo: '->> default .Env.FEATURE_CREFO "false" <<-',
        helicOfficialMapping: '->> default .Env.HELIC_OFFICIAL_MAPPING "true" <<-',
    }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
