import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

export class ValidationHelpers {
    public static markFormAsDirtyAndTouched(form: UntypedFormGroup | UntypedFormArray) {
        form.markAsDirty({ onlySelf: true });
        form.markAsTouched({ onlySelf: true });

        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);

            if (control instanceof UntypedFormControl) {
                control.markAsDirty({ onlySelf: true });
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.markFormAsDirtyAndTouched(control);
            } else if (control instanceof UntypedFormArray) {
                this.markFormAsDirtyAndTouched(control);
            }
        });
    }
}
