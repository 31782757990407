import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnsupportedDeviceModalComponent} from "./unsupported-device-modal.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

const dependencies = [
    MatDialogModule,
    MatButtonModule
]

@NgModule({
    declarations: [
        UnsupportedDeviceModalComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        UnsupportedDeviceModalComponent
    ]
})
export class UnsupportedDeviceModalModule {
}
