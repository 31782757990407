import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BottomNavComponent} from "./bottom-nav.component";
import {MatToolbarModule} from "@angular/material/toolbar";

const dependencies = [
    MatToolbarModule
];

@NgModule({
    declarations: [
        BottomNavComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        BottomNavComponent
    ]
})
export class BottomNavModule {
}
