import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from 'src/app/app-config.module';
import {User} from 'src/app/models/UserModel';
import {Company} from 'src/app/models/CompanyModel';
import {Bank} from 'src/app/models/bank.model';
import {OrganizationUnit} from 'src/app/models/OrganizationUnitModel';
import {Broker} from "../models/BrokerModel";
import {DocumentFileService} from './document-file.service';

@Injectable({providedIn: 'root'})
export class ProfilesService {
    constructor(
        @Inject(APP_CONFIG) private config: AppConfig,
        private http: HttpClient,
        private documentFileService: DocumentFileService,
    ) {
    }

    saveUserProfile(user: User): Observable<any> {
        return this.http.post(this.config.API_URL + this.config.API_URLs.account, user);
    }

    getCompanyProfile(corporateId): Observable<Company> {
        return this.http.get<Company>(this.config.API_URL + this.config.API_URLs.corporates + `/${corporateId}`);
    }

    getBrokerProfile(id: string): Observable<Broker> {
        return this.http.get<Broker>(this.config.API_URL + this.config.API_URLs.brokers + `/${id}`);
    }

    saveCompanyProfile(company: Company): Observable<any> {
        return this.http.put(this.config.API_URL + this.config.API_URLs.corporates, company);
    }

    saveBrokerProfile(broker: Broker): Observable<any> {
        return this.http.put(this.config.API_URL + this.config.API_URLs.brokers + `/${broker.brokerId}`, broker);
    }

    saveBankProfile(bank: Bank): Observable<any> {
        return this.http.put(this.config.API_URL + this.config.API_URLs.banks, bank);
    }

    getOrgUnitProfile(bankId, bankUnitId): Observable<Bank> {
        return this.http.get<Bank>(this.config.API_URL + this.config.API_URLs.orgUnits, {
            params: { bankId, bankUnitId }
        });
    }

    saveOrgUnitProfile(orgUnit: OrganizationUnit): Observable<any> {
        return this.http.put(this.config.API_URL + this.config.API_URLs.orgUnits, orgUnit);
    }

    saveProfilePhoto(croppedImage: string, userId: number, targetImageFormat: string): Observable<any> {
        return this.http.post(this.config.API_URL + 'docupload/api/profile-photos', {
            content: croppedImage.slice(croppedImage.indexOf(',') + 1),
            contentType: "image/" + targetImageFormat,
            documentHash: this.documentFileService.getRandomHash(),
            parentObjectId: userId,
            documentClass: "User__Profile_Photo",
            documentType: "PROFILE_PHOTO",
            companyId: userId
        });
    }

    getProfilePhotoUrl(user): string {
        return user?.imageUrl
            ? this.config.API_URL + this.config.API_URLs.docupload + user.imageUrl
            : '';
    }
}
