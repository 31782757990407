import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../app-config.module';

@Injectable()
export class ActivateRepository {
    constructor(private http: HttpClient,
                @Inject(APP_CONFIG) private config: AppConfig) {
    }

    get(key: string): Observable<any> {
        return this.http.get(this.config.API_URL + 'api/activate', {
            params: new HttpParams().set('key', key)
        });
    }
}
