<form [class.readonly]="readonly" [formGroup]="form" autocomplete="off" class="finmatch-form">
    <div *ngIf="!readonly" class="form-required-information" i18n>All fields with * are required</div>
    <app-financing-plans-field [readonly]="readonly" [form]="form"></app-financing-plans-field>

    <div class="section-label" i18n="@@Finanzierung">Loan</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="loanRedemptionAmount" i18n-placeholder placeholder="Redemption amount" required></app-number-input>
                <mat-error [errorMessage]="form.get('loanRedemptionAmount')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('loanRedemptionAmount')" i18n>Redemption amount</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field (click)="loanRedemptionDate.open()" *ngIf="!readonly">
                <input [matDatepicker]="loanRedemptionDate" [readonly]="true" formControlName="loanRedemptionDate" i18n-placeholder matInput placeholder="Redemtion amount date" required>
                <mat-datepicker-toggle [for]="loanRedemptionDate" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #loanRedemptionDate></mat-datepicker>
                <mat-error [errorMessage]="form.get('loanRedemptionDate')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('loanRedemptionDate')" format="DATE" i18n>Redemtion amount date</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Building</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select formControlName="buildingType" i18n-placeholder placeholder="Building type" required>
                    <ng-container *ngFor="let type of buildingTypes">
                        <mat-option *ngIf="!type.subTypes" [innerText]="buildingTypeLabels[type.value]" [value]="type.value"></mat-option>
                        <mat-optgroup *ngIf="type.subTypes" [label]="buildingTypeLabels[type.value]">
                            <mat-option *ngFor="let subType of type.subTypes" [innerText]="buildingTypeLabels[subType.value]" [value]="subType.value"></mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-error [errorMessage]="form.get('buildingType')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingType')" [map]="buildingTypeLabels" i18n>Building type</app-form-field-readonly>
        </div>
        <div *ngIf="form.controls.classification.enabled" class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select formControlName="classification" i18n-placeholder placeholder="Classification" required>
                    <ng-container *ngFor="let classification of buildingClassificationOptions">
                        <mat-option [innerText]="classification.viewValue" [value]="classification.value"></mat-option>
                    </ng-container>
                </mat-select>
                <mat-error [errorMessage]="form.get('classification')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('classification')" [map]="buildingClassificationOptions" i18n>Classification</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [simple]="true" formControlName="buildingConstructionYear" i18n-placeholder placeholder="Construction year"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingConstructionYear')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingConstructionYear')" format="NONE" i18n>Construction year</app-form-field-readonly>
        </div>
        <div class="form-field-100">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="buildingDescription" i18n-placeholder matInput placeholder="Description" type="text">
                <mat-error [errorMessage]="form.get('buildingDescription')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingDescription')" [unwrapped]="true" i18n>Description</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Building - Usage Category</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingRetailArea" i18n-placeholder placeholder="Retail / gastronomy" required></app-number-input>
                <mat-error [errorMessage]="form.get('buildingRetailArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingRetailArea')" i18n suffix="m²">Retail / gastronomy</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingOfficesArea" i18n-placeholder placeholder="Offices / doctor's practice" required></app-number-input>
                <mat-error [errorMessage]="form.get('buildingOfficesArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingOfficesArea')" i18n suffix="m²">Offices / doctor's practice</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingStoreArea" i18n-placeholder placeholder="Store" required></app-number-input>
                <mat-error [errorMessage]="form.get('buildingStoreArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingStoreArea')" i18n suffix="m²">Store</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingProductionArea" i18n-placeholder placeholder="Production" required></app-number-input>
                <mat-error [errorMessage]="form.get('buildingProductionArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingProductionArea')" i18n suffix="m²">Production</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input [required]="form.controls.buildingType.value !== 'COMMERCIAL_BUILDING'" formControlName="buildingApartmentsArea" i18n-placeholder placeholder="Apartments" required></app-number-input>
                <mat-error [errorMessage]="form.get('buildingApartmentsArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingApartmentsArea')" i18n suffix="m²">Apartments</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="housingUnitsCount" i18n-placeholder placeholder="Number of housing units" required></app-number-input>
                <mat-error [errorMessage]="form.get('housingUnitsCount')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('housingUnitsCount')" i18n>Number of housing units</app-form-field-readonly>
        </div>
    </div>

    <div *ngIf="!readonly" class="field-label" i18n>Usage category</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="buildingUsageCategory">
                <mat-radio-button class="radio-option" value="OWN_USAGE">{{usageCategories['OWN_USAGE']}}</mat-radio-button>
                <mat-radio-button class="radio-option" value="OTHER_USAGE">{{usageCategories['OTHER_USAGE']}}</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingUsageCategory')" [map]="usageCategories" i18n>Usage category</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Areas</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="propertyArea" i18n-placeholder placeholder="Property area"></app-number-input>
                <mat-error [errorMessage]="form.get('propertyArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('propertyArea')" i18n suffix="m²">Property area</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="buildingArea" i18n-placeholder placeholder="Building area"></app-number-input>
                <mat-error [errorMessage]="form.get('buildingArea')"></mat-error>
                <span matSuffix>m²</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('buildingArea')" i18n suffix="m²">Building area</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Location</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <mat-select formControlName="country" i18n-placeholder placeholder="Country" required>
                    <mat-option *ngFor="let country of countries" [innerText]="country.viewValue" [value]="country.value"></mat-option>
                </mat-select>
                <mat-error [errorMessage]="form.get('country')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('country')" [map]="countries" i18n>Country</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="streetAddress" i18n-placeholder matInput placeholder="Street" required type="text">
                <mat-error [errorMessage]="form.get('streetAddress')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('streetAddress')" i18n>Street</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="houseNumber" i18n-placeholder matInput placeholder="House number" type="text">
                <mat-error [errorMessage]="form.get('houseNumber')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('houseNumber')" i18n>House number</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="postalCode" i18n-placeholder matInput placeholder="Postcode">
                <mat-error [errorMessage]="form.get('postalCode')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('postalCode')" i18n>Postcode</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <input formControlName="city" i18n-placeholder matInput placeholder="City" required type="text">
                <mat-error [errorMessage]="form.get('city')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('city')" i18n>City</app-form-field-readonly>
        </div>
    </div>

    <div class="section-label" i18n>Leasehold</div>
    <div *ngIf="!readonly" class="field-label" i18n>Is there a leasehold on the property?</div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-radio-group *ngIf="!readonly" formControlName="leaseHoldOnProperty">
                <mat-radio-button [value]="true" class="radio-option" i18n>Yes</mat-radio-button>
                <mat-radio-button [value]="false" class="radio-option" i18n>No</mat-radio-button>
            </mat-radio-group>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('leaseHoldOnProperty')" i18n>Is there a leasehold on the property?</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="groundRent" i18n-placeholder placeholder="Ground rent"></app-number-input>
                <mat-error [errorMessage]="form.get('groundRent')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('groundRent')" i18n>Ground rent</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="remainingTermLeaseHold" i18n-placeholder placeholder="Remaining term leasehold"></app-number-input>
                <mat-error [errorMessage]="form.get('remainingTermLeaseHold')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('remainingTermLeaseHold')" i18n>Remaining term leasehold</app-form-field-readonly>
        </div>
    </div>
</form>
