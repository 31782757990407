import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {EventManagerService} from '../services/event-manager.service';
import {ToastrService} from 'ngx-toastr';
import {Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../app-config.module';
import {ErrorCodesTranslationService} from '../services/root/translation/error-codes-translation.service';
import {ActivationError} from "../modules/account/activate/activate.component";

export const ERROR_INTERCEPTOR_SKIP_HEADER = 'E_IC_SKIP';

export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private eventManager: EventManagerService,
        private toastService: ToastrService,
        private errorTranslationService: ErrorCodesTranslationService,
        @Inject(APP_CONFIG) private config: AppConfig
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {}, (err: any) => {
            // list of errors handled in other places - no need to show toast
            if (
                request.headers.has(ERROR_INTERCEPTOR_SKIP_HEADER)
                || !(err instanceof HttpErrorResponse)
                || (err.error instanceof Blob && err.status === 409)
                || (err.status === 401 && (err.message === '' || err.url && err.url.indexOf('/api/account') !== -1 || err.url.indexOf('/api/authenticate') !== -1))
                || err.error?.title?.startsWith('Bank should have at least one admin')
                || (err.error as any)?.message === ActivationError.alreadyUsedActivationKey
            ) {
                return;
            }

            if (this.eventManager !== undefined) {
                this.eventManager.broadcast({
                    name: this.config.eventTypes['gatewayError'],
                    content: err
                });
            }

            if (!this.toastService) {
                return;
            }

            let errorMessageCode = err.error.message;

            if (err.status === 503 && err?.url?.indexOf('/api/authenticate') !== -1) {
                errorMessageCode = 'error.throttlingLoginError';
            } else if (err.error.detail === 'Captcha failed') {
                errorMessageCode = 'error.captchaFailed';
            }

            const errorMessage = this.errorTranslationService.errorCodes[errorMessageCode] || err.error.title || 'Server Error';

            this.toastService.warning(errorMessage, 'Server Message');
        }));
    }
}
