import { HttpResponse } from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {Observable, of, Subject, zip} from 'rxjs';
import {catchError, map, share, tap} from 'rxjs/operators';
import {FinMatchLoggedOutMock} from 'src/app/models/finmatch-account.model';
import {APP_DI_CONFIG} from '../app-config.module';
import {Principal} from '../services/auth/principal.service';
import {UsageAgreementService} from '../services/bank/usage-agreement.service';
import {BankOffersService} from '../services/inquiry/bank-offers.service';
import {BankSelectionService} from '../services/inquiry/bank-selection.service';
import {
    FinancingType,
    InquiryConfigurationService,
    InquiryModuleCollectionProperty,
    InquiryModuleMode,
    InquiryModuleType,
    MachineModuleMode,
    PropertyModuleType,
    RealEstateModuleMode,
    RealEstateModuleType
} from '../services/inquiry/inquiry-configuration.service';
import {InquiryService} from '../services/inquiry/inquiry.service';
import {TranslationService} from '../services/root/translation/translation.service';
import {BrokerInquirySubmissionType} from './BrokerModel';
import {DocumentFile} from './document-file.model';
import {MissingDocument} from './document-missing.model';
import {FinMatchAccount} from './finmatch-account.model';

export type InquiryModuleKey = 'machine' | 'vehicle' | 'realEstate' | 'property' | 'workingCapital' | 'other' | 'fastLane';
export type InquiryState =
    | 'DRAFT'
    | 'WAITING_FOR_APPROVAL'
    | 'CANCELED' // translated to DEACTIVATED on the frontend
    | 'BANK_SELECTION'
    | 'REVISION'
    | 'CLOSED'
    | 'DELETED'

    // FINMATCH STATUSES
    | 'TO_ASSIGN' // user see FINMATCH_ASSESSMENT
    | 'ASSIGNED' // user see FINMATCH_ASSESSMENT
    | 'ACCEPTED' // user see FINMATCH_ASSESSMENT
    | 'BANK_RESPONSE_COMPLETED' // user see ACTIVE
    | 'MATCHING_FAILED' // user see ACTIVE
    | 'REVIEW_OFFER_SELECTION' // user see WAITING_FOR_FINMATCH
    | 'REVIEW_SIGNING' // user see SIGNING_OF_CONTRACTS

    // USER STATUSES (translated on frontend from backend status)
    | 'FINMATCH_ASSESSMENT' // finmatch see TO_ASSIGN or ASSIGNED or ACCEPTED
    | 'ACTIVE' // finmatch see ACTIVE or BANK_RESPONSE_COMPLETED OR MATCHING_FAILED
    | 'WAITING_FOR_FINMATCH' // finmatch see REVIEW_OFFER_SELECTION
    | 'SIGNING_OF_CONTRACTS' // finmatch see REVIEW_SIGNING

    // SECONDARY (used only for filtering, added on frontend)
    | 'NEW'
    | 'OFFER_MADE'
    | 'REJECTED_BY_BANK';

export interface MandateSettings {
    showOtua?: boolean;
    mandateAvailable?: {
        bank: boolean;
        company: boolean;
    };
    bank?: {
        machine: number,
        vehicle: number,
        realEstate: number,
        property: number,
        workingCapital: number,
        other: number,
        fastLane: number,
    };
    company?: {
        machine: number,
        vehicle: number,
        realEstate: number,
        property: number,
        workingCapital: number,
        other: number,
        fastLane: number,
    };
}

export interface UnifiedModuleItem {
    label?: string;
    quantity?: number;
    price: number;
}

export interface BuildingType {
    value: string;
    subTypes?: Array<BuildingType>;
}

export type BankOfferStatus =
    'NO_OFFER'
    | 'CREATED'
    | 'DRAFT'
    | 'REDRAFT'
    | 'SUBMITTED'
    | 'ACCEPTED'
    | 'REJECTED'
    | 'UNKNOWN';
export type ProductBankOfferStatus = 'NO_OFFER' | 'PENDING' | 'CAPTURED' | 'SENT' | 'UPDATED';
export const FinancingModules: Array<InquiryModuleKey> = [
    'machine',
    'vehicle',
    'realEstate',
    'property',
    'workingCapital',
    'other',
    'fastLane',
];

export interface MachineModuleMetadataDetails {
    hasMultiple: boolean;
    areDifferent?: boolean;  // Only set when multiple
    isAggregated?: boolean;  // Only set when hasMultiple && areDifferent
}

export type ModuleMetadataDetails = MachineModuleMetadataDetails; // Potentially other in the future
export interface ModuleMetadata {
    details: MachineModuleMetadataDetails;
}

export interface ModulesMetadata {
    machine: ModuleMetadata;
}

export enum EquityType {
    equity = 'EQUITY_CAPITAL',
    mezzanine = 'MEZZANINE_CAPITAL',
}

const modulesMapping: { [key: string]: string } = {
    machine: 'machineMode',
    vehicle: 'vehicleMode',
    property: 'propertyProjectType',
    realEstate: 'realEstateMode',
    workingCapital: 'workingCapitalMode',
    other: 'otherMode',
    fastLane: 'fastLaneMode',
};

export const ModulesPluralNames: { [key: string]: string } = {
    machine: 'machines',
    vehicle: 'vehicles',
    property: 'properties',
    realEstate: 'realEstates',
    workingCapital: 'workingCapitals',
    other: 'others',
    fastLane: 'fastLanes',
};

const InquiryAdditionalFields = ['alreadySeen', 'companyName', 'company', 'offerStatus', 'newBankOffersNo', 'bankOffersNo', 'rejectedByBank', 'agreementNeeded', 'otuaRejected', 'brokerCompanyName', 'anyBankRejected', 'allBanksRejected'];

const multipleEntries: { [key: string]: boolean } = {
    machine: true,
    vehicle: true,
    property: true,
    realEstate: true,
    workingCapital: false,
    other: false,
    fastLane: false,
};

export interface FinancingProduct {
    financingType: FinancingType;
    plannedDisbursement: string;
    dateOfDisbursement: string;
    plannedRepaymentStart: string;
    plannedRepaymentStartDate: string;
    differentDebtor: boolean;
    differentDebtorName: string;
    duration: number;
    durationUnit: 'MONTHS' | 'YEARS';
    comment: string;
    provision?: number;

    // other fields dependent on the financing type
    [key: string]: any;
}

export interface EquityInvestment {
    contributed: boolean;
    type: EquityType;
    capital: number;
    duration: number;
    interestRate: number;
    financier: string;
    comment: string;
}

export type EquityInvestments = {
    [key in InquiryModuleKey]: EquityInvestment;
};

export interface BrokerAssignee {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export interface FinmatchAssignee {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export const EquityCompliantModules: InquiryModuleKey[] = ['property', 'realEstate', 'other'];
export const FinancingCompliantModules: InquiryModuleKey[] = ['machine', 'vehicle', 'property', 'realEstate', 'workingCapital', 'other'];

export class Inquiry {
    // meta
    public id: string = null;
    public heading: string = null;
    public companyId: string = null;
    public userId: string = null;
    public currency: string = null;
    public deadline: Date = null;
    public financingParameters = null;
    public financingProducts: { [key in InquiryModuleKey]: FinancingProduct[] } = null;
    public equityInvestments: EquityInvestments = null;
    public financialStatements: Array<DocumentFile | MissingDocument> = [];
    public offerStatus: BankOfferStatus = null;
    public summaryDocuments: Array<any> = [];
    public rejectedByBank: boolean = null; // bank only
    public anyBankRejected: boolean = null;
    public allBanksRejected: boolean = null;
    public agreementNeeded: boolean = null;
    public otuaRejected: boolean = null;

    // creator
    public createdBy: string = null;
    public createdByFirstName: string = null;
    public createdByLastName: string = null;
    public createdDate: string = null;
    public version: number = null;

    // assignees
    public assignees: {
        broker: BrokerAssignee,
        finmatch: FinmatchAssignee,
    } = null;
    public currentApprover: {
        inquirySubmission: BrokerInquirySubmissionType,
        id: string
    } = null;

    // broker assignee
    public brokerAssignee: BrokerAssignee | null = null;
    public finmatchAssignee: FinmatchAssignee | null = null;

    // editor
    public lastModifiedBy: string = null;
    public lastModifiedByFirstName: string = null;
    public lastModifiedByLastName: string = null;
    public lastModifiedDate: string = null;
    public stateChangedAt: string = null;

    // numbers
    public messagesNo: number;
    public newMessagesNo: number;
    public bankOffersNo: number;
    public newBankOffersNo: number;

    public companyName: string;
    public company?: { // field only available in the inquiry listing
        companyName: string;
        postCode: string;
        city: string;
        customerId: string;
        crefoId: string;
    };
    public brokerCompanyName?: string = null;

    public alreadySeen: any;

    // modules meta
    public inquiryState: InquiryState = null;
    public machineMode: MachineModuleMode = null;
    public vehicleMode: boolean = null;
    public propertyProjectType: PropertyModuleType = null;
    public realEstateMode: RealEstateModuleMode = null;
    public workingCapitalMode: boolean = null;
    public otherMode: boolean = null;
    public fastLaneMode: boolean = null;
    public realEstateProjectType: RealEstateModuleType = null;
    public modules?: ModulesMetadata = null;

    // modules data
    public machines: Array<any> = [];
    public vehicles: Array<any> = [];
    public realEstates: Array<any> = [];
    public workingCapitals: Array<any> = [];
    public properties: Array<any> = [];
    public others: Array<any> = [];
    public fastLanes: Array<any> = [];

    /** Turns to true after successfull load from the server. Remains true even after applying changes. */
    public _loaded = false;
    /** Contain server-side (saved) version of the Inquiry. */
    public _origin: Inquiry;
    /* Used only for determining special statuses for FinMatch */
    public _bankOffers: any[];
    public _financingBanks: any[];
    public state = {
        toAssign: false,
        assigned: false,
        accepted: false,
        bankSelection: false,
        active: false,
        reviewOfferSelection: false,
        canceled: false,
        closed: false,
    };

    private __update: Subject<void> = new Subject();

    constructor(
        public inquiryService: InquiryService,
        public translationService: TranslationService,
        public principal: Principal,
        public loggedUser: FinMatchAccount | any,
        public inquiryConfigurationService: InquiryConfigurationService,
        public bankOffersService: BankOffersService,
        public bankSelectionService: BankSelectionService,
        private toastrService: ToastrService,
    ) {
        this.principal.getUserIdentitySubject().subscribe((identity: FinMatchAccount) => {
            this.loggedUser = identity || FinMatchLoggedOutMock;
        });
    }

    // private loggedUser: FinMatchAccount;

    public get hasModules() {
        return this.getModules().length !== 0;
    }

    public get isStatusFinMatchAssessment() {
        return ['TO_ASSIGN', 'ASSIGNED', 'ACCEPTED'].includes(this.inquiryState);
    }

    public isStatusActive(state = this.inquiryState) {
        return ['ACTIVE', 'BANK_RESPONSE_COMPLETED', 'MATCHING_FAILED'].includes(state);
    }

    public get isStatusAtLeastAccepted() {
        return this.isStatusFinMatchAssessment || this.isStatusActive() || ['BANK_SELECTION', 'REVIEW_OFFER_SELECTION'].includes(this.inquiryState);
    }

    public onUpdate(): Observable<void> {
        return this.__update.asObservable();
    }

    public hasModuleByName(module: string): boolean {
        return this[module] && this[module].length > 0;
    }

    /** Load inquiry by Id */
    public load(inquiryId: string): Observable<Inquiry> {
        return this.inquiryService.getInquiry(inquiryId).pipe(
            map(response => {
                if (response.body.hasOwnProperty('inquiry') && !response.body.inquiry.hasOwnProperty('financialStatements')) {
                    response.body.inquiry['financialStatements'] = [];
                } else if (!response.body.hasOwnProperty('inquiry') && !response.body.hasOwnProperty('financialStatements')) {
                    response.body['financialStatements'] = [];
                }

                this.applyData(response.body);
                return this;
            }),
            catchError(() => {
                this.toastrService.error(
                    this.translationService.inquiry.notFound.replace('[INQUIRY_ID]', inquiryId),
                    this.translationService.inquiry.notFoundHeader
                );
                return of(this);
            })
        );
    }

    public markInquiryAsSeen() {
        if (!this.id) return;
        return this.inquiryService.markInquiryAsSeen(this.id).subscribe(() => {
            this.alreadySeen = true;
        });
    }

    /** Reload the inquiry */
    public reload(): Promise<void> {
        return new Promise((resolve) => {
            this.inquiryService.getInquiry(this.id).subscribe(response => {
                const newState = response.body.inquiry.inquiryState;

                const requiresBankAndOffersData = (this.isStatusActive(newState) && this.loggedUser.isFinMatch());
                if (requiresBankAndOffersData) {
                    this.getBanksAndOffersData().then(() => {
                        this.applyData(response.body);
                        resolve();
                    });
                } else {
                    this.applyData(response.body);
                    resolve();
                }
            });
        });
    }

    //
    //
    // MODULES

    /** Applies raw object to Inquiry instance */
    public applyData(rawObject: any): void {
        const rawInquiry = rawObject.inquiry ? rawObject.inquiry : rawObject;
        _.assign(this, rawInquiry);
        _.assign(this._origin, rawInquiry);

        if (rawObject.inquiry) {
            InquiryAdditionalFields.forEach(field => {
                this[field] = rawObject[field] || null;
            });
        }

        this.setState();
        this.emitUpdate();
        this._loaded = true;

        delete this._origin._origin;
    }

    // save some commonly used states as bool values, so templates could refer to a variable instead of a function (for optimization purposes)
    private setState() {
        this.state.toAssign = this.inquiryState === 'TO_ASSIGN';
        this.state.assigned = this.inquiryState === 'ASSIGNED';
        this.state.accepted = this.inquiryState === 'ACCEPTED';
        this.state.bankSelection = this.inquiryState === 'BANK_SELECTION';
        this.state.active = this.isStatusActive();
        this.state.reviewOfferSelection = this.inquiryState === 'REVIEW_OFFER_SELECTION';
        this.state.canceled = this.inquiryState === 'CANCELED';
        this.state.closed = this.inquiryState === 'CLOSED';
    }

    /** Saves the Inquiry properties */
    public save(companyId?: string): Observable<HttpResponse<any>> {
        if (!this.heading || !this.currency) {
            return;
        }

        const inquiryJSON: { [key: string]: any } = {
            heading: this.heading,
            currency: this.currency,
            deadline: this.deadline,
            inquiryState: this.inquiryState,

            machineMode: this.machineMode,
            vehicleMode: this.vehicleMode,
            propertyProjectType: this.propertyProjectType,
            realEstateMode: this.realEstateMode,
            realEstateProjectType: this.realEstateProjectType,
            workingCapitalMode: this.workingCapitalMode,
            otherMode: this.otherMode,
            fastLaneMode: this.fastLaneMode,
            modules: this.modules,

            machines: this.machines,
            vehicles: this.vehicles,
            realEstates: this.realEstates,
            workingCapitals: this.workingCapitals,
            properties: this.properties,
            others: this.others,
            fastLanes: this.fastLanes,
            financingParameters: this.financingParameters,
            financingProducts: this.financingProducts,
            equityInvestments: this.equityInvestments,
            financialStatements: this.financialStatements,
            version: this.version
        };
        if (companyId) {
            inquiryJSON.companyId = companyId;
        }
        if (this.id) {
            inquiryJSON['id'] = this.id;
        }

        const inquirySave: Observable<any> = this.inquiryService.saveInquiry(inquiryJSON);
        inquirySave.subscribe(response => this.applyData(response.body));
        return inquirySave;
    }

    public send(): Observable<HttpResponse<any>> {
        const request = this.inquiryService.sendInquiry(this.id);
        request.subscribe(response => this.applyData(response.body));
        return request;
    }

    public accept(): Observable<HttpResponse<any>> {
        const request = this.inquiryService.acceptInquiry(this.id);
        request.subscribe(response => this.applyData(response.body));
        return request;
    }

    public assign(): Observable<HttpResponse<any>> {
        return this.inquiryService.assignInquiry(this.id).pipe(
            tap(response => this.applyData(response.body))
        );
    }

    public cancel(): Observable<HttpResponse<any>> {
        const request = this.inquiryService.cancelInquiry(this.id);
        request.subscribe(response => this.applyData(response.body));
        return request;
    }

    public delete(): Observable<HttpResponse<any>> {
        const request = this.inquiryService.deleteInquiry(this.id);
        request.subscribe(response => this.applyData(response.body));
        return request;
    }

    public revise(reason?: string, documents?: DocumentFile[]): Observable<HttpResponse<any>> {
        const request = this.inquiryService.reviseInquiry(this.id, reason, documents);
        request.subscribe(response => this.applyData(response.body));
        return request;
    }

    public close(): Observable<HttpResponse<any>> {
        const request = this.inquiryService.closeInquiry(this.id);
        request.subscribe(response => this.applyData(response.body));
        return request;
    }

    public reOpen(): Observable<HttpResponse<any>> {
        return this.inquiryService.reOpenInquiry(this.id).pipe(
            tap(response => this.applyData(response.body))
        );
    }

    public bankReject(reasons, reasonsTranslated, reasonOther, documents?: DocumentFile[], bankId: string | null = null): Observable<HttpResponse<any>> {
        return this.inquiryService.rejectInquiry(this.id, reasons, reasonsTranslated, reasonOther, documents, bankId);
    }

    /** Returns keys of all modules included in the Inquiry. */
    public getModules(): InquiryModuleKey[] {
        return _.filter(FinancingModules, key => this.hasModule(<InquiryModuleKey>key));
    }

    /** Returns completion status for a module. */
    public getModuleStatus(moduleKey: InquiryModuleKey): 'EMPTY' | 'INCOMPLETE' | 'DONE' {
        const itemsInModule = this.getModuleItems(moduleKey);
        if (itemsInModule.length === 0) {
            return 'EMPTY';
        } else {
            return 'DONE';
        }
    }

    public getModuleMetadata(moduleKey: InquiryModuleKey): ModuleMetadata | null {
        return (this.modules && this.modules[moduleKey]) || {};
    }

    public setModuleMetadataDetails(moduleKey: InquiryModuleKey, details: ModuleMetadataDetails): void {
        this.modules = {
            ...this.modules,
            [moduleKey]: {
                ...this.modules[moduleKey],
                details: details,
            },
        };
    }

    public hasInvestmentAndFinancingMismatch(): boolean {
        return this.areFinancingParametersComplete()
            && this.areEquityInvestmentsComplete()
            && this.getTotalCost() !== this.getFinancingAndEquityTotal();
    }

    public hasWarningColor() {
        return this.hasInvestmentAndFinancingMismatch() && this.getInternalState() === 'DRAFT';
    }

    public getFinancingAndEquityTotal() {
        return (this.getTotalEquityInvestment() || 0) + (this.getInquiryTotalFinancingValue() || 0);
    }

    public hasFinancingRequirementsSelectable() {
        return this.getFinancingModules().length > 0;
    }

    public hasEquityInvestmentSelectable() {
        return this.getEquityModules().length > 0;
    }

    public getEquityModules(): InquiryModuleKey[] {
        return this.getModules()
            .filter(moduleKey => EquityCompliantModules.includes(moduleKey as InquiryModuleKey));
    }

    public getFinancingModules(): InquiryModuleKey[] {
        return this.getModules()
            .filter(moduleKey => FinancingCompliantModules.includes(moduleKey as InquiryModuleKey));
    }

    getEquityCapitalForModule(moduleKey: InquiryModuleKey): number | null {
        if (this.equityInvestments && this.equityInvestments[moduleKey]) {
            return this.equityInvestments[moduleKey].capital || 0;
        }
        return null;
    }

    getTotalEquityInvestment() {
        if (!this.equityInvestments || !Object.keys(this.equityInvestments).length) {
            return null;
        }
        return Object.values(this.equityInvestments)
            .map(equityInvestment => equityInvestment ? equityInvestment.capital || 0 : 0)
            .reduce((next, agg) => agg + next, 0);
    }

    public setEquityInvestments(equityModules: { [key in InquiryModuleKey] }) {
        this.equityInvestments = equityModules;
    }

    public getModuleMetadataDetails(moduleKey: InquiryModuleKey): ModuleMetadataDetails {
        const moduleMetadata = this.getModuleMetadata(moduleKey);
        return (moduleMetadata && moduleMetadata.details) || {} as ModuleMetadataDetails;
    }

    //
    //
    // ITEMS

    /** Returns true if all modules in the Inquiry are in status "DONE". */
    public areAllModulesComplete(): boolean {
        const inquiryModules = this.getModules();
        return !_.some(inquiryModules, moduleKey => this.getModuleStatus(moduleKey) !== 'DONE');
    }

    /** Applies a module to the Inquiry. */
    public addModule(moduleKey: InquiryModuleKey) {
        switch (moduleKey) {
            case 'machine':
                this.machineMode = this.machineMode || (APP_DI_CONFIG.isQuickModeDisabled ? 'EXPERT' : 'EMPTY');
                break;
            case 'vehicle':
                this.vehicleMode = true;
                break;
            case 'realEstate':
                this.realEstateMode = this.realEstateMode || (APP_DI_CONFIG.isQuickModeDisabled ? 'EXPERT' : 'EMPTY');
                break;
            case 'property':
                this.propertyProjectType = this.propertyProjectType || 'EMPTY';
                break;
            case 'workingCapital':
                this.workingCapitalMode = true;
                break;
            case 'other':
                this.otherMode = true;
                break;
            case 'fastLane':
                this.fastLaneMode = true;
                break;
        }
    }

    public removeModule(moduleKey: InquiryModuleKey) {
        // tslint:disable-next-line:no-unused-expression
        this.modules && this.modules[moduleKey] && delete this.modules[moduleKey];
        switch (moduleKey) {
            case 'machine':
                this.machines = [];
                this.machineMode = null;
                break;
            case 'vehicle':
                this.vehicles = [];
                this.vehicleMode = false;
                break;
            case 'realEstate':
                this.realEstates = [];
                this.realEstateMode = null;
                this.realEstateProjectType = null;
                break;
            case 'property':
                this.properties = [];
                this.propertyProjectType = null;
                break;
            case 'workingCapital':
                this.workingCapitals = [];
                this.workingCapitalMode = null;
                break;
            case 'other':
                this.others = [];
                this.otherMode = null;
                break;
            case 'fastLane':
                this.fastLanes = [];
                this.fastLaneMode = null;
                break;
        }
        this.removeFinancialProducts(moduleKey);
    }

    /** Returns mode of a module (EMPTY/QUICK/EXPERT). Returns null if does not apply. */
    public getModuleMode(moduleKey: InquiryModuleKey): InquiryModuleMode {
        switch (moduleKey) {
            case 'machine':
                return this.machineMode;
            case 'realEstate':
                return this.realEstateMode;
            default:
                return null;
        }
    }

    public isCommissionSelectable() {
        return (<InquiryState[]>[
            'DRAFT', 'ACCEPTED', 'TO_ASSIGN', 'ACTIVE', 'ASSIGNED', 'REVIEW_OFFER_SELECTION', 'BANK_SELECTION'
        ]).includes(this.getInternalState());
    }

    /** Returns type of a module. Returns null if does not apply. */
    public getModuleType(moduleKey: InquiryModuleKey): InquiryModuleType {
        switch (moduleKey) {
            case 'realEstate':
                return this.realEstateProjectType;
            case 'property':
                return this.propertyProjectType;
            default:
                return null;
        }
    }

    /** Returns mode of a module (EMPTY/QUICK/EXPERT). Returns null if does not apply. */
    public setModuleMode(moduleKey: InquiryModuleKey, moduleMode: InquiryModuleMode): void {
        if (moduleKey === 'machine') {
            this.machineMode = moduleMode;
        } else if (moduleKey === 'realEstate') {
            this.realEstateMode = moduleMode;
        }
    }

    public setModuleType(moduleKey: InquiryModuleKey, inquiryType: InquiryModuleType): void {
        if (this.getModuleType(moduleKey) && inquiryType !== this.getModuleType(moduleKey)) {
            this.removeFinancialProducts(moduleKey);
        }
        if (moduleKey === 'realEstate') {
            this.realEstateProjectType = <RealEstateModuleType>inquiryType;
        } else if (moduleKey === 'property') {
            this.propertyProjectType = <PropertyModuleType>inquiryType;
        }
    }

    public getAvailableFinancingTypes(moduleKey: InquiryModuleKey, moduleType: InquiryModuleType): string[] {
        return this.inquiryConfigurationService.financingTypesPerModule[moduleKey][moduleType || 'generic'];
    }

    /** Returns total financing sum for the Inquiry. Returns NULL if configuration is not complete. */
    public getInquiryTotalFinancingValue(returnNull: boolean = true, isTile: boolean = false): number {
        const inquiryModules = this.getModules();
        const hasAllNecessaryValues = !_.some(inquiryModules, moduleKey => !this.getModuleFinancingValue(moduleKey));

        if (hasAllNecessaryValues) {
            return inquiryModules.reduce((sum, moduleKey) => sum + this.getModuleFinancingValue(moduleKey, true, null, false), 0);
        }
        if (isTile && this.fastLaneMode) {
            if (this.fastLanes[0]) {
                return this.fastLanes[0].financingVolume;
            }
        }
        return returnNull ? null : 0;
    }

    public getFinancingVolumeForProduct(financingProduct: any, moduleKey: InquiryModuleKey, ignoreDownPayment = false): number {
        switch (financingProduct.financingType) {
            case 'LOAN':
            case 'CREDIT_LINE':
            case 'INSTALLMENT_PURCHASE':
            case 'REAL_ESTATE_LEASING':
                return financingProduct.financingVolume;
            case 'LEASING':
                return ignoreDownPayment ? financingProduct.acquisitionValue : financingProduct.acquisitionValue - financingProduct.downPayment;
            case 'GUARANTEE':
                return financingProduct.creditLine;
            case 'FACTORING':
                return this.getModuleTotalCost(moduleKey);
        }
    }

    /** Returns financing value for a module according to financing parameters. Returns NULL if configuration is not complete. */
    public getModuleFinancingValue(moduleKey: InquiryModuleKey, returnNull: boolean = true, finProductData: any = null, ignoreDownPayment = false): number {
        const moduleCost = this.getModuleTotalCost(moduleKey);
        if (this.isLegacyFinancingParametersInquiry()) {
            const contribution = this.getModuleContributionValue(moduleKey);

            if (moduleCost !== null && contribution !== null) {
                return moduleCost - contribution;
            }
            return returnNull ? null : 0;
        }

        if (!finProductData && !(this.financingProducts && this.financingProducts[moduleKey])) {
            return null;
        }
        const moduleData = (finProductData || this.financingProducts[moduleKey]).filter(product => !!product.financingType);
        return moduleData.reduce((agg, singleProductValues) => {
            switch (singleProductValues.financingType) {
                case 'LOAN':
                case 'CREDIT_LINE':
                case 'INSTALLMENT_PURCHASE':
                case 'LEASING':
                case 'REAL_ESTATE_LEASING':
                case 'GUARANTEE':
                    return agg + this.getFinancingVolumeForProduct(singleProductValues, moduleKey, ignoreDownPayment);
                case 'FACTORING':
                    return moduleData.length === 1 ? agg + moduleCost : agg;
                default:
                    return agg;
            }
        }, 0);
    }

    /** Returns own contribution value for a module according to financing parameters. Returns NULL if configuration is not complete. */
    public getModuleContributionValue(moduleKey: InquiryModuleKey): number {
        if (this.financingParameters && this.financingParameters[moduleKey]) {
            return this.financingParameters[moduleKey]['equityInvestment'];
        }
        return null;
    }

    /** Returns representative label for given module item. */
    public getItemLabel(item: any, moduleKey: string): string {
        switch (moduleKey) {
            case 'machine':
                switch (this.machineMode) {
                    case 'QUICK':
                        return item.description;
                    case 'EXPERT':
                        return !this.getModuleMetadataDetails('machine').isAggregated
                            ? this.translationService.inquiryMachineCategoryKeyToViewValue[item.machineType] || item.machineType
                            : this.translationService.multipleMachines;
                }
                break;
            case 'vehicle':
                return this.translationService.inquiryVehicleCategoryKeyToViewValue[item.category] || item.category;
            case 'realEstate':
                let buildingTypeLabel;
                switch (this.realEstateMode) {
                    case 'QUICK':
                        buildingTypeLabel = this.translationService.inquiryBuildingTypesQuickMode[item.buildingType];
                        break;
                    case 'EXPERT':
                        buildingTypeLabel = this.translationService.inquiryBuildingTypes[item.buildingType];
                        break;
                    default:
                        buildingTypeLabel = this.translationService.inquiryBuildingTypes[item.buildingType];
                        break;
                }
                return buildingTypeLabel;
            case 'property':
                const countryLabel = _.find(this.translationService.inquiryRealEstateCountries, {value: item.country})
                    .viewValue;
                return item.city + ', ' + countryLabel;
            case 'workingCapital':
            case 'other':
            case 'fastLane':
                return item.description;
        }
    }

    // STATES

    /** Returns total price for given module item. */
    public getItemTotalPrice(item: any, moduleKey: InquiryModuleKey): number {
        switch (moduleKey) {
            case 'machine':
            case 'vehicle':
                return item.totalSalesPrice;
            case 'realEstate':
                switch (this.realEstateProjectType) {
                    case RealEstateModuleType.NEW_BUILDING:
                    case RealEstateModuleType.EXISTING_BUILDING:
                        return item.totalSalesPrice;
                    case RealEstateModuleType.RENOVATION:
                        return item.renovationCosts;
                    case RealEstateModuleType.FOLLOWUP_FINANCING:
                        return item.loanRedemptionAmount;
                }
                break;
            case 'property':
                switch (this.propertyProjectType) {
                    case 'PURCHASE':
                        return item.salesPrice + (item.buyingCosts ? item.buyingCosts : 0);
                    case 'FOLLOWUP_FINANCING':
                        return item.redemptionAmount;
                }
                break;
            case 'workingCapital':
            case 'other':
            case 'fastLane':
                return item.financingVolume;
        }
    }

    /** Returns array of raw objects items for given module. */
    public getModuleItems(moduleKey: InquiryModuleKey): any[] {
        const collectionProperty = InquiryModuleCollectionProperty[moduleKey];
        return this[collectionProperty];
    }

    /** Assigns an array of raw objects for to given module. */
    public setModuleItems(moduleKey: InquiryModuleKey, items: any[]): void {
        const collectionProperty = InquiryModuleCollectionProperty[moduleKey];
        this[collectionProperty] = items;
    }

    /** Returns module item in unified format for UI purposes. */
    public getUnifiedItem(item: any, moduleKey: InquiryModuleKey, priceOnly = false): UnifiedModuleItem {
        const result: UnifiedModuleItem = {price: this.getItemTotalPrice(item, moduleKey)};
        if (!priceOnly) {
            result.quantity = item.quantity ? item.quantity : 1;
            result.label = this.getItemLabel(item, moduleKey);
        }
        return result;
    }

    /** Returns array containing module items in unified format for UI purposes. */
    public getUnifiedModuleItems(moduleKey: InquiryModuleKey, priceOnly = false): Array<UnifiedModuleItem> {
        const moduleItems = this.getModuleItems(moduleKey);

        return _.map(moduleItems, moduleItem => {
            return this.getUnifiedItem(moduleItem, moduleKey, priceOnly);
        });
    }

    /** Returns total cost for items in the module. */
    public getModuleTotalCost(moduleKey: InquiryModuleKey): number {
        const moduleItems = this.getUnifiedModuleItems(moduleKey, true);
        if (moduleItems.length > 0) {
            return moduleItems.reduce((sum, item) => sum + item.price, 0);
        } else {
            return null;
        }
    }

    /** Returns total items quantity in the module. */
    public getModuleItemsQuantity(moduleKey: InquiryModuleKey): number {
        const moduleItems = this.getUnifiedModuleItems(moduleKey);
        return moduleItems.reduce((sum, item) => sum + (item.quantity || 1), 0);
    }

    /** Returns total modules cost in the inquiry. */
    public getTotalCost(): number {
        return FinancingModules.reduce((sum, moduleKey) => sum + this.getModuleTotalCost(moduleKey) || 0, 0);
    }

    /** Returns total items quantity in the inquiry. */
    public getTotalItemsQuantity(): number {
        return FinancingModules.reduce((sum, moduleKey) => sum + this.getModuleItemsQuantity(moduleKey), 0);
    }

    /** Is it possible to add modules (if there are not all modules applied). */
    public canAddModules(): boolean {
        return this.getModules().length !== _.keys(FinancingModules).length;
    }

    /** Returns true if module has no items applied yet. */
    public isModuleEmpty(moduleKey: InquiryModuleKey): boolean {
        return this.getModuleItems(moduleKey).length === 0;
    }

    /** Returns true if inquiry includes specified module. */
    public hasModule(moduleKey: InquiryModuleKey): boolean {
        const moduleProperty = this[modulesMapping[moduleKey]];
        return moduleProperty !== null && moduleProperty !== false;
    }

    public isLegacyFinancingParametersInquiry(): boolean {
        return !!this.financingParameters;
    }

    /** Returns true if financing parameters are complete for entire Inquiry. */
    public areFinancingParametersComplete(): boolean {
        if (!this.hasFinancingRequirementsSelectable()) {
            return true;
        }
        const financingTypesObj = this.isLegacyFinancingParametersInquiry()
            ? this.financingParameters
            : this.financingProducts;
        if (!financingTypesObj) {
            return false;
        }
        if (this.getModules().length === 0) {
            return false;
        }
        return !_.some(this.getModules(), moduleKey => {
            return !financingTypesObj[moduleKey];
        });
    }

    areEquityInvestmentsComplete(): boolean {
        if (!this.equityInvestments) {
            return false;
        }
        return this.getEquityModules().every(moduleKey => {
            return this.equityInvestments[moduleKey] && [true, false].includes(
                this.equityInvestments[moduleKey].contributed
            );
        });
    }

    /**
     * Returns true if financing parameters are complete for entire Inquiry.
     * As of FINMTCH-1263 they are considered "done" by default even without any documents uploaded.
     * */
    public areFinancialStatementsComplete(): boolean {
        if (!this.financialStatements?.length) {
            return false;
        }
        const uploadedDocTyps = this.financialStatements.map(doc => doc.documentType);
        return this.inquiryConfigurationService.requiredFinancialStatementTypes.every(fileType => uploadedDocTyps.includes(fileType));
    }

    public getCreator(): { name: string; date: Date } {
        return {
            name: this.createdByFirstName + ' ' + this.createdByLastName,
            date: new Date(this.createdDate)
        };
    }

    public getLastEditor(): { name: string; date: Date } {
        return {
            name: this.lastModifiedByFirstName + ' ' + this.lastModifiedByLastName,
            date: new Date(this.lastModifiedDate)
        };
    }

    public getFinmatchAssigneeId(): string {
        if (!this.assignees.finmatch) {
            return null;
        }
        return this.assignees.finmatch.id;
    }

    public getBrokerAssigneeId(): string {
        if (!this.assignees.broker) {
            return null;
        }
        return this.assignees.broker.id;
    }

    public hasFinmatchAssignee(): boolean {
        return !!this.assignees.finmatch;
    }

    public getFinmatchAssignee(): string {
        if (!this.assignees?.finmatch) {
            return null;
        }
        return `${this.assignees.finmatch.firstName} ${this.assignees.finmatch.lastName}`;
    }

    public getBrokerAssignee(): string | null {
        if (!this.assignees?.broker) {
            return null;
        }
        return `${this.assignees.broker.firstName} ${this.assignees.broker.lastName}`;
    }

    public getFinmatchAssigneePhone(): string {
        if (!this.assignees.finmatch) {
            return null;
        }
        return this.assignees.finmatch.phone;
    }

    public getFinmatchAssigneeEmail(): string {
        if (!this.assignees.finmatch) {
            return null;
        }
        return this.assignees.finmatch.email;
    }

    public getBrokerAssigneeEmail(): string {
        if (!this.assignees.broker) {
            return null;
        }
        return this.assignees.broker.email;
    }

    public getBrokerAssigneePhone(): string {
        if (!this.assignees.broker) {
            return null;
        }
        return this.assignees.broker.phone;
    }

    public isUserAssigned(user: FinMatchAccount) {
        if (user.isBroker()) {
            return user.id === this.getBrokerAssigneeId();
        }
        if (user.isFinMatch()) {
            return user.id === this.getFinmatchAssigneeId();
        }
        return false;
    }

    public getInternalStateSecondary() {
        if (!this.alreadySeen) {
            return 'NEW';
        } else if (this.bankOffersNo > 0) {
            return 'OFFER_MADE';
        } else if (this.anyBankRejected) {
            return 'REJECTED_BY_BANK';
        }
    }

    public getInternalState(): InquiryState {
        if (!this.loggedUser.isFinMatch()) {
            if (this.isStatusFinMatchAssessment) return 'FINMATCH_ASSESSMENT';
            if (this.isStatusActive()) return 'ACTIVE';
            if (this.inquiryState === 'REVIEW_OFFER_SELECTION') return 'WAITING_FOR_FINMATCH';
            if (this.inquiryState === 'REVIEW_SIGNING') return 'SIGNING_OF_CONTRACTS';
            return this.inquiryState;
        }

        return this.inquiryState;

        // moved to backend
        // if (this.inquiryState === 'FINMATCH_ASSESSMENT') {
        //     if (this.getFinmatchAssigneeId() === null) {
        //         return 'TO_ASSIGN';
        //     } else if (!this.isBankable) {
        //         return 'ASSIGNED';
        //     }
        //     return 'ACCEPTED';
        // } else
        // if (this.inquiryState === 'WAITING_FOR_FINMATCH') {
        //     return 'REVIEW_OFFER_SELECTION';
        // } else if (this.inquiryState === 'ACTIVE') {
        //     // In case when additional data is not available yet
        //     if (!this.hasOffersDataLoaded()) {
        //         return 'ACTIVE';
        //     } else if (!this.isAnyPendingBank()) {
        //         if (this.bankOffersNo <= 0) {
        //             return 'MATCHING_FAILED';
        //         } else {
        //             return 'BANK_RESPONSE_COMPLETED';
        //         }
        //     }
        //     return 'ACTIVE';
        // } else if (this.inquiryState === 'SIGNING_OF_CONTRACTS') {
        //     return 'REVIEW_SIGNING';
        // }
    }

    // moved to backend
    // public isAllBanksRejected() {
    //     const isAnyPendingBank = this._financingBanks?.some((bank) => {
    //         const bankRejectedByCompany = (!bank.newlyAdded && !bank.selected);
    //         const bankResponded = !!(bank.rejectedByBank || _.find(this._bankOffers, {bankId: bank.bankId}));
    //         return bank.newlyAdded || (!bankRejectedByCompany && !bankResponded);
    //     });
    //
    //     return !isAnyPendingBank && this.bankOffersNo <= 0;
    // }

    public isOverdue(): boolean {
        return this.inquiryService.isOverdue(this);
    }

    public getOverdueMessage(): string {
        const inquiryState = this.getInternalState();
        return this.inquiryConfigurationService.statusOverdueComments[inquiryState];
    }

    /** Returns boolean that determines if module can contain more than 1 financing entry. */
    public canHaveMultipleItems(moduleKey: InquiryModuleKey): boolean {
        return multipleEntries[moduleKey];
    }

    public isStatusContractSignature() {
        return this.inquiryState === 'REVIEW_SIGNING';
    }

    emitUpdate() {
        this.__update.next();
    }

    public getBanksAndOffersData(emitUpdate = false): Promise<void> {
        return new Promise((resolve) => {
            const inquiryOffers = this.bankOffersService.getInquiryBankOffers(this.id).pipe(
                tap((bankOffers) => this._bankOffers = bankOffers),
                catchError(() => of([])),
                share()
            );
            const inquiryBanks = this.bankSelectionService.getInquiryFinancingBanks(this.id).pipe(
                tap((financingBanks) => this._financingBanks = financingBanks),
                catchError(() => of([])),
                share()
            );
            zip(inquiryOffers, inquiryBanks).subscribe(() => {
                if (emitUpdate) {
                    this.emitUpdate();
                }
                resolve();
            });
        });
    }

    private removeFinancialProducts(moduleKey: InquiryModuleKey) {
        if (this.financingParameters) {
            delete this.financingParameters[moduleKey];
        }
        if (this.financingProducts) {
            delete this.financingProducts[moduleKey];
        }
        if (this.equityInvestments) {
            delete this.equityInvestments[moduleKey];
        }
    }

    // public hasOffersDataLoaded() {
    //     return this._financingBanks && this._bankOffers;
    // }
}

export interface InquiryOtua {
    companyName: string;
    inquiryId: string;
    inquiryTitle: string;
    agreementType?: 'REGULAR' | 'FACTORING';
}

export interface Agreement {
    bankId: string;
    signedOn?: string;
    signedById?: string;
    signedByLogin?: string;
    rejectedOn?: string;
    rejectedById?: string;
    rejectionReason?: string;
    user?: {
        firstName: string;
        lastName: string;
        email: string;
    };
    bank?: {
        usageAgreementSigned: boolean;
        bankName: string;
        city: string;
        country: string;
    };
}

@Injectable({providedIn: 'root'})
export class InquiryFactory {
    public loggedUser: FinMatchAccount;

    constructor(
        public inquiryService: InquiryService,
        public translationService: TranslationService,
        public principal: Principal,
        public inquiryConfigurationService: InquiryConfigurationService,
        public bankOffersService: BankOffersService,
        public bankSelectionService: BankSelectionService,
        private usageAgreementService: UsageAgreementService,
        private toastrService: ToastrService,
    ) {
        this.principal.getUserIdentitySubject().subscribe(identity => {
            this.loggedUser = identity || FinMatchLoggedOutMock;
        });
    }

    /** Returns empty Inquiry class instance. */
    public getInquiryInstance(): Inquiry {
        const inquiry = new Inquiry(
            this.inquiryService,
            this.translationService,
            this.principal,
            this.loggedUser,
            this.inquiryConfigurationService,
            this.bankOffersService,
            this.bankSelectionService,
            this.toastrService,
        );

        inquiry._origin = new Inquiry(
            this.inquiryService,
            this.translationService,
            this.principal,
            this.loggedUser,
            this.inquiryConfigurationService,
            this.bankOffersService,
            this.bankSelectionService,
            this.toastrService,
        );
        return inquiry;
    }

    public loadInquiry$(inquiryId: string): Observable<Inquiry> {
        const inquiry = this.getInquiryInstance();
        return inquiry.load(inquiryId);
    }

    /** Creates new, empty Inquiry (without saving). */
    public newInquiry(heading?: string, currency?: string, state: InquiryState = 'DRAFT'): Inquiry {
        const inquiry = this.getInquiryInstance();
        if (heading) {
            inquiry.heading = heading;
        }
        if (currency) {
            inquiry.currency = currency;
        }
        inquiry.inquiryState = state;
        return inquiry;
    }

    /** Retrieves array of Inquiry class instances. */
    public getInquiries(
        sortParam: string = '',
        size = 10000,
        page = 0,
        delayOfferFetch = false,
        withTotalCount = false,
        headerOnly = false,
    ): Observable<{ inquiries: Inquiry[], totalCount: number }> {
        return this.inquiryService.getInquiries(sortParam, size, page, headerOnly).pipe(
            map(response => {
                return {
                    inquiries: _.map(response.body.data, inquiryRawObject => {
                        const inquiryInstance = this.getInquiryInstance();
                        inquiryInstance.applyData(inquiryRawObject['inquiry']);

                        InquiryAdditionalFields.forEach(field => {
                            inquiryInstance[field] = inquiryRawObject[field] || null;
                        });

                        return inquiryInstance;
                    }),
                    totalCount: response.headers.get('x-total-count'),
                };
            }),
        );

        // if (!this.loggedUser.isFinMatch()) {
        //     return inquiries$;
        // }

        // "Enhance" inquiries with bank offers and financing banks
        // Needed to distinguish "Active" state from the "Bank response completed" and "Matching failed" internal states.
        // return inquiries$.pipe(
        //     switchMap(({inquiries, totalCount}) => {
        //         const activeInquiries = inquiries.filter((inquiry) => inquiry.isStatusActive());
        //         const ids = activeInquiries.map(inquiry => inquiry.id);
        //         return combineLatest(
        //             this.bankOffersService.getBankOffersPerInquiries(ids),
        //             this.bankSelectionService.getMultipleInquiriesFinancingBanks(ids),
        //         ).pipe(
        //             map(([offersData, financingBanksData]) => {
        //                 activeInquiries.forEach(inquiry => {
        //                     inquiry.setBankOffers(offersData[inquiry.id]);
        //                     inquiry.setFinancingBanks(financingBanksData[inquiry.id]);
        //                 });
        //             }),
        //             switchMap(() => of({inquiries, totalCount})),
        //         );
        //     }));
    }
}
