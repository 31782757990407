import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {LabelsMap, TranslationService} from '../../../../../../services/root/translation/translation.service';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {
    BuildingClassification,
    BuildingTypes,
    BuildingTypesV2,
    InquiryConfigurationService
} from 'src/app/services/inquiry/inquiry-configuration.service';
import {BuildingType} from '../../../../../../models/InquiryModel';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const RealEstateFollowupFinancingFormGroup = {
    id: [],
    financingPlans: ['', customValidators.financingPlans],
    buildingConstructionYear: [null, [customValidators.yearOfEstValidator()]],
    buildingApartmentsArea: [null, [Validators.max(9999999)]],
    buildingArea: [null, [customValidators.area9]],
    buildingDescription: [null, [Validators.required, Validators.maxLength(100)]],
    buildingOfficesArea: [null, [customValidators.area7]],
    buildingProductionArea: [null, [customValidators.area7]],
    buildingRetailArea: [null, [customValidators.area7]],
    buildingStoreArea: [null, [customValidators.area7]],
    buildingUsageCategory: ['OWN_USAGE', []],
    buildingType: [null, [Validators.required]],
    city: [null, [Validators.required, Validators.maxLength(100)]],
    classification: [null, [Validators.required]],
    country: ['GERMANY', [Validators.required]],
    groundRent: [null, [customValidators.price()]],
    houseNumber: [null, [Validators.maxLength(5)]],
    housingUnitsCount: [null, [Validators.required, Validators.min(0), Validators.max(9999)]],
    leaseHoldOnProperty: [false, []],
    loanRedemptionAmount: [null, [Validators.required, customValidators.price()]],
    loanRedemptionDate: [null, [Validators.required]],
    postalCode: [null, [Validators.max(99999)]],
    propertyArea: [null, [customValidators.area9]],
    remainingTermLeaseHold: [null, [customValidators.remainingTermLeaseHold]],
    streetAddress: [null, [Validators.required, Validators.maxLength(100)]],
};
const buildingTypeToBuildingClassifications = {
    COMMERCIAL_BUILDING: [
        BuildingClassification.PROJECT_FINANCING,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.HOTEL,
        BuildingClassification.PLANT,
    ],
    MIXED_USE: [
        BuildingClassification.PROJECT_FINANCING,
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.HOTEL,
        BuildingClassification.PLANT,
        BuildingClassification.APPORTIONMENT,
    ],
    RESIDENTIAL_USE: [
        BuildingClassification.INCOME_PROPERTY,
        BuildingClassification.APPORTIONMENT,
    ],
};

@Component({
    selector: 'app-real-estate-followup-financing-form',
    templateUrl: './real-estate-followup-financing-form.component.html',
    styleUrls: ['./real-estate-followup-financing-form.component.less']
})
export class RealEstateFollowupFinancingFormComponent implements OnInit {
    @Input() form: UntypedFormGroup;
    @Input() readonly: boolean = false;
    @Input() currency: string;
    public buildingTypes: Array<BuildingType>;
    public buildingTypeLabels: LabelsMap;
    public countries: Array<{ value: string, viewValue: string }> = [];
    public usageCategories = this.translationService.buildingUsageCategories;
    public buildingClassificationOptions = [];
    private subscriptions: Subscription[] = [];

    constructor(
        public translationService: TranslationService,
        private inquiryConfigurationService: InquiryConfigurationService,
    ) {
        this.countries = this.translationService.inquiryRealEstateCountries;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnChanges(changes: any) {
        if (changes.form) {
            this.updateBuildingTypeFields();
            this.updateLeaseholdFields();
            this.updateCountryRelatedFields();
        }
    }

    ngOnInit() {
        if (this.inquiryConfigurationService.hasFormOldBuildingType(this.form)) {
            this.buildingTypes = BuildingTypes;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypes;
            this.form.controls.classification.disable();
        } else {
            this.buildingTypes = BuildingTypesV2;
            this.buildingTypeLabels = this.translationService.inquiryBuildingTypesV2;
            this.subscriptions.push(
                this.form.controls.buildingType.valueChanges.pipe(startWith(this.form.controls.buildingType.value))
                    .subscribe(buildingType => {
                        this.buildingClassificationOptions = this.inquiryConfigurationService.buildingClassificationOptions.filter(
                            option => !buildingTypeToBuildingClassifications[buildingType] || buildingTypeToBuildingClassifications[buildingType].includes(option.value)
                        );
                        if (!this.buildingClassificationOptions.map(option => option.value).includes(this.form.controls.classification.value)) {
                            this.form.controls.classification.setValue(null);
                        }
                    })
            )
        }
        this.form.get('buildingType').valueChanges.subscribe(() => {
            this.updateBuildingTypeFields();
        });
        this.form.get('leaseHoldOnProperty').valueChanges.subscribe(() => {
            this.updateLeaseholdFields();
        });

        this.form.get('country').valueChanges.subscribe(() => this.updateCountryRelatedFields());
    }

    public updateBuildingTypeFields(): void {
        let isResidentialUse = this.form.get('buildingType').value === 'RESIDENTIAL_USE';
        let isMixedUse = this.form.get('buildingType').value === 'MIXED_USE';
        let isOtherUse = this.form.get('buildingType').value === 'OTHER';
        let isCommercialBuilding = this.form.get('buildingType').value === 'COMMERCIAL_BUILDING';
        const isMixedOrCommercial = isMixedUse || isCommercialBuilding;

        this.form.get('buildingDescription')[(isOtherUse ? 'enable' : 'disable')]();

        this.form.get('buildingProductionArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingRetailArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingStoreArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingOfficesArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();
        this.form.get('buildingApartmentsArea')[(isMixedOrCommercial ? 'enable' : 'disable')]();

        this.form.get('housingUnitsCount')[(isResidentialUse ? 'enable' : 'disable')]();
        this.form.get('buildingUsageCategory')[(isResidentialUse ? 'enable' : 'disable')]();

        if (!isOtherUse) {
            this.form.get('buildingDescription').setValue(null);
        }

        if (!isMixedOrCommercial) {
            this.form.get('buildingProductionArea').setValue(null);
            this.form.get('buildingRetailArea').setValue(null);
            this.form.get('buildingStoreArea').setValue(null);
            this.form.get('buildingOfficesArea').setValue(null);
            this.form.get('buildingApartmentsArea').setValue(null);
        }

        if (!isResidentialUse) {
            this.form.get('housingUnitsCount').setValue(null);
            this.form.get('buildingUsageCategory').setValue(null);
        } else {
            if (this.form.get('buildingUsageCategory').value === null) {
                this.form.get('buildingUsageCategory').setValue('OWN_USAGE');
            }
        }
    }

    public updateLeaseholdFields(): void {
        let isLeasehold = (this.form.get('leaseHoldOnProperty').value === true) ? true : false;

        this.form.get('groundRent')[(isLeasehold ? 'enable' : 'disable')]();
        this.form.get('remainingTermLeaseHold')[(isLeasehold ? 'enable' : 'disable')]();

        if (!isLeasehold) {
            this.form.get('groundRent').setValue(null);
            this.form.get('remainingTermLeaseHold').setValue(null);
        }
    }

    public updateCountryRelatedFields(): void {
        let country = this.form.get('country').value;

        let postCodeValidator = (country === 'GERMANY') ? customValidators.postalCodeOptionalValidator5 : customValidators.postalCodeOptionalValidator4;
        this.form.get('postalCode').setValidators([postCodeValidator]);
        this.form.get('postalCode').updateValueAndValidity();
    }
}
