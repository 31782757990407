import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {map} from "rxjs/operators";

export type CookieCategory = 'necessary' | 'analytics' | 'marketing';
type CookieCategoryKeys = keyof CookieCategory;
type CookieCategoryStatuses = {[key in CookieCategoryKeys]: boolean};

type CookieConsentCategory = {
    id: CookieCategory,
    value: boolean,
}
type CookieConsentStatus = {
    answered: boolean,
    gdpr: boolean,
    categories: CookieConsentCategory[],
}


@Injectable({
    providedIn: 'root'
})
export class CookieConsentService {

    private _cookieConsentStatus = new ReplaySubject<CookieConsentStatus>(1);

    public readonly cookieConsentStatus: Observable<CookieConsentStatus> = this._cookieConsentStatus.asObservable();
    public readonly cookieCategoryStatuses: Observable<CookieCategoryStatuses> = this.cookieConsentStatus.pipe(
        map(status => status.categories.reduce((agg, next) => {
                agg[next.id] = next.value;
                return agg;
            }, {} as CookieCategoryStatuses)
        ),
    );

    public setCookieConsentStatus(status: CookieConsentStatus): void {
        this._cookieConsentStatus.next(status);
    }
}
