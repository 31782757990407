import {Inject, Injectable} from '@angular/core';
import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {APP_CONFIG, AppConfig} from '../app-config.module';

@Injectable({ providedIn: 'root' })
export class FinMatchValidators {
    textStringValidator: ValidatorFn = Validators.compose([Validators.minLength(2), Validators.maxLength(100)]);
    shortTextStringValidator: Validators = Validators.compose([Validators.minLength(3), Validators.maxLength(20)]);
    mediumTextStringValidator: Validators = Validators.compose([Validators.minLength(3), Validators.maxLength(30)]);
    customerSearchValidator: Validators = Validators.compose([Validators.minLength(3), Validators.maxLength(50)]);
    yearValidator: Validators = Validators.compose([this.yearOfEstValidator()]);
    phoneNumberValidator: Validators = Validators.compose([this.phoneValidator()]);
    emailValidator: Validators = Validators.compose([Validators.pattern(this.config.regexPatterns.email)]);
    bicValidator: Validators = Validators.compose([Validators.pattern('^[a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?$')]);
    numeric: Validators = Validators.compose([Validators.pattern('^[0-9]*$')]);
    financingPlans = Validators.compose([Validators.required, Validators.maxLength(700)]);

    houseNumberValidator: ValidatorFn = Validators.compose([
        Validators.maxLength(5),
        Validators.pattern('^[ A-Za-z0-9_@./#&+-]*$')
    ]);

    postalCodeOptionalValidator5: ValidatorFn = Validators.compose([
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern('^[0-9]*$')
    ]);
    postalCodeOptionalValidator4: ValidatorFn = Validators.compose([
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern('^[0-9]*$')
    ]);

    postalCodeValidator5: ValidatorFn = Validators.compose([this.postalCodeOptionalValidator5, Validators.required]);

    postalCodeValidator4: ValidatorFn = Validators.compose([this.postalCodeOptionalValidator4, Validators.required]);

    passwordValidator: Validators = Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(128),
        Validators.pattern(this.config.regexPatterns.password)
    ]);

    // Inquiry module contents
    quantity3: Validators = Validators.compose([Validators.min(1), Validators.max(999), this.num(3, 0)]);
    quantity4: Validators = Validators.compose([Validators.min(1), Validators.max(9999), this.num(4, 0)]);
    area7: Validators = Validators.compose([Validators.min(0), Validators.max(9999999), this.num(7, 0)]);
    area9: Validators = Validators.compose([Validators.min(1), Validators.max(999999999), this.num(9, 0)]);
    ageOrYearsLimit: Validators = Validators.compose([Validators.min(1), Validators.max(99), this.num(2, 0)]);
    remainingTermLeaseHold: Validators = Validators.compose([Validators.min(1), Validators.max(99), this.num(2, 0)]);

    validationCrnLength = {DE: 9, AT: 7, CH: 13, LI: 12};

    constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

    yearOfEstValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            const currentYear = new Date().getFullYear();
            const isANumber = Number.isInteger(parseInt(control.value, 10));
            const isAValidYear = !!(control.value <= currentYear && control.value >= 1000 && isANumber);

            return isAValidYear ? null : { invalidYear: { value: control.value } };
        };
    }

    percent(decimalDigits: number = 0): Validators {
        return Validators.compose([Validators.min(0), Validators.max(100), this.num(3, decimalDigits)]);
    }

    price(allowZero: boolean = true): ValidatorFn {
        return Validators.compose([Validators.min(allowZero ? 0 : 1), Validators.max(999999999999), this.num(12, 0)]);
    }

    websiteUrlValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            const isValidURL: boolean = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
                control.value
            );

            return isValidURL ? null : { invalidURL: { value: control.value } };
        };
    }

    checkboxValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return !!control.value ? null : { requiredCheckbox: { value: control.value } };
        };
    }

    crnValidator(country: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            let crnIsValid: boolean;

            switch (country) {
                case 'DE':
                    crnIsValid = !!/^[0-9]{1,6}$/.test(control.value);
                    break;
                case 'AT':
                    crnIsValid = !!/^[0-9]{1,6}[a-zA-Z]{2,2}$/.test(control.value);
                    break;
                case 'CH':
                    crnIsValid = !!/^[0-9]{3,3}[.][0-9]{3,3}[.][0-9]{3,3}$/.test(control.value);
                    break;
                case 'LI':
                    crnIsValid = !!/^[0-9]{1,12}$/.test(control.value);
                    break;
                default:
                    crnIsValid = false;
                    break;
            }

            return crnIsValid ? null : { invalidCRN: { value: control.value } };
        };
    }

    bankCodeValidator(country: string): ValidatorFn {
        switch (country) {
            case 'DE':
                return Validators.pattern(/^[0-9]{8}$/);
            case 'AT':
            case 'CH':
            case 'LI': //TODO: confirmation required
                return Validators.pattern(/^[0-9]{5}$/);
            default:
                return Validators.pattern(/^[0-9]*$/);
        }
    }

    phoneValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return { required: { value: true } };
            } else {
                return !!(/^(\+{0,1})(?=.*[0-9])[- ()0-9]+$/.test(control.value) && control.value.length >= 7)
                    ? null
                    : { invalidPhoneNumber: { value: control.value } };
            }
        };
    }

    futureDate(allowToday = false): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            let currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            let controlDate = new Date(control.value);
            let isFutureDate = allowToday ? controlDate.getTime() >= currentDate.getTime() : controlDate.getTime() > currentDate.getTime() ;
            return isFutureDate ? null : { futureDate: { value: control.value } };
        };
    }

    num(integerDigits: number, decimalDigits: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            let abs = Math.abs(control.value);
            let parts = abs.toString().split('.');

            if (parts[0].length > integerDigits || (parts[1] && parts[1].length > decimalDigits)) {
                return { num: { value: control.value } };
            }

            return null;
        };
    }

    lessThanOrEq(formControlName: string): ValidatorFn {
        let prevValue;
        return (control: AbstractControl): { [key: string]: any } | null => {
            const maxControl = control.parent && control.parent.get(formControlName);
            if (!maxControl) {
                return null;
            }

            const max = parseFloat(maxControl.value);
            const controlValue = parseFloat(control.value);

            if (isNaN(max) || isNaN(controlValue) || controlValue <= max) {
                if (control.value !== prevValue) {
                    prevValue = control.value;
                    maxControl.updateValueAndValidity();
                }
                return null;
            } else {
                return { lessThanOrEq: { value: control.value } };
            }
        };
    }

    greaterThanOrEq(formControlName: string): ValidatorFn {
        let prevValue;
        return (control: AbstractControl): { [key: string]: any } | null => {
            const minControl = control.parent && control.parent.get(formControlName);
            if (!minControl) {
                return null;
            }

            const min = parseFloat(minControl.value);
            const controlValue = parseFloat(control.value);

            if (isNaN(min) || isNaN(controlValue) || controlValue >= min) {
                if (control.value !== prevValue) {
                    prevValue = control.value;
                    minControl.updateValueAndValidity();
                }
                return null;
            } else {
                return { greaterThanOrEq: { value: control.value } };
            }
        };
    }

    equityInvestmentValidator(investmentVolume: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value && (control.value >= investmentVolume)) {
                return { equityBiggerThanVolume: { value: control.value } }
            }
            return null;
        };
    }

    repaymentStartDateValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.parent) {
                return null;
            }

            const dateOfDisbursement = control.parent.get('dateOfDisbursement').value;

            if (!dateOfDisbursement) {
                return null;
            }

            const dateOfDisbursementTime = new Date(dateOfDisbursement);
            const plannedRepaymentStartDateTime = new Date(control.value);

            if (control.value && (dateOfDisbursementTime.getTime() > plannedRepaymentStartDateTime.getTime())) {
                return { repaymentLowerThanDisbursement: { value: control.value } }
            }
            return null;
        };
    }

    public collateralIndividualValidator: ValidatorFn = (form: UntypedFormGroup): ValidationErrors | null => {
        if (!form.controls.collateral || form.controls.collateral.value === 'BANK_STANDARD') {
            return null;
        }

        const collateralFields = ['cessionCollateral', 'mortgageCollateral', 'personalGuaranteeCollateral', 'chattelMortgageCollateral', 'pledgeAgreementCollateral', 'otherCollateral'];
        const isAnySelected = collateralFields.some(fieldName => {
            return form.controls[fieldName].value === true;
        });

        if (!isAnySelected) {

            return {collateralNotChosen: true}
        }

        return null;
    };

    fixedInterestRatePeriodVsDuration(form: AbstractControl) {
        if (
            !form.value.duration
            || !form.value.durationUnit
            || !(form.value.fixedInterestRatePeriodValue && form.value.fixedInterestRatePeriodValue.enabled)
            || !(form.value.fixedInterestRatePeriodUnit && form.value.fixedInterestRatePeriodUnit.enabled)
        ) {
            return null;
        }

        const durationMonths = form.value.durationUnit === 'YEARS' ? form.value.duration * 12 : form.value.duration;
        const fixedInterestRatePeriodValueMonths = form.value.fixedInterestRatePeriodUnit === 'YEARS' ? form.value.fixedInterestRatePeriodValue * 12 : form.value.fixedInterestRatePeriodValue;

        if (fixedInterestRatePeriodValueMonths > durationMonths) {
            return {fixedInterestHigherThanDuration: true};
        }

        return null;
    }

    allowanceForDepreciationVsDuration(form: AbstractControl) {
        if (
            !form.value.duration
            || !form.value.durationUnit
            || !(form.value.allowanceForDepreciation && form.value.allowanceForDepreciation.enabled)
            || !(form.value.allowanceForDepreciationUnit && form.value.allowanceForDepreciationUnit.enabled)
        ) {
            return null;
        }

        const durationMonths = form.value.durationUnit === 'YEARS' ? form.value.duration * 12 : form.value.duration;
        const allowanceForDepreciationMonths = form.value.allowanceForDepreciationUnit === 'YEARS' ? form.value.allowanceForDepreciation * 12 : form.value.allowanceForDepreciation;

        if (allowanceForDepreciationMonths > durationMonths) {
            return {allowanceForDepreciationHigherThanDuration: true};
        }

        return null;
    }

    dateOfDisbursementValidator(inquiryCreateDate: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.parent || !control.value) {
                return null;
            }
            const dateOfDisbursementTime = new Date(control.value);
            const inquiryCreation = new Date(inquiryCreateDate);
            if (dateOfDisbursementTime.getTime() <= inquiryCreation.getTime()) {
                return { disbursementBeforeInquiryCreation: { value: control.value } }
            }

            const plannedRepaymentStartDate = control.parent.get('plannedRepaymentStartDate') && control.parent.get('plannedRepaymentStartDate').value;
            if (!plannedRepaymentStartDate) {
                return null;
            }

            const plannedRepaymentStartDateTime = new Date(plannedRepaymentStartDate);

            if (dateOfDisbursementTime.getTime() > plannedRepaymentStartDateTime.getTime()) {
                return { disbursementBiggerThanRepayment: { value: control.value } }
            }
            return null;
        };
    }
}
