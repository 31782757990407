import {Inject, Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {APP_CONFIG, AppConfig} from '../../app-config.module';
import {BuildingType, Inquiry, InquiryModuleKey} from '../../models/InquiryModel';
import {FinancialStatementTypes} from '../../repositories/financial-statements/models/financial-statement-types.enum';

const Day = (60 * 60 * 24);
export const InquiryOverdueLimits = {
    DRAFT: (Day * 5),
    REVISION: (Day * 2),
    TO_ASSIGN: (Day * 1),
    ASSIGNED: (Day * 10),
    ACCEPTED: (Day * 1),
    BANK_SELECTION: (Day * 2),
    ACTIVE: (Day * 10),
    // BANK_RESPONSE_COMPLETED: (Day*2),
    MATCHING_FAILED: 0,
    WAITING_FOR_FINMATCH: (Day * 1),
    SIGNING_OF_CONTRACTS: (Day * 14),
    REVIEW_OFFER_SELECTION: (Day * 1),
    REVIEW_SIGNING: (Day * 14),
};
export enum FinancingType {
    LOAN = 'LOAN',
    CREDIT_LINE = 'CREDIT_LINE',
    LEASING = 'LEASING',
    REAL_ESTATE_LEASING = 'REAL_ESTATE_LEASING',
    INSTALLMENT_PURCHASE = 'INSTALLMENT_PURCHASE',
    INSTALLMENT_PURCHASE_LOAN = 'INSTALLMENT_PURCHASE_LOAN',
    GUARANTEE = 'GUARANTEE',
    FACTORING = 'FACTORING',
}
export enum FinancingTypeFastLane {
    FAST_LANE = 'FAST_LANE'
}

export const InquiryModulesMultipleItems: {[moduleKey in InquiryModuleKey]: (inquiry: Inquiry) => boolean} = {
    machine: (inquiry) => inquiry.getModuleMode('machine') === 'QUICK'
        || inquiry.getModuleMetadataDetails('machine').isAggregated === false,
    vehicle: () => true,
    realEstate: () => true,
    property: () => true,
    workingCapital: () => false,
    other: () => false,
    fastLane: () => false,
};

export const InquiryModuleCollectionProperty: {[moduleKey in InquiryModuleKey]: string} = {
    machine: 'machines',
    vehicle: 'vehicles',
    realEstate: 'realEstates',
    property: 'properties',
    workingCapital: 'workingCapitals',
    other: 'others',
    fastLane: 'fastLanes',
};


export enum RealEstateModuleType {
    NEW_BUILDING = 'NEW_BUILDING',
    EXISTING_BUILDING = 'EXISTING_BUILDING',
    RENOVATION = 'RENOVATION',
    FOLLOWUP_FINANCING = 'FOLLOWUP_FINANCING',
}
export type PropertyModuleType = 'EMPTY' | 'PURCHASE' | 'FOLLOWUP_FINANCING';
export type InquiryModuleType = RealEstateModuleType | PropertyModuleType;

type AvailableModuleType = InquiryModuleType | 'generic';

export type MachineModuleMode = 'EMPTY' | 'QUICK' | 'EXPERT';
export type RealEstateModuleMode = 'EMPTY' | 'QUICK' | 'EXPERT';
export type InquiryModuleMode = MachineModuleMode | RealEstateModuleMode;

export type DocumentFileClass =
    | 'Inquiry__Machine'
    | 'Inquiry__Vehicle'
    | 'Inquiry__Real_Estate__New_Building'
    | 'Inquiry__Real_Estate__Existing_Building'
    | 'Inquiry__Real_Estate__Renovation'
    | 'Inquiry__Real_Estate__Follow_Up_Financing'
    | 'Inquiry__Property__Purchase'
    | 'Inquiry__Property__Follow_Up_Financing'
    | 'Inquiry__Working_Capital'
    | 'Inquiry__Other'
    | 'Inquiry__Fast_Lane'
    | 'Inquiry__Financial_Statement'
    | 'Corporate__Profile_Other_Documents'
    | 'Offer__Document'
    | 'Inquiry__Teaser'
    | 'Bank_Rejection__Document'
    | 'Inquiry__Revision'
    | 'Inquiry__Summary';

export const BuildingTypes: BuildingType[] = [
    {
        value: 'COMMERCIAL_BUILDING',
        subTypes: [
            { value: 'ADMINISTRATION' },
            { value: 'PRODUCTION_LOGISTIC' },
            { value: 'HOTEL' },
            { value: 'RETAIL' },
            { value: 'HOSPITALS_HEALTHCARE' },
            { value: 'MEDICAL_CENTER' },
            { value: 'CARE_HOME' },
            { value: 'OTHER' }
        ]
    },
    { value: 'MIXED_USE' },
    { value: 'RESIDENTIAL_USE' }
];
export const BuildingTypesV2: BuildingType[] = [
    { value: 'COMMERCIAL_BUILDING' },
    { value: 'MIXED_USE' },
    { value: 'RESIDENTIAL_USE' }
];

export enum BuildingClassification {
    PROJECT_FINANCING = 'PROJECT_FINANCING',
    FINANCING_OF_PROPERTY_DEVELOPER = 'FINANCING_OF_PROPERTY_DEVELOPER',
    INCOME_PROPERTY = 'INCOME_PROPERTY',
    HOTEL = 'HOTEL',
    PLANT = 'PLANT',
    APPORTIONMENT = 'APPORTIONMENT',
}
export const BuildingTypesQuickMode: BuildingType[] = [
    { value: 'COMMERCIAL_USE' },
    { value: 'RESIDENTIAL_USE' },
    { value: 'MIXED_USE' }
];

@Injectable({ providedIn: 'root' })
export class InquiryConfigurationService {
    buildingClassificationOptions: Array<{value: BuildingClassification, viewValue: string}> = [
        { value: BuildingClassification.PROJECT_FINANCING, viewValue: $localize`Project financing` },
        { value: BuildingClassification.FINANCING_OF_PROPERTY_DEVELOPER, viewValue: $localize`Financing of property developer` },
        { value: BuildingClassification.INCOME_PROPERTY, viewValue: $localize`Income property` },
        { value: BuildingClassification.HOTEL, viewValue: $localize`Hotel` },
        { value: BuildingClassification.PLANT, viewValue: $localize`Plant` },
        { value: BuildingClassification.APPORTIONMENT, viewValue: $localize`Apportionment` },
    ];

    statusOverdueComments: any = {
        DRAFT: $localize`The company did not edit this draft for at least 5 days.`,
        REVISION: $localize`The inquiry was given back to the company for further editing. It was not resend for at least 48h.`,
        TO_ASSIGN: $localize`The inquiry has not been assigned during the last 24 hours. Please assign.`,
        ASSIGNED: $localize`The processing of this inquiry already takes more than 10 days. Please check the current status.`,
        ACCEPTED: $localize`The bank list has not been sent for at least 24 hours. Please send bank list.`,
        BANK_SELECTION: $localize`The company received the bank list but did not send a bank selection for at least 48 hours.`,
        ACTIVE: $localize`The inquiry is active for at least 10 days. If there are banks that did not send an offer yet, reminder as appropriate.`,
        MATCHING_FAILED: $localize`All proposed banks rejected the inquiry. Please propose further banks to corporate.`,
        WAITING_FOR_FINMATCH: $localize`The offer selection of the company has not been approved.`,
        SIGNING_OF_CONTRACTS: $localize`The credit contracts are not signed for 14 days.`,
        REVIEW_OFFER_SELECTION: $localize`The offer selection of the corporate has not been assessed by FinMatch for at least 24 hours.`,
        REVIEW_SIGNING:  $localize`The successful matching of this inquiry proceeded at least 14 days ago and yet no credit agreement has been signed. Please check current status.`
    };

    documentClasses_machine: any[] = [
        { value: 'PROPOSAL', viewValue: $localize`Proposals` },
        { value: 'INVOICE', viewValue: $localize`Invoices` },
        { value: 'SALES_CONTRACT', viewValue: $localize`Sales contracts` },
        { value: 'PRODUCT_DATASHEET', viewValue: $localize`Product datasheets` },
        { value: 'LIST_OF_MACHINES_INCLUDING_PRICES', viewValue: $localize`List of machines including prices` },
        { value: 'CERTIFICATE_OF_INSURANCE', viewValue: $localize`Certificate of insurance` },
        { value: 'OTHER', viewValue: $localize`Other` }
    ];

    documentClasses_vehicle: any[] = [
        { value: 'PROPOSAL', viewValue: $localize`Proposal` },
        { value: 'SALES_CONTRACT', viewValue: $localize`Sales contract` },
        { value: 'REGISTRATION_DOCUMENT', viewValue: $localize`Licence certificate part I (registration document)` },
        {
            value: 'IDENTIFICATION_DOCUMENT',
            viewValue: $localize`Licence certificate part II (identification document)`
        },
        { value: 'CONFIRMATION_OF_INSURANCE', viewValue: $localize`Confirmation of insurance` },
        { value: 'INVOICE', viewValue: $localize`Invoice` },
        { value: 'OTHER', viewValue: $localize`Other` }
    ];

    documentClasses_realEstate_newBuilding: any[] = [
        { value: 'NEW_BUILDING__SALES_CONTRACT', viewValue: $localize`Sales contract / draft sales contract` },
        { value: 'NEW_BUILDING__STATEMENT_OF_COSTS', viewValue: $localize`Statement of costs` },
        { value: 'NEW_BUILDING__BUILDING_CONTRACT', viewValue: $localize`Building contract` },
        {
            value: 'NEW_BUILDING__CURRENT_EXTRACT_FROM_THE_LAND_REGISTER',
            viewValue: $localize`Current extract from the land register`
        },
        {
            value: 'NEW_BUILDING__INFORMATION_FROM_FILES_OF_THE_LAND_REGISTER',
            viewValue: $localize`Information from files of the land register`
        },
        { value: 'NEW_BUILDING__BUILDING_APPLICATION', viewValue: $localize`Building application` },
        {
            value: 'NEW_BUILDING__PLANNING_DOCUMENTS',
            viewValue: $localize`Planning documents`
        },
        { value: 'NEW_BUILDING__LAND_USE_PLAN', viewValue: $localize`Land use plan` },
        { value: 'NEW_BUILDING__ZONING_PLAN', viewValue: $localize`Zoning plan` },
        { value: 'NEW_BUILDING__CADASTRAL_MAP', viewValue: $localize`Cadastral map` },
        {
            value: 'NEW_BUILDING__REGISTER_OF_CONTAMINATED_SITES',
            viewValue: $localize`Register of contaminated sites`
        },
        { value: 'NEW_BUILDING__PUBLIC_EASEMENT_DIRECTORY', viewValue: $localize`Public easement directory` },
        { value: 'NEW_BUILDING__PICTURES_OF_THE_REAL_ESTATE', viewValue: $localize`Pictures of the real estate` },
        { value: 'NEW_BUILDING__CONFIRMATION_OF_INSURANCE', viewValue: $localize`Confirmation of insurance` },
        { value: 'NEW_BUILDING__LEASE_AGREEMENTS', viewValue: $localize`Lease agreements` },
        { value: 'NEW_BUILDING__DECLARATION_OF_CONDOMINIUM', viewValue: $localize`Declaration of condominium` },
        { value: 'NEW_BUILDING__OTHER', viewValue: $localize`Other` },
    ];

    documentClasses_realEstate_existingBuilding: any[] = [
        { value: 'EXISTING_BUILDING__SALES_CONTRACT', viewValue: $localize`Sales contract / draft sales contract` },
        { value: 'EXISTING_BUILDING__REAL_ESTATE_VALUATION', viewValue: $localize`Real estate valuation` },
        {
            value: 'EXISTING_BUILDING__CURRENT_EXTRACT_FROM_THE_LAND_REGISTER',
            viewValue: $localize`Current extract from the land register`
        },
        {
            value: 'EXISTING_BUILDING__INFORMATION_FROM_FILES_OF_THE_LAND_REGISTER',
            viewValue: $localize`Information from files of the land register`
        },
        { value: 'EXISTING_BUILDING__BUILDING_APPLICATION', viewValue: $localize`Building application` },
        {
            value: 'EXISTING_BUILDING__PLANNING_DOCUMENTS',
            viewValue: $localize`Planning documents`
        },
        { value: 'EXISTING_BUILDING__LAND_USE_PLAN', viewValue: $localize`Land use plan` },
        { value: 'EXISTING_BUILDING__ZONING_PLAN', viewValue: $localize`Zoning plan` },
        { value: 'EXISTING_BUILDING__CADASTRAL_MAP', viewValue: $localize`Cadastral map` },
        {
            value: 'EXISTING_BUILDING__REGISTER_OF_CONTAMINATED_SITES',
            viewValue: $localize`Register of contaminated sites`
        },
        { value: 'EXISTING_BUILDING__PUBLIC_EASEMENT_DIRECTORY', viewValue: $localize`Public easement directory` },
        {
            value: 'EXISTING_BUILDING__PICTURES_OF_THE_REAL_ESTATE',
            viewValue: $localize`Pictures of the real estate`
        },
        { value: 'EXISTING_BUILDING__CONFIRMATION_OF_INSURANCE', viewValue: $localize`Confirmation of insurance` },
        { value: 'EXISTING_BUILDING__LEASE_AGREEMENTS', viewValue: $localize`Lease agreements` },
        { value: 'EXISTING_BUILDING__DECLARATION_OF_CONDOMINIUM', viewValue: $localize`Declaration of condominium` },
        { value: 'EXISTING_BUILDING__OTHER', viewValue: $localize`Other` }
    ];

    documentClasses_realEstate_renovation: any[] = [
        { value: 'RENOVATION__STATEMENT_OFT_COSTS', viewValue: $localize`Statement of costs` },
        { value: 'RENOVATION__BUILDING_CONTRACT', viewValue: $localize`Building contract` },
        {
            value: 'RENOVATION__CURRENT_EXTRACT_FROM_THE_LAND_REGISTER',
            viewValue: $localize`Current extract from the land register`
        },
        {
            value: 'RENOVATION__INFORMATION_FROM_FILES_OF_THE_LAND_REGISTER',
            viewValue: $localize`Information from files of the land register`
        },
        { value: 'RENOVATION__BUILDING_APPLICATION', viewValue: $localize`Building application` },
        {
            value: 'RENOVATION__PLANNING_DOCUMENTS',
            viewValue: $localize`Planning documents`
        },
        { value: 'RENOVATION__LAND_USE_PLAN', viewValue: $localize`Land use plan` },
        { value: 'RENOVATION__ZONING_PLAN', viewValue: $localize`Zoning plan` },
        { value: 'RENOVATION__CADASTRAL_MAP', viewValue: $localize`Cadastral map` },
        { value: 'RENOVATION__REGISTER_OF_CONTAMINATED_SITES', viewValue: $localize`Register of contaminated sites` },
        { value: 'RENOVATION__PUBLIC_EASEMENT_DIRECTORY', viewValue: $localize`Public easement directory` },
        { value: 'RENOVATION__PICTURES_OF_THE_REAL_ESTATE', viewValue: $localize`Pictures of the real estate` },
        { value: 'RENOVATION__CONFIRMATION_OF_INSURANCE', viewValue: $localize`Confirmation of insurance` },
        { value: 'RENOVATION__LEASE_AGREEMENTS', viewValue: $localize`Lease agreements` },
        { value: 'RENOVATION__DECLARATION_OF_CONDOMINIUM', viewValue: $localize`Declaration of condominium` },
        { value: 'RENOVATION__OTHER', viewValue: $localize`Other` }
    ];

    documentClasses_realEstate_followUpFinancing: any[] = [
        { value: 'FOLLOW_UP_FINANCING__SALES_CONTRACT', viewValue: $localize`Sales contract` },
        { value: 'FOLLOW_UP_FINANCING__REAL_ESTATE_VALUATION', viewValue: $localize`Real estate valuation` },
        {
            value: 'FOLLOW_UP_FINANCING__CURRENT_EXTRACT_FROM_THE_LAND_REGISTER',
            viewValue: $localize`Current extract from the land register`
        },
        {
            value: 'FOLLOW_UP_FINANCING__INFORMATION_FROM_FILES_OF_THE_LAND_REGISTER',
            viewValue: $localize`Information from files of the land register`
        },
        { value: 'FOLLOW_UP_FINANCING__BUILDING_APPLICATION', viewValue: $localize`Building application` },
        {
            value: 'FOLLOW_UP_FINANCING__PLANNING_DOCUMENTS',
            viewValue: $localize`Planning documents`
        },
        { value: 'FOLLOW_UP_FINANCING__LAND_USE_PLAN', viewValue: $localize`Land use plan` },
        { value: 'FOLLOW_UP_FINANCING__ZONING_PLAN', viewValue: $localize`Zoning plan` },
        { value: 'FOLLOW_UP_FINANCING__CADASTRAL_MAP', viewValue: $localize`Cadastral map` },
        {
            value: 'FOLLOW_UP_FINANCING__REGISTER_OF_CONTAMINATED_SITES',
            viewValue: $localize`Register of contaminated sites`
        },
        { value: 'FOLLOW_UP_FINANCING__PUBLIC_EASEMENT_DIRECTORY', viewValue: $localize`Public easement directory` },
        {
            value: 'FOLLOW_UP_FINANCING__PICTURES_OF_THE_REAL_ESTATE',
            viewValue: $localize`Pictures of the real estate`
        },
        { value: 'FOLLOW_UP_FINANCING__CONFIRMATION_OF_INSURANCE', viewValue: $localize`Confirmation of insurance` },
        { value: 'FOLLOW_UP_FINANCING__LEASE_AGREEMENTS', viewValue: $localize`Lease agreements` },
        { value: 'FOLLOW_UP_FINANCING__DECLARATION_OF_CONDOMINIUM', viewValue: $localize`Declaration of condominium` },
        { value: 'FOLLOW_UP_FINANCING__OTHER', viewValue: $localize`Other` },
    ];

    documentClasses_property_purchase: any[] = [
        { value: 'PURCHASE__SALES_CONTRACT', viewValue: $localize`Sales contract / draft sales contract` },
        {
            value: 'PURCHASE__CURRENT_EXTRACT_FROM_THE_LAND_REGISTER',
            viewValue: $localize`Current extract from the land register`
        },
        {
            value: 'PURCHASE__INFORMATION_FROM_FILES_OF_THE_LAND_REGISTER',
            viewValue: $localize`Information from files of the land register`
        },
        { value: 'PURCHASE__CALCULATION_OF_AREA', viewValue: $localize`Calculation of area` },
        { value: 'PURCHASE__LAND_USE_PLAN', viewValue: $localize`Land use plan` },
        { value: 'PURCHASE__ZONING_PLAN', viewValue: $localize`Zoning plan` },
        { value: 'PURCHASE__CADASTRAL_MAP', viewValue: $localize`Cadastral map` },
        { value: 'PURCHASE__REGISTER_OF_CONTAMINATED_SITES', viewValue: $localize`Register of contaminated sites` },
        { value: 'PURCHASE__PUBLIC_EASEMENT_DIRECTORY', viewValue: $localize`Public easement directory` },
        { value: 'PURCHASE__OTHER', viewValue: $localize`Other` },
    ];

    documentClasses_property_followUpFinancing: any[] = [
        {
            value: 'FOLLOW_UP_FINANCING__CONFIRMATION_OF_REDEMPTION_BY_FOLLOW_UP_FINANCING_INSTITUTION',
            viewValue: $localize`Confirmation of redemption by Follow Up Financing Institution`
        },
        {
            value: 'FOLLOW_UP_FINANCING__CURRENT_EXTRACT_FROM_THE_LAND_REGISTER',
            viewValue: $localize`Current extract from the land register`
        },
        {
            value: 'FOLLOW_UP_FINANCING__INFORMATION_FROM_FILES_OF_THE_LAND_REGISTER',
            viewValue: $localize`Information from files of the land register`
        },
        { value: 'FOLLOW_UP_FINANCING__CALCULATION_OF_AREA', viewValue: $localize`Calculation of area` },
        { value: 'FOLLOW_UP_FINANCING__LAND_USE_PLAN', viewValue: $localize`Land use plan` },
        { value: 'FOLLOW_UP_FINANCING__ZONING_PLAN', viewValue: $localize`Zoning plan` },
        { value: 'FOLLOW_UP_FINANCING__CADASTRAL_MAP', viewValue: $localize`Cadastral map` },
        {
            value: 'FOLLOW_UP_FINANCING__REGISTER_OF_CONTAMINATED_SITES',
            viewValue: $localize`Register of contaminated sites`
        },
        { value: 'FOLLOW_UP_FINANCING__PUBLIC_EASEMENT_DIRECTORY', viewValue: $localize`Public easement directory` }
    ];

    documentClasses_workingCapital: any[] = [
        { value: 'ACCOUNTS_RECEIVABLE', viewValue: $localize`Accounts receivable` },
        { value: 'ACCOUNTS_PAYABLE', viewValue: $localize`Accounts payable` },
        {
            value: 'LIST_OF_RECEIVABLES_OVERDUE_AND_IRREVOCABLE',
            viewValue: $localize`List of receivables overdue and irrevocable`
        },
        { value: 'STOCK_LIST', viewValue: $localize`Stock list` },
        { value: 'SITE_PLAN_POSITION', viewValue: $localize`Site plan position` },
        {
            value: 'LIST_OF_RESERVATION_OF_PROPRIETARY_RIGHTS',
            viewValue: $localize`List of reservation of proprietary rights`
        },
        { value: 'OTHER', viewValue: $localize`Other` }
    ];

    documentClasses_other: any[] = [
        { value: 'DOCUMENT', viewValue: $localize`Documents` },
    ];

    documentClasses_fastLane: any[] = [
        { value: 'DOCUMENT', viewValue: $localize`Documents` },
    ];

    documentClasses_financialStatements: any[] = [
        {
            value: FinancialStatementTypes.CURRENT_BUSINESS_ASSESSMENT,
            viewValue: $localize`Current business assessment`,
            required: true,
        },
        {
            value: FinancialStatementTypes.ANNUAL_REPORT_LAST_FINANCIAL_YEAR,
            viewValue: $localize`Annual report last financial year`,
            required: true,
        },
        {
            value: FinancialStatementTypes.ANNUAL_REPORT_SECOND_TO_LAST_FINANCIAL_YEAR,
            viewValue: $localize`Annual report second to last financial year`,
            required: true,
        },
        {
            value: FinancialStatementTypes.PLANNING_DOCUMENTS,
            viewValue: $localize`Planning documents`,
            // viewValue: this.i18n({
            //     value: 'Planning documents',
            //     description: "Occurrence in the Financial Statements Section",
            //     meaning: "DE: Plandaten"
            // }),
        },
        { value: FinancialStatementTypes.OTHER_COMPANY_DOCUMENTS, viewValue: $localize`Other company documents` }
    ];

    financingTypesPerModule: {[key in InquiryModuleKey]: {[key in (AvailableModuleType | string)]: FinancingType[]}} = {
        machine: {generic: [FinancingType.LEASING, FinancingType.INSTALLMENT_PURCHASE]},
        vehicle: {generic: [FinancingType.LEASING, FinancingType.INSTALLMENT_PURCHASE]},
        workingCapital: {generic: [FinancingType.LOAN, FinancingType.CREDIT_LINE, FinancingType.GUARANTEE, FinancingType.FACTORING]},
        other: {generic: [FinancingType.LOAN, FinancingType.CREDIT_LINE, FinancingType.INSTALLMENT_PURCHASE, FinancingType.GUARANTEE, FinancingType.FACTORING]},
        fastLane: {generic: [FinancingType.LOAN, FinancingType.CREDIT_LINE, FinancingType.INSTALLMENT_PURCHASE, FinancingType.GUARANTEE, FinancingType.FACTORING]},
        property: {
            PURCHASE: [FinancingType.LOAN],
            FOLLOWUP_FINANCING: [FinancingType.LOAN],
        },
        realEstate: {
            NEW_BUILDING: [FinancingType.LOAN, FinancingType.CREDIT_LINE, FinancingType.REAL_ESTATE_LEASING],
            EXISTING_BUILDING: [FinancingType.LOAN, FinancingType.CREDIT_LINE, FinancingType.REAL_ESTATE_LEASING],
            RENOVATION: [FinancingType.LOAN, FinancingType.REAL_ESTATE_LEASING],
            FOLLOWUP_FINANCING: [FinancingType.LOAN, FinancingType.REAL_ESTATE_LEASING],
        }
    };

    constructor(
        @Inject(APP_CONFIG) private config: AppConfig
    ) {}

    requiredFinancialStatementTypes: FinancialStatementTypes[] = this.documentClasses_financialStatements
        .filter(statement => !!statement.required)
        .map(statement => statement.value);

    getDocumentTypes(moduleKey: InquiryModuleKey, moduleType?: string): any {
        switch (true) {
            case moduleKey === 'machine':
                return this.documentClasses_machine;
            case moduleKey === 'vehicle':
                return this.documentClasses_vehicle;
            case moduleKey === 'realEstate' && moduleType === RealEstateModuleType.NEW_BUILDING:
                return this.documentClasses_realEstate_newBuilding;
            case moduleKey === 'realEstate' && moduleType === RealEstateModuleType.EXISTING_BUILDING:
                return this.documentClasses_realEstate_existingBuilding;
            case moduleKey === 'realEstate' && moduleType === RealEstateModuleType.RENOVATION:
                return this.documentClasses_realEstate_renovation;
            case moduleKey === 'realEstate' && moduleType === RealEstateModuleType.FOLLOWUP_FINANCING:
                return this.documentClasses_realEstate_followUpFinancing;
            case moduleKey === 'property' && moduleType === 'PURCHASE':
                return this.documentClasses_property_purchase;
            case moduleKey === 'property' && moduleType === 'FOLLOWUP_FINANCING':
                return this.documentClasses_property_followUpFinancing;
            case moduleKey === 'workingCapital':
                return this.documentClasses_workingCapital;
            case moduleKey === 'other':
                return this.documentClasses_other;
            case moduleKey === 'fastLane':
                return this.documentClasses_fastLane;
            default:
                return null;
        }
    }

    getFinancialStatementsDocumentTypes() {
        return this.documentClasses_financialStatements;
    }

    hasFormOldBuildingType(form: UntypedFormGroup): boolean {
        return form.value.buildingType && !form.value.classification;
    }
}
