import {Router} from '@angular/router';
import {UsageAgreementService} from '../../../../../services/bank/usage-agreement.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Principal} from 'src/app/services/auth/principal.service';
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {AuthServerProvider} from 'src/app/services/auth/auth-jwt.service';
import {from, Observable, of} from 'rxjs';
import {UntypedFormBuilder} from '@angular/forms';
import {catchError, switchMap, take} from 'rxjs/operators';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {iframeResize} from 'iframe-resizer';
import {Inquiry} from '../../../../../models/InquiryModel';
// import print from 'print-js';

@Component({
    selector: 'app-usage-agreement-dialog',
    templateUrl: './usage-agreement-dialog.component.html',
    styleUrls: ['./usage-agreement-dialog.component.less']
})
export class UsageAgreementDialogComponent implements OnInit {
    @ViewChild('agreementContent') agreementContent: ElementRef;

    public bankData: any;
    public agreementType: 'policy' | 'usage';
    public otuaData: any;
    public spinnerVisible = false;
    public inquiry: Inquiry | null;
    public shouldPolicyBeShown = false;
    public otuaUrl: SafeResourceUrl;
    public reWatch = false;
    public readOnly = false;
    public policyForm = this.formBuilder.group({
        isAgreed: this.formBuilder.control(false, [
            control => control.value === true ? null : {checkedRequired: true}
        ]),
    });

    public dataPolicyHtml$: Observable<string>;

    constructor(
        private dialogRef: MatDialogRef<UsageAgreementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        public principal: Principal,
        public usageAgreementService: UsageAgreementService,
        public router: Router,
        public authServerProvider: AuthServerProvider,
        private formBuilder: UntypedFormBuilder,
        private domSanitizer: DomSanitizer,
    ) {
        this.agreementType = this.data.agreementType;
        this.reWatch = this.data.reWatch;
        this.readOnly = this.data.readOnly;
        if (this.agreementType === 'usage') {
            this.inquiry = this.data.inquiry;
            this.bankData = this.data.bank;
        }
    }

    resize() {
        iframeResize({ checkOrigin: false }, '#otuaIframe');
        this.spinnerVisible = false;
    }

    ngOnInit() {
        switch (this.agreementType) {
            case 'policy':
                this.dataPolicyHtml$ = this.usageAgreementService.getDataPolicyHtml();
                break;
            case 'usage':
                this.spinnerVisible = true;
                this.usageAgreementService.getOtuaUrl(this.inquiry, this.bankData, true).subscribe((otuaUrl: string) => {
                    if (otuaUrl?.includes('https://') && otuaUrl?.includes('amazonaws.com/pub-cdn-au.apitemplate.io')) {
                        this.otuaUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(otuaUrl);
                    } else {
                        this.readOnly = true;
                        this.spinnerVisible = false;
                    }
                }, err => {
                    this.readOnly = true;
                    this.spinnerVisible = false;
                });
                break;
        }
    }

    download() {
        this.spinnerVisible = true;
        this.usageAgreementService.getOtuaUrl(this.inquiry, this.bankData).subscribe((otuaUrl) => {
            window.open(otuaUrl, "_blank");
            this.spinnerVisible = false;
        });
    }

    // deprecated
    // printOtua(): void {
        // this.spinnerVisible = true;
        // this.usageAgreementService.getOtuaUrl(this.getOtuaData()).subscribe((otuaUrlResponse: OtuaUrlResponse) => {
        //     print({
        //         printable: otuaUrlResponse.download_url,
        //         type: 'pdf',
        //         showModal: true,
        //         modalMessage: $localize`Retrieving document`,
        //         onLoadingEnd: () => { this.spinnerVisible = false; }
        //     });
        // });
        // const agreementContent = this.agreementContent.nativeElement;
        // this.usageAgreementService.print(this.bankData, agreementContent, this.agreementType);
    // }
    // downloadUsageAgreement(): void {
    //     this.usageAgreementService.download(this.bankData, this.inquiry || null);
    // }

    deny(): void {
        this.dialogRef.close(false);
    }

    acceptUsageAgreement(): void {
        this.spinnerVisible = true;
        if (!this.inquiry) {
            this.usageAgreementService.acceptAgreement().subscribe((response) => {
                this.principal.identity(true).then(() => {
                    this.spinnerVisible = false;
                    this.dialogRef.close(true);
                });
            });
            return;
        }

        this.usageAgreementService.acceptOneTimeInquiry(this.inquiry, this.bankData).subscribe(() => {
            this.spinnerVisible = false;
            this.dialogRef.close(true);
        });
    }

    acceptPolicyAgreement(): void {
        this.spinnerVisible = true;
        this.usageAgreementService.acceptAgreement('policy')
            .pipe(
                take(1),
                switchMap(() => from(this.principal.fetchUserData())),
                catchError(() => {
                    this.spinnerVisible = false;
                    this.dialogRef.close(false);
                    return of(null);
                }),
            )
            .subscribe(() => {
                this.spinnerVisible = false;
                this.dialogRef.close(true);
            });
    }

    close() {
        this.dialogRef.close(null);
    }
}
