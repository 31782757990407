import {LayoutModule} from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ErrorHandler,
    Inject,
    Injectable,
    Injector,
    LOCALE_ID,
    MissingTranslationStrategy,
    NgModule,
    TRANSLATIONS,
    TRANSLATIONS_FORMAT
} from '@angular/core';
import {DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IdleExpiry} from '@ng-idle/core';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {InlineSVGModule} from 'ng-inline-svg';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {CookieService} from 'ngx-cookie-service';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {
    LocalStorageService,
    // NgxWebstorageModule,
    provideNgxWebstorage,
    SessionStorageService, withLocalStorage, withNgxWebstorageConfig,
    withSessionStorage
} from 'ngx-webstorage';
import {environment} from 'src/environments/environment';
import {APP_CONFIG, AppConfig, AppConfigModule} from './app-config.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent, COOKIE_EXPIRATION_DATE} from './app.component';
import {EventManagerService} from './services/event-manager.service';
import {FMDateAdapter} from './data/fm-data-adapter';
import {AuthExpiredInterceptor} from './interceptors/auth-expired.interceptor';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorHandlerInterceptor} from './interceptors/errorhandler.interceptor';
import {ErrorCodesTranslationService} from './services/root/translation/error-codes-translation.service';
import {WINDOW_PROVIDERS} from './services/window.service';
import {ErrorModule} from './modules/error/error.module';
import {FinmatchDashboardModule} from './modules/finmatch-dashboard/finmatch-dashboard.module';
import {InquiryModule} from './modules/inquiry/inquiry.module';
import * as Sentry from "@sentry/angular";
import {RetryInterceptor} from "./interceptors/retry.interceptor";
import {AuthServerProvider} from "./services/auth/auth-jwt.service";
import {BottomNavModule} from "./modules/shared/components/navigation/bottom-nav/bottom-nav.module";
import {TopNavModule} from "./modules/shared/components/navigation/top-nav/top-nav.module";
import {CommonSpinnerModule} from "./modules/shared/components/common-spinner/common-spinner.module";
import {SupportModule} from "./modules/shared/components/support/support.module";
import {CookieConsentModule} from "./modules/shared/components/cookie-consent/cookie-consent.module";
import {UnsupportedDeviceDetectorModule} from "./modules/shared/components/unsupported-device/unsupported-device-detector/unsupported-device-detector.module";
import {GoogleAnalyticsModule} from "./modules/shared/components/google-analytics/google-analytics.module";
import {InquiryLayoutModule} from "./modules/shared/components/inquiry/inquiry-layout/inquiry-layout.module";
import {UsageAgreementDialogModule} from "./modules/shared/components/usage-agreement/usage-agreement-dialog/usage-agreement-dialog.module";
import {OneTimeInquiriesDialogModule} from "./modules/shared/components/inquiry/one-time-inquiries-dialog/one-time-inquiries-dialog.module";
import {UsageAgreementDenyDialogModule} from "./modules/shared/components/usage-agreement/usage-agreement-deny-dialog/usage-agreement-deny-dialog.module";
import {ConfirmationDialogModule} from "./modules/shared/components/confirmation-dialog/confirmation-dialog.module";
import {UnsupportedDeviceModalModule} from "./modules/shared/components/unsupported-device/unsupported-device-modal/unsupported-device-modal.module";
import {UiVersionOutdatedInterceptor} from './interceptors/ui-version-outdated.interceptor';
import {ConfirmationDialogService} from './services/root/confirmation-dialog.service';
import {SupportExpertModule} from './modules/shared/components/support-expert/support-expert.module';
import { initialize } from './app-init';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {MdePopoverModule} from '@muntazir86/material-extended';
import {MomentModule} from 'ngx-moment';

@Injectable()
export class CookieExpiry extends IdleExpiry {
    private lastValue: Date = undefined;

    constructor(
        private cookieService: CookieService,
        @Inject(APP_CONFIG) public config: AppConfig
    ) {
        super();
    }

    last(value?: Date): Date {
        if (value !== void 0 && value !== undefined) {
            this.lastValue = value;
            this.cookieService.set(this.config.browserStorageTypes.idleCookie,
                this.lastValue ? this.lastValue.toUTCString() : null,
                COOKIE_EXPIRATION_DATE,
                '/',
                this.config.general_domain,
                false,
                'Lax');
        } else {
            this.lastValue = new Date(this.cookieService.get(this.config.browserStorageTypes.idleCookie));
        }

        return this.lastValue;
    }

    isExpired(): boolean {
        const expiry = this.last();
        return expiry !== undefined && expiry <= this.now();
    }
}

declare const require;


@Injectable()
@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        AppRoutingModule,
        BottomNavModule,
        TopNavModule,
        AppConfigModule,
        KeycloakAngularModule,
        ErrorModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            toastClass: 'toast',
            preventDuplicates: true,
            closeButton: true
        }),
        // NgxWebstorageModule.forRoot({prefix: '', separator: '', caseSensitive: true}),
        MomentModule,
        InquiryLayoutModule,
        UsageAgreementDialogModule,
        OneTimeInquiriesDialogModule,
        UsageAgreementDenyDialogModule,
        NgIdleKeepaliveModule.forRoot(),
        InquiryModule,
        MatMomentDateModule,
        InlineSVGModule.forRoot(),
        FinmatchDashboardModule,
        ConfirmationDialogModule,
        RecaptchaV3Module,
        MdePopoverModule,
        CommonSpinnerModule,
        SupportModule,
        SupportExpertModule,
        CookieConsentModule,
        UnsupportedDeviceDetectorModule,
        UnsupportedDeviceModalModule,
        GoogleAnalyticsModule,
        GoogleTagManagerModule.forRoot({
            id: environment.googleTagManager?.id,
            // gtm_auth: environment.googleTagManager?.gtm_auth,
            // gtm_preview: environment.googleTagManager?.gtm_preview,
        })], providers: [
        provideNgxWebstorage(withNgxWebstorageConfig({ prefix: '', separator: ':', caseSensitive: true }), withLocalStorage(), withSessionStorage()),
        CookieService,
        KeycloakService,
        { provide: DateAdapter, useValue: NativeDateAdapter },
        { provide: SessionStorageService, useClass: SessionStorageService },
        { provide: IdleExpiry, useClass: CookieExpiry },
        { provide: LocalStorageService, useClass: LocalStorageService },
        { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
        // {provide: APP_INITIALIZER, useFactory: initialize, deps: [KeycloakService, AuthServerProvider], multi: true},
        {
            provide: MAT_DATE_LOCALE,
            useFactory: locale => locale,
            deps: [LOCALE_ID]
        },
        {
            provide: TRANSLATIONS,
            useFactory: locale => {
                locale = locale || 'en';
                if (locale.startsWith('en')) {
                    return '';
                }
                return require(`raw-loader!../i18n/messages.${locale}.xlf`).default;
            },
            deps: [LOCALE_ID]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [AuthServerProvider, APP_CONFIG]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [Injector]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
            deps: [EventManagerService, ToastrService, ErrorCodesTranslationService, APP_CONFIG]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UiVersionOutdatedInterceptor,
            multi: true,
            deps: [ConfirmationDialogService, APP_CONFIG]
        },
        WINDOW_PROVIDERS,
        { provide: DateAdapter, useClass: FMDateAdapter },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
        {
            provide: ErrorHandler,
            useValue: environment.sentryDsn
                ? Sentry.createErrorHandler({ showDialog: false, logErrors: true })
                : new ErrorHandler()
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
