<ng-container *ngIf="data?.modal === 'unsupported_browser' else wrong_resolution">
    <div class="dialog-title" mat-dialog-title><i class="action-arrow material-icons">report_problem</i><span i18n>Unsupported browser</span></div>
    <div mat-dialog-content>
        <p i18n>You are currently using a browser that does not support all features of the FinMatch application. You can still use the FinMatch application, but it might occure that appearance and functionality of the application differ from your accustomed experience.</p>
        <p i18n>For an ideal user experience we recommend using one of the following browsers:</p>

        <ul>
            <li>Google Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Safari</li>
            <li>MS Edge (<span i18n="As in starting from|ab">from</span> 2021)</li>
        </ul>
    </div>
    <div mat-dialog-actions class="dialog-buttons">
        <button mat-flat-button [mat-dialog-close]="false" color="primary" i18n>Continue</button>
    </div>
</ng-container>

<ng-template #wrong_resolution>
    <div class="dialog-title" mat-dialog-title><i class="action-arrow material-icons">report_problem</i><span i18n>Unsupported device</span></div>
    <div mat-dialog-content>
        <p i18n>You are currently using a mobile device or a device with a screen size smaller than recommended. You can still use the FinMatch application, but it might happen that not all functions will be available as usual.</p>
    </div>
    <div mat-dialog-actions class="dialog-buttons">
        <button mat-button [mat-dialog-close]="true" i18n>Logout</button>
        <button mat-flat-button [mat-dialog-close]="false" color="primary" i18n>Continue</button>
    </div>
</ng-template>
