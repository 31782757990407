import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsageAgreementDialogComponent} from "./usage-agreement-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CommonSpinnerModule} from "../../common-spinner/common-spinner.module";

const dependencies = [
    MatDialogModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTooltipModule,
    CommonSpinnerModule
]

@NgModule({
    declarations: [
        UsageAgreementDialogComponent
    ],
    imports: [
        CommonModule,
        ...dependencies
    ],
    exports: [
        UsageAgreementDialogComponent
    ]
})
export class UsageAgreementDialogModule {
}
