import {FinMatchValidators} from '../../../../../../validators/finmatch-validators';
import {APP_DI_CONFIG} from '../../../../../../app-config.module';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Component, Input} from '@angular/core';

const config = APP_DI_CONFIG;
const customValidators = new FinMatchValidators(config);

export const MachineQuickModeFormGroup = {
    id: [],
    totalSalesPrice: [null, [Validators.required, customValidators.price()]],
    quantity: [null, [Validators.required, customValidators.quantity4]],
    description: [null, [Validators.required, Validators.maxLength(200)]],
};

@Component({
    selector: 'app-machine-quick-form',
    templateUrl: './machine-quick-form.component.html',
    styleUrls: ['./machine-quick-form.component.less']
})
export class MachineQuickFormComponent {
    @Input() form: UntypedFormGroup;
    @Input() readonly = false;
    @Input() currency: string;
}
