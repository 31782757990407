import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from "@sentry/browser";
import {APP_DI_CONFIG} from "./app/app-config.module";

(window as any).Sentry = Sentry;

if (environment.production) {
  enableProdMode();
}

// not needed anymore - google analytics is added through google tag manager
// document.write(`
//     <script type="text/plain" data-consent="analytics">
//         (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
//         (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
//         m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
//         })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
//         ga('create', '${isDevMode() ? 'UA-testtest' : (<any>environment).gaId}', 'auto');
//         ga('set', 'anonymizeIp', true);
//     </script>
// `);
if (environment.sentryDsn) {
     Sentry.init({
         dsn: environment.sentryDsn,
         environment: environment.envName,
         release: APP_DI_CONFIG.version,
     });
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.warn(err));
