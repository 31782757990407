import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorComponent} from './error.component';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {ErrorRoutingModule} from './error-routing.module';

const dependencies = [
    MatButtonModule,
];

@NgModule({
    declarations: [
        ErrorComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ErrorRoutingModule,
        ...dependencies
    ],
    exports: [
        ErrorComponent
    ]
})
export class ErrorModule {
}
