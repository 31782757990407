import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslationService} from '../../../../services/root/translation/translation.service';
import {UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
    selector: '[errorMessage]'
})
export class ErrorMessageDirective implements OnInit, OnDestroy {
    public isInvalid: boolean;
    @Input('errorMessage')
    private formControl: UntypedFormControl;
    private _subscription: Subscription;
    private _skipPattern: boolean;

    constructor(private element: ElementRef, private translationService: TranslationService) {
    }

    @Input()
    set skipPattern(value) {
        this._skipPattern = value !== undefined;
    }

    ngOnInit() {
        this.isInvalid = this.formControl.invalid;
        this.element.nativeElement.innerText = this.getErrorMessage();
        this._subscription = this.formControl.statusChanges.subscribe(() => {
            if (this.formControl.invalid) {
                this.element.nativeElement.innerText = this.getErrorMessage();
            }
        });
    }

    ngAfterViewChecked() {
        if (this.formControl && this.formControl.invalid !== this.isInvalid) {
            this.element.nativeElement.innerText = this.getErrorMessage();
            this.isInvalid = this.formControl.invalid;
        }
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    getErrorMessage(): string {
        switch (true) {
            case !this.formControl:
                return '';
            case this.formControl.hasError('required') || this.formControl.hasError('requiredCheckbox'):
                return this.translationService.fieldRequired;
            case !this._skipPattern && this.formControl.hasError('pattern'):
                return this.translationService.wrongFormat;
            case this.formControl.hasError('minlength'):
                const min = this.formControl.errors.minlength.requiredLength;
                return this.translationService.minLength(min);
            case this.formControl.hasError('maxlength'):
                const max = this.formControl.errors.maxlength.requiredLength;
                return this.translationService.maxLength(max);
            case this.formControl.hasError('invalidYear'):
                return this.translationService.invalidYear;
            case this.formControl.hasError('min') ||
            this.formControl.hasError('max') ||
            this.formControl.hasError('num'):
                return this.translationService.invalidNumber;
            case this.formControl.hasError('futureDate'):
                return this.translationService.futureDateError;
            case this.formControl.hasError('lessThanOrEq'):
                return this.translationService.lessThanOrEq;
            case this.formControl.hasError('greaterThanOrEq'):
                return this.translationService.greaterThanOrEq;
            case this.formControl.hasError('fixedInterestHigherThanDuration'):
                return this.translationService.fixedInterestHigherThanDuration;
            case this.formControl.hasError('allowanceForDepraciationHigherThanDuration'):
                return this.translationService.allowanceForDepraciationHigherThanDuration;
            case this.formControl.hasError('postCodeBankCodeExists'):
                return this.translationService.postCodeBankCode;
            case this.formControl.hasError('equityBiggerThanVolume'):
                return this.translationService.equityBiggerThanVolume;
            case this.formControl.hasError('disbursementBiggerThanRepayment'):
                return this.translationService.disbursementBiggerThanRepayment;
            case this.formControl.hasError('repaymentLowerThanDisbursement'):
                return this.translationService.repaymentLowerThanDisbursement;
            case this.formControl.hasError('disbursementBeforeInquiryCreation'):
                return this.translationService.disbursementBeforeInquiryCreation;
            default:
                return '';
        }
    }
}
