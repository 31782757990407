
<form class="finmatch-form" [formGroup]="form" autocomplete="off" [class.readonly]="readonly">
    <div class="form-required-information" *ngIf="!readonly" i18n>All fields with * are required</div>
    <div class="form-row">
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="quantity" i18n-placeholder placeholder="Quantity" required></app-number-input>
                <mat-error [errorMessage]="form.get('quantity')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('quantity')" i18n>Quantity</app-form-field-readonly>
        </div>
        <div class="form-field-50">
            <mat-form-field *ngIf="!readonly">
                <app-number-input formControlName="totalSalesPrice" i18n-placeholder placeholder="Total sales price (net)" required></app-number-input>
                <mat-error [errorMessage]="form.get('totalSalesPrice')"></mat-error>
                <span matSuffix>{{ currency | appCurrency }}</span>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [currency]="currency" [field]="form.get('totalSalesPrice')" i18n>Total sales price (net)</app-form-field-readonly>
        </div>
    </div>
    <div class="form-row">
        <div class="form-field-100">
            <mat-form-field *ngIf="!readonly">
                <input matInput placeholder="Description" formControlName="description" required i18n-placeholder>
                <mat-error [errorMessage]="form.get('description')"></mat-error>
            </mat-form-field>
            <app-form-field-readonly *ngIf="readonly" [field]="form.get('description')" [unwrapped]="true" i18n>Description</app-form-field-readonly>
        </div>
    </div>
</form>
