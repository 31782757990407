import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslationService} from 'src/app/services/root/translation/translation.service';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.less']
})
export class ConfirmationDialogComponent implements OnInit {
    public confirmationMessage: string;
    public confirmationHeader: string;
    public confirmationSecondaryText: string;
    public confirmationYesButton: string;
    public confirmationNoButton: string;
    public noRejectionBtn: boolean;

    constructor(public translationService: TranslationService, private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {
        this.confirmationHeader = this.data.modalHeader;
        this.confirmationSecondaryText = this.data.bottomText;
        this.confirmationMessage = this.data.confirmationMessage;
        this.confirmationYesButton = this.data.confirmationYesButton || this.translationService.confirmationYesButton;
        this.confirmationNoButton = this.data.confirmationNoButton || this.translationService.confirmationNoButton;
        this.noRejectionBtn = !!this.data.noRejectionBtn;
    }

    confirm() {
        this.dialogRef.close(true);
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
